<template>
    <div class="calenderIcs">
        <!--<pre>{{cal}}</pre>-->
        <button class="btn btnDarkBlue btnDownloadIcs" @click="save()">{{translateString("ToevoegenAanKalender")}}</button>

        <!--<a @click="remove()">Remove All Events</a>-->
    </div>
</template>

<script>
import ICS from '../../static/ics'
import {Selector} from '../language/language'
export default {
    name:"calenderIcs",
    data() {
        return {
            cal: '',
            ics: ICS.install
        }
    },
    props: {
        language: {
            type: String
        },
        subject: {
            type: String
        },
        description: {
            type: String
        },
        location: {
            type: String
        },
        begin: {
            type: String
        },
        stop: {
            type: String
        },
        name: {
            type: String
        },
        email: {
            type: String
        },
    },
    computed: {
        url() {
            return String(location.href)
        }
    },
    methods: {
        save() {
            this.ics.download(this.subject)
        },
        remove() {
            this.ics.removeAllEvents();
            this.cal = this.ics.calendar()
        },
        translateString(stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON('Kalender', stringNotTranslated)
            return translatedString;
        },
    },
    mounted() {
        //let now = new Date()
        let start = new Date(this.begin)
        let end = new Date(this.stop)
        //let until = new Date(now.getFullYear(), now.getMonth() + 2, now.getDay(), now.getHours(), now.getMinutes(), 0, 0)

        var organizer = {
            name: this.name,
            email: this.email
        }

        /**
        * @type {RRule}
        */
        /*let rrule = {
            freq: 'WEEKLY',
            until: until,
            interval: 1
        }*/
        this.ics.addEvent(this.language, this.subject, this.description, this.location, start, end, this.url, organizer)
        this.cal = this.ics.calendar()
    },
    beforeUnmount() {
        this.remove();
    }
}
</script>

<style scoped>
</style>