// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.shoppingList[data-v-37886c06] {
    position: relative;
    padding: 20px 20px 0px 20px;
}
.lijn[data-v-37886c06] {
  border-top: 1px solid lightgray;
  width: 100%;
  padding: 0;
  margin: 10px 0px 10px 0px;
}
.subtotaallijn[data-v-37886c06] {
  border-top: 1px solid gray;
  width: 100%;
  padding: 0;
  margin: 30px 0px 10px 0px;
}
.totaallijn[data-v-37886c06] {
  border-top: 1px solid black;
  width: 100%;
  padding: 0;
  margin: 30px 0px 10px 0px;
}
.totaalLabelWrapper[data-v-37886c06], .subTotaalLabelWrapper[data-v-37886c06] {
    width: 30%;
}
.labelTotaal[data-v-37886c06], .labelSubTotaal[data-v-37886c06] {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    margin-right: 10px;
}
.labelTransactiekosten[data-v-37886c06] {
    margin-right: 10px;
    color: grey;
}
.labelKosten[data-v-37886c06], .labelSubKosten[data-v-37886c06] {
    color: grey;
}
.totaalPrice[data-v-37886c06] {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
}
.subTotaalPrice[data-v-37886c06] {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
}
.transactiekostenCurrency[data-v-37886c06] {
    color: grey;
    text-align: right;
    width: 100px;
    margin-right: 21px;
    font-size: 16px;
}
.transactiekostenCurrencyRightAlign[data-v-37886c06] {
    color: grey;
    text-align: right;
    width: 100px;
    margin-right: 0px;
    font-size: 16px;
}
.transactiekostenContainer[data-v-37886c06] {
    display: flex;
    margin-top: 10px;
    line-height: 18px;
}
.totaalContainer[data-v-37886c06], .subTotaalContainer[data-v-37886c06]{
    display: flex;
}
.totaalTextWrapper[data-v-37886c06], .subTotaalTextWrapper[data-v-37886c06], .transactiekostenTextWrapper[data-v-37886c06] {
    width: 90%;
}
.totaalPrijsWrapper[data-v-37886c06] {
    text-align: right;
    width: 100px;
    margin-right: 10px;
}
.totaalPrijsWrapperWithoutRemover[data-v-37886c06] {
    text-align: right;
    width: 90px;
    margin-right: 0px;
}
.subTotaalPriceWrapper[data-v-37886c06] {
    text-align: right;
    width: 100px;
    margin-right: 21px;
}
span[data-v-37886c06]:after {
  position: relative;
  content: '✖';
  color: red;
  cursor: pointer;
}
.removerContainer[data-v-37886c06] {  
    text-align: right;
    padding-top: 2px;
}
.NoItems[data-v-37886c06] {
    text-align: center;
    margin-bottom: 90px;
}
.donationContainer[data-v-37886c06] {
    margin-top: 10px;
}
.buttonBoxWrapper[data-v-37886c06] {
    margin-top: 30px;
    position:relative;
    display: flex;
    justify-content: flex-end;
}
.buttonBoxWrapperOnlyContinueShopping[data-v-37886c06] {
    margin-top: 30px;
    position: relative;
    text-align: right;
}
.btnBestellingAfronden[data-v-37886c06] {
    margin-right: 0px;
}
.btnVerderWinkelen[data-v-37886c06] {
    margin-right: 20px;
}
.btnNuBestellen[data-v-37886c06] {
    margin-top: 0px;
    min-height: 50px;
    position: relative;
    text-align: center;
    font-size:14px;
}
.btnNuBetalen[data-v-37886c06] {
    margin-top: 0px;
    /*height: 38px;*/
    min-height: 50px;
    width:auto!important;
    min-width: 200px!important;
    position: relative;
    padding-left:40px;
    text-align: center;
    font-size:14px;
}
.imageSelectedPaymentMethod[data-v-37886c06] {
    width: 30px;
    position: absolute;
    left:10px;
    top:14px;
    border-radius: 4px;
}
.countdown[data-v-37886c06] {
    margin-top: 20px;
}
.timeIsTicking[data-v-37886c06] {
    color: red;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
