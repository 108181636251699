<template>
  <div class="TextReadMore">
    <slot class="myslot"></slot>
    <div class="readMore"><img class="readMoreImg" :src="require('../assets/chevron-down-icon.png')" /></div>
  </div>
</template>

<script>
import $ from "jquery";

export default {
  data() {
    return {
      text: "",
    };
  },

  mounted() {
    this.text = $(".TextReadMore").html();
    this.readMoreText();
  },

  methods: {
    readMoreText() {
      var $el, $p, $up;

      $(".TextReadMore .readMore .readMoreImg").click(function () {

        // image
        $el = $(".TextReadMore .readMore .readMoreImg");
        
        // div om de image (.readMore)
        $p = $el.parent();

        // .TextReadMore
        $up = $p.parent();

        $up
          .css({
            // Set height to prevent instant jumpdown when max height is removed
            height: $up.height(),
            "height": "auto",
          })
          .animate({
            height: "auto",
          });

        // remove
        $p.remove();

        // anders fadeOut
        // $p.fadeOut();
      });
    },
  },
};
</script>

<style scoped>
.TextReadMore {
    height:80px;
    position: relative;
    overflow: hidden;
}
.TextReadMore .readMore {
    height:80px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 30px;
    margin: 0;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1)60%);
}
.readMore .readMoreImg {
    margin-top: 50px;
    width: 40px;
    height: 30px;
    cursor: pointer;
}
</style>