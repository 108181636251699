// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.processPayment[data-v-24550dd1] {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255);
    overflow-y: scroll;
}
.headerProcessPayment[data-v-24550dd1] {
    background-color: #005b8c;
    width: 100%;
    min-height: 350px; 
    position: relative;
    box-sizing: border-box;
    padding-top: 70px;
}
.contentWrapper[data-v-24550dd1] {
    display: inline-block;
    vertical-align: middle;
    margin-top: -280px;
}
.title[data-v-24550dd1] {
    color: rgb(70, 70, 70);
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    width:100%;
    max-width:1000px;
    text-align: center;    
    margin:auto;
    margin-top: 35px;
    margin-bottom:15px;
    font-size: 40px;
}
.contentContainer[data-v-24550dd1] {
    margin-top: 60px;
    min-width: 1000px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    min-height: 400px;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
    padding: 45px 45px 45px 45px;
    overflow-x: hidden;
}
.text[data-v-24550dd1] {
      color: rgb(80, 80, 80);
      font-weight: bold;
      font-family: 'Didact Gothic', sans-serif; 
      font-size: 25px;
      max-width:1000px;
      text-align: center;  
      white-space: nowrap;
      overflow: hidden;
      position: relative;
      margin-bottom: 35px;
}
.loading[data-v-24550dd1] {
    display: inline;
    overflow: hidden;
    width: 120px;
    margin-top: 50px;
    margin-left: 20px;
}
.loadingWrapper[data-v-24550dd1] {
    width: 225px;
    height: 225px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    border: 5px solid #1aa8e2;
    margin: 0 auto;
}
@media(max-width: 1120px) {
.contentContainer[data-v-24550dd1] {
        width: 90%;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
