// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.orderResult[data-v-7073a341] {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255);
    overflow-y: scroll;
}
.headerOrderResult[data-v-7073a341] {
    background-color: #005b8c;
    width: 100%;
    min-height: 350px; 
    position: relative;
    box-sizing: border-box;
    padding-top: 70px;
    padding-bottom:240px;
}
.contentWrapper[data-v-7073a341] {
    display: inline-block;
    vertical-align: middle;
    margin-top: -280px;
}
.contentContainer[data-v-7073a341] {
    margin-top: 60px;
    width:1000px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    min-height: 400px;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
    padding: 45px 45px 45px 45px;
    overflow-x: hidden;
}
.title[data-v-7073a341] {
    color: rgb(70, 70, 70);
    font-weight: bold;
    font-family: 'Nunito', sans-serif; 
    width:100%;
    max-width:1000px;
    text-align: center;    
    margin:auto;
    margin-top: 35px;
    margin-bottom:15px;
    font-size: 44px;
}
.error[data-v-7073a341] {
    color: rgb(70, 70, 70);
    font-weight: bold;
    font-family: 'Nunito', sans-serif; 
    width:100%;
    max-width:1000px;
    text-align: center;    
    margin:auto;
    margin-top: 18px;
    margin-bottom:15px;
    font-size: 18px;
}
.text[data-v-7073a341] {
    color: rgb(80, 80, 80);
    font-family: 'Didact Gothic', sans-serif; 
    font-size: 18px;
    max-width:1000px;
    text-align: center;  
    position: relative;
    margin-bottom: 35px;
}
.note[data-v-7073a341] {
    color: rgb(80, 80, 80);
    font-family: 'Didact Gothic', sans-serif; 
    font-size: 14px;
    max-width:1000px;
    text-align: center;  
    position: relative;
    margin-bottom: 35px;
}
.tip[data-v-7073a341] {
    color: rgb(80, 80, 80);
    font-family: 'Didact Gothic', sans-serif; 
    font-size: 14px;
    max-width:1000px;
    text-align: center;  
    position: relative;
}
.gif[data-v-7073a341] {
    display: inline;
    margin-left: -15%;
    height: 100%;
    width: auto;
    overflow: hidden;
}
.gifWrapper[data-v-7073a341] {
    width: 225px;
    height: 225px;
    position: relative;
    overflow: hidden;
    border-radius: 50%; 
    margin: 0px auto;
}
.gif-overlay1[data-v-7073a341] {
    position: absolute;
    left: -370px;
    top: -4px;
    width: 100%;
    z-index: 1;
    height: 150px;
}
.gif-overlay2[data-v-7073a341] {
    position: absolute;
    right: -370px;
    top: -4px;
    width: 100%;
    z-index: 1;
    height: 150px;
}
.gif-overlay3[data-v-7073a341] {
    position: absolute;
    height: 120px;
    width: 100%;
    left: 0%;
    top: -10px;
    z-index: 1;
}
.borderSuccess[data-v-7073a341] {
    border: 10px solid lightgreen;
}
.borderError[data-v-7073a341] {
    border: 10px solid red;
}
.borderDoubt[data-v-7073a341] {
    border: 10px solid orange;
}
.btnSluiten[data-v-7073a341] {
    margin: 0 auto;
    margin-top: 35px;
}
.shopTitleAuto[data-v-7073a341] {
  color: white;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
  width:100%;
  max-width:1000px;
  text-align: center;
  margin:auto;
  margin-bottom:20px;
}
@media(max-width: 1120px) {
.contentContainer[data-v-7073a341] {
        width: 90%;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
