import { error } from "jquery";
import {store} from "../store";
import {Selector} from '../language/language.js';
import router from '../router'

export const Functions = {

    getShopID: function() {

        var x = window.location.host;
        var shopID = 0;

        // Indien host niet gevonden dan error
        if (x ==null || x == undefined) {
            return shopID;
        }

        // Lokaal, dan handmatig de juiste shop invullen om te testen.
        if (x.includes('localhost')) {

                //var x = "cascom.ticketview.shop";



                //shopID = 'tickets';
                //shopID = '4402'; // deze gaat fout??
                // shopID = '2415'; // dorpshuizen laarbeek
                 //   shopID = 'tejaterke';
                //    shopID = 'cascom';
                shopID = 'keiebijters';
                //    shopID = 0;
                 // shopID = 'tickets';
                // shopID = 'dewieger';
                // shopID = 'dewieger';
                //shopID = 'vierbinden';
                //shopID = 4425; // harmonie maar 1 evennt
                // shopID = 4407; // Aarlerixtel
            // shopID = 4380; TicketSwap
            //shopID = 4307; // Circus met rangen
            //shopID = 3758; // Traverse / beer and beefs
            // shopID = 'stichtingoktoberfestaarlerixtel'; //
             shopID = 'cascom'; // cascom

        } else {

            var testPathArray = x.split('.');
            if (testPathArray.length >= 2) {
                shopID = testPathArray[0];
            }
        }

        return shopID;
    },

    getBrowserLanguage: function() {

        var a = navigator.languages;

        var arrayLanguage = a.toString().split(",");
        var lang = this.defineLanguage(arrayLanguage);
        if(lang == null) {
          lang = 'en-EN'
        } 

        //var hulp = new String(lang);

        //lang = hulp.substr(0,2)+"-"+hulp.substr(3,2).toUpperCase();
        return lang;
    },

    defineLanguage: function(prefLangs) {
        for (var i = 0; i < prefLangs.length; i++) {

          var hulp = prefLangs[i].substr(0,2);

          // Correctie ....
          if (hulp=='nl') { return 'nl-NL'; }
          if (hulp=='be') { return 'nl-NL'; }
          if (hulp=='fr') { return 'fr-FR'; }
          if (hulp=='en') { return 'en-EN'; }
          if (hulp=='de') { return 'de-DE'; }
          if (hulp=='pl') { return 'pl-PL'; }
          if (hulp=='es') { return 'es-ES'; }

        }
        return 'en-EN';
    },

    formatDateTime: function(date, lengthWeekday, lengthMonth, returnDate, returnTime, lengthYear = "short") {


        // de date converteren zodat deze kan worden gebruikt in elk browser
        var convertedYear = Number(date.substr(0,4));
        var convertedMonth = Number(date.substr(5,2));
        var convertedDay = Number(date.substr(8,2));
        var convertedHour = Number(date.substr(11,2));
        var convertedMinute = Number(date.substr(14, 2));

        // de date samenstellen
        var date = new Date(convertedYear, convertedMonth-1, convertedDay, convertedHour, convertedMinute, 0, 0);

        var fullDate;


        if(typeof date === 'undefined') {
            return fullDate = null;
        }

        var dateNumber = String(date.getDate());
        var dateYear = String(date.getFullYear());
        var dateHour = String(date.getHours());
        var dateMinute = String(date.getMinutes());

        var weekday;
        if(lengthWeekday == "short") {weekday = String(date.toLocaleString(String(store.state.localeLanguage.substr(0, 5)), { weekday: "short"}));}
        if(lengthWeekday == "long") {weekday = String(date.toLocaleString(String(store.state.localeLanguage.substr(0, 5)), { weekday: "long"}));}
        
        var dateMonth;
        if(lengthMonth == "short") {dateMonth = String(date.toLocaleString(String(store.state.localeLanguage.substr(0, 5)), { month: "short"}));}
        if(lengthMonth == "long") {dateMonth = String(date.toLocaleString(String(store.state.localeLanguage.substr(0, 5)), { month: "long"}));}    


        if(lengthYear == "short") {dateYear = "'" + dateYear.substr(2,2); }

        var dayAndMonth;
        if(store.state.localeLanguage == "en-US") {
            dayAndMonth = String(dateMonth)+" "+String(dateNumber);  
        } else {
            dayAndMonth = String(dateNumber)+" "+String(dateMonth); 
        }

        if(dateHour.length == 1) {
            dateHour = dateHour.padStart(2, '0');
        }

        if(dateMinute.length == 1) {
            dateMinute = dateMinute.padStart(2, '0');
            //alert("TODO bug eruit halen, padding min werken niet! " + dateMinute);
        }

        var sep = " ";
        if (lengthWeekday=="short") {
            sep = ". ";
        }


        // kijk of datum, tijd of allebei teruggegeven moeten worden
        if(returnDate && returnTime) {
            fullDate = weekday+sep+dayAndMonth+" "+dateYear+" "+dateHour+":"+dateMinute;  
        }
        else if(returnDate && !returnTime) {
            fullDate = weekday+sep+dayAndMonth+" "+dateYear; 
        }
        else if(!returnDate && returnTime) {
            fullDate = dateHour+":"+dateMinute; 
        }

        var dateToReturn = fullDate.replace('..', '.');
        
        return dateToReturn;
    },

    formatMyCurrency: function(price) {
        var formattedPrice = new Intl.NumberFormat(store.state.localeLanguage.substr(0, 5), { style: 'currency', currency: 'EUR' }).format(price);
        return formattedPrice
    }, 

    setErrorMessage: function(error1, error2, errorTimerMilliseconds) {
        // als errorTimerMilliseconds null of undefined is, blijft de error staan want is geen timer
        store.dispatch('setError', { error1: error1, error2: error2, errorTimerMilliseconds: errorTimerMilliseconds});
    },
    removeError: function() {
        store.dispatch('removeError');
    },

    setWarningMessage: function(warning1, warning2, warningTimerMilliseconds) {
        store.dispatch('setWarning', { warning1: warning1, warning2: warning2, warningTimerMilliseconds: warningTimerMilliseconds});
    },
    removeWarning: function() {
        store.dispatch('removeWarning');
    },

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },
    getFieldLayoutWithOverrule(layout,field,ticketType,cart) {
        var ret = layout[field];

        if (layout.tickettypes != null) {

            for (const [key, value] of Object.entries(layout.tickettypes)) {
                if (Functions.isTicketTypeInCart(key,cart)) {
                    if (ticketType==0 || ticketType==key) {

                        if (field=='mode' && value[field]>ret) {
                            // indien 'mode' dan de hoogste nemen (verplicht?) en dus alle tickettypes doorlopen.  
                            ret = value[field];
                        } else if (field=='textlines' && value[field]>ret) {
                            // indien 'textlines' dan de hoogste nemen (verplicht?) en dus alle tickettypes doorlopen.  
                            ret = value[field];
                        } else {
                            ret = value[field];
                            break;
                        }
                    }
                }
            }
        }
        return ret;
    },

    hasPersonalFieldsFromTicketType(customerFieldsLayout,ticketTypeID) {
        var personal = false;

            for (const [keyLayout, layout] of Object.entries(customerFieldsLayout)) {
                    
                    if (layout.personal==1) {
                        personal = true;
                        continue;
                    }

                    for (const [keyTT, valueTT] of Object.entries(layout.tickettypes)) {
                        if (keyTT==ticketTypeID) {
                            personal = true;
                            continue;
                        }
                    }

                    if (personal) {
                        continue;
                    }
            }
        return personal;

    },
    capitalizeFirstLetter: function(string) {
        if (string==null) { return string; }
        if (string=="" || string.toString().length<=1) { return string; }
        return string.charAt(0).toUpperCase() + string.slice(1);
    },
    isTicketTypeInCart : function(ticketTypeID,cart) {
        
        if (cart==null) {return false; }
        var ret = false;

        cart.bestelItems.forEach(function(bestelItem){
            if (bestelItem['ticketTypeID']==ticketTypeID) {
                ret = true;
                return; // soort break ....
            }
            
        });

        return ret;
    },
    isDateTimeEmpty(datum) {
        if (datum === null) { return true; }
        if (datum === "") { return true; }
        if (datum === "0000-00-00 00:00:00") { return true; }
        if (datum === "00-00-0000 00:00:00") { return true; }
        return false;
    },
    isValidDate(datum) {
        var dt = new Date(datum);
        return dt.getTime(datum) === dt.getTime(datum); // bij een fout datum geeft hij NaN terug, en dat is nooit gelijk
    },    
    getAge(birth,checkAgainst) {
        var ageMS = Date.parse(checkAgainst) - Date.parse(birth);
        var age = new Date();
        age.setTime(ageMS);
        var ageYear = age.getFullYear() - 1970;
        // ageMonth = age.getMonth(); // Accurate calculation of the month part of the age
        // ageDay = age.getDate();    // Approximate calculation of the day part of the age
      
        return ageYear;      
      },
    dateToTVDate(date) {
        return  date.getFullYear().toString() +  "-" +
                (date.getMonth()+1).toString().padStart(2,"0") + "-" + 
                date.getDate().toString().padStart(2,"0");
    },
    TVDateToDutch(date) {
        var dd = new Date(date);
        return  dd.getDate().toString().padStart(2,"0") +  "-" +
                (dd.getMonth()+1).toString().padStart(2,"0") + "-" + 
                dd.getFullYear().toString();

    },
    validateField(waarde,orderField,fieldType,required,minvalue,maxvalue) {


        var error = "";
            
        if (required==1) {
            if (waarde==NaN || waarde==null || waarde.toString() == "") {

                error = Selector.SelectLanguageStringFromLangJSON("Checkout","Required");
            } else if (fieldType=="date" && waarde==0) {
                error = Selector.SelectLanguageStringFromLangJSON("Checkout","Required");
            }
        }
        
        if (error=="" && fieldType=="date") {

            if (!this.isValidDate(waarde)) {
                error = Selector.SelectLanguageStringFromLangJSON("Checkout","InvalidDate");
                return;
            }

            // zoeken datum waarop geselecterd moet worden .... (vandaag OF -indien persoonlijk- de datum van het event).
                // rekening houden met periode?? Indien periode datum aanvang n0g neit geweest, dan die datum aanhouden; anders de datum van vandaag...

            var checkDate = new Date();

            if (orderField=='visitorAge') {

                var age = Functions.getAge(waarde,Functions.dateToTVDate(checkDate));
                if (minvalue!="" && age < minvalue) {
                    
                    error = Selector.SelectLanguageStringFromLangJSONWithParams("Checkout","MinAge",{
                        MinAge: minvalue
                    });
                }
                if (error=="" && maxvalue!="" && age > maxvalue) {
                    error = Selector.SelectLanguageStringFromLangJSONWithParams("Checkout","MaxAge",{
                        MaxAge: maxvalue
                    });

                }
                
            } else {
                if (minvalue!="" && waarde < minvalue) {
                    error = Selector.SelectLanguageStringFromLangJSONWithParams("Checkout","MinDate",{
                        MinDate: maxvalue
                    });
                }
                if (error=="" && maxvalue!="" && waarde > maxvalue) {
                    error = Selector.SelectLanguageStringFromLangJSONWithParams("Checkout","MaxDate",{
                        MaxDate: maxvalue
                    });
                }

            }

        }

        if (error=="" && fieldType=="num") {
            if (isNaN(waarde)) {
                error = Selector.SelectLanguageStringFromLangJSON("Checkout","InvalidNumber");
            }
            if (minvalue!="" && parseInt(waarde) < parseInt(minvalue)) {
                error = Selector.SelectLanguageStringFromLangJSONWithParams("Checkout","MinNumber",{
                    MinNumber: minvalue
                });

            }
            if (error=="" && maxvalue!="" && parseInt(waarde) > parseInt(maxvalue)) {
                error = Selector.SelectLanguageStringFromLangJSONWithParams("Checkout","MaxNumber",{
                    MaxNumber: maxvalue
                });

            }
        }

        if (error=="" && fieldType=="select") {

            // kijken of er eentje gekozen is
            if (waarde!=null && waarde.length>0) {
                var items = waarde.split(';');
                var gev = false;    
                for(var tel=0; tel<items.length; tel++) {
                    if (items[tel]==waarde) {
                        gev = true;
                    }
                }
                if (!gev) {
                    error = "Maak een keuze";
                }
            }
        }

        if (error=="" && (orderField=="visitorEmail" || orderField=="confirmationEmail")) {
            if (!this.validateEmail(waarde)) {
                error = Selector.SelectLanguageStringFromLangJSON("Checkout","EmailIsNietCorrect");
            }
        }

        return error;


    },
    finishOrder() {
                    
            var accId = store.state.cart.bestelItems[0].accountId;
            store.dispatch('startPayment', accId).then((response) => {
                if (response==undefined || response==false) {


                } else {
                    Functions.redirPage('/orderresult/'+response);
                }

            })

    },
    getEventFromID(eventID) {
        for (const [keyEvent, event] of Object.entries(store.state.events.events.items)) {
            if (eventID==event.ID) {
                return event;
            }
        }
        return null;
    },
    getTicketTypeFromID(ticketTypeID) {
        var ret=null;

        for (const [keyEvent, event] of Object.entries(store.state.events.events.items)) {
            for (const [keyTT, tickettype] of Object.entries(event.ticketTypes.items)) {
                if (tickettype.ID==ticketTypeID) {
                    ret = tickettype;
                    return ret;
                }
            }
        }
        return ret;
    },
    setTicketTypeSoldOut(eventID,ticketTypeID) {

        var hasAllSoldOut = true;

        // Zet tickettype op uitverkocht.
        for (const [keyEvent, event] of Object.entries(store.state.events.events.items)) {
            for (const [keyTT, tickettype] of Object.entries(event.ticketTypes.items)) {
                if (tickettype.ID==ticketTypeID) {
                    tickettype.ticketsAvailableForSale = 0;
                    tickettype.ttSoldOut = 1;
                }

                if (event.ID==eventID && tickettype.ttSoldOut==0 && tickettype.ttVisible>0) {
                    hasAllSoldOut = false;
                }
            }
        }

        if (hasAllSoldOut) {
            for (const [keyEvent, event] of Object.entries(store.state.events.events.items)) {
                if (event.ID == eventID) {
                    event.evSoldOut = 1;
                }
            }
        }


    },
    dateTimeJavascriptToNormal(javaDate) {
        var TVDate = "";
        var d = javaDate,
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear(),
            hh = d.getHours(),
            mm = d.getMinutes();

        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
        if (hh.length < 2) 
            hh = '0' + hh;
        if (mm.length < 2) 
            mm = '0' + mm;

        TVDate = [year, month, day].join('-');
        TVDate = TVDate+ ' '+hh+":"+mm+":00";

        return TVDate;

    },
    dateTimeFromTicketType(tt,lengthWeekday, lengthMonth, returnDate, returnTime, lengthYear = "short") {
        var ret = "";
        var geldigheid = false;

        /*
        0 = aanvang
        1 = aanvang - eindtijd
        2 = alleen eindtijd
        3 = niks
        */

        // bereken start datum; validFromOption 0 of 2 is de datum in het ttValidFrtom veld. 1 is de aankoopdatum (nu dus)
        var start = tt.ttValidFrom; 
        if (tt.ttValidFromOption==1) {
            start = this.dateTimeJavascriptToNormal(new Date());
        }


        // bereken einddatum, validUntilOption 0 en 2, is de datum in het ttvalidUntil veld. 1 = periode na aankoop; 3= periode na starten stream.
        var einde = tt.ttValidUntil;

        if (tt.ttValidUntilOption==1) { // periode na aankoop
            var today = new Date();
            if (tt.ttValidUntilInterval=="hours") {
                today.setTime(today.getTime() + (tt.ttValidUntilValue * 60 * 60 * 1000)); 
            }
            if (tt.ttValidUntilInterval=="days") {
                today.setTime(today.getTime() + (tt.ttValidUntilValue * 60 * 60 * 1000 * 24)); 
            }
            if (tt.ttValidUntilInterval=="weeks") {
                today.setTime(today.getTime() + (tt.ttValidUntilValue * 60 * 60 * 1000 * 24 * 7)); 
            }
            if (tt.ttValidUntilInterval=="months") {
                today.setMonth(today.getMonth()+parseInt(tt.ttValidUntilValue));
            }
            if (tt.ttValidUntilInterval=="years") {
                today.setFullYear(today.getFullYear() + parseInt(tt.ttValidUntilValue));
            }
            einde = this.dateTimeJavascriptToNormal(today);
        }

        if (tt.ttValidUntilOption==3) { // periode na aankoop
            if (tt.ttValidUntilInterval=="hours") {
                einde = Selector.SelectLanguageStringFromLangJSONWithParams("Checkout","ticketGeldigheidUren",{
                    Hours: parseInt(tt.ttValidUntilValue)
                });
            }
            if (tt.ttValidUntilInterval=="days") {
                einde = Selector.SelectLanguageStringFromLangJSONWithParams("Checkout","ticketGeldigheidDagen",{
                    Days: parseInt(tt.ttValidUntilValue)
                });
            }
            if (tt.ttValidUntilInterval=="weeks") {
                einde = Selector.SelectLanguageStringFromLangJSONWithParams("Checkout","ticketGeldigheidWeken",{
                    Weeks: parseInt(tt.ttValidUntilValue)
                });
            }
            if (tt.ttValidUntilInterval=="months") {
                einde = Selector.SelectLanguageStringFromLangJSONWithParams("Checkout","ticketGeldigheidMaanden",{
                    Months: parseInt(tt.ttValidUntilValue)
                });
            }
            if (tt.ttValidUntilInterval=="years") {
                einde = Selector.SelectLanguageStringFromLangJSONWithParams("Checkout","ticketGeldigheidJaren",{
                    Years: parseInt(tt.ttValidUntilValue)
                });
            }
            geldigheid = true;
        }    

        if (!geldigheid) {
            if (tt.ttShowDates==0) {
                ret = this.formatDateTime(start,lengthWeekday,lengthMonth,returnDate,returnTime,lengthYear);
            } else if (tt.ttShowDates==1) {
                ret = this.formatDateTime(start,lengthWeekday,lengthMonth,returnDate,returnTime,lengthYear) + 
                        " - " + 
                        this.formatDateTime(einde,lengthWeekday,lengthMonth,returnDate,returnTime,lengthYear);
            } else if (tt.ttShowDates==2) {
                ret = "t/m" + " " +this.formatDateTime(einde,lengthWeekday,lengthMonth,returnDate,returnTime,lengthYear);
            } else {
                ret = "";
            }
        }

        if (geldigheid) {
            if (tt.ttShowDates==0) {
                ret = this.formatDateTime(start,lengthWeekday,lengthMonth,returnDate,returnTime,lengthYear);
            } else if (tt.ttShowDates==1) {
                ret = this.formatDateTime(start,lengthWeekday,lengthMonth,returnDate,returnTime,lengthYear) + 
                        " - " + 
                        einde;
            } else if (tt.ttShowDates==2) {
                ret = "t/m" + " " +einde;
            } else {
                ret = "";
            }
        }

        return ret;

    },

    checkIfTicketTypeIsVisible(ttVisible, dateFrom, dateFromOption, dateUntil, dateUntilOption) {
        // als ttVisible 0 is, dan laat niet het ticketType zien
        if(ttVisible == 0) { 
            return 0;
        }

        // pak datum van vandaag
        var today = new Date();
        var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
        var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        var dateTimeToday = date+' '+time;

        // split de drie dates
        var splittedDateTimeToday = dateTimeToday.split(/[^0-9]/);
        var splittedDateFrom = dateFrom.split(/[^0-9]/);
        var splittedDateUntil = dateUntil.split(/[^0-9]/);

        // zet de gesplitte stukjes om in een dateformat dat gebruikt kan worden in elke browser
        var newDateToday = new Date(splittedDateTimeToday[0],splittedDateTimeToday[1]-1,splittedDateTimeToday[2],splittedDateTimeToday[3],splittedDateTimeToday[4],splittedDateTimeToday[5] );
        var newDateFrom = new Date(splittedDateFrom[0],splittedDateFrom[1]-1,splittedDateFrom[2],splittedDateFrom[3],splittedDateFrom[4],splittedDateFrom[5] );
        var newDateUntil = new Date(splittedDateUntil[0],splittedDateUntil[1]-1,splittedDateUntil[2],splittedDateUntil[3],splittedDateUntil[4],splittedDateUntil[5] );

        // als de datum en tijd binnen de salesdatums vallen
        // dan is er niks aan de hand en mag de prijs gewoon op het scherm komen
        // dus geef null terug
        if(newDateToday.getTime() >= newDateFrom.getTime() && newDateToday.getTime() <= newDateUntil.getTime()) {
            return;
        }   

        if(newDateToday.getTime() < newDateFrom.getTime()) {
            if(dateFromOption == 0) { 
                return 0;
            }   
        }
        else if(newDateToday.getTime() > newDateUntil.getTime()) {
            if(dateUntilOption == 0) {
                return 0;
            }

        }
    },

    isShopOpen() {
        var date = new Date();
        // format date asl 0000-00-00 00:00:00 met de dag en maan als 2 cijfers
        var formattedDate = date.getFullYear().toString() + "-" + (date.getMonth() + 1).toString().padStart(2, '0') + "-" + date.getDate().toString().padStart(2, '0') + " " + date.getHours().toString().padStart(2, '0') + ":" + date.getMinutes().toString().padStart(2, '0') + ":" + date.getSeconds().toString().padStart(2, '0');

        if (store.state.status.shopStatus == "OPEN" ||
            (store.state.status.shopStatus == "CLOSED" && store.state.status.shopStatusReopen>="2020-01-01 00:00:00" &&  store.state.status.shopStatusReopen < formattedDate)) {
            return true;
        } else {
            return false;
        }
    },
    isShopClosed() {
        return !this.isShopOpen();
    },

    redirPage: function(page) {
        if (store.state.shopNumber!="0") {
            page = "/shop-"+store.state.shopNumber+page;
        }
        router.push(page);
    },

    getShopNumberFromURL: function(path)  {
        var parts = path.split("/");
        var shopNumber = "";
        for (var i = 0; i < parts.length; i++) {
            // Als de string met /shop begint, dan is dat het shopNumber
            if (parts[i].includes("shop-")) {

                // Verwijder shop
                var temp = parts[i].replace("shop-", "");

                // Kijk of er e '-' teken inzit. Zoja dan is het eerste deel het shopNumber en het tweede deel het shopTrackerID
                if (temp.includes("-")) {
                    var tempParts = temp.split("-");
                    shopNumber = tempParts[0];
                    //shopTrackerID = tempParts[1];
                } else {
                    shopNumber = temp;
                }
            }
        }
        return shopNumber;
    },

    validateSelectedEvent() {
        var event = store.state.selectedEvent;
        if (event == null) {
            // Pak eerste ID de cart
            if (store.state.cart.bestelItems.length > 0) {
                var ID = store.state.cart.bestelItems[0].ID;
            } else {
                // eerste van events
                if (store.state.events.events.items.length > 0) {
                    var ID = store.state.events.events.items[0].ID;
                }
            }
            // Loop events af en pak de gevonden
            for (const [key, value] of Object.entries(store.state.events.events.items)) {
                if (value.ID == ID) {
                    store.dispatch('setSelectedEvent', value);
                    break;
                }
            }
        }
    }


}