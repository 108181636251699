// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.seatingscreen[data-v-6c94b386]{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    scale: 1;
    background: white;
    padding: 2%;
    margin: 5px;
    z-index: 3;
    font-size: 13px;
}
.stoelenplan_wrapper[data-v-6c94b386]{
    top: 2%;
    position:relative;
    align-self: center;
    text-align: center;
    background: black;
    width: 100%;
    height: 85%;
    overflow-y: auto;
    overflow-x: auto;
    left: 50%;
    transform: translate(-50%);
    border-radius: 10px;
}
.stoelenplan[data-v-6c94b386]{
    top: 5%;
    position: relative;
    align-self: center;
    text-align: center;
    background: transparent;
    width: 80%;
    height: 90%;
    transform-origin: top center;
    left: 20%;
}
.seat[data-v-6c94b386]{
    background: white;
    text-align: center;
    position: absolute;
    border-radius: 0em 0em 0.3em 0.3em;
    padding-top:0.0em;
    width: 1.3em;
    height: 1.3em;
    box-sizing: border-box;
    font-family:Arial, Helvetica, sans-serif;
    cursor: pointer;
}
.sold[data-v-6c94b386]{
    background: crimson;
    cursor: default;
}
.blocked[data-v-6c94b386]{
    background: gray;
    cursor: default;
}
.reserved[data-v-6c94b386]{
    background: orange;
    cursor: default;
}
.selected[data-v-6c94b386]{
    background: green;
}
.blok[data-v-6c94b386]{
    background: #555555;
    position: absolute;
    padding-top: 0.7em;
    text-align: center;
}
.label[data-v-6c94b386]{
    color: aliceblue;
    position: absolute;
}
.line[data-v-6c94b386]{
    background: black;
    position: absolute;
}
.zoomwraper[data-v-6c94b386]{
    position: relative;
    text-align: center;
    align-content: center;
    left: 50%;
    top: 10px;
    transform: translate(-50%);
    color: white;
}
.zoombnt[data-v-6c94b386]{
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}
.buttons[data-v-6c94b386]{
    position: relative;
    left: 50%;
    top: 20px;
    font-size: 20px;
    transform: translate(-50%);
}
.seatingplanLoading[data-v-6c94b386] {
    text-align: center;
}
.seatingplanLoading img[data-v-6c94b386] {
    margin-top:50px;
    width: 100px;
    height: auto;
}
.seatingplanLoading label[data-v-6c94b386] {
    font-size: 15px;
    font-weight: bold;
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
