// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.paymentForm[data-v-71f8cf50]{
    position: relative;
    left: 0;
    text-align: left;
}
.paymentFormHeader[data-v-71f8cf50] {
    min-height: 100px;
    width: 100%;
    padding: 20px 20px 20px 20px;
    position: relative;
    background-color: whitesmoke;
    border-top-left-radius: 10px;
}
.paymentFormTitle[data-v-71f8cf50] {
    font-weight: bold;
    font-size: 28px;  
    font-family: 'Nunito', sans-serif;
    max-width: 550px;
    color: rgb(65, 65, 65);
}
.paymentFormBody[data-v-71f8cf50] {
    padding: 20px 20px 20px 20px;
    font-family: 'Didact Gothic', sans-serif;
}
.paymentMethodListWrapper[data-v-71f8cf50] {
    margin-top: 30px;
    flex-shrink: 0;
    flex-grow: 1;
}
.paymentMethodList[data-v-71f8cf50] {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, auto));
    max-width:auto;
    margin-top:0px;
    justify-items: flex-start;
    align-items: center;
}
.btnVerderWinkelen[data-v-71f8cf50] {
    margin-left: 10px;
}
.buttonWrapper[data-v-71f8cf50] {
    display: flex;
}
.btnNuBetalen > img[data-v-71f8cf50] {
    position: absolute;
    height:auto;
    left:10px;
    top:10px;
}
.btnNuBetalen[data-v-71f8cf50] {
    position:relative;
    margin-left:auto;
    min-width: auto!important;
    width:auto;
    padding-left:50px;
    padding-top:8px;
    padding-right:20px;
    height:45px;
}
.btnOverzichtCntr[data-v-71f8cf50] {
  display: flex;
  justify-content: center; /* Centreer de button horizontaal */
  align-items: center; /* Centreer de button verticaal (optioneel) */
  height: 100%; /* Zorg ervoor dat de container de volledige hoogte inneemt */
}
.btnOverzicht[data-v-71f8cf50] {
  display: flex;
  justify-content: center; /* Centreer de inhoud van de button */
  align-items: center; /* Centreer de inhoud van de button verticaal */
  background-color: green;
  padding: 10px 20px;
  border: none;
  color: white;
  font-weight: bold;
  cursor: pointer;
}
.lijn[data-v-71f8cf50] {
  border-top: 1px solid lightgray;
  width: 100%;
  padding: 0;
  margin: 30px 0px 30px 0px;
}
.nextStepBtn[data-v-71f8cf50] {
  margin-left:auto;
}
.image[data-v-71f8cf50] {
    width: 100px;
    height: 100px;
    border: 1px solid green;
}
@media(max-width: 1120px) {
.paymentFormHeader[data-v-71f8cf50] {
        border-top-right-radius: 10px;
}
.paymentFormBody[data-v-71f8cf50] {
        padding: 20px 20px 20px 20px;
}
}
@media(max-width: 450px) {
.stepBackBtn[data-v-71f8cf50] {
        margin-right: 10px;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
