<template>
    <div>
        <div>
            <div class="floatLabelNormal" v-bind:class="myPlaceholder()">                
                <input :value="modelValue" ref="myInput"  @input="updateChange" @blur="updateChange" :placeholder="myHintForMobile()" />
                <span @click="focusInput" class="hint">{{ hint }}</span>
                <strong v-if="error !== ''">{{ translateString(error) }}</strong>
            </div>
        </div>


    </div>
</template>

<script>

import {Functions} from '@/util/util.js';
import {Selector} from '@/language/language.js';
import {store} from '@/store/index';

export default {
    name: 'InputField2',
    setup() {
        return {
            state: store.state
        }
    },
    data() {
        return {
            visited: false,
            error: ""
        }   
    },
    props: {
        modelValue: String,
        required: { type: Boolean, required: true },
        fieldType: { type: String, required: true },
        hint: { type: String, required: false },
        revalidate: {type: String, default : "0" }

    },
    computed: {
    },
    methods: {
      translateString(stringNotTranslated) {
        var translatedString = Selector.SelectLanguageStringFromLangJSON('Checkout', stringNotTranslated)
        return translatedString;
      },
      updateChange(event) {
            this.visited = true;
            const newValue = event.target.value;
            this.validate(newValue);
            this.$emit('update:modelValue',event.target.value);
        },
        focusInput() {
          this.visited = true;
          this.validate(this.modelValue);
          this.$refs.myInput.focus();
        },
        myHintForMobile() {
          return this.hint;
        },
        myPlaceholder() {
            var gevuld = false;
            if (this.modelValue!="") {
                gevuld = true;
            }

            var ret = "";
            if (gevuld) {
                ret = "floatLabelSmall";
            }
            if (this.required) {
                if (ret!=="") { ret += " "; }
                ret = ret + "required";
            }
            if (this.visited && this.required && (!gevuld || this.error!="")) {
                if (ret!=="") { ret += " "; }
                ret = ret + "invalidValue";
            }

            return ret;
        },
        validate(waarde) {

          if (waarde==undefined) { // als hij van de change event op de input afkomt...
            waarde = ""
          }

          this.error = "";

          if (this.required==1) {
            if (waarde==NaN || waarde==null || waarde.toString() == "") {
              //this.error = Selector.SelectLanguageStringFromLangJSON("Checkout","Required");
              this.error = "Required"
            }
          }

          if (this.fieldType=="email" || this.fieldType=="confirmationemail") {
            if (waarde!="" && !Functions.validateEmail(waarde)) {
              this.error = "EmailIsNietCorrect"
            }
          }

          if (this.fieldType=="confirmationemail") {
            if (waarde.toUpperCase() != store.state.customerFields.visitorEmail.toUpperCase()) {
              this.error = "EmailEnBevestigEmailZijnNietGelijk"

            }
          }




        }

    },
    mounted() {

        if (this.modelValue!=NaN && this.modelValue!=null) {
            this.validate(this.modelValue); // zet eventuele fouten ...
        }

    },
    watch: {
      revalidate: function(){
        this.validate(this.modelValue);
      },
      modelValue(newValue) {
          // Force update the local input value when the parent updates the modelValue
          if (newValue!=undefined) {
          //this.$refs.myInput.value = newValue;
            this.validate(newValue)
          }
        }
    }
}
</script>

<style>

input::placeholder {
  color: transparent;
}

.floatLabelNormal, .floatLabelSelect {
    position: relative;
    margin-bottom:10px;
}
.floatLabelNormal > span {
    position: absolute;
    font-size:14px;
    color:black;
  color:gray;

  left:10px;
    top:10px;
    transition: all .3s cubic-bezier(0.000, 0.205, 0.035, 1.570);

}
.floatLabelNormal > input, .floatLabelSelect > select, .floatLabelNormal > textarea {
    font-size: 14px;
    width: 100%;
    height: 45px;
    outline: none;
    border:1px lightgray solid;
    padding-left:10px;
    padding-top:0px;
    border-radius:4px;
}
.floatLabelNormal > textarea {
    height:auto;
}

.floatLabelSmall > span, .floatLabelNormal > input:focus + .hint, .floatLabelNormal > textarea:focus + .hint {
    font-size: 9px;
    top:5px;
    color:gray;
}

.floatLabelSmall > input, .floatLabelNormal > input:focus {
    padding-top: 12px;
}  
.floatLabelNormal > textarea, .floatLabelNormal > textarea:focus {
    padding-top: 20px;
}
.floatLabelNormal strong, .floatLabelSelect strong {
    position: absolute;
    font-size: 9px;
    top:3px;
    right:10px;
    color:red;
    font-weight: normal;
    font-style: italic;
}

.floatLabelSelect > select {
    padding-left:10px;
    padding-top: 12px;
}

.floatLabelSelect > span {
    position: absolute;
    font-size: 9px;
    top:5px;
    left:10px;
    color:gray;
}


.allCapsUpper > input {
    text-transform: uppercase;
}

.allCapsLower > input {
    text-transform: lowercase;
}

/* datums */
.customFormDate {
  border:1px lightgray solid;
  border-radius: 4px;
  height:45px;
  padding-left:10px;
  padding-top: 15px;
  display: flex;
}
.customFormDate > span {
    font-size: 9px;
    color: rgb(53, 53, 53);
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 10px;
    padding-top:8px;
}

.mySelect {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;       /* Remove default arrow */
    -ms-word-break: normal;
    word-break: normal;
    background-color: #ffffff;
    background-image: none;

    border:1px solid lightgray;
    padding-left:10px;
}

.customFormDate > select {

    border:1px dotted lightgray;
    font-size: 12px;
    margin:0;
    padding:0px 10px;
    height:20px;
    margin-top:5px;
    margin-right:10px;
    border-radius: 0px;
}

.customFormDate select::-ms-expand {
  display: none;
}

/*
.required > i {
    color:rgb(77, 77, 77);
    position: absolute;
    right:10px;
    top:0px;
    font-size:24px;
}
*/

.invalidValue > input, .invalidValue > select, .invalidValue > textarea {
    border-color:red;
    border-width:2px;
}
.invalidValue > i {
    color:red;
}   

.required > i > span {
    position: absolute;
    display: none;
}
.invalidValue > i > span {
    position: absolute;
    display:block;
    font-size:24px;
    top:3px;
    right:14px;
    font-size: 12px;
}

/* Media query voor mibile telefoons */
@media only screen and (max-width: 767px) and (orientation: portrait) {


  input {
    font-size: 16px!important;
  }

  input::placeholder {
    color: gray;
  }

  /* Jouw CSS regels hier */
  .floatLabelNormal span {
    display: none;
  }

  .floatLabelSmall span {
    display: none;
  }

  .required > i > span {
    display: none;
  }

  .invalidValue > i > span {
    display: none;
  }

  .floatLabelSmall > input, .floatLabelNormal > input:focus {
    padding-top: 0!important;
    padding-bottom: 0!important;
    font-size: 16px!important;
  }
}

</style>
