<template>
  <div class="countdown">
    <div class="countdown__container">
      <svg class="countdown__svg" viewBox="0 0 100 100">
        <g class="countdown__circle">
          <circle
            class="countdown__path-elapsed"
            cx="50"
            cy="50"
            r="45"
          ></circle>
          <path
            :stroke-dasharray="circleDasharrayDays"
            class="countdown__path-remaining"
            d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
            "
          ></path>
        </g>
      </svg>
      <div class="countdown__time">{{ days }}</div>
      <div class="countdown__label">{{getStringTimeFromJSON('dagen')}}</div>
    </div>

    <div class="countdown__container">
      <svg class="countdown__svg" viewBox="0 0 100 100">
        <g class="countdown__circle">
          <circle
            class="countdown__path-elapsed"
            cx="50"
            cy="50"
            r="45"
          ></circle>
          <path
            :stroke-dasharray="circleDasharrayHours"
            class="countdown__path-remaining"
            d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
                "
          ></path>
        </g>
      </svg>
      <div class="countdown__time">{{ hours }}</div>
      <div class="countdown__label">{{getStringTimeFromJSON('uren')}}</div>
    </div>

    <div class="countdown__container">
      <svg class="countdown__svg" viewBox="0 0 100 100">
        <g class="countdown__circle">
          <circle
            class="countdown__path-elapsed"
            cx="50"
            cy="50"
            r="45"
          ></circle>
          <path
            :stroke-dasharray="circleDasharrayMinutes"
            class="countdown__path-remaining"
            d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
            "
          ></path>
        </g>
      </svg>
      <div class="countdown__time">{{ minutes }}</div>
      <div class="countdown__label">{{getStringTimeFromJSON('minuten')}}</div>
    </div>

    <div class="countdown__container">
      <svg class="countdown__svg" viewBox="0 0 100 100">
        <g class="countdown__circle">
          <circle
            class="countdown__path-elapsed"
            cx="50"
            cy="50"
            r="45"
          ></circle>
          <path
            :stroke-dasharray="circleDasharraySeconds"
            class="countdown__path-remaining"
            d="
                M 50, 50
                m -45, 0
                a 45,45 0 1,0 90,0
                a 45,45 0 1,0 -90,0
            "
          ></path>
        </g>
      </svg>
      <div class="countdown__time">{{ seconds }}</div>
      <div class="countdown__label">{{getStringTimeFromJSON('seconden')}}</div>
    </div>
  </div>
</template>

<script>
import {Selector} from '../language/language';
const FULL_DASH_ARRAY = 283;
let interval = null;
export default {
  data() {
    return {
      now: Math.trunc(new Date().getTime() / 1000),
      date: null,
      diff: 0,
    };
  },
  props: {
    deadline: {
      type: String,
    },
    end: {
      type: String,
    },
    stop: {
      type: Boolean,
    },
  },
  methods: {
    getStringTimeFromJSON(value) {
      var stringTime = Selector.SelectLanguageStringFromLangJSON('General', value)
      return stringTime;
    },
  },
  computed: {
    seconds() {
      return Math.trunc(this.diff) % 60;
    },
    minutes() {
      return Math.trunc(this.diff / 60) % 60;
    },
    hours() {
      return Math.trunc(this.diff / 60 / 60) % 24;
    },
    days() {
      return Math.trunc(this.diff / 60 / 60 / 24);
    },

    circleDasharrayDays() {
      return `${(this.timeFractionDays * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },
    circleDasharrayHours() {
      return `${(this.timeFractionHours * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },
    circleDasharrayMinutes() {
      return `${(this.timeFractionMinutes * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },
    circleDasharraySeconds() {
      return `${(this.timeFractionSeconds * FULL_DASH_ARRAY).toFixed(0)} 283`;
    },

    timeFractionDays() {
      // time left devided by time limit
      const rawTimeFraction = this.days / 365;
      return rawTimeFraction;
    },
    timeFractionHours() {
      // time left devided by time limit
      const rawTimeFraction = this.hours / 24;
      return rawTimeFraction;
    },
    timeFractionMinutes() {
      // time left devided by time limit
      const rawTimeFraction = this.minutes / 60;
      return rawTimeFraction;
    },
    timeFractionSeconds() {
      // time left devided by time limit
      const rawTimeFraction = this.seconds / 60;
      return rawTimeFraction;
    },
  },
  created() {
    if (!this.deadline && !this.end) {
      throw new Error("Missing props 'deadline' or 'end'");
    }

    let endTime = this.deadline ? this.deadline : this.end;
    this.date = Math.trunc(Date.parse(endTime.replace(/-/g, "/")) / 1000);

    if (!this.date) {
      throw new Error("Invalid props value, correct the 'deadline' or 'end'");
    }

    interval = setInterval(() => {
      this.now = Math.trunc(new Date().getTime() / 1000);
    }, 1000);
  },
  watch: {
    now(value) {
      this.diff = this.date - this.now;
      if (this.diff <= 0 || this.stop) {
        this.diff = 0;
        clearInterval(interval);
        this.$emit("end");
      }
    },
  },
  filters: {
    twoDigits(value) {
      if (value.toString().length <= 1) {
        return "0" + value.toString();
      }
      return value.toString();
    },
  },

  onUnmounted() {
    clearInterval(interval);
  },
};
</script>

<style scoped>
.countdown {
  display: table;
  margin: auto;
  margin-top: 20px;
} 
.countdown__container {
  position: relative;
  display: table-cell;
}
.countdown__svg {
  transform: scaleX(-1);
  height: 120px;
  width: 120px;
  margin-right: 20px;
}

.countdown__circle {
  fill: none;
  stroke: none;
}

.countdown__path-elapsed {
  stroke-width: 7px;
  stroke: lightgrey;
}

.countdown__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: orange;
}

.countdown__time {
  position: absolute;
  font-family: 'Nunito', sans-serif;
  top: -0.5rem;
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;
  color: rgb(110, 110, 110);
}

.countdown__label {
  position: absolute;
  top: 1.20rem;
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: 'Didact Gothic', sans-serif; 
  text-transform: uppercase;
  color: darkgray;
}

@media(max-width: 767px) {
  .countdown__svg {
    height: 10rem;
    width: 5rem;
    margin-right: 0px;
  }
  .countdown__time {
    height: 10rem;
    width: 5rem;
    font-size: 20px;
  }
  .countdown__label {
    height: 8rem;
    width: 5rem;
    font-size: 10px;
  }
}
</style>
