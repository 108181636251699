// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.Donation[data-v-504e9f72] {
    position: relative;
    color: grey;
    font-size: 12px;
}
.donationWrapper[data-v-504e9f72] {
    display: flex;
}
.donationName[data-v-504e9f72] {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}
.fromDonateMinLabel[data-v-504e9f72] {
    font-size: 12px;
    text-transform: lowercase;
    font-weight: normal;
}
.donationNameAndTextWrapper[data-v-504e9f72] {
    width: 90%;
}
input[data-v-504e9f72] {
    width: 90px;
  height: 20px;
  font-size: 14px;
  transition: 0.6s;
  border: none;
  border-bottom: 1px solid #CCC;
  background-color: transparent;
  text-align: right;
  margin-right: 10px;
}
input[data-v-504e9f72]:focus {
    outline: none;
    border-bottom: 1px solid #28a2a2;
}
.disabledInput[data-v-504e9f72] {
    margin-right: 0px;
}
.remover[data-v-504e9f72]:after {
  position: relative;
  content: '✖';
  color: red;
  cursor: pointer;
}
.removerContainer[data-v-504e9f72] {  
    text-align: right;
    padding-top: 2px;
}
.donationText[data-v-504e9f72] {
  font-style: italic;
  font-weight: bold;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
