<template>
    <div class="termsPage" v-if="state.terms != null">
        <Alert :title="alertTitle" :text="alertText" :alertType="alertType" :active="active" :time="alertTime" :okButtonActive="okButtonActive" @closeAlert="closeAlert"></Alert>
        <div v-if="!state.openCustomTerms">
          <br />
          <div class="termsTitle">{{ translateString('termsPage', "termsTitle") }}</div>
          <br />
          <br />


          <!-- twee knoppen met woorwaarden waarvan de tweede aanvullend van de organiste (indien hasPrivicy aanstaat -->
          <div style="display: flex; flex-direction: row; gap:20px;" v-if="hasPrivacy()">
            <button @click="showTV()" class="btn" :class="[showTicketViewTerms?'btnDarkBlue':'btnBlue']">TicketView</button>
            <button @click="showCustom()" class="btn" :class="[showCustomTerms?'btnDarkBlue':'btnBlue']">{{ org() }}</button>
          </div>
          <div v-if="hasPrivacy()" style="color: red">
            <br />
            <div v-html="translateString('termsPage', 'termsWarning')"></div>
          </div>


          <div v-show="showTicketViewTerms">
            <div class="termsTicketView" style="overflow-y: scroll" v-html="getTranslationFromArray(state.terms.generalTermsTicketView.items)"></div>
          </div>

          <div v-show="showCustomTerms">
            <br />
            <div class="titleOrg">{{ org() }}</div>
            <div class="titleTermsOrg"  v-html="getTranslationFromArray(state.terms.generalTermsTitleOrg.items)"></div>
            <hr />
            <div class="termsOrg" style="overflow-y: scroll" v-html="getTranslationFromArray(state.terms.generalTermsTextOrg.items)"></div>
          </div>

        </div>

        <div v-else-if="state.openCustomTerms">

            <div class="titleTicketView">
                
            </div>

            <div class="titleCustom" v-html="getStringInTheRightLanguage(state.terms.shopCustomTermsTitle.items)"></div>
            <div class="termsWrapper">
                <div class="termsCustom" v-html="getStringInTheRightLanguage(state.terms.shopCustomTermsText.items)"></div>
            </div>
        </div>

        <div class="buttonWrapper">
            <div v-if="state.step=='voorwaarden'" v-on:click="saveAnswerAgreement(false)" class="btn btnWhite notAgreedBtn">{{ translateString('termsPage', "termsNietAkkoord") }}</div>
            <div v-if="state.step=='voorwaarden'" v-on:click="saveAnswerAgreement(true)" class="btn btnBlue agreedBtn">{{ translateString('termsPage', "termsAkkoord") }}</div>   
        </div>
    </div>
    <div v-else>
        <br />
        <br />
        <br />
        <br />
        <h3 >{{ translateString('termsPage', "termsOphalen") }} ....</h3>
        <br />
        <img style="max-width: 150px;" class="calendarLoading" src="@/assets/Loader.gif" />

    </div>
</template>

<script>
import {store} from '@/store'
import {Selector} from '@/language/language.js';
import Alert from '@/components/Alert';
import {selector} from "postcss-selector-parser";
export default {
    setup() {
        return {
            state: store.state
        }
    },
    data() {
        return {
            alertTitle: '',
            alertText: '',
            alertType: '',
            active: false,
            alertTime: null,
            okButtonActive: 0,
            showTicketViewTerms: true,
            showCustomTerms: false
        }
    },
    components: {
        Alert
    },
    created() {
        if(store.state.terms == null) {
            this.alertType = '';
            this.active = true;
            this.alertText = this.translateString('Alert', 'VoorwaardenOphalen')

            store.dispatch('getTerms').then(response => {
                
                if(response != "OK") {
                    this.alertTitle = "ERROR"
                    this.alertText = this.translateString('Alert', 'VoorwaardenKondenNietOpgehaaldWorden');
                    this.alertType = 2;  
                    this.okButtonActive = 1;
                } else {
                    this.closeAlert();
                }
            }) 
        }       
    },
    methods: {
      selector,
        translateString(componentOrPageName, stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated)
            return translatedString;
        },
        org() {
            return 'Organisator, '+this.state.status.accountName;
        },
        getTranslationFromArray(arrayWithTransations) {
            var stringTranslation = Selector.SelectLanguageStringFromArray(arrayWithTransations)
            return stringTranslation;
        },
        closeAlert(close) {
            this.active = close;
            this.alertTitle = ''
            this.alertText = ''
            this.alertType = ''
            this.alertTime = null
            this.okButtonActive = 0;
        },
        saveAnswerAgreement(agreed) {            

            var customerFields = store.state.customerFields;

            if(agreed) {
                if(store.state.openCustomTerms == true) {
                    customerFields.customTerms = true;
                } else {
                    customerFields.generalTerms = true;
                }             
            } else if(!agreed) {
                if(store.state.openCustomTerms) {
                    customerFields.customTerms = false;
                } else {
                    customerFields.generalTerms = false;
                }        
            }    

            store.dispatch('saveCustomerFields', customerFields)

            this.closeTerms();
        },
        closeTerms() {
            store.dispatch('changeStep', 'klantinformatie')
        },
        hasPrivacy() {
            if (store.state.terms.generalTermsTitleOrg.items.nl!='') {
                return true;
            } else {
                return false;
            }
        },
        showTV() {
            this.showTicketViewTerms = true;
            this.showCustomTerms = false;
        },
        showCustom() {
            this.showTicketViewTerms = false;
            this.showCustomTerms = true;
        },
    }
}
</script>

<style> /* GEEN SCOPE !! */

.termsTicketView, .termsOrg, .termsCustom {
    margin-top: 10px;
    max-height: 500px;
    margin-bottom: 30px;
    background: white;
    padding: 15px 0;
}

.termsTicketView, .termsOrg, .termsCustom {
    font-size: 14px;
    color:rgb(65, 65, 65);
    padding-bottom: 40px;
}
.termsTicketView > h1,.termsOrg > h1,.termsCustom > h1 {
    font-size: 28px!important;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color:rgb(65, 65, 65);
    margin-top:0px;
}
.termsTicketView > h2,.termsOrg > h2,.termsCustom > h2 {
    font-size: 20px!important;
    color:rgb(65, 65, 65);
    margin-top:10px;;
}
.termsTicketView > h3,.termsOrg > h3,.termsCustom > h3 {
    font-size: 14px!important;
    color:rgb(65, 65, 65);
    margin-top:10px;;
}

</style>

<style scoped>

.termsPage {
    padding: 20px 20px 20px 20px;
    text-align: left;
    position: relative;
}
.termsTitle {
    color:#1aa8e2;
    font-size:24px;
}

.titleTermsOrg {
    font-size: 20px!important;
    color: rgb(65, 65, 65);
    margin-top: 10px;
    font-weight: bold;
}

.titleTicketView, .titleOrg, .titleCustom {
    font-weight: bold;
    font-size: 28px;   
    font-family: 'Nunito', sans-serif;
    color: rgb(65, 65, 65);
}

.termsWrapper {
    position: relative;
    max-height:500px;
    overflow-y: scroll;
}
.termsWrapper::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25px;
    background: linear-gradient(rgba(255, 255, 255, 0.001), white)
}

.twoTerms1 {
    max-height: 250px;
}
.twoTerms2 {
    max-height: 200px;
}


.lijn {
  border-top: 1px solid #1aa8e2;
  margin-top: 10px;
  margin-bottom: 30px;
}

.agreedBtn, .notAgreedBtn {
    text-align: center;
}

.buttonWrapper {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
}

@media(max-width: 550px) {
    .notAgreedBtn {
        margin-right: 10px;
    }   
}
</style>