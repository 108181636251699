// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.btnRight[data-v-05c25aa6] {
    margin-left:auto;
}
.ticketsPersonaliseren[data-v-05c25aa6]{
    position: relative;
    left: 0;
    text-align: left;
}
.ticketsPersonaliserenHeader[data-v-05c25aa6] {
    min-height: 100px;
    width: 100%;
    padding: 20px 20px 20px 20px;
    position: relative;
    background-color: whitesmoke;
    border-top-left-radius: 10px;
}
.ticketsPersonaliserenTitle[data-v-05c25aa6] {
    font-weight: bold;
    font-size: 28px;  
    font-family: 'Nunito', sans-serif;
    max-width: 550px;
    color: rgb(65, 65, 65);
}
.ticketsPersonaliserenBody[data-v-05c25aa6] {
    padding: 20px 20px 20px 20px;
    font-family: 'Didact Gothic', sans-serif;
}
.btnVerderWinkelen[data-v-05c25aa6] {
    margin-left: 10px;
}
.nextStepBtn[data-v-05c25aa6] {
    margin-left: 150px;
}
.rightAlignBtnDirectOrder[data-v-05c25aa6] {
    margin-left: 450px;
}
.rightAlignBtnDirectCart[data-v-05c25aa6] {
    margin-left: 300px;
}
.buttonWrapper[data-v-05c25aa6] {
    display: flex;
}
.lijn[data-v-05c25aa6] {
  border-top: 1px solid lightgray;
  width: 100%;
  padding: 0;
  margin: 30px 0px 30px 0px;
}
input:focus ~ .floating-label[data-v-05c25aa6],
 input:not(:focus):valid ~ .floating-label[data-v-05c25aa6] {
  left: -240px;
  top: -16px;
  font-size: 10px;
  opacity: 1;
  color: gray;
}
.floating-label[data-v-05c25aa6] {
  position: relative;
  pointer-events: none;
  left: -240px;
  top: -4px;
  /*transition: 0.2s ease-out all; */
  transition: all .3s cubic-bezier(0.000, 0.205, 0.035, 1.570);
}
.inputText[data-v-05c25aa6] {
  font-size: 14px;
  width: 250px;
  height: 45px;
  outline: none;
  border:1px lightgray solid;
  padding-left:8px;
  padding-top:12px;
  border-radius:4px;
}
.inputWrapper[data-v-05c25aa6]  {
  margin-bottom: 10px;
}
.inputFirstLetterCap[data-v-05c25aa6]::first-letter {
    text-transform: uppercase;
}
.textAgeWarning[data-v-05c25aa6] {
    font-size: 14px;
}
.labelTerms[data-v-05c25aa6] {
    cursor: pointer;
    display: block;
    margin-top: -23px;
    margin-left: 20px;
}
.labelLeesMeer[data-v-05c25aa6] {
    color: #2d468a;
    -webkit-text-decoration: underline #2d468a;
            text-decoration: underline #2d468a;
}
@media(max-width: 1120px) {
.ticketsPersonaliserenHeader[data-v-05c25aa6] {
        border-top-right-radius: 10px;
}
.rightAlignBtnDirectCart[data-v-05c25aa6] {
        margin-left: auto;
}
}
@media(max-width: 550px) {
.btnVerderWinkelen[data-v-05c25aa6], .stepBackBtn[data-v-05c25aa6] {
        margin-right: 10px;
        margin-left: 0px;
}
}
@media(max-width: 450px) {
.floating-label[data-v-05c25aa6] {
        position: relative;
        pointer-events: none;
        left: 10px;
        top: -32px;
        display: inline-block;
        /*transition: 0.2s ease-out all; */
        transition: all .3s cubic-bezier(0.000, 0.205, 0.035, 1.570);
}
input:focus ~ .floating-label[data-v-05c25aa6],
    input:not(:focus):valid ~ .floating-label[data-v-05c25aa6]{
        left: 10px;
        top: -46px;
        font-size: 10px;
        opacity: 1;
        color: gray;
        display: inline-block;
}
.nextStepBtn[data-v-05c25aa6] {
        margin-left: 10px;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
