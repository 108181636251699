// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.closedShop[data-v-a37a8988] {
      display: flex;
      flex-direction: column;
      height:100%;
}
.content[data-v-a37a8988] {
    flex-grow: 1;
}
.footer[data-v-a37a8988] {
    flex-shrink: 0;
}
.clientPage[data-v-a37a8988] {
    margin-top:20px!important;
}
.headerClosedShop[data-v-a37a8988] {
    background-color: #005b8c;
    width: 100%;
    min-height: 350px; 
    position: relative;
    box-sizing: border-box;
    padding-top: 70px;
}
.shopTitleAuto[data-v-a37a8988] {
    color: white;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    width:100%;
    max-width:1000px;
    text-align: center;    
    margin:auto;
    margin-bottom:20px;
}
.closedShopContainer[data-v-a37a8988] {
      max-width:1000px;
      margin-top: -180px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 60px;
      font-size: 15px;
      background-color:white;
      border-radius: 10px;
      box-shadow: 2px 2px 2px 2px grey;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
      position: relative;
      padding: 35px 1px 35px 1px;
}
.accountName[data-v-a37a8988] {
      color: white;
      font-weight: bold;
      font-family: 'Nunito', sans-serif;
      width:100%;
      max-width:1000px;
      text-align: center;    
      margin:auto;
      white-space: nowrap;
      overflow: hidden;
}
.closedShopTitle[data-v-a37a8988] {
      display: flex;
      justify-content: center;
}
.lockWrapper[data-v-a37a8988] {
      padding-top: 30px;
      margin-right: 10px;
}
.lock[data-v-a37a8988] {
      background: gray;
      border-radius: 3px;
      width: 25px;
      height: 20px;
      position: relative;
}
.lock[data-v-a37a8988]:before {
      content: "";
      position: absolute;
      border:3px solid gray;
      top: -14px;
      left: 2.3px;
      width: 15px;
      height: 27px;
      border-radius: 35px 35px 0 0;
}
.shopStatusText[data-v-a37a8988] {
      font-size: 20px;
      color: rgb(80, 80, 80);
      font-family: 'Didact Gothic', sans-serif;
      padding-left: 30px;
      padding-right: 30px;
      max-width: 800px;
      margin: auto;
}
.shopOpentOp[data-v-a37a8988] {
      color: rgb(80, 80, 80);
      font-weight: bold;
      font-family: 'Didact Gothic', sans-serif; 
      font-size: 20px;
      margin-top: 35px;
      max-width:1000px;
      text-align: center;  
      white-space: nowrap;
      overflow: hidden;
      position: relative;
}
.closedShopValidationCodeContainer[data-v-a37a8988] {
      font-size: 14px;
      margin-top: 35px;
      color: rgb(82, 82, 82);
      font-family: 'Didact Gothic', sans-serif;
}
.LabelCodeReceived[data-v-a37a8988] {
  color: rgb(82, 82, 82);
  margin-top: 10px;
  font-size: 16px;
}
.LabelClickHere[data-v-a37a8988] {
  cursor: pointer;
  color: #005b8c;
  text-decoration: underline;
}
.heeftMoeiteMetWachten[data-v-a37a8988] {
      margin-top: 35px;
      font-size: 14px;
      color: grey;
}
.wachtRegel1Wrapper[data-v-a37a8988] {
      font-family: 'Didact Gothic', sans-serif;
}
.jeKuntBijvoorbeeld[data-v-a37a8988] {
      margin-right: 5px;
      color: grey;
}
.wachtRegel2Wrapper[data-v-a37a8988] {
      display: flex;
      justify-content: center;
      font-family: 'Didact Gothic', sans-serif;
}
.labelOf[data-v-a37a8988] {
      margin-left: 5px;
      margin-right: 5px;
      color: grey;
}
@media(max-width: 1100px) {
.closedShopContainer[data-v-a37a8988]{
      max-width:80%;
      padding: 35px 20px 35px 20px;
}
}
@media(max-width: 767px) {
.closedShopContainer[data-v-a37a8988]{
      font-size: 12px;
}
.shopOpentOp[data-v-a37a8988] {
      font-size: 16px;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
