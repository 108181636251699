<template xmlns="http://www.w3.org/1999/html">
    <div class="customerForm" v-if="state.isLoaded">
        <Alert :title="alertTitle" :text="alertText" :alertType="alertType" :active="alertActive" :time="alertTime" :okButtonActive="okButtonActive" @closeAlert="closeAlert"></Alert>
        <Alert :title="confirmTitle" :text="confirmText" :alertType="confirmType" :active="confirmActive" :time="confirmTime" :okButtonActive="okButtonActive" @confirmAnswer="onAfrondenBestelling" @closeAlert="closeAlert"></Alert>

      <div class="customerFormHeader">
            <Flag v-once></Flag>
            <div class="customerFormTitle">{{translateString('Checkout', 'JouwGegevens')}}</div>
        </div>
        <div class="customerFormBody">
            <div class="buttonWrapper">
                <!-- <button v-if="state.popUpOpenByShoppingCartInfo && !state.isDirectOrder && !state.isDirectCart" class="btn btnWhite stepBackBtn" v-on:click="closePopUp">{{translateString('Checkout', 'Sluiten')}}</button>
                <button v-else-if="!state.isDirectOrder" class="btn btnWhite stepBackBtn" v-on:click="goBackToStepBestellen"><font-awesome-icon icon="caret-left" /> {{translateString('Checkout', 'Vorige')}}</button>
                <button v-if="!state.isDirectOrder && !state.isDirectCart" class="btn btnWhite btnVerderWinkelen" v-on:
                click="closePopUp()">{{translateString('Checkout', 'VerderWinkelen')}}</button>  -->

                <button v-if="!state.isDirectOrder && !state.popUpOpenByShoppingCartInfo" class="btn btnWhite stepBackBtn" v-on:click="goBackToStepBestellen"><font-awesome-icon icon="caret-left" /> {{translateString('Checkout', 'Vorige')}}</button>
                <button v-if="!state.isDirectOrder && state.popUpOpenByShoppingCartInfo" class="btn btnWhite btnVerderWinkelen" v-on:click="closePopUp()">{{translateString('Checkout', 'VerderWinkelen')}}</button>


                <button v-if="nextPayment" v-bind:class="buttonNextClass" v-on:click="goToNextStep">{{translateString('Checkout', 'Volgende')}} <font-awesome-icon icon="caret-right" /></button>
                <button v-if="nextAfronden" class="btn btnGreen nextStepBtn rightAlignBtnDirectOrder" v-on:click="goToNextStep">{{translateString('Checkout', 'BestellingAfronden')}} <font-awesome-icon icon="caret-right" /></button>
                <button v-if="nextBesteloverzicht" class="btn btnGreen nextStepBtn rightAlignBtnDirectOrder" v-on:click="goToNexBesteloverzicht">{{translateString('Checkout', 'Besteloverzicht')}} <font-awesome-icon icon="caret-right" /></button>

            </div>

            <div class="lijn"></div>

            <div style="max-width: 600px;">

              <div class="blockContainerTitle">{{translateString('Checkout', 'JouwGegevens')}}</div>

              <div style="display: flex; flex-direction: row; gap:20px;">
                <InputField2 style="width:25%"
                     ref="visitorFirstName"
                    v-model="customerFields.visitorFirstName"
                    v-bind="{ fieldType: 'text', maxlength: 80, required: true, hint: translateString('Checkout', 'hintVoornaam') }"
                    @change="saveCustomerFields(false)"
                />
                  <InputField2
                      ref="visitorLastName"
                      v-model="customerFields.visitorLastName"
                      v-bind="{ fieldType: 'text', maxlength: 80, required: true, hint: translateString('Checkout', 'hintAchternaam') }"
                      @change="saveCustomerFields(false)"
                      style="flex: 1"
                  />
              </div>

              <InputField2
                  ref="visitorEmail"
                  v-model="customerFields.visitorEmail"
                  v-bind="{ fieldType: 'email', maxlength: 80, required: true, hint: translateString('Checkout', 'hintUwEmail') }"
                  @change="saveCustomerFields(true)"
              />

              <InputField2
                  ref="confirmEmail"
                  v-model="customerFields.confirmEmail"
                  v-bind="{ fieldType: 'confirmationemail', maxlength: 80, required: true, hint: translateString('Checkout', 'hintBevestigUwEmail') }"
                  @change="saveCustomerFields(true)"
                  v-bind:revalidate="revalidate"
              />


              <div v-if="hasPhone()">
                  <InputField2
                      ref="visitorPhone"
                      v-model="customerFields.visitorPhone"
                      v-bind="{ fieldType: 'text', maxlength: 80, required: this.getfieldPropertie('shopHasPhone','mode') === 1, hint: translateString('Checkout', 'hintTelefoonnummer') }"
                      @change="saveCustomerFields(false)"
                  />
              </div>


              <div v-if="state.status.allowInvoices==1" style="margin-bottom:15px; margin-top: 35px;">
                  <label>
                    <input @change="saveCustomerFields(false)" type="radio" value="particulier" v-model="customerFields.visitorType"> {{translateString('Checkout', 'Particulier')}}
                  </label>
                  <label>&nbsp;&nbsp;/&nbsp;&nbsp;</label>
                  <label>
                    <input @change="saveCustomerFields(false)" type="radio" value="zakelijk" v-model="customerFields.visitorType"> {{translateString('Checkout', 'Zakelijk (factuur)')}}
                  </label>
              </div>


              <div v-show="customerFields.visitorType === 'zakelijk'">
                <div style="margin-top: 30px;" class="blockContainerTitle">{{translateString('Checkout', 'kopBedrijfsGegevens')}}</div>

                <InputField2
                    ref="visitorCompany"
                  v-model="customerFields.visitorCompany"
                  v-bind="{ fieldType: 'text', maxlength: 80, required: true, hint: translateString('Checkout', 'hintBedrijfsnaam') }"
                  @change="saveCustomerFields(false)"
                />



                <InputField2
                    ref="visitorVatNumber"
                    v-model="customerFields.visitorVatNumber"
                    v-bind="{ fieldType: 'text', maxlength: 80, required: false, hint: translateString('Checkout', 'hintBTWNummer') }"
                    @change="saveCustomerFields(false)"
                />


                <div style="display: flex; gap:20px;">
                  <InputField2
                      ref="visitorStreet"
                    v-model="customerFields.visitorStreet"
                    v-bind="{ fieldType: 'text', maxlength: 80, required: true, hint: translateString('Checkout', 'hintStraatNaam')}"
                    @change="saveCustomerFields(false)"
                    style="flex: 1"
                  />

                  <InputField2
                      ref="visitorHouseNumber"
                      v-model="customerFields.visitorHouseNumber"
                      v-bind="{ fieldType: 'text', maxlength: 80, required: true, hint: translateString('Checkout', 'hintNr.')}"
                      @change="saveCustomerFields(false)"
                      style="max-width: 100px;"
                  />

                </div>

                <div style="display: flex; gap:20px;">

                    <InputField2
                        ref="visitorZipcode"
                        v-model="customerFields.visitorZipcode"
                        v-bind="{ fieldType: 'text', maxlength: 80, required: true, hint: translateString('Checkout', 'hintPostcode')}"
                        @change="saveCustomerFields(false)"
                        style="max-width: 150px;"
                    />

                  <InputField2
                      ref="visitorCity"
                      v-model="customerFields.visitorCity"
                      v-bind="{ fieldType: 'text', maxlength: 80, required: true, hint: translateString('Checkout', 'hintPlaats')}"
                      @change="saveCustomerFields(false)"
                      style="flex: 1"
                  />



                </div>

                <div class="select-container" style="width: 100%; margin-bottom: 10px;">
                  <select v-model="customerFields.visitorCountryCode" @change="saveCustomerFields(false)">
                    <option v-for="(country, index) in countries" :key="index" :value="country.code">
                      {{ translateStringCountry(country.code) }}
                    </option>
                  </select>
                  <span class="hint" style="position:absolute; left:10px; top:4px; color:gray; font-size:9px;">Land ...</span>
                </div>

              </div>



            <div v-show="customerFields.visitorType === 'particulier'">

                <div style="margin-top: 30px;" class="blockContainerTitle">{{translateString('Checkout', 'OverigeGegevens')}}</div>

                <div v-if="hasOrganisation()">
                  <InputField2
                      ref="visitorCompany2"
                      v-model="customerFields.visitorCompany"
                      v-bind="{ fieldType: 'text', maxlength: 80, required: getfieldPropertie('shopHasOrganisation','mode') === 1, hint: translateString('Checkout', 'hintBedrijfsnaam') }"
                      @change="saveCustomerFields(false)"
                  />
                </div>


                <div v-if="hasAddress()" style="display: flex; gap:20px;">
                  <InputField2
                      ref="visitorStreet2"
                      v-model="customerFields.visitorStreet"
                      v-bind="{ fieldType: 'text', maxlength: 80, required: getfieldPropertie('shopHasAddress','mode') === 1, hint: translateString('Checkout', 'hintStraatNaam')}"
                      @change="saveCustomerFields(false)"
                      style="flex: 1"
                  />

                  <InputField2
                      ref="visitorHouseNumber2"
                      v-model="customerFields.visitorHouseNumber"
                      v-bind="{ fieldType: 'text', maxlength: 10, required: getfieldPropertie('shopHasAddress','mode') === 1, hint: translateString('Checkout', 'hintNr.')}"
                      @change="saveCustomerFields(false)"
                      style="max-width: 100px;"
                  />

                </div>


                <div style="display: flex; gap:20px;">
                  <InputField2
                      ref="visitorZipcode2"
                      v-if="hasZipcode()"
                      v-model="customerFields.visitorZipcode"
                      v-bind="{ fieldType: 'text', maxlength: 20, required: getfieldPropertie('shopHasZipcode','mode') === 1, hint: translateString('Checkout','hintPostcode')}"
                      @change="saveCustomerFields(false)"
                      style="max-width: 150px;"
                  />

                  <InputField2
                      ref="visitorCity2"
                      v-if="hasCity()"
                      v-model="customerFields.visitorCity"
                      v-bind="{ fieldType: 'text', maxlength: 20, required: getfieldPropertie('shopHasCity','mode') === 1, hint: translateString('Checkout','hintPlaats')}"
                      @change="saveCustomerFields(false)"
                      style="flex: 1"
                  />
                </div>


                <div v-if="hasCity() && hasAddress() && hasZipcode()" class="select-container" style="width: 100%; margin-bottom: 10px;">
                  <select v-model="customerFields.visitorCountryCode" @change="saveCustomerFields(false)">
                    <option v-for="(country, index) in countries" :key="index" :value="country.code">
                      {{ translateStringCountry(country.code) }}
                    </option>
                  </select>
                  <span class="hint" style="position:absolute; left:10px; top:4px; color:gray; font-size:9px;">Land ...</span>
                </div>



            </div>

        </div>
            <br />
            <div v-if="hasNewsLetter()" class="newsLetterWrapper" style="height: 30px;">
                 <input type="checkbox" id="visitorNewsLetter" ref="visitorNewsLetter" v-model="customerFields.visitorNewsLetter" v-on:change="saveCustomerFields(false)">
                 <label class="labelTerms" for="visitorNewsLetter">{{translateString('Checkout', 'NewsLetter')}}</label>
            </div>
            <div class="termsWrapper">
                <input type="checkbox" id="checkbox" v-model="customerFields.generalTerms" v-on:change="saveCustomerFields(false)">
                <label class="labelTerms" for="checkbox">{{translateString('Checkout', 'IkGaAkkoordMetDeGebruiksvoorwaardenEnHetPrivacybeleidVanTicketView')}} <a class="labelLeesMeer" v-on:click="openTerms(false)">{{translateString('Checkout', 'Lees')}}</a></label><br>

                <input v-if="state.shopCustomTerms == '1'" type="checkbox" id="checkedBoxCustomTerms" v-model="customerFields.customTerms" v-on:change="saveCustomerFields(false)">
                <label class="labelTerms" v-if="state.shopCustomTerms == 1" for="checkedBoxCustomTerms">{{state.shopCustomTermsTitle}} <a class="labelLeesMeer" v-on:click="openTerms(true)">{{translateString('Checkout', 'LeesMeer')}}</a></label><br>
            </div>
        </div>        
    </div>
</template>

<script>
import Flag from '../../Flag.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {store} from '@/store/index';
import {Selector} from '@/language/language';
import {Functions} from '@/util/util';
import Alert from '@/components/Alert';
import InputField from '../components/InputField.vue';
import InputField2 from "@/components/Checkout/components/InputField2.vue";
import Lang from "@/language/lang.json";
import router from "@/router/index.js";



export default {
    name: 'CustomerForm',
    data() {
        return {
            state: store.state,
            customerFieldsLayout: store.state.customerFieldsLayout, 
            customerFields: store.state.customerFields,    
            alertTitle: '',
            alertText: '',
            alertType: '',
            alertTime: null,
            alertActive: false,
            okButtonActive: 0,

            confirmTitle: '',
            confirmText: '',
            confirmType: '',
            confirmTime: null,
            confirmActive: false,



            revalidate : 0,
            setFocusAfterErrorMsg : "",

            countries: [
              { code: 'AL', name: 'Albania' },
              { code: 'AD', name: 'Andorra' },
              { code: 'AM', name: 'Armenia' },
              { code: 'AT', name: 'Austria' },
              { code: 'AZ', name: 'Azerbaijan' },
              { code: 'BY', name: 'Belarus' },
              { code: 'BE', name: 'Belgium' },
              { code: 'BA', name: 'Bosnia and Herzegovina' },
              { code: 'BG', name: 'Bulgaria' },
              { code: 'HR', name: 'Croatia' },
              { code: 'CY', name: 'Cyprus' },
              { code: 'CZ', name: 'Czech Republic' },
              { code: 'DK', name: 'Denmark' },
              { code: 'EE', name: 'Estonia' },
              { code: 'FI', name: 'Finland' },
              { code: 'FR', name: 'France' },
              { code: 'GE', name: 'Georgia' },
              { code: 'DE', name: 'Germany' },
              { code: 'GR', name: 'Greece' },
              { code: 'HU', name: 'Hungary' },
              { code: 'IS', name: 'Iceland' },
              { code: 'IE', name: 'Ireland' },
              { code: 'IT', name: 'Italy' },
              { code: 'KZ', name: 'Kazakhstan' },
              { code: 'XK', name: 'Kosovo' },
              { code: 'LV', name: 'Latvia' },
              { code: 'LI', name: 'Liechtenstein' },
              { code: 'LT', name: 'Lithuania' },
              { code: 'LU', name: 'Luxembourg' },
              { code: 'MT', name: 'Malta' },
              { code: 'MD', name: 'Moldova' },
              { code: 'MC', name: 'Monaco' },
              { code: 'ME', name: 'Montenegro' },
              { code: 'NL', name: 'Netherlands' },
              { code: 'MK', name: 'North Macedonia' },
              { code: 'NO', name: 'Norway' },
              { code: 'PL', name: 'Poland' },
              { code: 'PT', name: 'Portugal' },
              { code: 'RO', name: 'Romania' },
              { code: 'RU', name: 'Russia' },
              { code: 'SM', name: 'San Marino' },
              { code: 'RS', name: 'Serbia' },
              { code: 'SK', name: 'Slovakia' },
              { code: 'SI', name: 'Slovenia' },
              { code: 'ES', name: 'Spain' },
              { code: 'SE', name: 'Sweden' },
              { code: 'CH', name: 'Switzerland' },
              { code: 'TR', name: 'Turkey' },
              { code: 'UA', name: 'Ukraine' },
              { code: 'GB', name: 'United Kingdom' },
              { code: 'VA', name: 'Vatican City' }
            ]
        }
    },
    components: {
      InputField2,
        Flag,
        Alert,
        InputField
    },
    methods: {
        getfieldPropertie(fieldName, property) {
          // Controleren of fieldName bestaat in customerFieldsLayout
          if (this.customerFieldsLayout.hasOwnProperty(fieldName)) {
            // Controleren of het property bestaat in het veld
            if (this.customerFieldsLayout[fieldName].hasOwnProperty(property)) {
              return this.customerFieldsLayout[fieldName][property];
            }
          }
          return "";
        },
        goBackToStepBestellen() {

            if (this.state.isMobileCart) {
              var currentStep = 'changeoverzicht';
            } else {
              var currentStep = 'bestellen';
            }
            store.dispatch('changeStep', currentStep);
        },
        hasNewsLetter() {
            return this.customerFieldsLayout.shopHasNewsLetter.mode>0;
        },
        hasOrganisation() {
            return this.customerFieldsLayout.shopHasOrganisation.mode>0;
        },
        hasPhone() {
            return this.customerFieldsLayout.shopHasPhone.mode>0;
        },
        hasAddress() {
            return this.customerFieldsLayout.shopHasAddress.mode>0;
        },
        hasZipcode() {
            return this.customerFieldsLayout.shopHasZipcode.mode>0;
        },
        hasCity() {
            return this.customerFieldsLayout.shopHasCity.mode>0;
        },
        validateField(waarde,mode,errorMsg) {
          var error = "";
          if (mode===1) {
            if (waarde==NaN || waarde==null || waarde.toString() == "") {
              error = Selector.SelectLanguageStringFromLangJSON("Checkout",errorMsg);
            }
          }
          return error;
        },

        validateOneField(field,orderField,overRuleRequired,value,errorMessage) {
            var error = "";
            var fieldLayout = this.customerFieldsLayout[field];
            var mode = Functions.getFieldLayoutWithOverrule(fieldLayout,"mode",0,this.state.cart);
            var layout = Functions.getFieldLayoutWithOverrule(fieldLayout,"layout",0,this.state.cart);
            var minvalue = Functions.getFieldLayoutWithOverrule(fieldLayout,"minvalue",0,this.state.cart);
            var maxvalue = Functions.getFieldLayoutWithOverrule(fieldLayout,"maxvalue",0,this.state.cart);

            if (overRuleRequired==1) { mode = 2; }

            error = Functions.validateField(value,
                                orderField,
                                layout,
                                ( (mode==2)?1:0 ),
                                minvalue,
                                maxvalue); 

            if (error!="") { error = Selector.SelectLanguageStringFromLangJSON("Checkout",errorMessage)+": " + error};

            return error;
        },        
        validateForm() {
            
            var error = "";
            var layout = null;
            var ret = false;
            var mode = 0;
            var dataType = "";
            var minValue = "";
            var maxValue = "";

            var errorTXT = Selector.SelectLanguageStringFromLangJSON("Checkout","Error")

            // Naam, email altijd verplicht
            error = "";
            if (error === "") { error = this.validateField(this.customerFields.visitorFirstName,1,"customerNameRequired"); this.setFocusAfterErrorMsg="visitorFirstName"; }
            if (error === "") { error = this.validateField(this.customerFields.visitorLastName,1,"customerNameRequired");  this.setFocusAfterErrorMsg="visitorLastName"; }
            if (error === "") { error = this.validateField(this.customerFields.visitorEmail,1,"customerEmailRequired");  this.setFocusAfterErrorMsg="visitorEmail";  }
            if (error === "") { error = this.validateField(this.customerFields.confirmEmail,1,"customerEmailRequired");  this.setFocusAfterErrorMsg="confirmEmail";  }

            // Formaat controleren
            if (error ==="") { if (!Functions.validateEmail(this.customerFields.visitorEmail)) { error = Selector.SelectLanguageStringFromLangJSON("Checkout","EmailIsNietCorrect"); this.setFocusAfterErrorMsg="visitorEmail"; } }
            if (error ==="") { if (!Functions.validateEmail(this.customerFields.confirmEmail)) { error = Selector.SelectLanguageStringFromLangJSON("Checkout","EmailIsNietCorrect"); this.setFocusAfterErrorMsg="confirmEmail"; }  }

            // Emails gelijk?
            if (error === "") { if (this.customerFields.visitorEmail.toLowerCase().trim() != this.customerFields.confirmEmail.toLowerCase().trim()) { error = Selector.SelectLanguageStringFromLangJSON("Checkout","EmailEnBevestigEmailZijnNietGelijk"); this.setFocusAfterErrorMsg="confirmEmail";   }}

            // Overige velden (indien aangezet) controleren
            if (error === "" && this.hasPhone()) { error = this.validateField(this.customerFields.visitorPhone,this.customerFieldsLayout.shopHasPhone.mode,"customerPhoneRequired"); this.setFocusAfterErrorMsg="visitorPhone"; }

            // Indien zakelijk dan alle adresvelden (behavle BTWnummer) verplicht
            if (this.customerFields.visitorType === "zakelijk") {

              if (error === "") { error = this.validateField(this.customerFields.visitorCompany,1,"customerCompanyNameRequired");  this.setFocusAfterErrorMsg="visitorCompany"; }
              // BTW altijd optioneel.
              if (error === "") { error = this.validateField(this.customerFields.visitorStreet,1,"customerStreetRequired");  this.setFocusAfterErrorMsg="visitorStreet"; }
              if (error === "") { error = this.validateField(this.customerFields.visitorHouseNumber,1,"customerHouseNumberRequired"); this.setFocusAfterErrorMsg="visitorHouseNumber"; }
              if (error === "") { error = this.validateField(this.customerFields.visitorZipcode,1,"customerZipcodeRequired"); this.setFocusAfterErrorMsg="visitorZipcode"; }
              if (error === "") { error = this.validateField(this.customerFields.visitorCity,1,"customerCityRequired"); this.setFocusAfterErrorMsg="visitorCity"; }

            } else {

              if (error === "" && this.hasOrganisation()) { error = this.validateField(this.customerFields.visitorCompany,this.customerFieldsLayout.shopHasOrganisation.mode,"customerCompanyNameRequired");this.setFocusAfterErrorMsg="visitorCompany2"; }
              if (error === "" && this.hasAddress()) { error = this.validateField(this.customerFields.visitorStreet,this.customerFieldsLayout.shopHasAddress.mode,"customerStreetRequired"); this.setFocusAfterErrorMsg="visitorStreet2"; }
              if (error === "" && this.hasAddress()) { error = this.validateField(this.customerFields.visitorHouseNumber,this.customerFieldsLayout.shopHasAddress.mode,"customerHouseNumberRequired"); this.setFocusAfterErrorMsg="visitorHouseNumber2"; }
              if (error === "" && this.hasZipcode()) { error = this.validateField(this.customerFields.visitorZipcode,this.customerFieldsLayout.shopHasZipcode.mode,"customerZipcodeRequired"); this.setFocusAfterErrorMsg="visitorZipcode2"; }
              if (error === "" && this.hasCity()) { error = this.validateField(this.customerFields.visitorCity,this.customerFieldsLayout.shopHasCity.mode,"customerCityRequired"); this.setFocusAfterErrorMsg="visitorCity2"; }

            }


          // indien Zakelijk dan het hele adres controleren
            if (error === "" && this.customerFields.visitorType === "zakelijk") {
                error = this.validateField(this.customerFields.visitorCompany,2,"customerCompanyNameRequired");
                if (error === "") { error = this.validateField(this.customerFields.visitorStreet,2,"customerStreetRequired" ); }
                if (error === "") { error = this.validateField(this.customerFields.visitorHouseNumber,2,"customerHouseNumberRequired"); }
                if (error === "") { error = this.validateField(this.customerFields.visitorZipcode,2,"customerZipcodeRequired"); }
                if (error === "") { error = this.validateField(this.customerFields.visitorCity,2,"customerCityRequired"); }
                if (error === "") { error = this.validateField(this.customerFields.visitorCountry,2,"customerCountryRequired"); }
            } else {
                // per veld bekijken
              if (error === "" && this.hasOrganisation()) { error = this.validateField('visitorCompany',this.customerFieldsLayout.shopHasOrganisation.mode,"customerCompanyNameRequired"); }
              if (error === "" && this.hasAddress()) { error = this.validateField('visitorStreet',this.customerFieldsLayout.shopHasAddress.mode,"customerStreetRequired"); }
              if (error === "" && this.hasAddress()) { error = this.validateField('visitorHouseNumber',this.customerFieldsLayout.shopHasAddress.mode,"customerHouseNumberRequired"); }
              if (error === "" && this.hasZipcode()) { error = this.validateField('visitorZipcode',this.customerFieldsLayout.shopHasZipcode.mode,"customerZipcodeRequired"); }
              if (error === "" && this.hasCity()) { error = this.validateField('visitorCity',this.customerFieldsLayout.shopHasCity.mode,"customerCityRequired"); }

            }


            if(error=="" && store.state.customerFields.generalTerms == false) {
                error = Selector.SelectLanguageStringFromLangJSON("Alert","ErIsNietAkkoordGegaanMetDeGebruiksvoorwaardenEnPrivacybeleid");
            }
            if(error=="" && store.state.customerFields.customTerms == false && store.state.shopCustomTerms == 1) {
                error = Selector.SelectLanguageStringFromLangJSON("Alert","ErIsNietAkkoordGegaanMetDeExtraVoorwaarden");
            }
            if(error=="" && store.state.cart.bestelItems.length == 0) {
                error = Selector.SelectLanguageStringFromLangJSON("Alert",'ErZittenGeenTicketsInDeWinkelwagen');
            }



            return error;
        },
        onAfrondenBestelling(answer) {

          this.confirmActive = false;


          if (answer) {
            this.alertTitle = '';
            this.alertType = '';
            this.alertText = Selector.SelectLanguageStringFromLangJSON('Alert', 'BestellingVerwerken')
            this.alertActive = true;

            var accId = store.state.cart.bestelItems[0].accountId;
            store.dispatch('startPayment', accId).then(response => {

              if (response.status === "OK") {

                Functions.redirPage('/orderresult/OK');

              } else if (response.status === "ERROR") {


                this.alertTitle = Selector.SelectLanguageStringFromLangJSON('Alert', 'ErIsIetsMisgegaan');
                this.alertText = response.errorMsg;
                this.alertType = 2;
                this.alertActive = true;
                this.okButtonActive = 1;
                if (response.errorLevel === "SEVERE") {
                  // Op de achtergrond

                  store.dispatch('setError', this.selectedPaymentMethod);
                  store.dispatch('setError', {
                    error1: this.alertTitle,
                    error2: this.alertText,
                    errorTimerMilliseconds: 10000
                  })


                }

              }

            });
        }

        },
        goToNexBesteloverzicht() {
          if (this.state.cart.totalPrice<0) {
            return; // doe niks.
          }

          var error = "";

          error = this.validateForm();

          if (error=="") {
              store.dispatch('changeStep', 'overzicht');
          } else {
            this.showAlertError(error)
          }

        },
        goToNextStep() {

            if (this.state.cart.totalPrice<0) {
              return; // doe niks.
            }

            var error = "";
            
            error = this.validateForm();

            if (error=="") {

                if (this.state.cart.totalPrice==0) {

                  // Alert zetten om te vragen voor confirmation.
                  this.confirmTitle = Selector.SelectLanguageStringFromLangJSON('Alert', 'BevestigBestelling');
                  this.confirmText = Selector.SelectLanguageStringFromLangJSON('Alert', 'WeetJeHetZeker');
                  this.confirmType = 4;
                  this.confirmActive = true;
                  this.confirmTime = null;

                } else {
                    store.dispatch('changeStep', 'betalen');
                }
            } else {
                this.showAlertError(error)
            }
        },
        showAlertError(alertMessage) {
            this.alertTitle = this.translateString('Alert', 'Oeps');
            this.alertText = alertMessage;
            this.alertType = 2;
            this.okButtonActive = 1;
            this.alertActive = true;
        },
        closeAlert(close) {
            this.alertActive = close;
            this.alertTitle = ''
            this.alertText = ''
            this.alertType = ''
            this.alertTime = null
            this.answer = null
            this.okButtonActive = 0;

            if (this.setFocusAfterErrorMsg != "") {
                this.$refs[this.setFocusAfterErrorMsg].focusInput();
                this.setFocusAfterErrorMsg = ""; // uitzetten
            }


        },
        saveCustomerFields(revalidate) {

          var lastName = "";

          // CapitalizeFirstLetter alleen als als er geen spatie in de achternaam zir (i.v.m. voorvoegsel)
          if (this.customerFields.visitorLastName.trim().indexOf(" ") === -1) {
            lastName =  Functions.capitalizeFirstLetter(this.customerFields.visitorLastName);
          } else {
            lastName = this.customerFields.visitorLastName;
          }

          this.customerFields.visitorCompany      = Functions.capitalizeFirstLetter(this.customerFields.visitorCompany);
          this.customerFields.visitorVatNumber    = this.customerFields.visitorVatNumber.toUpperCase();
          this.customerFields.visitorFirstName    = Functions.capitalizeFirstLetter(this.customerFields.visitorFirstName);
          this.customerFields.visitorName         = this.customerFields.visitorFirstName + " " + lastName;

          this.customerFields.visitorEmail        = this.customerFields.visitorEmail.toLowerCase();
          this.customerFields.confirmEmail        = this.customerFields.confirmEmail.toLowerCase();

          this.customerFields.visitorStreet       = Functions.capitalizeFirstLetter(this.customerFields.visitorStreet);
          this.customerFields.visitorZipcode      = this.customerFields.visitorZipcode.toUpperCase().replace(" ","");

          this.customerFields.visitorCity         = Functions.capitalizeFirstLetter(this.customerFields.visitorCity);
          this.customerFields.visitorCountry      = Functions.capitalizeFirstLetter(this.customerFields.visitorCountry);

          store.dispatch('saveCustomerFields', this.customerFields)

          if (revalidate) {
              var hulp = parseInt(this.revalidate);
              hulp = hulp + 1;
              this.revalidate = hulp.toString();
          }





        },
        closePopUp() {
            const $body = document.querySelector('body');
            let scrollPosition = store.state.scrollPosition;
            $body.style.removeProperty('overflow');
            $body.style.removeProperty('position');
            $body.style.removeProperty('top');
            $body.style.removeProperty('width');
            window.scrollTo(0, scrollPosition);
            store.dispatch('closePopUp');

            // Indien via directcart op clietnpopup, dna dit window sluiten
            const message = {
              type: 'close'
            };
            // TODO
            if (document.referrer !== null && document.referrer !== undefined && document.referrer !== '') {
              window.parent.postMessage(message, document.referrer);
            }




        },
        openTerms(openCustomTerms) {
            store.dispatch('changeStep', 'voorwaarden')
            store.dispatch('changeOpenCustomTerms', openCustomTerms);
        },
        translateString(componentOrPageName, stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated)
            return translatedString;
        },
        translateStringCountry(code) {
          var json = Lang;
          var landen = json["Countries"];

          var storedLanguage = store.state.localeLanguage;
          return landen[storedLanguage][code];
        }
    },
    computed: {

        nextPayment() {
          if (this.state.cart.totalPrice>0) {
              return true;
          } else {
              return false;
          }
        },

        nextAfronden() {
          if (this.state.cart.totalPrice == 0 && !this.state.isMobileCart) {
              return true;
          } else {
              return false;
          }
        },
        nextBesteloverzicht() {
          if (this.state.cart.totalPrice == 0 && this.state.isMobileCart) {
            return true;
          } else {
            return false;
          }
        },

        buttonNextClass() {
          var cl = "btn ";
          if (this.state.cart.totalPrice>0) {
              cl += " btnGreen nextStepBtn rightAlignBtnDirectOrder";
          } else {
              cl += " btnDisabled rightAlignBtnDirectOrder";
          }
          return cl;
        }
    },
    watch: {
        revalidate: {
            handler() {
                this.customerFields = store.state.customerFields;
                this.customerFieldsLayout = store.state.customerFieldsLayout;
            },
            deep: true
        }
    },
    mounted() {

        this.customerFields = store.state.customerFields;
        this.customerFieldsLayout = store.state.customerFieldsLayout;


        // zetten land, indien land leeg is.
        if (this.customerFields.visitorCountryCode === "") {

            var storedLanguage = store.state.localeLanguage;

            if (storedLanguage === "nl-NL") {
              this.customerFields.visitorCountry = "Nederland";
              this.customerFields.visitorCountryCode = "NL";
            } else if (storedLanguage === "fr-FR") {
              this.customerFields.visitorCountry = "France";
              this.customerFields.visitorCountryCode = "FR";
            } else if (storedLanguage === "de-DE") {
              this.customerFields.visitorCountry = "Deutschland";
              this.customerFields.visitorCountryCode = "DE";
            } else if (storedLanguage === "en-GB") {
              this.customerFields.visitorCountry = "United Kingdom";
              this.customerFields.visitorCountryCode = "GB";
            } else if (storedLanguage === "en-EN") {
              this.customerFields.visitorCountry = "United Kingdom";
              this.customerFields.visitorCountryCode = "GB";
            } else if (storedLanguage === "nl-NL-br") {
              this.customerFields.visitorCountry = "Nederland";
              this.customerFields.visitorCountryCode = "NL";
            } else if (storedLanguage === "pl-PL") {
              this.customerFields.visitorCountry = "Polska";
              this.customerFields.visitorCountryCode = "PL";
            } else if (storedLanguage === "es-ES") {
              this.customerFields.visitorCountry = "España";
              this.customerFields.visitorCountryCode = "ES";
            } else {
              this.customerFields.visitorCountry = "Nederland";
              this.customerFields.visitorCountryCode = "NL";
            }

        }

    },
}
</script>

<style scoped>
.customerForm{
    position: relative;
    left: 0;
    text-align: left;
}

.customerFormHeader {
    min-height: 100px;
    width: 100%;
    padding: 20px 20px 20px 20px;
    position: relative;
    background-color: whitesmoke;
    border-top-left-radius: 10px;
} 

.customerFormTitle {
    font-weight: bold;
    font-size: 28px;  
    font-family: 'Nunito', sans-serif;
    max-width: 550px;
    color: rgb(65, 65, 65);
}

.customerFormBody {
    padding: 20px 20px 20px 20px;
    font-family: 'Didact Gothic', sans-serif; 
}
.customerFormBodyTitle {
    font-weight: bold;
    font-size: 22px;  
    font-family: 'Nunito', sans-serif;
    max-width: 550px;
    margin-bottom: 30px;
}

.blockContainer {
    margin-bottom: 30px;
    max-width:600px;
}
.blockContainerTitle {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 22px;
}

.nameAndBirthContainer {    
    margin-top: 30px;
}

.rightAlignBtnDirectOrder {
  margin-left: 450px;
}


/*
.btnVerderWinkelen {
    margin-left: 10px;
}
.nextStepBtn {
    margin-left: 150px;
}
.rightAlignBtnDirectOrder {
    margin-left: 450px;
}
.rightAlignBtnDirectCart {
    margin-left: 300px;
}
*/

.buttonWrapper {
    display: flex;
}
.stepBackBtn {
  margin-right:15px  

}
.btnVerderWinkelen {
  margin-right:15px  

}
.nextStepBtn {
    margin-left: auto; 
}


.lijn {
  border-top: 1px solid lightgray;
  width: 100%;
  padding: 0;
  margin: 30px 0px 30px 0px;
}

input:focus ~ .floating-label,
 input:not(:focus):valid ~ .floating-label {
  left: -240px;
  top: -16px;
  font-size: 10px;
  opacity: 1;
  color: gray;
}

.floating-label {
  position: relative;
  pointer-events: none;
  left: -240px;
  top: -4px;
  /*transition: 0.2s ease-out all; */
  transition: all .3s cubic-bezier(0.000, 0.205, 0.035, 1.570);
}


.inputText {
  font-size: 14px;
  width: 250px;
  height: 45px;
  outline: none;
  border:1px lightgray solid;
  padding-left:8px;
  padding-top:12px;
  border-radius:4px;
}
.inputWrapper  {
  margin-bottom: 10px;
}
.inputFirstLetterCap::first-letter {
    text-transform: uppercase;
}

.textAgeWarning {
    font-size: 14px;
}

.labelTerms {
    cursor: pointer;
    display: block;
    margin-top: -23px;
    margin-left: 20px;
}
.labelLeesMeer {
    color: #2d468a;
    text-decoration: underline #2d468a;
}


.btnRight {
    margin-left:auto;
}
    

/*
.floatLabelNormal, .floatLabelSelect {
    position: relative;
    margin-bottom:10px;
}
.floatLabelNormal > span {
    position: absolute;
    font-size:14px;
    color:black;
    left:10px;
    top:10px;
    transition: all .3s cubic-bezier(0.000, 0.205, 0.035, 1.570);

}
.floatLabelNormal > input, .floatLabelSelect > select {
    font-size: 14px;
    width: 100%;
    height: 45px;
    outline: none;
    border:1px lightgray solid;
    padding-left:10px;
    padding-top:0px;
    border-radius:4px;
}

.floatLabelSmall > span, .floatLabelNormal > input:focus + span {
    font-size: 9px;
    top:5px;
    color:gray;
}

.floatLabelSmall > input, .floatLabelNormal > input:focus {
    padding-top: 12px;
    
}  

.floatLabelSelect > select {
    padding-left:10px;
    padding-top: 12px;
}

.floatLabelSelect > span {
    position: absolute;
    font-size: 9px;
    top:5px;
    left:10px;
    color:gray;
}


.allCapsUpper > input {
    text-transform: uppercase;
}

.allCapsLower > input {
    text-transform: lowercase;
}
*/

/* datums */
.customFormDate {
  border:1px lightgray solid;
  border-radius: 4px;
  height:45px;
  padding-left:10px;
  padding-top: 15px;
  display: flex;
}
.customFormDate > span {
    font-size: 9px;
    color: rgb(53, 53, 53);
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 10px;
    padding-top:8px;
}

.mySelect {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;       /* Remove default arrow */
    -ms-word-break: normal;
    word-break: normal;
    background-color: #ffffff;
    background-image: none;

    border:1px solid lightgray;
    padding-left:10px;
}

.customFormDate > select {

    border:1px dotted lightgray;
    font-size: 12px;
    margin:0;
    padding:0px 10px;
    height:20px;
    margin-top:5px;
    margin-right:10px;
    border-radius: 0px;
}

.customFormDate select::-ms-expand {
  display: none;
}



@media(max-width: 1120px) {
    .customerFormHeader {
        border-top-right-radius: 10px;
    }
    .rightAlignBtnDirectCart {
        margin-left: auto;
    }
}
@media(max-width: 550px) {
    .btnVerderWinkelen, .stepBackBtn {
        margin-right: 10px;
        margin-left: 0px;
    }
}
@media(max-width: 450px) {
    .floating-label {
        position: relative;
        pointer-events: none;
        left: 10px;
        top: -32px;
        display: inline-block;
        /*transition: 0.2s ease-out all; */
        transition: all .3s cubic-bezier(0.000, 0.205, 0.035, 1.570);
    } 
    input:focus ~ .floating-label,
    input:not(:focus):valid ~ .floating-label{
        left: 10px;
        top: -46px;
        font-size: 10px;
        opacity: 1;
        color: gray;
        display: inline-block;
    }

}







</style>