// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.calendar-wrapper {
   position: relative;
}
.calendarClose {
   position: absolute;
   top:0px;
   right:0px;
   margin-top: 10px;
   margin-right: 10px;
   color: red;
   font-size: 24px;
   cursor: pointer;
   border-radius: 15px;
   height:30px;
   width:30px;
}
.calendar {
   max-width:383px;
}
.timeSlotCalender {
   margin: auto;
   width:100px;
   border:1px solid gray; 
   background-color:white;
   
   display: table;
    border-collapse: collapse;
    width:100%;
   color:#707070;
}
.monthCntr {
   float:left;
}
.timeCntr {
   float:right;
   width:56px;
}
.tsfooter {
   border-top:1px solid #dddddd;
   padding:5px 5px 5px 0px;
}
.tsfooter > button {
   float:right;
}
.tsfooter > label {
   padding:8px 0px 0px 10px;
}
.tsblok > div {
   float:left;
   padding:5px 5px 0px 5px;
   width:40px;
   height:32px;
   display: table-cell;
/* border: 1px solid #dddddd; */
   text-align:right;
   color:#707070;
   font-size:16px;
}
.tsvandaag {
   border:1px red dashed;
}
.tsblok > div:hover {
   color:white;
   background-color:#1d759d;
}
.tsdays > div {
    text-align:center;
    background-color:#e4f7ff;
    border:none;
}
.tsdag {
   cursor:pointer;
}
.tsdag.selected {
   background-color:#1d759d;
   color:white!important;
}
.almostSoldOut {
	background-color: white;
	color:orange!important;
}
.meldingOver {
	background-color:orange!important;
	color:white;
	padding:8px 8px;
	text-align: center;
	font-size:14px;
}
.tsoptions {
   width:106px;
}
.tsprev > img {
   height: 14px;
}
.tsnext > img {
   height: 14px;
}
.tsclose {
   padding-right:5px;
   font-size:14px;
}
.tshome {
   font-size:14px;
   float:right;
   margin-top:5px;
   margin-right:8px;
}
.tshome > img {
   height:18px;
}
.tstop {
   font-size:24px;
   text-align:center;
   color:#707070;
}
.tsexcl {
   background-color:#f9f9f9;
   color:#acacac!important;
}
.tsnoslots {
   background-color:#bababa;
   color:white!important;
   cursor:default!important;
}
.tssoldout {
   background-color:#cc6666;
}
.timeCntrInner {
   font-size:15px;    
   height:170px;
   margin-top:-17px;
}
.timeCntrInner ul {
   height:100%;
   overflow-y:scroll;
   padding:0px 0px;
}
.timeCntrInner ul >li {
   list-style: none;
   text-align:center;
   padding:3px 0px 3px 0px;
   cursor:pointer;
   margin:2px 0px 0px 0px;
}
.timeCntrInner ul > li.selected {
   background-color:#1d759d;
   color:white!important;
}
.timeHdr, .timeFtr {
   box-sizing: border-box;
   background-color:#e4f7ff;
   border:none;
   padding:0px 0px 0px 0px;
   color:#707070;
   font-size:16px;
   width:100%;
   height:32px;   
   font-size:20px;
   text-align:center;
}
.timeHdr a {
   margin:auto;
   display: block;
}
.timeHdr img, .timeFtr img { 
   height:14px;
}
.timeHdr a  {
   padding-top:3px;
}
.timeFtr {
   height:24px;
}
.timeFtr a {
   margin:0px 0px!important;
   padding:0px 0px!important;
}
.timeFtr a > i {
   display:block;
   margin:0px 0px!important;
   padding:0px 0px!important;
}
.tslabel {
   font-size:21px;
   margin-top:-5px;
}
.calendarInfo {
   font-size: 18px;
   padding-bottom:20px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
