<template>
    <div class="TextFit">
        <slot class="myslot"></slot>
    </div>
</template>

<script>
import $ from 'jquery';
import {store} from '../store/index';


export default {

    data() {
        return {
            text: "",
            busy: false,
            state: store.state
        }
    },

    props: {
        maxFontSize: {
            
        }
    },

    mounted() {
        this.text = $('.TextFit').html()
        this.setFontSize();
    },

    created() {
        window.addEventListener("resize", this.myEventHandler);
    },
    unmounted() {
        window.removeEventListener("resize", this.myEventHandler);
    },
    methods: {
        
        myEventHandler(e) {
            
            if (this.busy) { return; }
            
            this.busy = true;
            this.setFontSize();
            this.busy = false;
        },

        setFontSize() {
        $('.TextFit').css('text-align:center')
        
        $('.TextFit').html('<div style="margin:auto; vertical-align: middle; ">'+this.text+'</div>');

        var maxWidth = $('.TextFit').width();
        var maxHeight = $('.TextFit').height();
        var minFontSize = 8;
        var maxFontSizeH = 0; // oneindig groot ...
        var textColor = $('.TextFit').css('color');
        textColor = this.state.status.layoutTextColor;
        var lastSize = 8;

        if ($('.TextFit').css('max-height')!=undefined) {
            maxHeight = $('.TextFit').css('max-height').replace("px","");
        }


        // Wrap de hele handel in een DIV
        $('.TextFit > div').css('color',$('.TextFit').css('background-color')); // voor de zekerheid de tekst verstoppen (voorgrond + achtrond kleur hetzelfde)
        $('.TextFit > div').css('padding','0px;');
        $('.TextFit > div').css('margin','0px;');

        // init min font size
        $('.TextFit').css('font-size',minFontSize+'px');
        if (typeof this.maxFontSize !== 'undefined') {
            maxFontSizeH = this.maxFontSize;
        }

        // Check of de tekst nog binnen het blok valt .. zo ja, maar de tekst groter ...
        var tel = 0;
        while ( true ) {
                tel++;

                if (tel>200) { break; } // indien het component niet zichtbaar is, 

                lastSize = minFontSize;                
                //minFontSize++;
                minFontSize = minFontSize + 2;
                if (maxFontSizeH>0 && minFontSize>maxFontSizeH) {
                    break;
                }

                $('.TextFit > div').css('font-size',minFontSize+'px'); 
                $('.TextFit > div').css('line-height',minFontSize+'px');  

                if ($('.TextFit > div').height() > maxHeight) {
                    break;
                }

                if ($('.TextFit > div').width() > maxWidth) {
                    break;
                }


        }
        $('.TextFit > div').css('font-size',lastSize+'px');
        $('.TextFit > div').css('color',textColor); // tekst kleur herstellen.

      }
  },

}
</script>

<style>

</style>