// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.floatLabelNormal, .floatLabelSelect {
    position: relative;
    margin-bottom:10px;
}
.floatLabelNormal > span {
    position: absolute;
    font-size:14px;
    color:black;
    left:10px;
    top:10px;
    transition: all .3s cubic-bezier(0.000, 0.205, 0.035, 1.570);
}
.floatLabelNormal > input, .floatLabelSelect > select, .floatLabelNormal > textarea {
    font-size: 14px;
    width: 100%;
    height: 45px;
    outline: none;
    border:1px lightgray solid;
    padding-left:10px;
    padding-top:0px;
    border-radius:4px;
}
.floatLabelNormal > textarea {
    height:auto;
}
.floatLabelSmall > span, .floatLabelNormal > input:focus + .hint, .floatLabelNormal > textarea:focus + .hint {
    font-size: 9px;
    top:5px;
    color:gray;
}
.floatLabelSmall > input, .floatLabelNormal > input:focus {
    padding-top: 12px;
}
.floatLabelNormal > textarea, .floatLabelNormal > textarea:focus {
    padding-top: 20px;
}
.floatLabelNormal strong, .floatLabelSelect strong {
    position: absolute;
    font-size: 12px;
    top:3px;
    right:10px;
    color:red;
    font-weight: normal;
    font-style: italic;
}
.floatLabelSelect > select {
    padding-left:10px;
    padding-top: 12px;
}
.floatLabelSelect > span {
    position: absolute;
    font-size: 9px;
    top:5px;
    left:10px;
    color:gray;
}
.allCapsUpper > input {
    text-transform: uppercase;
}
.allCapsLower > input {
    text-transform: lowercase;
}

/* datums */
.customFormDate {
  border:1px lightgray solid;
  border-radius: 4px;
  height:45px;
  padding-left:10px;
  padding-top: 15px;
  display: flex;
}
.customFormDate > span {
    font-size: 9px;
    color: rgb(53, 53, 53);
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 10px;
    padding-top:8px;
}
.mySelect {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;       /* Remove default arrow */
    -ms-word-break: normal;
    word-break: normal;
    background-color: #ffffff;
    background-image: none;

    border:1px solid lightgray;
    padding-left:10px;
}
.customFormDate > select {

    border:1px dotted lightgray;
    font-size: 12px;
    margin:0;
    padding:0px 10px;
    height:20px;
    margin-top:5px;
    margin-right:10px;
    border-radius: 0px;
}
.customFormDate select::-ms-expand {
  display: none;
}

/*
.required > i {
    color:rgb(77, 77, 77);
    position: absolute;
    right:10px;
    top:0px;
    font-size:24px;
}
*/
.invalidValue > input, .invalidValue > select, .invalidValue > textarea {
    border-color:red;
    border-width:2px;
}
.invalidValue > i {
    color:red;
}
.required > i > span {
    position: absolute;
    display: none;
}
.invalidValue > i > span {
    position: absolute;
    display:block;
    font-size:24px;
    top:3px;
    right:14px;
    font-size: 12px;
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
