

import { createApp } from 'vue';
import App from './App.vue'
import {store} from './store/index.js'
import router from './router'
import VueGtm from 'vue-gtm';

/* fontawesome */
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSpinner, faAlignLeft,faUserSecret,faHome,faTimesCircle,faCaretUp,faCaretDown,faCaretLeft,faCaretRight } from '@fortawesome/free-solid-svg-icons'
import {Functions} from "@/util/util.js";
library.add(faSpinner, faAlignLeft,faUserSecret,faHome,faTimesCircle,faCaretUp,faCaretDown,faCaretLeft,faCaretRight)

const app = createApp(App)
app.component('font-awesome-icon', FontAwesomeIcon)


// Initialiseer Vue GTM met dynamische enabled waarde voor Google Analytics
if (localStorage.getItem("TicketViewCookie") === 'accepted' && localStorage.getItem('shopAnalytics') !== '') {
    app.use(VueGtm, {
        id: localStorage.getItem("shopAnalytics"), // Vervang dit met je GTM-ID
        vueRouter: router, // Voeg je router toe
        enabled: true, // Controleer of tracking is ingeschakeld
        debug: true, // Debug-modus inschakelen
        loadScript: true, // Script automatisch laden
    });
}

if (localStorage.getItem("TicketViewCookie") === 'accepted' && localStorage.getItem('shopFacebookPixel') !== '') {

    !function(f,b,e,v,n,t,s)
    {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
    fbq('init', localStorage.getItem('shopFacebookPixel')); // Vervang met je Pixel ID
    fbq('track', 'PageView');

}




app.use(router).use(store).mount('#app');
