// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.MobileOverviewForm[data-v-60d4faec]{
  position: relative;
  left: 0;
  text-align: left;
}
.MobileOverviewFormHeader[data-v-60d4faec] {
  min-height: 100px;
  width: 100%;
  padding: 20px 20px 20px 20px;
  position: relative;
  background-color: whitesmoke;
  border-top-left-radius: 10px;
}
.MobileOverviewFormTitle[data-v-60d4faec] {
  font-weight: bold;
  font-size: 28px;
  font-family: 'Nunito', sans-serif;
  max-width: 550px;
  color: rgb(65, 65, 65);
}
.MobileOverviewFormBody[data-v-60d4faec] {
  padding: 20px 20px 20px 20px;
  font-family: 'Didact Gothic', sans-serif;
}
.lijn[data-v-60d4faec] {
  border-top: 1px solid lightgray;
  width: 100%;
  padding: 0;
  margin: 30px 0px 30px 0px;
}
.buttonWrapper[data-v-60d4faec] {
  display: flex;
}
.btnNuBetalen > img[data-v-60d4faec] {
  position: absolute;
  height:auto;
  left:10px;
  top:10px;
}
.btnNuBetalen[data-v-60d4faec] {
  position:relative;
  margin-left:auto;
  min-width: auto!important;
  width:auto;
  padding-left:50px;
  padding-top:8px;
  padding-right:20px;
  height:45px;
}
.btnAfronden[data-v-60d4faec] {
  margin-left:auto;
}




`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
