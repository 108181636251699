// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.centerBlock[data-v-35887cc4] {
    text-align: center;
    padding-left:20px;
    padding-right:20px;
}
.header[data-v-35887cc4] {
    background-color: #005b8c;
    width: 100%;
    min-height: 450px; 
    position: relative;
    box-sizing: border-box;
    padding-top: 70px;


    padding-bottom:240px; /* hoogte van de list event + eventuele tussenafstand, die overlapt */
}
.popupOpen[data-v-35887cc4] {
  overflow-y: hidden;
}
.accountName[data-v-35887cc4] {
    color: white;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    width:100%;
    max-width:1000px;
    text-align: center;    
    margin:auto;
    margin-bottom:20px;
}
.shopTitle[data-v-35887cc4] {
    color: white;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    width:100%;
    max-width:1000px;
    text-align: center;    
    margin:auto;
    margin-bottom:20px;
}
.eventInfo[data-v-35887cc4] {
    position: relative;
    padding-bottom: 180px;
    min-height: 100%;
    height:100%;
    color: rgb(46, 46, 46);
    z-index:0;
}
.infoEventContentWrapper[data-v-35887cc4] {
    max-width:1100px;
    margin-top: -293px;
    margin-bottom: 50px;

    margin-left:auto;
    margin-right:auto;

    height: auto;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
}
.infoEventContentHeader[data-v-35887cc4] {
    position: relative;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    background-color: whitesmoke;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
}
.infoEventContentHeaderTextBox[data-v-35887cc4] {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    display: block;   
    float: left;
    text-align: left;
    width: 60%;
}
.accountName[data-v-35887cc4], .shopTitle[data-v-35887cc4] {
    --max-lines: 2;
    overflow: hidden;
    font-weight: bold;
    font-size: 40px;  
    font-family: 'Nunito', sans-serif;
}
.infoEventTitle[data-v-35887cc4] {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-weight: bold;
    font-size: 26px;
    font-family: 'Nunito', sans-serif;
}
.infoEventSubtitle[data-v-35887cc4] {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    color: #6d6d6d;
}
.infoEventTopCntrDateAndCalender[data-v-35887cc4] {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right:20px;
}
.infoEventDateCntr[data-v-35887cc4] {
  text-align: left;
}
.infoEventIcsCntr[data-v-35887cc4] {
  text-align: right;
  padding-bottom  : 20px;
}
.infoEventDate[data-v-35887cc4] {
    color: sandybrown;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Didact Gothic', sans-serif;
    text-transform: uppercase;
    display: inline-block;
    margin-right:20px;
}
.infoEventContentContainer[data-v-35887cc4] {
    text-align: left;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 60px;
    font-family: 'Didact Gothic', sans-serif;

    position:relative;
    margin-top:-5px;
    background-color: white
}
.infoEventTicketsContainer[data-v-35887cc4] {
    overflow-x: auto;
}
.infoEventTicketsTitle[data-v-35887cc4] {
    font-weight: bold;
    font-size: 22px;
}
.infoEventTicketsTable[data-v-35887cc4] {
    margin-top: 5px;
    font-size: 16px; 
    min-width: 20%;
    table-layout: auto;
    border-collapse:separate; 
    border-spacing: 0 5px;
}
.infoEventTicketPrices div[data-v-35887cc4] {
    padding-left: 20px;
}
.labelUitverkocht[data-v-35887cc4] {
    text-transform: uppercase;
    color: red;
}
.labelAllePrijzenInclServicekosten[data-v-35887cc4] {
    font-size: 14px;
    margin-top: 15px;
}
.labelErZijnGepersonaliseerdeVelden[data-v-35887cc4] {
  font-size: 14px;
  margin-top: 0px;
  max-width: 445px;
}
.btnBestelTickets[data-v-35887cc4], .btnUitverkocht[data-v-35887cc4], .btnUitgeschakeldeBestelTickets[data-v-35887cc4], .btnWachtlijst[data-v-35887cc4], .btnUitgeschakeldeBeschikbaarheid[data-v-35887cc4], .btnBeschikbaarheid[data-v-35887cc4] {
    margin-top: 15px;
    margin-right:20px;
    float: left;
}
.infoEventLocationWrapper[data-v-35887cc4] {
    display: flex;
    margin-top: 40px;
}
.infoEventLocationContainer[data-v-35887cc4], .infoEventParkingContainer[data-v-35887cc4] {
    padding-right: 5px;
    max-width: 400px;
    overflow-x:hidden; /* voor het geval dat de tekst niet gewrapped kan worden */
    margin-right: 10px;
}
.infoEventLocationContainer[data-v-35887cc4] {
    padding-right: 40px;
}
.infoEventLocationTitle[data-v-35887cc4] {
    font-weight: bold;
    font-size: 22px;
}
.infoEventLocationName[data-v-35887cc4] {
    margin-top: 5px;
    font-size: 16px;  
    font-style: italic;
}
.infoEventLocationAddress[data-v-35887cc4], .infoEventLocationCountry[data-v-35887cc4] {
    font-size: 16px;
    font-style: italic;
}
.infoEventLocationZipcodeCityWrapper[data-v-35887cc4] {
    font-size: 16px; 
    font-style: italic;
}
.infoEventParkingTitle[data-v-35887cc4] {
    font-weight: bold;
    font-size: 22px;
}
.infoEventParkingInfo[data-v-35887cc4] {
    margin-top: 5px;
    font-size: 16px;  
    font-style: italic;
}
.infoEventTags[data-v-35887cc4] {
    margin-top: 40px;
}
.infoEventTagsTitle[data-v-35887cc4] {
    font-weight: bold;
    font-size: 22px;
}
.infoEventTagsWrapper[data-v-35887cc4] {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.tag[data-v-35887cc4] {
    font-size: 14px;
    margin: 5px 5px;
    padding: 5px 10px 5px 10px;
    border-radius: 80px;    
    background-color: white;
    color: lightgray;
    border: 2px solid lightgray;
}
.activeTag[data-v-35887cc4] {
    font-size: 14px;
    margin: 5px 5px;
    padding: 5px 10px 5px 10px;
    border-radius: 80px;    
    background-color: white;
    border: 2px solid #005b8c;
    color: #005b8c;
}
.infoEventDescription[data-v-35887cc4] {
    margin-top: 40px;
    position: relative;
    margin-right: 40px;
    /*max-width: 800px;*/
}
.infoEventDescriptionTitle[data-v-35887cc4] {
    font-weight: bold;
    font-size: 22px;
}
.infoEventDescriptionContainer[data-v-35887cc4] {
    padding-top: 5px;
}
.shortDescription[data-v-35887cc4] {
    margin-bottom: 15px;
    font-weight: bolder;
    font-style: italic;
}
.infoEventImage[data-v-35887cc4] {
    height: 100%;
    border-radius: 20px;
    -o-object-position: 50% 50%;
       object-position: 50% 50%;
    -o-object-fit:cover;
       object-fit:cover;
    border:4px solid rgb(223, 216, 216);
}
.infoEventImgBK[data-v-35887cc4] {
    width:100%;
    position: absolute;
    left:0;
    top:0;
    max-height:350px;
    overflow:hidden;
}
.infoEventImgBK img[data-v-35887cc4] {
    width:120%;
    height:400px;
    margin-top:-25px;
    margin-left:-10%;
    margin-right:-10%;
    filter: blur(10px);
  -webkit-filter: blur(10px);
}
.infoEventImgCntr[data-v-35887cc4] {
  box-sizing: border-box;
  height: auto;
  width: 40%;
  aspect-ratio: 3 / 2;
  border-top-left-radius: 10px;
  float:left;
}
.infoEventImg[data-v-35887cc4] {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 50% 50%;
     object-position: 50% 50%;
  border-top-left-radius: 10px;
  /*
    position: relative;
   */
}
.infoEventBottomSocialIcos[data-v-35887cc4] {
  display:none;
}
.infoEventHeaderSocialIcos[data-v-35887cc4] {
  position: absolute;
  right:20px;
  bottom:20px;
}
@media(max-width: 768px) {
.infoEventContentContainer[data-v-35887cc4] {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 40px;
}
.infoEventContentHeader[data-v-35887cc4] {
}
.infoEventContentHeaderTextBox[data-v-35887cc4] {
    padding: 20px 40px 20px 40px;
}
.infoEventImgCntr[data-v-35887cc4] {
    width: 100%;
    height: auto;
    aspect-ratio: 3 / 2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.infoEventImg[data-v-35887cc4] {
    border-top-right-radius: 10px;
}
.infoEventBottomSocialIcos[data-v-35887cc4] {
    display: block;
    text-align: center;
    max-width: 200px;
    margin: auto;
    padding-bottom: 40px;
}
.infoEventHeaderSocialIcos[data-v-35887cc4] {
    display: none;
}
.infoEventContentHeaderTextBox[data-v-35887cc4] {
    width: 100%;
    padding: 20px;
}
.infoEventIcsCntr[data-v-35887cc4] .btn { /* overschijven child in componentn! */
    font-size: 10px;
    width: auto;
    padding: 10px 10px 10px 10px;
}
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
