<template>
  <div class="home" v-bind:class="{popupOpen: state.isPopUpOpen}" v-if="state.isLoaded">

    <div class="header" id="header" v-if="state.isLoaded">
      <HeaderBackground />
      <div id="searchbar">
        <div class="searchbar-wrapper">
          <div class="input-holder">
            <input type="text" class="searchbar-input" :placeholder="translateString('HomePage', 'zoekPlaceholder')" v-model="search" />
            <button class="searchbar-icon" ref="searchBtn" v-on:click="searchBarToggle($event)"><span></span></button>
          </div>
          <span class="close" ref="closeBtn" v-on:click="setAllDefault"></span>      
        </div>
      </div>

      <Flag></Flag>

      <!--<div class="shopTitle" v-html="state.status.shopTitle"></div>-->
      <TextFit v-once class="shopTitleAuto" style="position: relative; max-height:60px; padding-left:20px; padding-right:20px;"  :style="{color:textColor}">{{ state.status.shopTitle }}</TextFit>
      
      <div class="tags">
        <div class="tagList" v-for="(tag, index) in tagsFromStore" :key="index">
          <div class="tag-wrapper">
            <div v-if="tag.ID == `All`">
              <div class="tag active" :ref="setTagRef" v-on:click="tagToggle(index, tag.ID)" v-html="getTranslationFromArray(tag.genre.items)"></div>
            </div>
            <div v-else>
              <div class="tag" :ref="setTagRef" v-on:click="tagToggle(index, tag.ID)" v-html="getTranslationFromArray(tag.genre.items)"></div>
            </div>
          </div>
        </div>
      </div>
      <div style="clear:both;"></div>

      <div v-if="state.status.showPersonalShopInput == '1'" class="inputCode">
        <br />
        <div v-show="!isCodeActive">

          <div class="LabelCodeReceived">{{translateString('General', 'PersoonlijkeOfActiecodeOntvangen')}}</div>

          <div v-show="!hasCodeOpen">
            <div class="LabelCodeReceived">&raquo; <a @click="hasCodeOpen = true" style="text-decoration: underline; font-size: 14px; cursor: pointer">{{ translateString("General","KlikHierOmUwCodeInTeVullen")}}</a> &laquo;</div>
          </div>

          <div v-show="hasCodeOpen && !isCodeActive">
            <!-- Toon input voor code -->
            <div class="LabelCodeReceived" style="font-size: 14px;">{{translateString('General', 'VulHieronderDeCodeIN')}}:</div>
            <CodeInput :isVisible="hasCodeOpen" style="margin-top:15px;" @verified="handleHasInvitationCode"  />
          </div>
        </div>
        <div v-show="isCodeActive">
            <div class="LabelCodeReceived">{{translateString('General','CodeIsActief')}}</div>
            <div class="LabelCodeReceived">{{translateString('General','CodeShopAangepast')}}</div>
            <div class="LabelCodeReceived"><a @click="undoInvitation()" style="text-decoration: underline; font-size: 14px; cursor: pointer">{{translateString('General','CodeCancel')}}</a></div>
        </div>

        <br />
        <br />
        <!--<a v-on:click="goToPersonalShop" class="LabelClickHere">{{translateString('General', 'KlikHier')}}</a>-->
      </div>



    </div>
    <div class="eventListWrapper">
      <div class="eventList" v-if="state.isLoaded">
        <div v-if="state.events.events.count == 0">
          <div class="giphyContainer">         
            <img class="giphy" :src="require(`@/assets/giphy.webp`)" alt="">
            <div class="noEventsFoundText">No events found</div>
          </div>
        </div>
          <div  v-for="(event) in filteredEvents" v-bind:key="event.ID" :ref="setEventRef">
            <EventCard :id="String(event.ID)"
              :title="getTranslationFromArray(event.title.items)" 
              :image="event.image" :minPrice="event.minPrice" :maxPrice="event.maxPrice" 
              :dateFrom="event.evFrom"
              :dateUntil="event.evUntil"
              :soldOut="event.evSoldOut"
              :allowRegister="event.evAllowRegister"
              :showDatesOptions="event.evShowDatesOptions"
              :periodLabel="event.evPeriodLabel"
              :statusOverrule="event.statusOverruleByTicketTypes"
              :statusOverruleDate="event.statusOverruleByTicketTypesDate"
              :ticketTypes="event.ticketTypes"
               v-bind:data-month="getMonth(event.evFrom)"
            >
            </EventCard>
          </div>
          <div v-show="firstVisibleEvent != null" class="month-label" ref="leftDateLabelRef">
            {{ firstVisibleEvent }}
          </div>
      </div>
      <div style="clear:both;"></div>
    </div>

    <ShoppingCartInfo class="ShoppingCartInfo" @shoppingCartInfoClicked="openPopUpByShoppingCartInfo"></ShoppingCartInfo>

    <Footer></Footer>
    <PopUpMask v-if="state.isPopUpOpen" :whichPopUpToOpen="'checkout'"></PopUpMask>

    <SeatingPlan v-if="state.seatingshow"></SeatingPlan>
    
  </div>
</template>

<script>
import CookieMessage from "@/components/CookieMessage.vue";
import EventCard from '../components/EventCard.vue';
import {Selector} from '../language/language.js';
import Flag from '../components/Flag.vue';
import PopUpMask from '../components/PopUpMask.vue';
import $ from 'jquery';
import {store} from '../store/index';
import TextFit from '../components/TextFit.vue';
import Footer from '../components/Footer.vue';
import ShoppingCartInfo from '../components/Checkout/components/ShoppingCartInfo';
import router from '../router'
import SeatingPlan from '../components/SeatingPlan.vue';
import CodeInput from '../components/CodeInput.vue';
import HeaderBackground from "@/components/HeaderBackground.vue";

export default {
  name: 'Home',
  data() {
    return {
      search: '',
      activatedTags: [],
      tagRefs: [],
      scrollPosition: 0,
      hasCodeOpen: false,
      isCodeActive: false,
      firstVisibleEvent: null,
      eventRefs: [],
      textColor: 'green',
      textShadowColor: 'black',
      textShadowPixel: 1
    }
  },
  setup() {
        return {
            state: store.state
        }
    },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  components: {
    HeaderBackground,
    CodeInput,
    EventCard,
    Flag,
    PopUpMask,
    TextFit,
    Footer,
    ShoppingCartInfo,
    SeatingPlan,
    CookieMessage
  },
  methods: {
    setEventRef(el) {
      if (el) {
        this.eventRefs.push(el);
      }
    },
    handleScroll() {
      const windowHeight = window.innerHeight;

      // Er moeten er wel meer dan 9 events zijn. Andrers heeft het niet zoveel zin.
      if (this.eventRefs.length < 9) {
      //  return;
      }

      var outView = false;

      for (let index = 0; index < this.eventRefs.length; index++) {
        const el = this.eventRefs[index];
        if (el && typeof el.getBoundingClientRect === 'function') {
          const rect = el.getBoundingClientRect();
          const inView = rect.top >= -350 && rect.bottom-350 <= windowHeight;

          if (inView) {
            if (this.filteredEvents[index]!==undefined) {

              var arrayMetMaanden = [];
              arrayMetMaanden.push(this.getMonth(this.filteredEvents[index].evFrom));

              var teZoekenTop = rect.top;
              // console.error("Te zoeken: "+teZoekenTop);
              // nieuwe loop om te kijken welke events op dezelfde y positie staan.
              for (let index2 = 0; index2 < this.filteredEvents.length; index2++) {
                const el = this.eventRefs[index2];
                if (el && typeof el.getBoundingClientRect === 'function') {
                  const rect = el.getBoundingClientRect();
                  if (rect.top === teZoekenTop) {
                    arrayMetMaanden.push(this.getMonth(this.filteredEvents[index2].evFrom));
                  }
                }
              }

              // Extra controle op het allerlaatste evenement. Als deze in beeld is (ook incl de hoogte), dan moet de maand ook getoond worden
              var index4 = this.eventRefs.length-1;
              const el = this.eventRefs[index4];
              if (el && typeof el.getBoundingClientRect === 'function') {
                const rect = el.getBoundingClientRect();
                // Als de top van het laatste element in beeld is, dan moet de maand ook getoond worden
                if (rect.top + 200 <= windowHeight) {
                  arrayMetMaanden.push(this.getMonth(this.filteredEvents[this.filteredEvents.length-1].evFrom));
                  }
              }



              // Loop door alle maanden en zet deze in een string
              // Indien de maanden hetzelfde zijn, dan hoef ik alleen de eerste maand te tonen
              var firstMonth = arrayMetMaanden[0];
              var lastMonth = arrayMetMaanden[arrayMetMaanden.length-1];
              if (firstMonth === lastMonth) {
                  this.firstVisibleEvent = firstMonth;
                  break;
              } else {

                  // Indien de laatste 3 tekens gelijk zijn, dan is het een maand van hetzelfde jaar
                  if (firstMonth.slice(-3) === lastMonth.slice(-3)) {
                    this.firstVisibleEvent = firstMonth.substring(0,3) + ' - ' + lastMonth;
                  } else {
                    this.firstVisibleEvent = firstMonth + ' - ' + lastMonth;
                  }
              }

              break;
            }
          } else {
            outView = true;
          }

        }
      }

      // Indien er geen enkele outView is dan hee4ft een label geen zin.
      if (outView === false) {
        this.firstVisibleEvent = null;
        return;
      }

      // Na het afhandelen kijken of mijn element met ref leftDateLabelRef nog zichtvaar is. Zoniet dan de abolsute omzetten naar sticky
      if(this.$refs.leftDateLabelRef != null) {
        var rect = this.$refs.leftDateLabelRef.getBoundingClientRect();
        if(rect.top < 0) {
          this.$refs.leftDateLabelRef.classList.add('sticky-label');
        }
      }

      var klaar = false;
      for (let index = 0; index < this.eventRefs.length; index++) {
        const el = this.eventRefs[index];
        if (el && typeof el.getBoundingClientRect === 'function') {
          if (this.filteredEvents[index] !== undefined) {
            const rect = el.getBoundingClientRect();
            // console.error(this.filteredEvents[index].title.items.nl + " positie: " + rect.top);
            if (rect.top > 0) {
               this.$refs.leftDateLabelRef.classList.remove('sticky-label');
            }
            klaar = true;
            break;  // Stop de iteratie
          }
        }
      }

    },
    translateString(componentOrPageName, stringNotTranslated) {
      var translatedString = Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated)
      return translatedString;
    },
    getMonth(evFrom) {
      var date = new Date(evFrom);
      // Zet mijn local en NL om
      var month = date.toLocaleString(store.state.localeLanguage, { month: 'short' });
      // Maak de maand uppercase
      month = month.toUpperCase();
      var year = date.getFullYear().toString().slice(-2);
      return month + ". '" + year;
    },
    goToPersonalShop() {
      router.push('/personalshop')
    },

    openPopUpByShoppingCartInfo() {
      const $body = document.querySelector('body');
      let scrollPosition = 0;
      scrollPosition = window.pageYOffset;
      $body.style.overflow = 'hidden';
      $body.style.position = 'fixed';
      $body.style.top = `-${scrollPosition}px`;
      $body.style.width = '100%';
      store.dispatch('updateScrollPosition', scrollPosition);

      var selectedEvent;
      Object.keys(store.state.events.events.items).forEach(key => {
      if(store.state.events.events.items[key].ID == store.state.cart.bestelItems[0].eventID) {
        return selectedEvent = store.state.events.events.items[key]
      }});


      store.dispatch('openPopUp', {selectedEvent: selectedEvent,popUpOpenByShoppingCartInfo: true,whichPopUpToOpen: "Checkout"});
    },

    handleHasInvitationCode(code) {
      this.isCodeActive = true;
      this.state.hasCodeOpen = true;
      this.state.inviteCode = code;
    },
    undoInvitation() {
      this.isCodeActive = false;
      this.state.hasCodeOpen = false;
      this.state.inviteCode = "";
      this.hasCodeOpen = false;
    },


    getTranslationFromArray(arrayWithTransations) {
      var stringTranslation = Selector.SelectLanguageStringFromArray(arrayWithTransations)
      return stringTranslation;
    },

    /*
    *
    * SearchBarToggle: open en dichtklappen zoekbalk
    * 
    */
    searchBarToggle: function(evt) {
      // eerst resetten
      this.setAllDefault(true);

      var container = $(this.$refs.searchBtn).closest('.searchbar-wrapper');
      if(!container.hasClass('active')){
        container.addClass('active');
        evt.preventDefault();
      }
      else if(container.hasClass('active') && $(object).closest('.input-holder').length == 0) {
        container.removeClass('active');
        this.search ='';
      }
    },
    filteredBySearchBar() {
      var eventsFromStore = store.state.events.events.items;
      var obj = [];
      Object.keys(eventsFromStore).forEach(key => {
        var event = eventsFromStore[key];
        if(this.getTranslationFromArray(event.title.items).toLowerCase().includes(this.search.toLowerCase())) {
          obj.push(event);
        }
        else if(this.getTranslationFromArray(event.accAccountName.items).toLowerCase().includes(this.search.toLowerCase())) {
          obj.push(event);
        }
      })
      return obj;
    },

    /*
    *
    * TagToggle: activeren en deactiveren tags
    * Dichtklappen zoekbalk
    *
    */
    tagToggle: function(index, id) {
      var container = $(this.tagRefs[index]);

      // is het de All tag de aangeklikt is, voer dan setAllDefault() uit
      if(id == "All") {          
        this.setAllDefault();
      }
      else if(!container.hasClass('active')){
        // zet tag op actief en voeg deze toe aan de array met activatedTags
        container.addClass('active');
        this.activatedTags.push(String(id));

        // haal de active class van de All tag af
        $(this.tagRefs[0]).removeClass('active');
        
        // searchbar leeg en dichtklappen
        this.search = '';    
        var SearchbarContainer = $(this.$refs.closeBtn).closest('.searchbar-wrapper');
        SearchbarContainer.removeClass('active');
      }
      else if(container.hasClass('active')) {
        container.removeClass('active');

        // haal de id uit de array activatedTage
        var indexOfId = this.activatedTags.indexOf(id);
        this.activatedTags.splice(indexOfId, 1);
        
        // als alle tags uit staan, dan reset
        if(this.activatedTags.length == 0) {
          this.setAllDefault();
        }
      }
    },
    filteredByTag() {
      var eventsFromStore = store.state.events.events.items;
      var tagsAreActivated = this.activatedTags;
      var obj = [];
      Object.keys(eventsFromStore).forEach(id => {
        var event = eventsFromStore[id];
        Object.keys(tagsAreActivated).forEach(id => {
          var tag = tagsAreActivated[id];
          if(event.tags.items[tag]) {
            obj.push(event);
          }
        })    
      })

      // zorg ervoor dat er geen dubbele events worden teruggegeven
      /*
      function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
      }

      var uniqueEventList = obj.filter(onlyUnique)
       */
      return obj;

      return uniqueEventList;
    }, 
    setTagRef(el) {
      if(el) {
        this.tagRefs.push(el)
      }
    },
    setAllDefault(fromSearchbar) {
      var object = null;
      
      // zoekbalk dichtklappen als de functie niet vanaf de searchbar wordt aangeroepen
      if(fromSearchbar != true) {
        this.search = '';    
        var SearchbarContainer = $(this.$refs.closeBtn).closest('.searchbar-wrapper');
        SearchbarContainer.removeClass('active');
      }
      
      if(this.tagRefs != undefined) {
        this.activatedTags = [];
        $(this.tagRefs).removeClass('active');
        
        if(this.tagRefs[0] != 'undefined'){
          object = this.tagRefs[0];
        }
        
        var container = $(object);
        container.addClass('active');
      }          
    },
  },
  computed: {
    /*
    *
    * TagsFromStore zorgt ervoor dat de tags op het scherm getoond worden
    * Zit ook een mogelijkheid in om tags te filteren, wordt nu niet gebruikt
    * 
    */
    tagsFromStore() {
      var obj = [];
      var objTagAll = { "ID": "All", "genre": {"count": 1, "items": { "nl": "Alle" }}};
      obj.push(objTagAll);
      if ( store.state.status.genres!= undefined) {
        for (var i = 0; i < store.state.status.genres.items.length; i++) {
          obj.push(store.state.status.genres.items[i]);
        }
      }
      // alleen tags teruggeven als dit er meer dan twee zijn
      if(obj.length > 2) {
        return obj; 
      }    
    },
    
    /*
    *
    * FilteredEvents wordt gebruikt om events op het scherm te zetten waar op gezocht wordt
    * Default worden alle events op het scherm getoond
    * 
    */
    filteredEvents() {
      var obj = [];
      // kijken of er tags zijn geactiveerd
      if(this.activatedTags.length != 0) {
        obj = this.filteredByTag();
      }
      // anders kijken of er gezocht is via de zoekbalk, anders komen default alle events op het scherm
      else {
        obj = this.filteredBySearchBar();
      }
      return obj;

      /*return Object.values(eventsFromStore).filter(event => {
        return event.title.items.nl.toLowerCase().includes(this.search.toLowerCase()) 
      })*/
    },
  },
}
</script>

<style scoped>







.home {
   width:100%;   
   height:100%;

   
   display: flex;
   flex-direction: column;

   
   /*
   display: flex;
   flex-direction: column;
   flex-shrink: 0;
   flex-grow: 0;
   */
   position: relative;
}

.eventListWrapper {
  flex-shrink: 0;
  flex-grow: 1;
  position: relative;
}
Footer {
  flex-shrink: 0;
}

 .eventList {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, auto));
    max-width:1200px;
    margin:auto;
    margin-top:-200px;
    justify-items: center;
    align-items: center;
  }

  .giphyContainer {
    width: 300px;
    height: 400px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px; 
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
  }
  .giphy {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 50%;
    width: 100%;
  }
  .noEventsFoundText {
      color: rgb(80, 80, 80);
      font-weight: bold;
      font-family: 'Didact Gothic', sans-serif; 
      font-size: 25px;
      max-width:1000px;
      text-align: center;  
      white-space: nowrap;
      overflow: hidden;
      position: relative;
      margin-top: 50px;
  }
  
  .header {

    flex-shrink: 0;
    flex-grow: 0;

    background-color: #005b8c;
    width: 100%;
    min-height: 450px; 
    min-width: 350px; 
    position: relative;
    box-sizing: border-box;
    padding-top: 70px;


    padding-bottom:240px; /* hoogte van de list event + eventuele tussenafstand, die overlapt */
  }

  .tags {
    margin-bottom:200px;

    position: relative /* voor de geblurrede header */
  }

  .accountName {
    color: white;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;

    width:100%;
    max-width:1000px;
    text-align: center;
    
    margin:auto;

    white-space: nowrap;
    overflow: hidden;

  }
  .shopTitle {
    color: orangered;
  }
  .shopTitleAuto {
    color: white;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    width:100%;
    max-width:1000px;
    text-align: center;    
    margin:auto;
    margin-bottom:20px;

  }

/********** Search **********/
/* component linksboven op het scherm zetten */
.searchbar-wrapper {
    position: absolute;
    left: 25px;
    top: 25px;
}
  
/* zoekbalk en zoekbutton */
.searchbar-wrapper .input-holder {
    height: 40px;
    width: 40px;
    overflow: hidden;
    background: rgba(255,255,255,0);
    border-radius: 6px;
    position: relative;
    transition: all 0.3s ease-in-out;
}
.searchbar-wrapper.active .input-holder {
    width: 250px;
    border-radius: 50px;
    background: whitesmoke;
    transition: all .5s cubic-bezier(0.000, 0.105, 0.035, 1.570);
}
  
/* input text in zoekbalk */
.searchbar-wrapper .input-holder .searchbar-input {
    width: 100%;
    height: 20px;
    padding: 0px 50px 0 20px;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    background: transparent;
    box-sizing: border-box;
    border: none;
    outline: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: grey;
    transform: translate(0, 60px);
    transition: all .3s cubic-bezier(0.000, 0.105, 0.035, 1.570);
    transition-delay: 0.3s;
}
.searchbar-wrapper.active .input-holder .searchbar-input {
    opacity: 1;
    transform: translate(0, 10px);
}
  
/* button zoekicon (vergrootglas) */
.searchbar-wrapper .input-holder .searchbar-icon {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50px;
    background: #005b8c;
    padding: 0px;
    outline: none;
    position: relative;
    z-index: 2;
    float: right;
    border: 2px solid white;
    cursor: pointer;
    transition: all 0.3 ease-in-out;
}
.searchbar-wrapper.active .input-holder .searchbar-icon {
    width: 34px;
    height: 34px;
    margin: 3px;
    border-radius: 30px;
}
  
/* Plaatsing vergrootglas op button */
.searchbar-wrapper .input-holder .searchbar-icon span {
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    transform: rotate(45deg);
    transition: all .4s cubic-bezier(0.650, -0.600, 0.240, 1.650);
}
.searchbar-wrapper.active .input-holder .searchbar-icon span {
    width: 17px;
    height: 19px;
    transform: rotate(-45deg);
}
.searchbar-wrapper .input-holder .searchbar-icon span::before, .searchbar-wrapper .input-holder .searchbar-icon span::after {
    position: absolute;
    content:'';
}
  
/* Streepje van het vergrootglas */
.searchbar-wrapper .input-holder .searchbar-icon span::before {
    width: 3px;
    height: 8px;
    left: 7px;
    top: 14px;
    border-radius: 2px;
    background: white;
}
/* Rondje van het vergrootglas */
.searchbar-wrapper .input-holder .searchbar-icon span::after {
    width: 11px;
    height: 11px;
    left: 0px;
    top: 0px;
    border-radius: 16px;
    border: 3px solid white; 
}
  
/* button close */
.searchbar-wrapper .close {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    transform: rotate(-180deg);
    transition: all .3s cubic-bezier(0.285, -0.450, 0.935, 0.110);
    transition-delay: 0.2s;
}
.searchbar-wrapper.active .close {
    right: -30px;
    transform: rotate(45deg);
    transition: all .6s cubic-bezier(0.000, 0.105, 0.035, 1.570);
    transition-delay: 0.5s;
}
.searchbar-wrapper .close::before, .searchbar-wrapper .close::after {
    position: absolute;
    content: '';
    background: white;
    border-radius: 2px;
}
  
/* kruisje in de close button */
.searchbar-wrapper .close::before {
    width: 3px;
    height: 20px;
    left: 9px;
    top: 0px;
}
.searchbar-wrapper .close::after {
    width: 20px;
    height: 3px;
    left: 0px;
    top: 9px;
}

/*************** Tags ******************/
.tags{
    display: table;
    margin: 0 auto;
}

.tagList {
    display: inline-block;  
}

.tag {
    font-size: 16px;
    margin: 5px 5px;
    padding: 5px 10px 5px 10px;
    border-radius: 80px;    
    background-color: #005b8c;
    color: whitesmoke;
    border: 2px solid whitesmoke;
    cursor: pointer;
}
.tag:hover {
    background-color: lightgray;
    color: black;
}

.tag.active {
    color: #005b8c;
    background-color: whitesmoke;
}

.LabelCodeReceived {
  color: white;
  font-size: 16px;
}

.LabelClickHere {
  cursor: pointer;
}

.month-label {
  color: #005b8c;
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  position: absolute;
  left:0;
  top:-200px;

  font-size: 12px;
  background-color: red;
  padding: 15px;
  color: white;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  z-index: 2;
}

.sticky-label {
  position: fixed;
  top:0px;
}

.inputCode {
  position: relative
}


@media(max-width: 1120px) {
    .header {
        padding-top: 80px;
    }
    .accountName, .shopTitle, .shopTitleAuto {
        width:90%;
    }
}
/*
@media (max-width: 767px) {
  .ShoppingCartInfo {
    display:none;
  }  
}

 */







</style>