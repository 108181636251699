// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
/* Voeg hier eventueel extra CSS toe */
#cookieConsentContainer[data-v-0a439f83] {
    position: relative;
    z-index:9999;
    top: 0;
    width: 100%;
    background-color: #2c2c2c;
    color:white;
    padding: 30px;
    text-align: center;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
