<template>
    <div>

        <div v-if="fieldType=='text' && textlines<=1">
            <div class="floatLabelNormal" v-bind:class="myPlaceholder()">                
                <input :value="modelValue" ref="myInput"  @input="updateChange" @blur="updateChange" />
                <span @click="focusInput" class="hint">{{ hint }}</span>
                <strong v-if="error!=''">{{error}}</strong>
            </div>
        </div>

        <!--
        <div v-if="fieldType=='text' && textlines<=1">
            <div class="floatLabelNormal" v-bind:class="myPlaceholder()">                
                <input ref="myInput" v-model="modelValue" @input="updateChange()" @blur="updateChange()" />
                <span @click="this.$refs.myInput.focus();" class="hint">{{hint}}</span>
                <strong v-if="error!=''">{{error}}</strong>
            </div>
        </div>
        
        <div v-if="fieldType=='text' && textlines>1">
            <div class="floatLabelNormal" v-bind:class="myPlaceholder()">                
                <textarea class="xx" maxlength="250" v-bind:rows="textlines" ref="myInput" v-bind="modelValue" @input="updateChange()"></textarea>
                <span @click="this.$refs.myInput.focus();" class="hint">{{hint}}</span>
                <strong v-if="error!=''">{{error}}</strong>
            </div>
        </div>

        

        <div v-if="fieldType=='date'">
            <div class="floatLabelNormal" v-bind:class="myPlaceholder()">
                <span>{{hint}}</span>
                <strong v-if="error!=''">{{error}}</strong>

                        <div class="customFormDate">
                            <span>Dag: </span>
                            <select class="mySelect" v-bind="curDay" v-on:blur="saveCustomerFields" @change="updateChange()" >
                                <option v-for="(text, key) in days" 
                                    :value="text"
                                    :key="key">
                                    {{text}}
                                </option>

                            </select>
                            <span>Maand: </span>
                            <select class="mySelect" v-bind="curMonth" v-on:blur="saveCustomerFields" @change="updateChange()">
                                <option v-for="(text, key) in months" 
                                    :value="text"
                                    :key="key">
                                    {{text}}
                                </option>

                            </select>
                            <span>Jaar: </span>
                            <select class="mySelect" v-bind="curYear" v-on:blur="saveCustomerFields" @change="updateChange()">
                                <option v-for="(text, key) in years" 
                                    :value="text"
                                    :key="key">
                                    {{text}}
                                </option>
                            </select>

                        </div>



            </div>
        </div>

        <div v-if="fieldType=='select'">

            <div class="floatLabelSelect">   
                <strong v-if="error!=''">{{error}}</strong>                     
                <select  class="mySelect" v-bind="modelValue" @change="updateChange()">                    
                    <option v-for="(text, key) in selectValues" 
                        :value="text"
                        :key="key">
                        {{text}}
                    </option>
                </select>
                <span>{{hint}}</span>
            </div>

        </div>

        <div v-if="fieldType=='num'">
            <div class="floatLabelNormal" v-bind:class="myPlaceholder()">
                <input v-bind="modelValue" @input="updateChange()" />
                <span @click="this.$refs.myInput.focus();" class="hint">{{hint}}</span>
                <strong v-if="error!=''">{{error}}</strong>
            </div>
        </div>
-->

    </div>
</template>

<script>

import {Functions} from '@/util/util.js';
import {Selector} from '@/language/language.js';
import {store} from '@/store/index';

export default {
    name: 'InputField',
    setup() {
        return {
            state: store.state
        }
    },
    data() {
        return {
            ticketType: 0,
            fieldType:'',
            textLines:'',
            selectValues: [],
            minvalue: '',
            maxvalue: '',
            default: '',
            hint: '',
            required: 0,
            visited: false,
            days: [],
            months: [],
            years: [],
            curDay: "0",
            curMonth: "0",
            curYear: "0",
            error: ""
        }   
    },
    props: {
        layout : { type : Object },
        modelValue: String,
        hintOverrule : { type : String, default : "" }, /* hint overrule, bij email confirmation */
        confirmationEmail: {type : String, default : "0"}, /* validate overrule, bij email confirmation */
        revalidate: {type: String, default : "0" }
    },
    computed: {
    },
    methods: {        
        updateChange(event) {
            this.visited = true;
            if (this.fieldType=="date") {

                this.curDay = this.curDay.toString();
                this.curMonth = this.curMonth.toString();
                this.curYear = this.curYear.toString();

                var sDat = this.curYear+"-"+this.curMonth.padStart(2,"0")+"-"+this.curDay.padStart(2,"0");
                if (Functions.isValidDate(sDat)) {
                    // omzetten indien de dagen nie tkloppen
                    var dt = new Date(sDat);
                    this.curDay = dt.getDate();
                    this.curMonth = dt.getMonth() + 1;
                    this.curYear = dt.getFullYear();

                    this.curDay = this.curDay.toString();
                    this.curMonth = this.curMonth.toString();
                    this.curYear = this.curYear.toString();

                    sDat = this.curYear+"-"+this.curMonth.padStart(2,"0")+"-"+this.curDay.padStart(2,"0");
                    this.$emit('update:modelValue',this.curYear+"-"+this.curMonth.padStart(2,"0")+"-"+this.curDay.padStart(2,"0"));
                    this.validate(sDat);
                    return;
    

                }
                this.validate();
                this.$emit('update:modelValue',this.curYear+"-"+this.curMonth.padStart(2,"0")+"-"+this.curDay.padStart(2,"0"));
                return;    
            }

            this.validate(event.target.value);
            this.$emit('update:modelValue',event.target.value);
        },
      focusInput() {
        this.$refs.myInput.focus();
      },
      myPlaceholder() {
            var gevuld = false;
            if (this.modelValue!="") {
                gevuld = true;
            }
            
           // datum, altijd ..... 
           if (this.fieldType=="date") {
                gevuld = true;
           }

            var ret = "";
            if (gevuld) {
                ret = "floatLabelSmall";
            }
            if (this.required) {
                if (ret!=="") { ret += " "; }
                ret = ret + "required";
            }
            if (this.visited && this.required && (!gevuld || this.error!="")) {
                if (ret!=="") { ret += " "; }
                ret = ret + "invalidValue";
            }

            return ret;
        },
        getLayoutWithOverrule(field,ticketType) {


            return Functions.getFieldLayoutWithOverrule(this.layout,field,ticketType,this.state.cart);

        },

        validate(waarde) {

            if (waarde==undefined) { // als hij van de change event op de input afkomt...
                waarde = this.modelValue;
            }

            var veld = this.layout.orderField;
            if (this. confirmationEmail==1) {
                veld = 'confirmationEmail';
            }


            this.error = Functions.validateField(waarde,
                                                veld,
                                                this.fieldType,
                                                this.required,
                                                this.minvalue,
                                                this.maxvalue);

            if (this.error=="" && veld=="confirmationEmail") {
                if (waarde.toLowerCase()!=store.state.customerFields.visitorEmail.toLowerCase()) {
                    this.error = Selector.SelectLanguageStringFromLangJSON("Checkout","EmailEnBevestigEmailZijnNietGelijk");
                }
            }

            if (this.error=="" && veld=="visitorEmail" && store.state.customerFields.confirmEmail!="") {
                if (waarde.toLowerCase()!=store.state.customerFields.confirmEmail.toLowerCase()) {
                    this.error = Selector.SelectLanguageStringFromLangJSON("Checkout","EmailEnBevestigEmailZijnNietGelijk");
                }
            }


        }

    },
    mounted() {

        var waarde = this.modelValue;
        

        this.fieldType = this.getLayoutWithOverrule('layout',this.ticketType);
        this.default = this.getLayoutWithOverrule('default',this.ticketType);
        this.hint = this.getLayoutWithOverrule('hint',this.ticketType);
        this.minvalue = this.getLayoutWithOverrule('minvalue',this.ticketType);
        this.maxvalue = this.getLayoutWithOverrule('maxvalue',this.ticketType);
        this.default = this.getLayoutWithOverrule('default',this.ticketType);
        this.textlines = this.getLayoutWithOverrule('textlines',this.ticketType);

        if (this.ticketType==0) {
            //isValidCustomerForm1Error = '';
        }

        if (this.modelValue=="" && this.default!="") {
            this.$emit('update:modelValue',this.default);
            waarde = this.default;
        }

        this.hint = Selector.SelectLanguageStringFromLangJSON("Checkout","hintUwEmail");
        if (this.hintOverrule!="") {
          //  this.hint = this.hintOverrule;
            this.hint = Selector.SelectLanguageStringFromLangJSON("Checkout","hintBevestigUwEmail");
        }


        this.required = this.getLayoutWithOverrule('mode',0)==2;
        if (this.fieldType=="select") {
            var ar = this.default.split(";");
            if (ar.length>0) {
                this.selectValues = ar;
            }
        }
        
        this.days = ["1","2","3","4","5","6","7","8","9","10","11","12","13","14","15","16","17","18","19","20","21","22","23","24","25","26","27","28","29","30","31"];
        this.months = Array("1","2","3","4","5","6","7","8","9","10","11","12");
        this.years = Array();
        // data veld voorbereiden ...
        const dt = new Date();
        var curJaar =  dt.getFullYear();
        for(var tel=curJaar; tel>curJaar-106; tel--) {
            this.years.push(tel.toString());
        }

        var yearStr = "";
        if (this.fieldType=="date") {
            if (waarde!=null && waarde!="" && Functions.isValidDate(waarde) ) {
                const dt = new Date(waarde);
                this.curDay = dt.getDate().toString(); // geeft alleen de dag!! terug.
                this.curMonth = dt.getMonth() + 1; // Raar, maand telt vanaf 0 ?!?!?
                this.curYear = dt.getFullYear();
            }
        }

        if (waarde!=NaN && waarde!=null) {
            this.validate(waarde); // zet eventuele fouten ...
        }
        


    },
    watch: {     
        revalidate: function(){
            this.validate();
        },
        modelValue(newValue) {
          // Force update the local input value when the parent updates the modelValue
          if (newValue!==null) {
              this.validate(newValue);
          }
        }
    }
}
</script>

<style>


.floatLabelNormal, .floatLabelSelect {
    position: relative;
    margin-bottom:10px;
}
.floatLabelNormal > span {
    position: absolute;
    font-size:14px;
    color:black;
    left:10px;
    top:10px;
    transition: all .3s cubic-bezier(0.000, 0.205, 0.035, 1.570);

}
.floatLabelNormal > input, .floatLabelSelect > select, .floatLabelNormal > textarea {
    font-size: 14px;
    width: 100%;
    height: 45px;
    outline: none;
    border:1px lightgray solid;
    padding-left:10px;
    padding-top:0px;
    border-radius:4px;
}
.floatLabelNormal > textarea {
    height:auto;
}

.floatLabelSmall > span, .floatLabelNormal > input:focus + .hint, .floatLabelNormal > textarea:focus + .hint {
    font-size: 9px;
    top:5px;
    color:gray;
}

.floatLabelSmall > input, .floatLabelNormal > input:focus {
    padding-top: 12px;
}  
.floatLabelNormal > textarea, .floatLabelNormal > textarea:focus {
    padding-top: 20px;
}
.floatLabelNormal strong, .floatLabelSelect strong {
    position: absolute;
    font-size: 12px;
    top:3px;
    right:10px;
    color:red;
    font-weight: normal;
    font-style: italic;
}

.floatLabelSelect > select {
    padding-left:10px;
    padding-top: 12px;
}

.floatLabelSelect > span {
    position: absolute;
    font-size: 9px;
    top:5px;
    left:10px;
    color:gray;
}


.allCapsUpper > input {
    text-transform: uppercase;
}

.allCapsLower > input {
    text-transform: lowercase;
}

/* datums */
.customFormDate {
  border:1px lightgray solid;
  border-radius: 4px;
  height:45px;
  padding-left:10px;
  padding-top: 15px;
  display: flex;
}
.customFormDate > span {
    font-size: 9px;
    color: rgb(53, 53, 53);
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 10px;
    padding-top:8px;
}

.mySelect {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;       /* Remove default arrow */
    -ms-word-break: normal;
    word-break: normal;
    background-color: #ffffff;
    background-image: none;

    border:1px solid lightgray;
    padding-left:10px;
}

.customFormDate > select {

    border:1px dotted lightgray;
    font-size: 12px;
    margin:0;
    padding:0px 10px;
    height:20px;
    margin-top:5px;
    margin-right:10px;
    border-radius: 0px;
}

.customFormDate select::-ms-expand {
  display: none;
}

/*
.required > i {
    color:rgb(77, 77, 77);
    position: absolute;
    right:10px;
    top:0px;
    font-size:24px;
}
*/

.invalidValue > input, .invalidValue > select, .invalidValue > textarea {
    border-color:red;
    border-width:2px;
}
.invalidValue > i {
    color:red;
}   

.required > i > span {
    position: absolute;
    display: none;
}
.invalidValue > i > span {
    position: absolute;
    display:block;
    font-size:24px;
    top:3px;
    right:14px;
    font-size: 12px;
}

</style>
