// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.customerForm[data-v-45dd3b4c]{
    position: relative;
    left: 0;
    text-align: left;
}
.customerFormHeader[data-v-45dd3b4c] {
    min-height: 100px;
    width: 100%;
    padding: 20px 20px 20px 20px;
    position: relative;
    background-color: whitesmoke;
    border-top-left-radius: 10px;
}
.customerFormTitle[data-v-45dd3b4c] {
    font-weight: bold;
    font-size: 28px;  
    font-family: 'Nunito', sans-serif;
    max-width: 550px;
    color: rgb(65, 65, 65);
}
.customerFormBody[data-v-45dd3b4c] {
    padding: 20px 20px 20px 20px;
    font-family: 'Didact Gothic', sans-serif;
}
.customerFormBodyTitle[data-v-45dd3b4c] {
    font-weight: bold;
    font-size: 22px;  
    font-family: 'Nunito', sans-serif;
    max-width: 550px;
    margin-bottom: 30px;
}
.blockContainer[data-v-45dd3b4c] {
    margin-bottom: 30px;
    max-width:600px;
}
.blockContainerTitle[data-v-45dd3b4c] {
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 22px;
}
.nameAndBirthContainer[data-v-45dd3b4c] {    
    margin-top: 30px;
}
.rightAlignBtnDirectOrder[data-v-45dd3b4c] {
  margin-left: 450px;
}


/*
.btnVerderWinkelen {
    margin-left: 10px;
}
.nextStepBtn {
    margin-left: 150px;
}
.rightAlignBtnDirectOrder {
    margin-left: 450px;
}
.rightAlignBtnDirectCart {
    margin-left: 300px;
}
*/
.buttonWrapper[data-v-45dd3b4c] {
    display: flex;
}
.stepBackBtn[data-v-45dd3b4c] {
  margin-right:15px
}
.btnVerderWinkelen[data-v-45dd3b4c] {
  margin-right:15px
}
.nextStepBtn[data-v-45dd3b4c] {
    margin-left: auto;
}
.lijn[data-v-45dd3b4c] {
  border-top: 1px solid lightgray;
  width: 100%;
  padding: 0;
  margin: 30px 0px 30px 0px;
}
input:focus ~ .floating-label[data-v-45dd3b4c],
 input:not(:focus):valid ~ .floating-label[data-v-45dd3b4c] {
  left: -240px;
  top: -16px;
  font-size: 10px;
  opacity: 1;
  color: gray;
}
.floating-label[data-v-45dd3b4c] {
  position: relative;
  pointer-events: none;
  left: -240px;
  top: -4px;
  /*transition: 0.2s ease-out all; */
  transition: all .3s cubic-bezier(0.000, 0.205, 0.035, 1.570);
}
.inputText[data-v-45dd3b4c] {
  font-size: 14px;
  width: 250px;
  height: 45px;
  outline: none;
  border:1px lightgray solid;
  padding-left:8px;
  padding-top:12px;
  border-radius:4px;
}
.inputWrapper[data-v-45dd3b4c]  {
  margin-bottom: 10px;
}
.inputFirstLetterCap[data-v-45dd3b4c]::first-letter {
    text-transform: uppercase;
}
.textAgeWarning[data-v-45dd3b4c] {
    font-size: 14px;
}
.labelTerms[data-v-45dd3b4c] {
    cursor: pointer;
    display: block;
    margin-top: -23px;
    margin-left: 20px;
}
.labelLeesMeer[data-v-45dd3b4c] {
    color: #2d468a;
    -webkit-text-decoration: underline #2d468a;
            text-decoration: underline #2d468a;
}
.btnRight[data-v-45dd3b4c] {
    margin-left:auto;
}
    

/*
.floatLabelNormal, .floatLabelSelect {
    position: relative;
    margin-bottom:10px;
}
.floatLabelNormal > span {
    position: absolute;
    font-size:14px;
    color:black;
    left:10px;
    top:10px;
    transition: all .3s cubic-bezier(0.000, 0.205, 0.035, 1.570);

}
.floatLabelNormal > input, .floatLabelSelect > select {
    font-size: 14px;
    width: 100%;
    height: 45px;
    outline: none;
    border:1px lightgray solid;
    padding-left:10px;
    padding-top:0px;
    border-radius:4px;
}

.floatLabelSmall > span, .floatLabelNormal > input:focus + span {
    font-size: 9px;
    top:5px;
    color:gray;
}

.floatLabelSmall > input, .floatLabelNormal > input:focus {
    padding-top: 12px;
    
}  

.floatLabelSelect > select {
    padding-left:10px;
    padding-top: 12px;
}

.floatLabelSelect > span {
    position: absolute;
    font-size: 9px;
    top:5px;
    left:10px;
    color:gray;
}


.allCapsUpper > input {
    text-transform: uppercase;
}

.allCapsLower > input {
    text-transform: lowercase;
}
*/

/* datums */
.customFormDate[data-v-45dd3b4c] {
  border:1px lightgray solid;
  border-radius: 4px;
  height:45px;
  padding-left:10px;
  padding-top: 15px;
  display: flex;
}
.customFormDate > span[data-v-45dd3b4c] {
    font-size: 9px;
    color: rgb(53, 53, 53);
    font-weight: bold;
    text-transform: uppercase;
    margin-right: 10px;
    padding-top:8px;
}
.mySelect[data-v-45dd3b4c] {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;       /* Remove default arrow */
    -ms-word-break: normal;
    word-break: normal;
    background-color: #ffffff;
    background-image: none;

    border:1px solid lightgray;
    padding-left:10px;
}
.customFormDate > select[data-v-45dd3b4c] {

    border:1px dotted lightgray;
    font-size: 12px;
    margin:0;
    padding:0px 10px;
    height:20px;
    margin-top:5px;
    margin-right:10px;
    border-radius: 0px;
}
.customFormDate select[data-v-45dd3b4c]::-ms-expand {
  display: none;
}
@media(max-width: 1120px) {
.customerFormHeader[data-v-45dd3b4c] {
        border-top-right-radius: 10px;
}
.rightAlignBtnDirectCart[data-v-45dd3b4c] {
        margin-left: auto;
}
}
@media(max-width: 550px) {
.btnVerderWinkelen[data-v-45dd3b4c], .stepBackBtn[data-v-45dd3b4c] {
        margin-right: 10px;
        margin-left: 0px;
}
}
@media(max-width: 450px) {
.floating-label[data-v-45dd3b4c] {
        position: relative;
        pointer-events: none;
        left: 10px;
        top: -32px;
        display: inline-block;
        /*transition: 0.2s ease-out all; */
        transition: all .3s cubic-bezier(0.000, 0.205, 0.035, 1.570);
}
input:focus ~ .floating-label[data-v-45dd3b4c],
    input:not(:focus):valid ~ .floating-label[data-v-45dd3b4c]{
        left: 10px;
        top: -46px;
        font-size: 10px;
        opacity: 1;
        color: gray;
        display: inline-block;
}
}







`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
