<template>
    <div class="shoppingList">
            <Alert :title="alertTitle" :text="alertText" :alertType="alertType" :active="active" :time="alertTime" :okButtonActive="okButtonActive" @confirmAnswer="confirm" @closeAlert="closeAlert"></Alert>
            <div class="shoppingListContainer" v-if="state.cart.bestelItems != null && state.cart.bestelItems.length != 0">       
                <div v-for="(item, index) in state.cart.bestelItems" v-bind:key="index">
                    <TicketRemover :ticketType="item" @removeThisItem="removeBestelItemFromRemover"></TicketRemover>
                    <div v-if="(index+1) < state.cart.bestelItems.length">
                        <div class="lijn"></div>
                    </div>
                </div>          

                <div class="subtotaallijn" v-if="state.hasPaymentTransactionCost == 1 && state.cart.totalPriceTickets > 0 && state.cart.transactionCost > 0"></div>

                <div class="transactiekostenContainer" v-if="state.hasPaymentTransactionCost == 1 && state.cart.totalPriceTickets > 0 && state.cart.transactionCost > 0">
                    <div class="transactiekostenTextWrapper">  
                        <div v-if="state.selectedPaymentMethod.Name == ''" class="labelTransactiekosten">{{translateString('Checkout', 'Transactiekosten')}} ({{state.paymentMethodDefault}})</div>
                        <div v-else class="labelTransactiekosten">{{translateString('Checkout', 'Transactiekosten')}} ({{state.selectedPaymentMethod.Name}})</div>  <!-- state.cart.method -->
                    </div>
                    <div class="transactiekostenCurrencyWrapper">
                        <div v-if="allowEditing">
                            <div v-if="state.selectedPaymentMethod.Name == ''" class="transactiekostenCurrency">{{getMyCurrencyFormatted(state.cart.transactionCost)}}</div>
                            <div v-else class="transactiekostenCurrency">{{getMyCurrencyFormatted(getTransactionCosts)}}</div>
                        </div>
                        <div v-else>
                            <div v-if="state.selectedPaymentMethod.Name == ''" class="transactiekostenCurrencyRightAlign">{{getMyCurrencyFormatted(state.paymentMethodDefaultCost)}}</div>
                            <div v-else class="transactiekostenCurrencyRightAlign">{{getMyCurrencyFormatted(getTransactionCosts)}}</div>
                        </div>
                    </div>         
                </div>   

                <div class="donationContainer" v-if="checkIfDonationHasToBeDisplayed() == '1'">
                    <Donation /> 
                </div>

                <div style="clear:both;"></div>              

                <div class="totaallijn"></div>

                <div class="totaalContainer">
                    <div class="totaalTextWrapper">
                        <div class="labelTotaal">{{translateString('Checkout', 'Totaal')}}</div>
                        <div class="labelKosten">{{translateString('Checkout', 'InclusiefServiceEnTransactiekosten')}}</div>
                    </div>

                    <div v-if="allowEditing" class="totaalPrijsWrapper">
                        <div class="totaalPrice">{{getMyCurrencyFormatted(totalPrice)}}</div>  
                    </div>    
                    <div v-else class="totaalPrijsWrapperWithoutRemover">
                        <div class="totaalPrice">{{getMyCurrencyFormatted(totalPrice)}}</div>  
                    </div>   

                    <div v-if="allowEditing" class="removerContainer">
                        <span v-on:click="removeAllBestelItems" class="remover"></span>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="NoItems">{{translateString('Checkout', 'GeenItemsInWinkelwagen')}}</div>
            </div>

            <!-- Timer gebaseerd op validUntil uit de cart -->
            <div class="countdown" v-bind:class="{timeIsTicking: minutes <= 5}" v-if="showTimer"> 
                {{translateString('Checkout', 'UHeeftNogOmDeBestellingAfTeRonden', {tijd: getLengthOfTime})}}
            </div>

            <!--
            <div v-if="allowEditing" class="buttonBoxWrapper"> 
                <div v-if="state.cart.totalPrice == 0 && state.step == 'klantinformatie' && state.cart.bestelItems.length > 0 && !hasPersonalTickets()" class="btnImagePaymentWrapper">
                    <button class="btn btnGreen btnNuBestellen" v-on:click="orderTickets">{{translateString('Checkout', 'NuBestellen')}}</button> 
                </div>
            </div>
            <div v-else class="buttonBoxWrapperOnlyContinueShopping"> 
                <div v-if="state.cart.totalPrice > 0 && state.step == 'betalen' && state.cart.bestelItems.length > 0" class="btnImagePaymentWrapper">
                    <button class="btn btnGreen btnNuBetalen" v-on:click="startPayment"><img v-if="state.selectedPaymentMethod != {}" class="imageSelectedPaymentMethod" :src="state.selectedPaymentMethod.Image" />{{translateString('Checkout', 'NuBetalen')}}</button> 
                </div>          
                <div v-else-if="state.cart.totalPrice == 0 && state.step == 'personaliseren' && state.cart.bestelItems.length > 0 && hasPersonalTickets()" class="btnImagePaymentWrapper">
                    <button class="btn btnGreen btnNuBestellen" v-on:click="orderTickets">{{translateString('Checkout', 'NuBestellen')}}</button> 
                </div> 
            </div>
            -->
    </div>
</template>

<script>
import {store} from '@/store/index';
import {Functions} from '@/util/util';
import TicketRemover from './TicketRemover';
import {Selector} from '@/language/language.js';
import Donation from '../components/Donation';
import Alert from '@/components/Alert';
import router from '@/router/index.js';
export default {
    name: 'ShoppingList',
    data() {
        return {
            state: store.state,
            priceOfTickets: 0,
            alertTitle: '',
            alertText: '',
            alertType: '',
            alertTime: null,
            active: false,
            answer: null,
            okButtonActive: 0,
            timesThePayButtonIsClicked: 0,
            minutes: '',
            seconds: '',
            hasTimer: false,
            timerObj: null,
            autoclose: false
        }
    },
    components: {
        TicketRemover,
        Donation,
        Alert,
    },
    setup() {
        // als de selectedPaymentMethod in de localStorage zit, sla deze dan ook op in de store
        if(localStorage.getItem("selectedPaymentMethod") !== null) {
            store.dispatch('setSelectedPaymentMethod', JSON.parse(localStorage.getItem("selectedPaymentMethod")))
        }
    },
    created() {
        Functions.validateSelectedEvent();
        this.setTimer();
    },
    computed: {
        showTimer() {
            if(this.state.cart.bestelItems.length > 0 && (this.minutes > 0 || this.seconds>0)){
                return true
            } else {
                return false
            }
        },
        allowEditing() {
            var isEditingAllowed = true;
            if(store.state.step != 'bestellen' && store.state.step != "klantinformatie" && store.state.step != "changeoverzicht") {
                isEditingAllowed = false;
            }
            if (store.state.isDirectOrder) {
                isEditingAllowed = false;
            }
            return isEditingAllowed;
        },
        getTransactionCosts() {
            var transactionCostsToReturn;

            if(this.state.selectedPaymentMethod.Key != ''){
                Object.keys(store.state.transactionCost).forEach(key => {
                var keyFromSelectedMethod = this.state.selectedPaymentMethod.Key;
                transactionCostsToReturn = this.state.transactionCost[keyFromSelectedMethod];})
            }
            
            return transactionCostsToReturn;
        },
        totalPrice() {
            if(store.state.cart.totalPriceTickets == 0) {
                return store.state.cart.totalPriceTickets + store.state.cart.donation;
            }
            else if(store.state.selectedPaymentMethod.Key == '') {
                // Alleen defualt kosten als er ook iets af te rekenen is (dus niet bij gratis tickets)
                if (store.state.cart.totalPriceTickets > 0) {
                    return store.state.cart.totalPriceTickets + store.state.cart.donation + store.state.paymentMethodDefaultCost;
                } else {
                    return 0;
                }
            } else {
                var transactionCost = this.getTransactionCosts;
                var totalPrice = transactionCost + store.state.cart.donation + store.state.cart.totalPriceTickets;
                return totalPrice;
            }
        },
        checkIfSelectedPaymentIsCorrect() {
            // return isCorrect -> 1: is inderdaad correct / 2: Nog geen paymentMethod geselecteerd / 3: Nog geen issuer geselecteerd

            var isCorrect;

            if(this.state.cart.method == '') {
                isCorrect = 2;
            } else {     
                Object.keys(this.state.paymentMethods).forEach(key => {
                    var payment = this.state.paymentMethods[key];
                    if(payment.Key == this.state.selectedPaymentMethod.Key) {
                        if(payment.issuers.length > 0){
                            // als method issuers heeft dan moet er ook een ingevuld worden
                            if(this.state.selectedPaymentMethod.selectedIssuer == '' || this.state.selectedPaymentMethod.selectedIssuer == undefined) {
                                // geen issuer geselecteerd
                                isCorrect = 3;
                                return;
                            }
                            else {
                                // wel issuer geselecteerd
                                isCorrect = 1;
                                return;
                            }
                        }
                        else {
                            // method heeft geen issuers, dus dan zijn alle belangrijke dingen ingevuld                    
                            isCorrect = 1;
                            return;
                        }
                    }
                })
            }
            return isCorrect;
        },
        getLengthOfTime() {
            var text = '';
            var time = '';
            var lang = store.state.localeLanguage;

            if(this.minutes > 0) {
                time = this.minutes;
                if(lang=='nl-NL') {text = this.minutes > 1 ? 'minuten' : 'minuut'}
                if(lang=='nl-BE') {text = this.minutes > 1 ? 'minuten' : 'minuut'}
                if(lang=='en-EN') {text = this.minutes > 1 ? 'minutes' : 'minute'}
                if(lang=='en-GB') {text = this.minutes > 1 ? 'minutes' : 'minute'}     
                if(lang=='fr-BE') {text = this.minutes > 1 ? 'minutes' : 'minute'}
                if(lang=='fr-FR') {text = this.minutes > 1 ? 'minutes' : 'minute'}
                if(lang=='de-DE') {text = this.minutes > 1 ? 'Minuten' : 'Minute'}
                if(lang=='nl-NL-br') {text = this.minutes > 1 ? 'minuten' : 'minuut'}            
            } else if(this.seconds > 0){
                time = this.seconds;
                if(lang=='nl-NL') {text = this.seconds > 1 ? 'seconden' : 'seconde'}
                if(lang=='nl-BE') {text = this.seconds > 1 ? 'seconden' : 'seconde'}
                if(lang=='en-EN') {text = this.seconds > 1 ? 'seconds' : 'second'}
                if(lang=='en-GB') {text = this.seconds > 1 ? 'seconds' : 'second'}     
                if(lang=='fr-BE') {text = this.seconds > 1 ? 'secondes' : 'seconde'}
                if(lang=='fr-FR') {text = this.seconds > 1 ? 'secondes' : 'seconde'}
                if(lang=='de-DE') {text = this.seconds > 1 ? 'Sekunden' : 'Sekunde'}
                if(lang=='nl-NL-br') {text = this.seconds > 1 ? 'seconden' : 'seconde'}    
            }
            return time+' '+text;
        }
    },
    methods: {
        translateString(componentOrPageName, stringNotTranslated, ObjectParam) {
            var translatedString;
            if(ObjectParam != undefined) {
                translatedString = Selector.SelectLanguageStringFromLangJSONWithParams(componentOrPageName, stringNotTranslated, ObjectParam)
            } else {
                translatedString = Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated)
            }    
            return translatedString;
        },
        removeAllBestelItems() {
            this.alertType = '';
            this.active = true;
            this.alertText = this.translateString('Alert', 'WinkelwagenLegen');
            
            store.dispatch('emptyCart').then(response => {
                if(response == 'OK') {
                    this.alertTitle = this.translateString('Alert', 'Succesvol');
                    this.alertText = this.translateString('Alert', 'WinkelwagenIsLeeg');
                    this.alertType = 1;
                    this.alertTime = 1000;
                } else {
                    this.alertTitle = this.translateString('Alert', 'Error');
                    this.alertText = this.translateString('Alert', 'WinkelwagenKonNietGeleegdWorden');
                    this.alertType = 2;
                    this.okButtonActive = 1;
                }          
            })        
        },
        // remove een item, op basis van index
        removeBestelItemByIndex(i) {
            this.alertType = '';
            this.active = true;
            this.alertText = this.translateString('Alert', 'TickettypeVerwijderen');

            var itemToRemove = store.state.cart.bestelItems[i]
            
            // accountId, key
            var accountId = itemToRemove.accountId;
            var key = itemToRemove.key

            store.dispatch('delCart', {accountId: accountId, key: key}).then(response => {
                if (response.status === 'OK') {
                    this.alertTitle = this.translateString('Alert', 'Succesvol');
                    this.alertText = this.translateString('Alert', 'TickettypeIsVerwijderdUitDeWinkelwagen');
                    this.alertType = 1;
                    this.alertTime = 1000;
                } else {
                    this.alertTitle = this.translateString('Alert', 'Oeps');
                    this.alertText = response.message;
                    this.alertType = 2;
                    this.alertTime = null;
                    this.okButtonActive = 1;
                }
            })
        },
        removeBestelItemFromRemover(dataFromTicketRemover) {
            for(var i = 0; i < store.state.cart.bestelItems.length; i++) {
                if(store.state.cart.bestelItems[i].key == dataFromTicketRemover.key ) {
                    this.removeBestelItemByIndex(i);
                }
            }

        },
        getMyCurrencyFormatted(price){
            return Functions.formatMyCurrency(price);
        },
        closePopUp() {
            const $body = document.querySelector('body');
            let scrollPosition = store.state.scrollPosition;
            $body.style.removeProperty('overflow');
            $body.style.removeProperty('position');
            $body.style.removeProperty('top');
            $body.style.removeProperty('width');
            window.scrollTo(0, scrollPosition);
            
            store.dispatch('changeCurrentSlot', {slotFrom:'',available:0})
            store.dispatch('closePopUp');
        },
        nextView() {
            if(this.cart.bestelItems.length != 0) {
                var currentStep = store.state.step;
                if(currentStep == 'bestellen') {
                    currentStep = 'klantinformatie';
                    store.dispatch('changeStep', currentStep);
                }
                else if(currentStep == 'klantinformatie'){
                    currentStep = 'betalen';
                    store.dispatch('changeStep', currentStep);
                }
            }
            else {
                this.alertTitle = this.translateString('Alert', 'Oeps');
                this.alertText = this.translateString('Alert', 'ErZittenGeenTicketsInDeWinkelwagen');
                this.alertType = 2;
                this.okButtonActive = 1;
                this.active = true;
            }
        },
        showAlertError(alertMessage) {
            this.alertTitle = this.translateString('Alert', 'Oeps');
            this.alertText = alertMessage;
            this.alertType = 2;
            this.okButtonActive = 1;
            this.active = true;
        },
        orderTickets() {
            var resultCheck = Functions.checkFieldsAndShoppingCart();
            
            if(resultCheck == true) {
                this.alertType = '';
                this.alertText = this.translateString('Alert', 'BestellingVerwerken')
                this.active = true;
                
                var accId = store.state.cart.bestelItems[0].accountId;
                store.dispatch('startPayment', accId).then((response) => {

                    Functions.redirPage('/orderresult/'+response);



                })
            } else {
                this.showAlertError(this.translateString('Alert', resultCheck))
            }
        },
        startPayment() {
            if(this.checkIfSelectedPaymentIsCorrect == 1) {
                if(this.timesThePayButtonIsClicked == 0) {
                    this.alertType = '';
                    this.active = true;
                    this.alertText = this.translateString('Alert', 'BestellingVerwerken')

                    this.timesThePayButtonIsClicked = this.timesThePayButtonIsClicked +1;
                    var accId = store.state.cart.bestelItems[0].accountId;
                    store.dispatch('startPayment', accId).then(response => {
                        if(response == undefined) {
                            this.alertTitle = this.translateString('Alert', 'ErIsIetsMisgegaan');
                            this.alertType = 2;
                            this.active = true;
                            this.alertText = this.translateString('Alert', 'KijkBovenaanDePaginaVoorMeerInformatie');
                            this.okButtonActive = 1;
                        } else {

                        }
                   }); 
                } 
            }  
            else if(this.checkIfSelectedPaymentIsCorrect == 2){
                this.alertTitle = this.translateString('Alert', 'Oeps')
                this.alertType = 2;
                this.active = true;
                this.alertText = this.translateString('Alert', 'JeMoetNogEenBetaalmethodeKiezen');
                this.okButtonActive = 1;
            } else if(this.checkIfSelectedPaymentIsCorrect == 3){
                this.alertTitle = this.translateString('Alert', 'Oeps');
                this.alertType = 2;
                this.active = true;
                this.alertText = this.translateString('Alert', 'JeMoetNogEenBankKiezen');
                this.okButtonActive = 1;
            }         
        },
        closeAlert(close) {
            this.active = close;
            this.alertTitle = ''
            this.alertText = ''
            this.alertType = ''
            this.alertTime = null
            this.answer = null
            this.okButtonActive = 0;

            if (this.autoclose) {
                this.autoclose = false;
                this.closePopUp();
            }

        },
        confirm(answer) {
            this.answer = answer
        },
        checkIfDonationHasToBeDisplayed() {
            if(this.state.isDirectOrder == false){
                if(store.state.cart.bestelItems.length > 0) {
                    var accountID = store.state.cart.bestelItems[0].accountId;
                    for(var i = 0; i < store.state.events.events.items.length; i++) {
                    if(store.state.events.events.items[i].accAccountID == accountID) {
                        // op basis van accountId, geef de accPaymentDonate terug
                        return store.state.events.events.items[i].accPaymentDonate;
                    }
                }
                } else {
                    // anders geef de accPaymentDonate van het geselecteerde event terug
                    return this.state.selectedEvent.accPaymentDonate.items.nl;
                } 
            } else {
                // als isDirectOrder true is, dan return de paymentDonate uit de state
                return this.state.paymentDonate;
            }    
        },
        setTimer() {

            if (this.hasTimer) {return; }

            var self = this;

            // Update the count down every 1 second
            this.timerObj = setInterval(function() {
                if (self.state.cart.bestelItems.length==0) {
                    return;
                }


                // Set the date we're counting down to
                var countDownDate = new Date(self.state.cart.validUntil).getTime();
                //var countDownDate = new Date("2021-06-22 12:30:00").getTime();

                // Get today's date and time
                var now = new Date().getTime();
    
                // Find the distance between now and the count down date
                var distance = countDownDate - now;
    
                // Time calculations for minutes and seconds
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                var seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
                // If the count down is over, stop
                if (distance < 0) {

                    store.dispatch('emptyCart').then(response => {
                        self.alertTitle = self.translateString('Alert', 'TijdIsOm')
                        self.alertText = self.translateString('Alert', 'DeWinkelwagenIsGeleegd')
                        self.alertType = 2;
                        self.okButtonActive = 1;

                        self.active = true;

                        if (store.state.isPopUpOpen && !store.state.isClientPage) {
                            self.autoclose = true;
                        }

                    })
                }

                if(minutes >= 1) {
                    self.minutes = minutes;
                } else {
                    self.minutes = 0;
                    self.seconds = seconds;
                }
//                console.log('over ', self.minutes + ":" + seconds);

            }, 1000);     
        },
        
    },
}


</script>

<style scoped>
.shoppingList {
    position: relative;
    padding: 20px 20px 0px 20px;
}

.lijn {
  border-top: 1px solid lightgray;
  width: 100%;
  padding: 0;
  margin: 10px 0px 10px 0px;
}
.subtotaallijn {
  border-top: 1px solid gray;
  width: 100%;
  padding: 0;
  margin: 30px 0px 10px 0px;  
}
.totaallijn {
  border-top: 1px solid black;
  width: 100%;
  padding: 0;
  margin: 30px 0px 10px 0px;  
}

.totaalLabelWrapper, .subTotaalLabelWrapper {
    width: 30%;
}
.labelTotaal, .labelSubTotaal {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    margin-right: 10px;
}
.labelTransactiekosten {
    margin-right: 10px;
    color: grey;
}
.labelKosten, .labelSubKosten {
    color: grey;
}
.totaalPrice {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
}
.subTotaalPrice {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
}
.transactiekostenCurrency {
    color: grey;
    text-align: right;
    width: 100px;
    margin-right: 21px;
    font-size: 16px;
}
.transactiekostenCurrencyRightAlign {
    color: grey;
    text-align: right;
    width: 100px;
    margin-right: 0px;
    font-size: 16px;
}
.transactiekostenContainer {
    display: flex;
    margin-top: 10px;
    line-height: 18px;
}
.totaalContainer, .subTotaalContainer{
    display: flex;
}
.totaalTextWrapper, .subTotaalTextWrapper, .transactiekostenTextWrapper {
    width: 90%;
}
.totaalPrijsWrapper {
    text-align: right;
    width: 100px;
    margin-right: 10px;
}
.totaalPrijsWrapperWithoutRemover {
    text-align: right;
    width: 90px;
    margin-right: 0px;
}
.subTotaalPriceWrapper {
    text-align: right;
    width: 100px;
    margin-right: 21px;
}
span:after {
  position: relative;
  content: '✖';
  color: red;
  cursor: pointer;
}
.removerContainer {  
    text-align: right;
    padding-top: 2px;
}

.NoItems {
    text-align: center;
    margin-bottom: 90px;
}

.donationContainer {
    margin-top: 10px;
}
.buttonBoxWrapper {
    margin-top: 30px;
    position:relative;
    display: flex;
    justify-content: flex-end;
}
.buttonBoxWrapperOnlyContinueShopping {
    margin-top: 30px;
    position: relative;
    text-align: right;
}
.btnBestellingAfronden {
    margin-right: 0px;
}
.btnVerderWinkelen {
    margin-right: 20px;
}

.btnNuBestellen {
    margin-top: 0px;
    min-height: 50px;
    position: relative;
    text-align: center;
    font-size:14px;
}
.btnNuBetalen {
    margin-top: 0px;
    /*height: 38px;*/
    min-height: 50px;
    width:auto!important;
    min-width: 200px!important;
    position: relative;
    padding-left:40px;
    text-align: center;
    font-size:14px;
}
.imageSelectedPaymentMethod {
    width: 30px;
    position: absolute;
    left:10px;
    top:14px;
    border-radius: 4px;;
}

.countdown {
    margin-top: 20px;
}
.timeIsTicking {
    color: red;
}
</style>