// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#flags[data-v-3649159c] {
    position: absolute;
    right: 25px;
    top: 25px;
}
#default-flag[data-v-3649159c] {
    position: relative;
    float: right;
    width: 40px;
    cursor: pointer;
    border-radius: 50px;
    background-color: white;
    border: 2px solid white;
}
.other-flags[data-v-3649159c] {
    margin-right:-48px;
    margin-top:50px;
    position:relative;
    display: none;
    cursor: pointer;
    font-size: 12px; 
    text-align: center;
    font-weight: bold;
    background-color: rgb(255, 255, 255);
    width: 140px;
    border: solid 1px rgb(221, 221, 221);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    z-index:1;
}
.other-flags-active[data-v-3649159c] {
    display: block;
}
.other-flag[data-v-3649159c] {
    padding: 8px;
    box-sizing: border-box;
    vertical-align: middle;
    height:57px; 
    /* background-color: red; */
    clear: both;
}
.other-flag[data-v-3649159c]:hover {
    background-color: rgb(174, 213, 252);
}
.other-flag span[data-v-3649159c] {
    float:left;
    padding-top:14px;
}
.other-flag img[data-v-3649159c] {
    width: 40px;
    float:right;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
