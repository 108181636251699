<template>
    <div class="seatingscreen">
        <Alert :title="alertTitle" :text="alertText" :alertType="alertType" :active="active" :time="alertTime" :okButtonActive="okButtonActive" @confirmAnswer="setAnswerFromAlert" @closeAlert="closeAlert"></Alert>
        <ErrorMessage/>
        <WarningMessage/>
        <div v-if="seats.length == 0" class="seatingplanLoading">
            <img src="@/assets/Loader.gif"/><br/>
            <br/>
            <label> {{ translateString('Alert', 'BeschikbaarheidControleren') }}</label>
        </div>

        <div v-if="seats.length > 0" class="stoelenplan_wrapper">
            <div class="zoomwraper">
                <h2> <button @click="Zoomin" class="zoombnt btnWhite">+</button> ZOOM <button @click="Zoomout" class="zoombnt btnWhite">-</button> </h2>
            </div>
            <SeatingLegenda/>
            <div ref="stoelenplan" class="stoelenplan" v-if="state.seatingplan != null" :style="{ transform: 'scale('+ zoom + ')' }" @touchstart="startDrag($event,1)" @touchend="endDrag($event)" @touchmove="moveDrag($event)">
                
                <div class="blok" v-for="blok in bloks" :key="blok" :style="{ left: AbsoluteLeft(blok.absolute), top: AbsoluteTop(blok.absolute), 
                width: Width(blok.value1), height: Height(blok.value1)}">
                </div>

                <div class="seat" v-bind:class="{'sold' :seat.state == 'Sold', 'blocked' :seat.state == 'Blocked', 'reserved' :seat.state == 'Reserved', 'selected' :isSelected(seat.value1)}" 
                v-for="seat in seats" :key="seat" :style="{ left: AbsoluteLeft(seat.absolute), top: AbsoluteTop(seat.absolute), borderTop: RankColor(seat.rank)}"
                @click="Select(seat.value1, seat.rank, seat.state)">
                    {{seat.value1.split('|')[2]}}
                </div>

                <div class="label" v-for="label in labels" :key="label" :style="{ left: AbsoluteLeft(label.absolute), top: AbsoluteTop(label.absolute)}">
                    {{label.value1}}
                </div>

                <div class="line" v-for="line in lines" :key="line" :style="{ left: AbsoluteLeft(line.absolute), top: AbsoluteTop(line.absolute)}">
                </div>
            </div>
        </div>

        <div v-if="seats.length > 0" class="buttons">
            <button class="btn btnGreen" @click="btnSSClose"> Sluiten </button>
        </div>
        <SeatPopUp :selectedSeat="svalseat" :defaultTicketType="defaulttickettype" @commitseat="CommitSeat" v-if="state.seatpopupshow == true"/>
    </div>

</template>

<script>
import {store} from '../store/index';
import SeatingLegenda from './SeatingLegenda.vue';
import SeatPopUp from './SeatPopUp.vue';
import {Selector} from '@/language/language.js';
import ErrorMessage from './ErrorMessage.vue';
import WarningMessage from './WarningMessage.vue';
import Alert from './Alert.vue';

export default {
    components:{
        SeatingLegenda,
        SeatPopUp,
        ErrorMessage,
        WarningMessage,
        Alert
    },
    setup() {
        return {
            state: store.state,
        }
    },
    data(){
        return{     
            alertTitle: '',
            alertText: '',
            alertType: '',
            active: false,
            alertTime: null,
            okButtonActive: 0,       
            seats: store.state.seatingplan.seats,
            bloks: store.state.seatingplan.bloks,
            labels: store.state.seatingplan.labels,
            lines: store.state.seatingplan.lines,
            zoom: 1,
            defaulttickettype: store.state.defaultseatingplantickettype,
            touchStartDistance:0,
            svalseat: null,
        }
    },
    methods:{
        translateString(componentOrPageName, stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated)
            return translatedString;
        },
        startDrag(event) {
            if (event.touches.length==2) {
                this.touchStartDistance =  Math.hypot(event.touches[1].clientX - event.touches[0].clientX, event.touches[1].clientY - event.touches[0].clientY);
                event.preventDefault();
                event.stopPropagation();
            }
        },
        moveDrag(event) {
            if (event.touches.length==2) {
                var nieuweAfstand =  Math.hypot(event.touches[1].clientX - event.touches[0].clientX, event.touches[1].clientY - event.touches[0].clientY);

                // Positie werkt nog niet.
                var htmlObj = this.$refs.stoelenplan;
                var x =  (event.touches[0].clientX - event.touches[1].clientX) * this.zoom;
                var y =  (event.touches[0].clientY - event.touches[1].clientY) * this.zoom;
                htmlObj.style.transform = "translate("+x+"px,"+y+"px)";                

                this.zoom = (nieuweAfstand / this.touchStartDistance);

                event.preventDefault();
                event.stopPropagation();                
            }
        },
        endDrag(event) {
            return false;
        },
        isSelected(seatnumb){
            var selectedeventID = this.state.selectedEvent.ID;
            //loop door de winkelwagen heen
            for(var i = 0; i < this.state.cart.bestelItems.length; i++) {     
                let bestelitemscart = this.state.cart.bestelItems[i]
                //checken of een van de stoelen overeen komen               
                if(bestelitemscart.seat == seatnumb && bestelitemscart.eventID == selectedeventID) {
                    //return true als een stoel overeen komt 
                    return true;
                }
            }
            //return false als geen van de stoelnummers overeen komt
            return false;
        },
        RankColor(seatrankc){

            //rank kleur verwerken
            let colorseattop = '3px solid gray';
            console.log(this.ranks)
            store.state.ranks.items.forEach(rank => {
                console.log(rank.name + " "+ seatrankc)
                if (rank.name == seatrankc) {
                    let color = rank.color;
                    colorseattop= '3px solid ' + color;
                }
            });
            return colorseattop
        },
        AbsoluteLeft(absolute){
            let aleft = absolute.split('|')[0];
            if (aleft != '') {
                return aleft;
            }
            else{
                aleft = '50px'
                return aleft;
            }
        },
        AbsoluteTop(absolute){
            let atop = absolute.split('|')[1];
            if (atop != '') {
                return atop;
            }
            else{
                atop = '50px'
                return atop
            }
        },
        Width(value){
            let width = value.split('|')[0];
            if (width != '') {
                width = width + 'em';
                return width;
            }
            else{
                width = '5em'
                return width
            }
        },
        Height(value){
            let height = value.split('|')[1];
            if (height != '') {
                height = height + 'em';
                return height;
            }
            else{
                height = '5em'
                return height
            }
        },
        EndpointCheck(seatnumb){

            let seatindex = 0;
            let endpointright = 0;
            let endpointleft = 0;
            let row = [];

            for (let index = 0; index < this.seats.length; index++) {
                if(this.seats[index].value1 == seatnumb){
                    seatindex = index;
                }
                else{
                    //doe niets
                }
            }

            for (let index = seatindex; index < this.seats.length; index++) {
                if(this.seats[index].endpoint == 1 && endpointright == 0){
                    if (this.seats[index+1].endpoint == 0) {
                        //doe niets
                    }
                    else{
                        endpointright = index;
                        break;
                    }
                }
                else{
                    //doe niets
                }
            }

            for (let index = seatindex; index > 1; index--) {
                if(this.seats[index].endpoint == 1 && endpointleft == 0){
                    if (this.seats[index-1].endpoint == 0) {
                        //doe niets
                    }
                    else{
                        endpointleft = index;
                        break;
                    }
                }
                else{
                    //doe niets
                }
            }

            for (let index = endpointleft; index < endpointright+1; index++){
                row[row.length] = this.seats[index].value1
            }
            console.log("row: " + row)
            return row;

        },
        CommitSeat(payload){
            //afhandelen pop-up resultaten     
            try {
                store.commit("SAVE_SEATPOPUPSHOW", false);
                this.defaulttickettype = payload.tickettype;
                this.addSeatToCart({rank: payload.seatrank, seatnumb:payload.seatnumb, tickettype: payload.tickettype});

            } catch (error) {

                this.alertTitle = this.translateString('Alert', 'Oeps');
                this.alertText = "er gaat iets mis. probeer het opnieuw";
                this.alertType = 2;
                this.okButtonActive = 1;
                this.active = true;
                this.seats= store.state.seatingplan.seats

            }
        },
        Select(seatnumb, seatrank, seatstate){

            let alreadSelected = this.isSelected(seatnumb);
            // let row = this.EndpointCheck(seatnumb);
            // let selectedinrow = [];
            // let tempindex
            // rightselectedseat = null;
            // rightcount = 1;
            // leftselectedseat = null;
            // leftcount = 1;

            // row.forEach(rowseat =>{
            //     rowseatselected = this.isSelected(rowseat)
            //     if (rowseatselected == true) {
            //         selectedinrow[selectedinrow.length] = rowseat
            //     }
            //     else{
            //         // doe niets
            //     }
            // });

            // for (let index = 0; index < row.length; index++) {
            //     if(seatnumb == row[index]){
            //         tempindex = index
            //         if (this.isSelected(row[tempindex - 1]) == true || this.isSelected(row[tempindex + 1]) == true) {
            //             // doe niets
            //         }
            //         else{
            //             for (let index = tempindex; index < row.length; index++){
            //                 if(this.isSelected(row[index])){
            //                     rightselectedseat = index
            //                     rightcount++
            //                 }                           
            //             }
            //             for (let index = tempindex; index < row.length; index--){
            //                 if(this.isSelected(row[index])){
            //                     leftselectedseat = index
            //                     leftcount++
            //                 }                           
            //             }
            //         }
            //         break;
            //     }
            //     if (leftcount < rightcount) {
            //         seatnumb = row[leftselectedseat+1]
            //     } 
            //     else if (leftcount > rightcount) {
            //         seatnumb = row[rightselectedseat - 1]
            //     }
            // }

            if(seatstate == 'AV') {
                //selectie checken
                if (alreadSelected == true) {
                    //selectie ongedaan maken
                    try {
                        this.delSeatFromCart(seatnumb);
                    } catch (error) {
                        this.alertTitle = this.translateString('Alert', 'Oeps');
                        this.alertText = "er zijn geen tickettypes gevonden voor deze stoel";
                        this.alertType = 2;
                        this.okButtonActive = 1;
                        this.active = true;
                        this.seats= store.state.seatingplan.seats
                    }
                }  
                else if (alreadSelected == false) {

                    let ranks = store.state.ranks.items;
                    let count = 0

                    //rank checken
                    ranks.forEach(rank => {
                        if (rank.name == seatrank) {
                            if (rank.ticketTypes.count > 1){
                                //er zijn meerdere tickettypes voor deze rank
                                let tickettypes = rank.ticketTypes.items;
                                this.svalseat = {seatnumb, seatrank, tickettypes };
                                //popup word aangeroepen
                                store.commit("SAVE_SEATPOPUPSHOW", true);
                            }
                            else if(rank.ticketTypes.count == 1){
                                //als een rank maar een tickettype heef is er geen pop-up nodig
                                try {
                                    this.addSeatToCart({rank: seatrank, seatnumb: seatnumb, tickettype: rank.ticketTypes.items[0].ticketTypeID});
                                } catch (error) {
                                    this.alertTitle = this.translateString('Alert', 'Oeps');
                                    this.alertText = "er zijn geen tickettypes gevonden voor deze stoel";
                                    this.alertType = 2;
                                    this.okButtonActive = 1;
                                    this.active = true;
                                    this.seats= store.state.seatingplan.seats
                                }
                            }
                            else{
                                this.alertTitle = this.translateString('Alert', 'Oeps');
                                this.alertText = "er zijn geen tickettypes gevonden voor deze stoel";
                                this.alertType = 2;
                                this.okButtonActive = 1;
                                this.active = true;
                                this.seats= store.state.seatingplan.seats
                            }
                        }
                        else{
                            //als het rank niet kan worden gevonden wordt de defaulttickettype mee gegeven
                            count++
                            if(count == ranks.length){
                                try {
                                    this.addSeatToCart({rank: seatrank, seatnumb: seatnumb, tickettype: this.defaulttickettype});
                                } catch (error) {
                                    this.alertTitle = this.translateString('Alert', 'Oeps');
                                    this.alertText = "er zijn geen tickettypes gevonden voor deze stoel";
                                    this.alertType = 2;
                                    this.okButtonActive = 1;
                                    this.active = true;
                                    this.seats= store.state.seatingplan.seats
                                }
                            }
                        }
                    });
                }
                else{
                    this.alertTitle = this.translateString('Alert', 'Oeps');
                    this.alertText = "er is iets fout gegaan";
                    this.alertType = 2;
                    this.okButtonActive = 1;
                    this.active = true;
                    this.seats= store.state.seatingplan.seats
                }
            }
            else{
                this.alertTitle = this.translateString('Alert', 'Oeps');
                this.alertText = "je kan deze stoel niet selecteren";
                this.alertType = 2;
                this.okButtonActive = 1;
                this.active = true;
                this.seats= store.state.seatingplan.seats
            }
        },
        Zoomin(){
            this.zoom = this.zoom + 0.1;
        },
        Zoomout(){
            this.zoom = this.zoom - 0.1;
        },
        btnSSClose() {
            //alle benodigde in formatie voor het stoelenplan wordt geleegt en stoelenplan wordt gesloten
            store.commit("EMPTY_SEATINGPLAN")
            store.commit("EMPTY_RANKS")
            store.commit("SAVE_SEATPOPUPSHOW", false)
            store.commit("SAVE_SEATINGSHOW", false)
            store.commit("SAVE_DEFAULT_TICKETTYPE_SEATINGPLAN", null)
        },
        delSeatFromCart(seatnumb) {

            // Opzoeken unieke key die bij deze stoel hoort in de bestellijst.
            var accountId = this.state.selectedEvent.accAccountID;
            var eventID = this.state.selectedEvent.ID;
            var uniqueKey = ""
            for(var i = 0; i < this.state.cart.bestelItems.length; i++) {                    
                if(this.state.cart.bestelItems[i].seat == seatnumb) {
                    //this.removeBestelItemByIndex(i);
                    uniqueKey = this.state.cart.bestelItems[i].key;
                    break;
                }
            }

            if (uniqueKey!="") {
                store.dispatch('delCart', {accountId: accountId, key: uniqueKey}).then(response => {
                  if (response.status === 'OK') {
                    this.alertTitle = this.translateString('Alert', 'Succesvol');
                    this.alertText = this.translateString('Alert', 'TickettypeIsVerwijderdUitDeWinkelwagen');
                    this.alertType = 1;
                    this.alertTime = 1000;
                    this.active = true;
                  } else {
                    this.alertTitle = this.translateString('Alert', 'Oeps');
                    this.alertText = response.message;
                    this.alertType = 2; // Error type
                    this.alertTime = null;
                    this.okButtonActive = 1;
                  }
                });
            }
        },
        addSeatToCart(payload) { /* {"rank":"", "seatnumb":"", "tickettype":""} */

            // payload = {"rank":"Balkon","seatnumb":"Balkon|2|12","tickettype":"7459"};

            console.log(this.state.selectedEvent);
            var accountId = this.state.selectedEvent.accAccountID;
            var eventID = this.state.selectedEvent.ID;
            var ticketTypeID = payload.tickettype;
            var timeSlot = "";
            var price = 0;
            var quantity = 1;
            var seat = payload;

            store.dispatch('addCart', {accountId: accountId, eventID: eventID, ticketTypeID: ticketTypeID, timeSlot: timeSlot, price: price, quantity: quantity, seat: seat }).then(response => {

                store.dispatch('resetPopUpToTop', false); // niet nodig ivm stoelenplan?
                              
                if(response.status == "OK"){

                    this.alertTitle = this.translateString('Alert', 'Succesvol');
                    this.alertText = response.message;
                    this.alertType = 1;
                    this.alertTime = 1000;      
                    this.active = true;

                } else {

                    this.alertTitle = this.translateString('Alert', 'Oeps');
                    this.alertText = response.message;
                    this.alertType = 2; // Error type
                    this.alertTime = null;
                    this.okButtonActive = 1;
                    this.seats= store.state.seatingplan.seats

                }
            })        
        },
        closeAlert(close) {
            this.active = close;
            this.alertTitle = ''
            this.alertText = ''
            this.alertType = ''
            this.alertTime = null
            this.answer = null
            this.okButtonActive = 0;
        }
    }
}
</script>

<style scoped>
.seatingscreen{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    scale: 1;
    background: white;
    padding: 2%;
    margin: 5px;
    z-index: 3;
    font-size: 13px;
}
.stoelenplan_wrapper{
    top: 2%;
    position:relative;
    align-self: center;
    text-align: center;
    background: black;
    width: 100%;
    height: 85%;
    overflow-y: auto;
    overflow-x: auto;
    left: 50%;
    transform: translate(-50%);
    border-radius: 10px;
}
.stoelenplan{
    top: 5%;
    position: relative;
    align-self: center;
    text-align: center;
    background: transparent;
    width: 80%;
    height: 90%;
    transform-origin: top center;
    left: 20%;
}
.seat{
    background: white;
    text-align: center;
    position: absolute;
    border-radius: 0em 0em 0.3em 0.3em;
    padding-top:0.0em;
    width: 1.3em;
    height: 1.3em;
    box-sizing: border-box;
    font-family:Arial, Helvetica, sans-serif;
    cursor: pointer;
}
.sold{
    background: crimson;
    cursor: default;
}
.blocked{
    background: gray;
    cursor: default;
}
.reserved{
    background: orange;
    cursor: default;
}
.selected{
    background: green;
}

.blok{
    background: #555555;
    position: absolute;
    padding-top: 0.7em;
    text-align: center;
}
.label{
    color: aliceblue;
    position: absolute;
}
.line{
    background: black;
    position: absolute;
}

.zoomwraper{
    position: relative;
    text-align: center;
    align-content: center;
    left: 50%;
    top: 10px;
    transform: translate(-50%);
    color: white;
}
.zoombnt{
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
}
.buttons{
    position: relative;
    left: 50%;
    top: 20px;
    font-size: 20px;
    transform: translate(-50%);
}
.seatingplanLoading {
    text-align: center;
}
.seatingplanLoading img {
    margin-top:50px;
    width: 100px;
    height: auto;
}
.seatingplanLoading label {
    font-size: 15px;
    font-weight: bold;
}

</style>