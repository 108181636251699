// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.countdown[data-v-a1f07c7c] {
  display: table;
  margin: auto;
  margin-top: 20px;
}
.countdown__container[data-v-a1f07c7c] {
  position: relative;
  display: table-cell;
}
.countdown__svg[data-v-a1f07c7c] {
  transform: scaleX(-1);
  height: 120px;
  width: 120px;
  margin-right: 20px;
}
.countdown__circle[data-v-a1f07c7c] {
  fill: none;
  stroke: none;
}
.countdown__path-elapsed[data-v-a1f07c7c] {
  stroke-width: 7px;
  stroke: lightgrey;
}
.countdown__path-remaining[data-v-a1f07c7c] {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: orange;
}
.countdown__time[data-v-a1f07c7c] {
  position: absolute;
  font-family: 'Nunito', sans-serif;
  top: -0.5rem;
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 34px;
  color: rgb(110, 110, 110);
}
.countdown__label[data-v-a1f07c7c] {
  position: absolute;
  top: 1.20rem;
  height: 120px;
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: 'Didact Gothic', sans-serif; 
  text-transform: uppercase;
  color: darkgray;
}
@media(max-width: 767px) {
.countdown__svg[data-v-a1f07c7c] {
    height: 10rem;
    width: 5rem;
    margin-right: 0px;
}
.countdown__time[data-v-a1f07c7c] {
    height: 10rem;
    width: 5rem;
    font-size: 20px;
}
.countdown__label[data-v-a1f07c7c] {
    height: 8rem;
    width: 5rem;
    font-size: 10px;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
