// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.maskseatpopup[data-v-3a396647]{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0.2;
    background-color: white;
}
.seatingscreenPopup[data-v-3a396647]{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -85%);
    width: 400px;
    height:220px;
    background: white;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0,0,0,.4);  
    padding-top:20px;
}
.buttons[data-v-3a396647] {
    width:100%;
}
button[data-v-3a396647] {
    display: inline-block;
    width:150px;
    margin-left:5px;
    margin-right:5px;
}
.selector[data-v-3a396647] {
    background: transparent;
    outline: 0;
    color: rgb(46, 46, 46);
    border: 1px solid rgba(0, 0, 0, 1);
    box-shadow: 0 0 0 1px rgb(255 255 255 / 25%);
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position-x: 285px;
    background-position-y: 0px;

    width:310px;
    margin-top:40px;
    margin-bottom: 20px;
    border-radius: 4px;
    padding:5px 5px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
