<template>
    <div class="bestelContainer" v-if="state.isLoaded && hasSelectedEvent">
        <div class="bestelContainer-header">
            <Flag v-once></Flag>
            <div class="eventTitle" v-html="getTranslationFromArray(state.selectedEvent.title.items)"></div>
            <div v-if="getTranslationFromArray(state.selectedEvent.subtitle.items) != ''" class="eventSubtitle" v-html="getTranslationFromArray(state.selectedEvent.subtitle.items)"></div>
            <div class="eventDate">
                <div v-if="state.selectedEvent.evShowDatesOptions == 0">
                    <div>{{ getDateAndTimeFormatted(state.selectedEvent.evFrom, state.selectedEvent.evShowDatesOptions, true, true) }} <!-- {{ getDateAndTimeFormatted(evUntil) }}  --></div>
                </div>
                <div v-if="state.selectedEvent.evShowDatesOptions == 1">
                    <div>{{translateString("t/m")}} {{getDateAndTimeFormatted(state.selectedEvent.evUntil, state.selectedEvent.evShowDatesOptions, true, true)}}</div>
                </div>
                    <div v-if="state.selectedEvent.evShowDatesOptions == 2">
                <div v-if="getDateAndTimeFormatted(state.selectedEvent.evFrom, state.selectedEvent.evShowDatesOptions, true, false) == getDateAndTimeFormatted(state.selectedEvent.evUntil, state.selectedEvent.evShowDatesOptions, true, false)">
                    <div>{{ getDateAndTimeFormatted(state.selectedEvent.evFrom, state.selectedEvent.evShowDatesOptions, true, true)}} - {{getDateAndTimeFormatted(state.selectedEvent.evUntil, state.selectedEvent.evShowDatesOptions, false, true)}}</div>
                </div>
                    <div v-else>
                <div>{{ getDateAndTimeFormatted(state.selectedEvent.evFrom, state.selectedEvent.evShowDatesOptions, true, false)}} - {{getDateAndTimeFormatted(state.selectedEvent.evUntil, state.selectedEvent.evShowDatesOptions, true, false)}}</div>
                    </div>
                </div>
                <div v-if="state.selectedEvent.evShowDatesOptions == 3">
                </div>
                <div v-if="state.selectedEvent.evShowDatesOptions == 99">
                    <div>{{ state.selectedEvent.evPeriodLabel }}</div>
                </div>
            </div>
        </div>
        <div class="bestelContainer-body">
            <TicketOrderList></TicketOrderList>
        </div>
    </div>
</template>

<script>
import {Selector} from '@/language/language.js';
import {store} from '@/store/index';
import {Functions} from '@/util/util.js';
import Flag from '../../Flag.vue';
import TicketOrderList from '../components/TicketOrderList.vue';

export default {
    name: "BestelContainer",
    setup() {
        return {
            state: store.state
        }
    },
    computed: {
        hasSelectedEvent() {
          if (this.state.selectedEvent.ID == undefined) { // Ingeval van een directcart is er geen selected event.
            return false;
          } else {
            return true;
          }
        }
    },
    methods: {
        getTranslationFromArray(arrayWithTransations) {
            if (arrayWithTransations == undefined) { return ''; }
            var stringTranslation = Selector.SelectLanguageStringFromArray(arrayWithTransations)
            return stringTranslation;
        },
        getDateAndTimeFormatted(date, options,returnDate, returnTime) {
            var lengthWeekday = "short";
            var lengthMonth = "short";
            //var returnDate = true;
            //var returnTime = true;
            return Functions.formatDateTime(date, lengthWeekday, lengthMonth, returnDate, returnTime);
        },
        translateString(stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON('General', stringNotTranslated)
            return translatedString;
        },
    },
    components: {
        Flag,
        TicketOrderList
    }
}
</script>

<style scoped>
.bestelContainer {
    position: relative;
    left: 0;
    text-align: left;
}

.bestelContainer-header {
    min-height: 100px;
    width: 100%;
    padding: 20px 20px 20px 20px;
    position: relative;
    background-color: whitesmoke;
    border-top-left-radius: 10px;
}

.eventTitle {
    --max-lines: 2;
    overflow: hidden;
    font-weight: bold;
    font-size: 30px;  
    font-family: 'Nunito', sans-serif;
    max-width: 550px;
    color: rgb(65, 65, 65);
}
.eventSubtitle {
    --max-lines: 2;
    overflow: hidden;
    font-size: 16px;
    font-family: 'Nunito', sans-serif; 
}
.eventDate {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: sandybrown;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Didact Gothic', sans-serif; 
}

@media(max-width: 1120px) {
    .bestelContainer-header {
        border-top-right-radius: 10px;
    }
}
@media(max-width: 1024px) {
    .eventTitle {
        font-size: 22px;
        width: 80%;
    }
    .eventSubtitle {
        font-size: 14px;
        width: 80%;
    }
    
}
</style>