<template>
    <div class="home">
      <div class="header" id="header" v-if="state.isLoaded">
        <HeaderBackground />
        <Flag v-if="state.loadCartResult.errorCode>0"></Flag>
        <TextFit v-once class="shopTitleAuto" style="position: relative; z-index:1; max-height:60px; padding-left:20px; padding-right:20px;" >{{ state.status.shopTitle }}</TextFit>
      </div>
      <div class="wrapper">
        <div class="inner">
          <Checkout v-if="state.loadCartResult.errorCode==0" class="directOrderCheckout"></Checkout>
          <div class="errorContainer" v-if="state.loadCartResult.errorCode>0">
             <br />
             <img class="okIcon" src="@/assets/icons/error-icon.svg" />
             <br />
             <br />
             <label>{{this.translateString('currentError')}}</label>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
</template>

<script>
import {store} from '@/store/index.js'
import Checkout from '@/components/Checkout/CheckoutPopUp.vue'
import HeaderBackground from "@/components/HeaderBackground.vue";
import TextFit from "@/components/TextFit.vue";
import Flag from "@/components/Flag.vue";
import Footer from "@/components/Footer.vue";
import Alert from "@/components/Alert.vue";
import {Selector} from "@/language/language.js";
export default {
    components: {
      Alert,
      Footer,
      Flag, TextFit,
      HeaderBackground,
        Checkout
    },
    methods: {
      translateString() {

        // Vertalen bestaande codes
        if(this.state.loadCartResult.errorCode == 1) { // Unexpected
          return this.state.loadCartResult.errorMessage;
        } else if (this.state.loadCartResult.errorCode == 2) { // Reservering niet gevonden
          return Selector.SelectLanguageStringFromLangJSON('Alert', 'DeReserveringNietGevonden');
        } else if (this.state.loadCartResult.errorCode == 3) { // Reservering is verlopen
          return Selector.SelectLanguageStringFromLangJSON('Alert', 'DeReserveringIsVerlopen');
        } else if (this.state.loadCartResult.errorCode == 4) { // Reservering is al verwerkt.
          return Selector.SelectLanguageStringFromLangJSON('Alert', 'DeReserveringIsNietMeerGeldig');
        }


        return store.getters.translateString;
      }
    },
    data() {

    },
    setup() {
      return {
        state: store.state
      }
    },
    mounted() {
          if (this.state.isMobileCart) {
            store.dispatch('changeStep', 'changeoverzicht');
          } else {
            store.dispatch('changeStep', 'klantinformatie');
          }
      }
}
</script>

<style scoped>
.directOrderCheckout {
    margin-top: 80px;
}

.home {
  width:100%;
  height:100%;

  display: flex;
  flex-direction: column;

  position: relative;
}

.header {

  flex-shrink: 0;
  flex-grow: 0;

  background-color: #005b8c;
  width: 100%;
  min-height: 450px;
  min-width: 350px;
  position: relative;
  box-sizing: border-box;
  padding-top: 70px;


  padding-bottom:240px; /* hoogte van de list event + eventuele tussenafstand, die overlapt */
}

.shopTitleAuto {
  color: white;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
  width:100%;
  max-width:1000px;
  text-align: center;
  margin:auto;
  margin-bottom:20px;

}

.wrapper {
  flex-shrink: 0;
  flex-grow: 1;
  z-index: 1;
}

.inner {
  max-width:1200px;
  margin:auto;
  justify-items: center;
  align-items: center;
  margin-top: -350px;
  position: relative;
}

Footer {
  flex-shrink: 0;
}

.errorContainer {
  position: relative;
  margin: auto;
  margin-top:-400px;
  max-width: 400px;
  min-height: 300px;
  background-color: white;
  border-radius: 12px;
}

.okIcon {
  width: 100px;
  margin: 20px auto;
}




</style>