// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.bestelContainer[data-v-ccad9cc8] {
    position: relative;
    left: 0;
    text-align: left;
}
.bestelContainer-header[data-v-ccad9cc8] {
    min-height: 100px;
    width: 100%;
    padding: 20px 20px 20px 20px;
    position: relative;
    background-color: whitesmoke;
    border-top-left-radius: 10px;
}
.eventTitle[data-v-ccad9cc8] {
    --max-lines: 2;
    overflow: hidden;
    font-weight: bold;
    font-size: 30px;  
    font-family: 'Nunito', sans-serif;
    max-width: 550px;
    color: rgb(65, 65, 65);
}
.eventSubtitle[data-v-ccad9cc8] {
    --max-lines: 2;
    overflow: hidden;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
}
.eventDate[data-v-ccad9cc8] {
    margin-top: 0.5rem;
    margin-bottom: 1rem;
    color: sandybrown;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    font-family: 'Didact Gothic', sans-serif;
}
@media(max-width: 1120px) {
.bestelContainer-header[data-v-ccad9cc8] {
        border-top-right-radius: 10px;
}
}
@media(max-width: 1024px) {
.eventTitle[data-v-ccad9cc8] {
        font-size: 22px;
        width: 80%;
}
.eventSubtitle[data-v-ccad9cc8] {
        font-size: 14px;
        width: 80%;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
