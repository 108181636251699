<template>
    <div class="sharer-class">
        <WhatsappShare :title="title" :url="url" v-once />
        <TwitterShare :title="title" :url="url" v-once />
        <FacebookShare :url="url" v-once />
        <LinkedinShare :url="url" v-once />
        <PinterestShare :url="url" :image="image" v-once />
        <EmailShare :title="title" :url="url" v-once />
    </div>
</template>

<script>
import WhatsappShare from './SocialButtons/WhatsappShare'
import TwitterShare from './SocialButtons/TwitterShare'
import FacebookShare from './SocialButtons/FacebookShare'
import LinkedinShare from './SocialButtons/LinkedinShare'
import PinterestShare from './SocialButtons/PinterestShare'
import EmailShare from './SocialButtons/EmailShare'
import {Selector} from '../language/language'
export default {
    components: {
        WhatsappShare,
        TwitterShare,
        FacebookShare,
        LinkedinShare,
        PinterestShare,
        EmailShare
    },
    props: {
        eventTitle: {
            type: String
        },
        accountName: {
            type: String
        },
        image: { 
        }
    },
    computed: {
        title() {
            var stringCheckNu = Selector.SelectLanguageStringFromLangJSONWithParams('Social', 'CheckNu', {eventTitle: this.eventTitle, accountName: this.accountName})
            return stringCheckNu;
        },
        url() {
            return String(location.href)
        }
    },
    mounted() {
        delete require.cache[require.resolve('/static/sharer')];
        require('/static/sharer')
    }
}
</script>

<style scoped>
.sharer-class {
    display: flex;
    flex-wrap: wrap;
}
</style>