// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.TicketRemover[data-v-445e3012] {
    width: 100%;
}
.ticketTypeWrapper[data-v-445e3012] {
    display: flex;
}
.ticketTypeNameAndEventNameWrapper[data-v-445e3012] {
    width: 90%;
}
.ticketTypeCalculatedQuantityAndEventName[data-v-445e3012] {
    font-size: 16px;
    font-weight: bold;
    margin-right: 2px;
}
.ticketTypeName[data-v-445e3012]{
    font-size: 16px;
    margin-right: 2px;
}
.ticketTypeTimeSlot[data-v-445e3012] {
    font-size: 16px;
    margin-right: 2px;
    text-transform: capitalize;
}
.ticketTypeSeat[data-v-445e3012] {
    font-size: 12px;
    font-style: italic;
    margin-right: 2px;
    text-transform: capitalize;
}
.ticketTypeCalculatedPrice[data-v-445e3012] {
    font-size: 16px;
}
/*.ticketTypeCalculatedPrice + span {
  padding-right: 0px;
}*/
span[data-v-445e3012]:after {
  position: relative;
  content: '✖';
  color: red;
  cursor: pointer;
}
.ticketRemoverContainer[data-v-445e3012] {  
    text-align: right;
    padding-top: 1px;
}
.ticketPriceWrapper[data-v-445e3012] {
    text-align: right;
    width: 100px;
    margin-right: 10px;
}
.ticketPriceWrapperWithoutRemover[data-v-445e3012] {
    text-align: right;
    width: 120px;
    margin-right: 0px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
