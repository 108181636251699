<template>
    <div @click.stop @click="goBackOnBackground" class="eventInfo" v-if="state.isLoaded" v-bind:class="{popupOpen: state.isPopUpOpen}" >
        <div class="header" v-if="selectedEvent != null">
            <HeaderBackground />
            <div v-if="state.status.accountID != selectedEvent.accAccountID">
                <TextFit v-once class="accountName" style="position: relative; z-index:1; max-height:60px; padding-left:20px; padding-right:20px;" v-html="getTranslationFromArray(selectedEvent.accAccountName.items)"></TextFit>
            </div>
            <div v-else>
                <TextFit v-once class="shopTitle" style="position: relative; z-index:1; max-height:60px; padding-left:20px; padding-right:20px;" v-html="getTranslationFromArray(selectedEvent.accShopTitle.items)"></TextFit>
            </div>
            <GoBack></GoBack>
            <Flag></Flag>
        </div> 


        
        <div class="centerBlock">
            <div v-if="selectedEvent != null">
                    <div @click.stop class="infoEventContentWrapper">

                        <div class="infoEventContentHeader">

                            <div class="infoEventImgCntr">
                               <img class="infoEventImg" :src="selectedEvent.image" />
                            </div>
                            <div class="infoEventContentHeaderTextBox">
                                <div class="infoEventTitle" v-html="getTranslationFromArray(selectedEvent.title.items)"></div>
                                <div class="infoEventSubtitle" v-html="getTranslationFromArray(selectedEvent.subtitle.items)"></div>
                            </div>
                            <div class="infoEventHeaderSocialIcos">
                              <SocialSharer :eventTitle="getTranslationFromArray(selectedEvent.title.items)"
                                            :accountName="state.status.accountName"
                                            :image="selectedEvent.image" />
                            </div>
                            <div style="clear: both;"></div>
                        </div>
                        <div class="infoEventContentContainer">

                          <div class="infoEventTopCntrDateAndCalender">
                            <div class="infoEventDateCntr">

                              <div class="infoEventDate" v-if="selectedEvent.evShowDatesOptions == 0">
                                <div>{{ getDateAndTimeFormatted(selectedEvent.evFrom, selectedEvent.evShowDatesOptions, true, true) }} <!-- {{ getDateAndTimeFormatted(dateUntil) }}  --></div>
                              </div>
                              <div class="infoEventDate" v-if="selectedEvent.evShowDatesOptions == 1">
                                <div>{{translateString('General', "t/m")}} {{getDateAndTimeFormatted(selectedEvent.evUntil, selectedEvent.evShowDatesOptions, true, true)}}</div>
                              </div>
                              <div class="infoEventDate" v-if="selectedEvent.evShowDatesOptions == 2">
                                <div v-if="getDateAndTimeFormatted(selectedEvent.evFrom, selectedEvent.evShowDatesOptions, true, false) == getDateAndTimeFormatted(selectedEvent.evUntil, selectedEvent.evShowDatesOptions, true, false)">
                                  <div>{{ getDateAndTimeFormatted(selectedEvent.evFrom, selectedEvent.evShowDatesOptions, true, true)}} - {{getDateAndTimeFormatted(selectedEvent.evUntil, selectedEvent.evShowDatesOptions, false, true)}}</div>
                                </div>
                                <div v-else>
                                  <div>{{ getDateAndTimeFormatted(selectedEvent.evFrom, selectedEvent.evShowDatesOptions, true, false)}} - {{getDateAndTimeFormatted(selectedEvent.evUntil, selectedEvent.evShowDatesOptions, true, false)}}</div>
                                </div>
                              </div>
                              <div v-if="selectedEvent.evShowDatesOptions == 3">
                              </div>
                              <div class="infoEventDate" v-if="selectedEvent.evShowDatesOptions == 99">
                                <div>{{ selectedEvent.evPeriodLabel }}</div>
                              </div>
                            </div>

                            <div v-if="needAddToCalender()" class="infoEventIcsCntr">
                              <DownloadIcs :language="state.localeLanguage"
                                           :subject="getTranslationFromArray(selectedEvent.title.items)"
                                           :description="getTranslationFromArray(selectedEvent.subtitle.items)"
                                           :location="selectedEvent.locName"
                                           :begin="selectedEvent.evFrom"
                                           :stop="selectedEvent.evUntil"
                                           :name="getTranslationFromArray(selectedEvent.accAccountName.items)" />
                              </div>
                          </div>



                          <div class="infoEventTicketsContainer">






                            <div class="infoEventTicketsTitle">{{translateString("General",'Tickets')}}*</div>
                                <table class="infoEventTicketsTable">
                                    <tbody>
                                        <tr v-for="ticketType in selectedEvent.ticketTypes.items" v-bind:key="ticketType.ID" v-bind="checkDateTimeTicketSales(ticketType.ttSoldOut, ticketType.ttVisible, ticketType.ttSalesFrom, ticketType.ttSalesFromOption, ticketType.ttSalesUntil, ticketType.ttSalesUntilOption)">
                                            <td class="infoEventTicketNames">
                                                <div v-if="ticketNameToShow == 1"> 
                                                    <div style="display:inline-block" v-html="getTranslationFromArray(ticketType.ttTitle.items)"></div>
                                                    <span v-if="ticketType.ttMinAge>0" style="font-weight:bold; color:red">&nbsp;&nbsp;{{ticketType.ttMinAge}}+</span>
                                                    <span v-if="ticketType.ttHasCheckoutFields>0"  ><sup style="font-weight:bold">&nbsp;**</sup></span>
                                                </div>
                                                <!-- Als ticketNameToShow 0 is moet de naam niet op het scherm -->
                                                <div v-else-if="ticketNameToShow == 0">
                                                </div>
                                            </td> 
                                            <td class="infoEventTicketPrices">
                                                <!-- Als ticketPriceToShow 1 is moet de prijs op het scherm -->
                                                <div v-if="ticketPriceToShow == 1">
                                                    <div>{{getMyCurrencyFormatted(ticketType.ttPrice)}}</div>
                                                </div>   
                                                <!-- Als ticketPriceToShow 0 is moet de prijs niet op het scherm -->
                                                <div v-else-if="ticketPriceToShow == 0">
                                                </div>
                                                <!-- Als ticketPriceToShow 2 is moet de prijs + Beschikbaar vanaf + datum op het scherm -->
                                                <div v-else-if="ticketPriceToShow == 2">
                                                    <div>{{getMyCurrencyFormatted(ticketType.ttPrice)+" "+translateString("General",'beschikbaarVanaf')+" "+getDateAndTimeFormatted(ticketType.ttSalesFrom, selectedEvent.evShowDatesOptions,true, false)+" "+translateString("General",'om')+" "+getDateAndTimeFormatted(ticketType.ttSalesFrom,selectedEvent.evShowDatesOptions, false, true)}}</div>
                                                </div>
                                                <!-- Als ticketPriceToShow geen 0, 1 of 2 maar een string heeft, moet de string getoond worden -->
                                                <div v-else>
                                                    <div class="labelUitverkocht">{{ticketPriceToShow}}</div>
                                                </div>
                                            </td>
                                        </tr>  
                                    </tbody> 
                                </table>

                                <div v-if="hiddenTicketTypes == selectedEvent.ticketTypes.count">
                                    <div>{{translateString("General",'GeenVerkoop')}}</div>
                                </div>

                                <div class="labelAllePrijzenInclServicekosten">* {{translateString("General",'allePrijzenZijnInclServicekosten')}}</div>
                                <div v-if="hasPersonalisedTickets()>0" class="labelErZijnGepersonaliseerdeVelden">** {{translateString("General",'erZijnGepersonaliseerdeVelden')}}</div>



                                <div v-if="selectedEvent.evSoldOut || selectedEvent.statusOverruleByTicketTypes == 2">
                                    <button class="btn btnRed btnUitverkocht">{{translateString("General","uitverkocht")}}</button> 
                                </div>
                                <div v-else-if="selectedEvent.statusOverruleByTicketTypes == 1 || selectedEvent.statusOverruleByTicketTypes == 3 || hiddenTicketTypes == selectedEvent.ticketTypes.count">
                                    <button class="btn btnGrey btnUitgeschakeldeBestelTickets">{{translateString("General","TicketsBestellen")}}</button>
                                </div>
                                <div v-else-if="(hiddenTicketTypes + ticketTypesSoldOut) == selectedEvent.ticketTypes.count">
                                    <button class="btn btnRed btnUitverkocht">{{translateString("General","uitverkocht")}}</button> 
                                </div>
                                <div v-else>
                                    <button class="btn btnGreen btnBestelTickets" v-on:click="openPopUp('checkout', false)">{{translateString("General","TicketsBestellen")}}</button>
                                </div>
                                
                                <div v-if="selectedEvent.evSoldOut || selectedEvent.statusOverruleByTicketTypes == 2">
                                    <div v-if="selectedEvent.evAllowRegister">
                                        <button class="btn btnOrange btnWachtlijst">{{translateString("General","zetMeOpDeWachtlijst")}}</button>
                                    </div>
                                    <div v-else>
                                    <!--    <button class="btn btnGrey btnUitgeschakeldeBeschikbaarheid">{{translateString("General","beschikbaarheid")}}</button> -->
                                    </div>
                                </div>
                                <div v-else-if="selectedEvent.statusOverruleByTicketTypes == 1 || selectedEvent.statusOverruleByTicketTypes == 3 || hiddenTicketTypes == selectedEvent.ticketTypes.count || (hiddenTicketTypes + ticketTypesSoldOut) == selectedEvent.ticketTypes.count">
                                  <!-- <button class="btn btnGrey btnUitgeschakeldeBeschikbaarheid">{{translateString("General","beschikbaarheid")}}</button> -->
                                </div>

                                <div v-else-if="selectedEvent.evHasTimeSlots == 1">
                                  <button class="btn btnWhite btnBeschikbaarheid" v-on:click="openPopUp('availability')">{{translateString("General","beschikbaarheid")}}</button>
                                </div>
                                <div v-else>
                               <!--     <button class="btn btnWhite btnBeschikbaarheid" v-on:click="openPopUp('availability')">{{translateString("General","beschikbaarheid")}}??</button> -->
                                </div>

                            <!-- Alle ticketTypes zijn gecheckt -> ticketTypesAreChecked op true, anders worden de hiddenTicketTypes opnieuw opgeteld als de popup is geopend/taal is veranderd -->
                                <div v-bind="setAllTicketTypesCheckedTrue()" style="clear:both;"></div>
                            </div>

                            <div class="infoEventLocationWrapper">
                                <div class="infoEventLocationContainer">
                                    <div class="infoEventLocationTitle">{{translateString("General",'Locatie')}}</div>
                                    <div v-if="selectedEvent.locHasMultipleLocations == 0">
                                        <div class="infoEventLocationName" v-html="selectedEvent.locName"></div>
                                        <div class="infoEventLocationAddress" v-html="selectedEvent.locAddress"></div>
                                        <div v-if="selectedEvent.locZipcode != '' && selectedEvent.locCity != ''" class="infoEventLocationZipcodeCityWrapper" v-html="selectedEvent.locZipcode +', '+selectedEvent.locCity"></div>
                                        <div class="infoEventLocationCountry" v-html="selectedEvent.locCountry"></div>
                                    </div>
                                    <div v-else>
                                        <div class="infoEventLocationName">Er zijn meerdere locaties voor dit evenement</div>
                                    </div>
                                </div>
                                <div class="infoEventParkingContainer" v-if="selectedEvent.locParkingInfo != ''">
                                    <div class="infoEventParkingTitle">{{translateString("General",'Parkeren')}}</div>
                                    <div class="infoEventParkingInfo" v-html="selectedEvent.locParkingInfo"></div>
                                </div>
                                <div style="clear:both;"></div>
                            </div>

                            <div class="infoEventTags">
                                <div class="infoEventTagsTitle">#Tags</div>
                                <div class="infoEventTagsWrapper">
                                    <div v-for="genre in state.status.genres.items" :key="genre">
                                        <div v-if="checkIfGenreMatchesWithTagsEvent(genre)">
                                            <div class="activeTag">{{getTranslationFromArray(genre.genre.items)}}</div>
                                        </div>
                                        <div v-else>
                                            <div class="tag">{{getTranslationFromArray(genre.genre.items)}}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="infoEventDescription">
                                <div class="infoEventDescriptionTitle">{{translateString("General",'OverDitEvent')}}</div>
                                <div class="infoEventDescriptionContainer">
                                    <div class="shortDescription" v-html="getTranslationFromArrayWithBr(selectedEvent.shortDescription.items)"></div>
                                    <div v-if=" getTranslationFromArrayWithBr(selectedEvent.longDescription.items).length > 400">
                                      <TextReadMore>
                                        <template v-slot:default>
                                          <div v-html="getTranslationFromArrayWithBr(selectedEvent.longDescription.items)"></div>
                                        </template>
                                      </TextReadMore>
                                    </div>
                                    <div v-else>
                                      <div v-html="getTranslationFromArrayWithBr(selectedEvent.longDescription.items)"></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                      <div class="infoEventBottomSocialIcos">
                          <SocialSharer :eventTitle="getTranslationFromArray(selectedEvent.title.items)"
                                        :accountName="state.status.accountName"
                                        :image="selectedEvent.image"/>
                      </div>

                    </div>
                    &nbsp;
            </div>
            <div v-else>
                <div>{{translateString("General",'GeenInfoOmTeLatenZien')}}</div>
            </div>
        </div>
        <ShoppingCartInfo class="ShoppingCartInfo" @shoppingCartInfoClicked="openPopUp('checkout', true)"></ShoppingCartInfo>
        <Footer></Footer>
        <SeatingPlan v-if="state.seatingshow"></SeatingPlan>
        <PopUpMask  @close="handleClosePopup" v-if="state.isPopUpOpen" :whichPopUpToOpen="state.whichPopUpToOpen" ></PopUpMask>
    </div>
</template>

<script>
import {Selector} from '../language/language.js';
import {Functions} from '../util/util.js';
import {store} from '../store/index';
import Flag from '../components/Flag.vue';
import GoBack from '../components/GoBack.vue';
import PopUpMask from '../components/PopUpMask.vue';
import SocialSharer from '../components/SocialShare';
import DownloadIcs from '../components/DownloadIcs';
import TextReadMore from '../components/TextReadMore';
import Footer from '../components/Footer';
import TextFit from '../components/TextFit.vue';
import ShoppingCartInfo from '../components/Checkout/components/ShoppingCartInfo';
import SeatingPlan from '../components/SeatingPlan.vue';
import HeaderBackground from "@/components/HeaderBackground.vue";
import {forEach} from "vue-resize-text";

export default {
    name: "EventInfo",
    setup() {
        return {
            state: store.state,
            hiddenTicketTypes: 0,
            ticketTypesSoldOut: 0,
            ticketNameToShow: 0,
            ticketPriceToShow: 0,
            ticketTypesAreChecked: false,
            popUpName: '',
            goHistory: true
        }
    },
  data() {
    return {
        isValid: false
      }
    },
    props: {
        id: {
            type: String,
            required: true
        }
    },
    components: {
      HeaderBackground,
        Flag,
        GoBack,
        PopUpMask,
        SocialSharer,
        DownloadIcs,
        TextReadMore,
        Footer,
        TextFit,
        ShoppingCartInfo,
        SeatingPlan
    },
    computed: {
        selectedEvent() {

            // Controleren of het id wel betaat....)
            /*if(typeof store.state.events.events.items.item[this.id] !== 'undefined') {
                // gevonden, dus doorgeven
                var arEventsFromStore = store.state.events.events.items[this.id];
            } else {          
                // is undefined, set null en maak errormessage
                var arEventsFromStore = null;
                var errorMessage = "This ID is not connected to an existing event";
                Functions.setErrorMessage(errorMessage);
            }*/

           if (store.state.events==undefined) {
                return null;
           }

            var arEventsFromStore = null;
            // Controleren of er een event is met de id...
            for(var i = 0; i < store.state.events.events.items.length; i++) {
                if(store.state.events.events.items[i].ID == this.id) {
                    this.isValid = true;
                    return arEventsFromStore = store.state.events.events.items[i];
                }
            }
            // event met id niet gevonden in for loop, dus geef error
            if(arEventsFromStore == null) {         
                var errorMessage = "This event is not found of available.";
                Functions.setErrorMessage(errorMessage);
                this.$router.push('/ShopDoesNotExist');
            }

            // TODO: error1, error2 en errorTimerSeconds legen als je een pagina teruggaat.

            /* Deze loop werkt ook 
            for (const [key, value] of Object.entries(state.events.events.items)) {
                if (value.ID==this.id) {
                    arEventsFromStore = value;
                    break;
                }
            }*/
            return arEventsFromStore;            
        },
    },
    methods: {
      validateData() {

        // Zit ID in de state.events.events.items?
        /*
        if(store.state.events.items.length>0) {
          forEach(store.state.events.items, function (value, key) {
            if(value.ID == this.id) {
              this.isValid = true;
            }
          });
        }
        if (!this.isValid) {
          // Redirect naar home met een refresh
          window.location.href = '/';
          this.isValid = false; // Stel isValid in op false zodat de rest van de pagina niet wordt geladen
        }
         */

      },
      hasPersonalisedTickets() {
        var ret = false;
        // doorloop alle tickettypes en kijk of er een bij zit voor gepersonaliseerde ticket
        for (var i = 0; i < this.selectedEvent.ticketTypes.items.length; i++) {
          if (this.selectedEvent.ticketTypes.items[i].ttHasCheckoutFields > 0) {
            ret = true;
            break;
          }
        }
        return ret;
      },

      needAddToCalender() {
          var ret = true;

          if (this.selectedEvent.evShowDatesOptions === 0) { //  FROM
            ret = true;
          } else if (this.selectedEvent.evShowDatesOptions === 1) { // UNTIL
            ret = false;
          } else if (this.selectedEvent.evShowDatesOptions === 2) { // PERIOD
            ret = true;
          } else if (this.selectedEvent.evShowDatesOptions === 3) { // NONE
            ret = false;
          } else if (this.selectedEvent.evShowDatesOptions === 99) { // CUSTOM / Label
            ret = false;
          }

          return ret;
        },
      getTranslationFromArray(arrayWithTransations) {
        var stringTranslation = Selector.SelectLanguageStringFromArray(arrayWithTransations)
        return stringTranslation;
      },
      getTranslationFromArrayWithBr(arrayWithTransations) {
        var stringTranslation = Selector.SelectLanguageStringFromArray(arrayWithTransations)
        // Replace alle nieuwe rgels met een br
        //stringTranslation = stringTranslation.replace("\n","<br><br>");
        //alert(stringTranslation);
        return stringTranslation;
      },
      translateString(page, stringNotTranslated) {
        var translatedString = Selector.SelectLanguageStringFromLangJSON(page, stringNotTranslated)
        return translatedString;
      },
      getDateAndTimeFormatted(date, opties, returnDate, returnTime) {
        var lengthWeekday = "short";
        var lengthMonth = "short";
        //var returnDate = true;
        //var returnTime = true;
        return Functions.formatDateTime(date, lengthWeekday, lengthMonth, returnDate, returnTime);
      },
      getMyCurrencyFormatted(price) {
        return Functions.formatMyCurrency(price);
      },
      getEventPriceRange(minPrice, maxPrice) {

        if (minPrice != maxPrice) {
          return Functions.formatMyCurrency(minPrice) + " - " + Functions.formatMyCurrency(maxPrice);
        }

        if (minPrice == 0) {
          return "** GRATIS **";
        }

        return Functions.formatMyCurrency(minPrice);
      },
      goBackOnBackground() {
        store.dispatch('removeError');

        // Indien directEvent, dan doe niks.
        if (store.state.isDirectEvent) {
          return;
        }

        if (this.goHistory) {
           return this.$router.go(-1);
        } else {
          this.goHistory = true;
        }
      },
      handleClosePopup() {
        this.goHistory = false; // Voorkom dat hij nog een niveau teruggaat.
      },

      openPopUp(namePopUp, openByCartInfo) {

        const $body = document.querySelector('body');
        let scrollPosition = 0;
        scrollPosition = window.pageYOffset;
        $body.style.top = `-${scrollPosition}px`;
        $body.style.width = '100%';
        store.dispatch('updateScrollPosition', scrollPosition);

        if (!openByCartInfo) {
          store.dispatch('changeStep', 'bestellen');
        }

        // open popup
        store.dispatch('openPopUp', {selectedEvent: this.selectedEvent, popUpOpenByShoppingCartInfo: openByCartInfo, whichPopUpToOpen: namePopUp});
        },
        setAllTicketTypesCheckedTrue(){
            this.ticketTypesAreChecked = true;
        },
        checkDateTimeTicketSales(ttSoldOut, ttVisible, dateFrom, dateFromOption, dateUntil, dateUntilOption) {
            // als ttVisible 0 is, dan laat niet het ticketType zien
            if(ttVisible == 0) { 
                if(this.hiddenTicketTypes < this.selectedEvent.ticketTypes.count && this.ticketTypesAreChecked == false) {
                    this.hiddenTicketTypes++;
                }         
                this.ticketNameToShow = 0;
                this.ticketPriceToShow = 0;
                return;
            }

            // pak datum van vandaag
            var today = new Date();
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
            var dateTimeToday = date+' '+time;

            // split de drie dates
            var splittedDateTimeToday = dateTimeToday.split(/[^0-9]/);
            var splittedDateFrom = dateFrom.split(/[^0-9]/);
            var splittedDateUntil = dateUntil.split(/[^0-9]/);

            // zet de gesplitte stukjes om in een dateformat dat gebruikt kan worden in elke browser
            var newDateToday = new Date(splittedDateTimeToday[0],splittedDateTimeToday[1]-1,splittedDateTimeToday[2],splittedDateTimeToday[3],splittedDateTimeToday[4],splittedDateTimeToday[5] );
            var newDateFrom = new Date(splittedDateFrom[0],splittedDateFrom[1]-1,splittedDateFrom[2],splittedDateFrom[3],splittedDateFrom[4],splittedDateFrom[5] );
            var newDateUntil = new Date(splittedDateUntil[0],splittedDateUntil[1]-1,splittedDateUntil[2],splittedDateUntil[3],splittedDateUntil[4],splittedDateUntil[5] );
    
            // als de datum en tijd binnen de salesdatums vallen
            // dan is er niks aan de hand en mag de prijs gewoon op het scherm komen
            // tenzij ttsoldOut op 1 staat, dan moet uitverkocht geforceerd op het scherm komen
            if(newDateToday.getTime() >= newDateFrom.getTime() && newDateToday.getTime() <= newDateUntil.getTime()) {
                if(ttSoldOut == 1) {
                    this.ticketNameToShow = 1;
                    this.ticketPriceToShow = this.translateString("General",'uitverkocht');

                    if(this.ticketTypesAreChecked == false){
                        this.ticketTypesSoldOut++;
                    }               
                } else {
                    this.ticketNameToShow = 1;
                    this.ticketPriceToShow = 1;
                }                
                return;
            }   

            if(newDateToday.getTime() < newDateFrom.getTime()) {
                if(dateFromOption == 0) { 
                    if(this.hiddenTicketTypes < this.selectedEvent.ticketTypes.count && this.ticketTypesAreChecked == false) {
                        this.hiddenTicketTypes++;
                    }
                    this.ticketNameToShow = 0;
                    this.ticketPriceToShow = 0;
                    return;
                }
                else if(dateFromOption == 1) {
                    this.ticketNameToShow = 1;
                    this.ticketPriceToShow = 2;
                    return;
                }       
            }
            else if(newDateToday.getTime() > newDateUntil.getTime()) {
                if(dateUntilOption == 0) {
                    if(this.hiddenTicketTypes < this.selectedEvent.ticketTypes.count && this.ticketTypesAreChecked == false) {
                        this.hiddenTicketTypes++;
                    }
                    this.ticketNameToShow = 0;
                    this.ticketPriceToShow = 0;
                    return;
                }
                else if(dateUntilOption == 1) {
                    this.ticketNameToShow = 1;
                    this.ticketPriceToShow = this.translateString("General",'nietMeerBeschikbaar');
                    return;
                }       
                else if(dateUntilOption == 2) {
                    this.ticketNameToShow = 1;
                    this.ticketPriceToShow = this.translateString("General",'uitverkocht');
                    if(this.ticketTypesAreChecked == false){
                        this.ticketTypesSoldOut++;
                    }      
                    return;
                }
            }
        },
        
        checkIfGenreMatchesWithTagsEvent(genre) {
            for(var tag in this.selectedEvent.tags.items) {
                if (genre.ID == tag) {
                    return true;
                }
            }
            return false            
        },
        eventSentToIFrame() {
            console.error("iFrame message to parent");
            window.parent.postMessage({type:"redir",redir:"http:/xyz.nl"},document.referrer) ; 
        },
        testCart(payload) { /* {rank:"", seatnumb:"", tickettype:""} */

            payload = {"rank":"Balkon","seatnumb":"Balkon|2|12","tickettype":"7459"};

            console.log(this.selectedEvent);
            var accountId = this.selectedEvent.accAccountID;
            var eventID = this.selectedEvent.ID;
            var ticketTypeID = payload.tickettype;
            var timeSlot = "";
            var price = 0;
            var quantity = 1;


            store.dispatch('addCart', {accountId: accountId, eventID: eventID, ticketTypeID: ticketTypeID, timeSlot: timeSlot, price: price, quantity: quantity, seat: payload }).then(response => {


                store.dispatch('resetPopUpToTop', false); // niet nodig ivm stoelenplan?

                if(response.status == "OK"){
                    var text;
                    if(this.state.isAdded == 1){
                        if(quantity > 1) {
                            text = this.translateString('Alert', 'TicketsZijnToegevoegdAanDeWinkelwagen');
                        } else {
                            var textFromJSON = this.translateString('Alert', 'TicketsZijnToegevoegdAanDeWinkelwagen');
                            var res = textFromJSON.replace('Tickets zijn', 'Ticket is').replace('Tickets have', 'Ticket is').replace('Les billets ont été ajoutés', 'Le billet a été ajouté').replace('Tickets wurden', 'Ticket wurde')
                            text = res;
                        }
                    } else if(this.state.isUpdated == 1) {
                        text = this.translateString('Alert', 'AantalTicketsIsAangepast');
                    }
                
        //            this.alertTitle = this.translateString('Alert', 'Succesvol');
          //          this.alertText = text;
             //       this.alertType = 1;
               //     this.alertTime = 1000;          
                } else {
                    var text;
                    if(quantity > 1){
                        text = this.translateString('Alert', 'TicketsKondenNietToegevoegdWorden')
                    } else {
                        var textFromJSON = this.translateString('Alert', 'TicketsKondenNietToegevoegdWorden')
                        var res = textFromJSON.replace('Tickets konden', 'Ticket kon').replace('Tickets', 'Ticket').replace("Les billets n'ont pas pu être ajoutés", "Le billet n'a pas pu être ajouté").replace('Tickets konnten', 'Ticket konnte')
                        text = res;
                    }
//                    this.alertTitle = this.translateString('Alert', 'Oeps');
  //                  this.alertText = text;
    //                this.alertType = 2;
      //              this.okButtonActive = 1;
                }
                  
            })        


        }
    },
  mounted() {
        this.validateData();
        window.scrollTo(0,0);
    },
    beforeUnmount() {
        this.hiddenTicketTypes = 0;
        this.ticketTypesSoldOut = 0;
        this.ticketTypesAreChecked = false;
    }
}
</script>

<style scoped>

.centerBlock {
    text-align: center;
    padding-left:20px;
    padding-right:20px;
}
.header {
    background-color: #005b8c;
    width: 100%;
    min-height: 450px; 
    position: relative;
    box-sizing: border-box;
    padding-top: 70px;


    padding-bottom:240px; /* hoogte van de list event + eventuele tussenafstand, die overlapt */
  }

.popupOpen {
  overflow-y: hidden;
}

.accountName {
    color: white;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    width:100%;
    max-width:1000px;
    text-align: center;    
    margin:auto;
    margin-bottom:20px;
  }

  .shopTitle {
    color: white;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    width:100%;
    max-width:1000px;
    text-align: center;    
    margin:auto;
    margin-bottom:20px;

  }

.eventInfo {
    position: relative;
    padding-bottom: 180px;
    min-height: 100%;
    height:100%;
    color: rgb(46, 46, 46);
    z-index:0;
}

.infoEventContentWrapper {
    max-width:1100px;
    margin-top: -293px;
    margin-bottom: 50px;

    margin-left:auto;
    margin-right:auto;

    height: auto;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
}

.infoEventContentHeader {
    position: relative;
    width: 100%;
    height: auto;
    box-sizing: border-box;
    background-color: whitesmoke;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;

}

.infoEventContentHeaderTextBox {
    padding-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    position: relative;
    display: block;   
    float: left;
    text-align: left;
    width: 60%;
}

.accountName, .shopTitle {
    --max-lines: 2;
    overflow: hidden;
    font-weight: bold;
    font-size: 40px;  
    font-family: 'Nunito', sans-serif;
}
.infoEventTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-weight: bold;
    font-size: 26px;
    font-family: 'Nunito', sans-serif;
}
.infoEventSubtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    -webkit-box-orient: vertical;
    font-size: 18px;
    font-family: 'Nunito', sans-serif;
    color: #6d6d6d;
}

.infoEventTopCntrDateAndCalender {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right:20px;
}



.infoEventDateCntr {
  text-align: left;
}

.infoEventIcsCntr {
  text-align: right;
  padding-bottom  : 20px;
}

.infoEventDate {
    color: sandybrown;
    font-size: 18px;
    font-weight: bold;
    font-family: 'Didact Gothic', sans-serif;
    text-transform: uppercase;
    display: inline-block;
    margin-right:20px;

}

.infoEventContentContainer {
    text-align: left;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 40px;
    padding-bottom: 60px;
    font-family: 'Didact Gothic', sans-serif;

    position:relative;
    margin-top:-5px;
    background-color: white
}

.infoEventTicketsContainer {
    overflow-x: auto;
}
.infoEventTicketsTitle {
    font-weight: bold;
    font-size: 22px;  
}
.infoEventTicketsTable {
    margin-top: 5px;
    font-size: 16px; 
    min-width: 20%;
    table-layout: auto;
    border-collapse:separate; 
    border-spacing: 0 5px;
}
.infoEventTicketPrices div {
    padding-left: 20px;
}

.labelUitverkocht {
    text-transform: uppercase;
    color: red;
}

.labelAllePrijzenInclServicekosten {
    font-size: 14px;
    margin-top: 15px;
}
.labelErZijnGepersonaliseerdeVelden {
  font-size: 14px;
  margin-top: 0px;
  max-width: 445px;
}

.btnBestelTickets, .btnUitverkocht, .btnUitgeschakeldeBestelTickets, .btnWachtlijst, .btnUitgeschakeldeBeschikbaarheid, .btnBeschikbaarheid {
    margin-top: 15px;
    margin-right:20px;
    float: left;
}

.infoEventLocationWrapper {
    display: flex;
    margin-top: 40px;
}

.infoEventLocationContainer, .infoEventParkingContainer {
    padding-right: 5px;
    max-width: 400px;
    overflow-x:hidden; /* voor het geval dat de tekst niet gewrapped kan worden */
    margin-right: 10px;
}
.infoEventLocationContainer {
    padding-right: 40px;
}

.infoEventLocationTitle {
    font-weight: bold;
    font-size: 22px;  
}
.infoEventLocationName {
    margin-top: 5px;
    font-size: 16px;  
    font-style: italic;
}
.infoEventLocationAddress, .infoEventLocationCountry {
    font-size: 16px;
    font-style: italic;
}
.infoEventLocationZipcodeCityWrapper {
    font-size: 16px; 
    font-style: italic;
}
.infoEventParkingTitle {
    font-weight: bold;
    font-size: 22px; 
}
.infoEventParkingInfo {
    margin-top: 5px;
    font-size: 16px;  
    font-style: italic;
}

.infoEventTags {
    margin-top: 40px;
}
.infoEventTagsTitle {
    font-weight: bold;
    font-size: 22px;
}
.infoEventTagsWrapper {
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.tag {
    font-size: 14px;
    margin: 5px 5px;
    padding: 5px 10px 5px 10px;
    border-radius: 80px;    
    background-color: white;
    color: lightgray;
    border: 2px solid lightgray;
}
.activeTag {
    font-size: 14px;
    margin: 5px 5px;
    padding: 5px 10px 5px 10px;
    border-radius: 80px;    
    background-color: white;
    border: 2px solid #005b8c;
    color: #005b8c;
}

.infoEventDescription {
    margin-top: 40px;
    position: relative;
    margin-right: 40px;
    /*max-width: 800px;*/
}
.infoEventDescriptionTitle {
    font-weight: bold;
    font-size: 22px;  
}
.infoEventDescriptionContainer {
    padding-top: 5px;
}
.shortDescription {
    margin-bottom: 15px;
    font-weight: bolder;
    font-style: italic;
}

.infoEventImage {
    height: 100%;
    border-radius: 20px;
    object-position: 50% 50%;
    object-fit:cover;
    border:4px solid rgb(223, 216, 216);
}
.infoEventImgBK {
    width:100%;
    position: absolute;
    left:0;
    top:0;
    max-height:350px;
    overflow:hidden;
}
.infoEventImgBK img {
    width:120%;
    height:400px;
    margin-top:-25px;
    margin-left:-10%;
    margin-right:-10%;
    filter: blur(10px);
  -webkit-filter: blur(10px);
}


.infoEventImgCntr {
  box-sizing: border-box;
  height: auto;
  width: 40%;
  aspect-ratio: 3 / 2;
  border-top-left-radius: 10px;
  float:left;
}

.infoEventImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  border-top-left-radius: 10px;
  /*
    position: relative;
   */
}


.infoEventBottomSocialIcos {
  display:none;
}

.infoEventHeaderSocialIcos {
  position: absolute;
  right:20px;
  bottom:20px;
}


@media(max-width: 768px) {

  .infoEventContentContainer {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    padding-bottom: 40px;
  }


  .infoEventContentHeader {

  }

  .infoEventContentHeaderTextBox {
    padding: 20px 40px 20px 40px;
  }

  .infoEventImgCntr {
    width: 100%;
    height: auto;
    aspect-ratio: 3 / 2;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
  }

  .infoEventImg {
    border-top-right-radius: 10px;
  }


  .infoEventBottomSocialIcos {
    display: block;
    text-align: center;
    max-width: 200px;
    margin: auto;
    padding-bottom: 40px;
  }

  .infoEventHeaderSocialIcos {
    display: none;
  }

  .infoEventContentHeaderTextBox {
    width: 100%;
    padding: 20px;
  }

  .infoEventIcsCntr >>> .btn { /* overschijven child in componentn! */
    font-size: 10px;
    width: auto;
    padding: 10px 10px 10px 10px;
  }
}

</style>