import Vuex, { createStore } from 'vuex';
import axios from 'axios';
import $, { data } from 'jquery';
import router from '../router';
import slots from '../../static/timeslots.js';
import {Selector} from '../language/language.js';
import {Functions} from '../util/util.js';
import OrderResult from "@/views/OrderResult.vue";



const HTTP = axios.create({
  baseURL: getActiveServer("apiUrl")
})

export const store = Vuex.createStore({

  /**
   * Bewaart data voor de applicatie.
   */
  state: {
    shopNumber: "0",
    shopTrackerID: "0",
    queueToken: "",

    // seatingplan
    ranks: {
      items: [],
      count: [],
    },
    seatingshow: false,
    seatpopupshow: false,
    //default tickettype voor het stoelenplan
    defaultseatingplantickettype: null,
    seatingplan:{
      seats: [],
      bloks: [],
      labels: [],
      lines: []
    },
    // seatingplan
    
    browserLanguage: null,
    localeLanguage: null,   /* gekozen taal */
    cart: {
      bestelItems: [],
      method: '',
      totalPrice: 0,
      totalTickets: 0,
      totalPriceTickets: 0,
      transactionCost: 0,
      validUntil: '',
      donationPrice: 0,
      issuer: ''
    },
    currentSlot: {
      slotFrom: '',
			available: 0,
			arTSTT: []
    },
    customerFieldsLayout: null, /* Instellingen van de invoervelden */
    customerFields: {     /* alle invoervelden */
      visitorType: 'particulier',
      visitorCompany:'',
      visitorVatNumber:'',
      visitorFirstName:'',
      visitorLastName:'',
      visitorName:'',
      visitorEmail:'',
      confirmEmail: '',
      visitorPhone:'',
      visitorStreet:'',
      visitorZipcode:'',
      visitorCity:'',
      visitorCountry:'',
      visitorCountryCode:'',
      visitorNewsLetter:false,
      generalTerms: false,
    },
    error: {
      error1: "",
      error2: "",
      errorTimerMilliseconds: "", 
    },
    events: undefined,
    fields: undefined,
    paymentMethods: undefined,
    paymentMethodDefault: undefined,
    paymentMethodDefaultCost: undefined,
    hasPaymentTransactionCost: undefined,
    paymentStatus: null,
    shopCustomTerms: undefined,
    shopCustomTermsTitle: undefined,
    selectedEvent: {

    },
    selectedPaymentMethod: { 
      CostDisplay: '',
      Key: '',
      Name: '',
      Image: '',
      issuers: [],
      selectedIssuer: undefined
    },
    timeslots: undefined,
    transactionCost: undefined,
    isPopUpOpen: false,
    isInIFrame: false,
    whichPopUpToOpen: null,
    scrollPosition: 0,
    popUpOpenByShoppingCartInfo: false,
    isDirectOrder: false, /* order vanuit de boxoffice */
    isDirectEvent: false, /* direct naar 1 event */
    isDirectCart: false,
    isClientPage: false, /* clientshop, alleen de losse bestel pagina's */
    isMobileCart: false,
    isLoaded: false,
    isLoading: false,    
    isAdded: 0,
    isUpdated: 0,
    isValidCustomerForm1Error: '', /* test */
    isValidCustomerForm2Error: '', /* test */
    openCustomTerms: false,
    resetPopUpToTop: false,
    shopID: null,
    status: [],
    step: 'bestellen',
    stringEvenement: undefined,
    stringHallo: undefined,   
    terms: null,
    terugBtnText: undefined,
    warning: {
      warning1: "",
      warning2: "",
      warningTimerMilliseconds: "", 
    },
    loadCartResult: {
      shopReservationKey: '',
      shopReservationOrderRef: '',
      shopReservationUntil: '',
      errorCode: 0,
      errorMessage: ''
    },
    hasInviteCode: false,
    inviteCode:"",
    development: getActiveServer("developmentMode"), // true = development, false = production

    /**
     * Hier staan alle talen die ingesteld kunnen worden bij het gebruiken van de applicatie
     * Parameters:
     * - name (Naam van de taal, ook geschreven in eigen taal)
     * - code (De taalcode van de (browser)taal)
     * - fileName (De naam van de file van de vlag)
     * LET OP: voor het toevoegen van een taal moet de vlag in src/assets/flags geplaatst worden.
     */
    languages: [
      { 'name': 'Nederlands', 'code': 'nl-NL', 'fileName': 'nl-NL.png' },
      { 'name': 'English', 'code': 'en-EN', 'fileName': 'en-EN.png' },
      { 'name': 'Deutsch', 'code': 'de-DE', 'fileName': 'de-DE.png' },
      { 'name': 'Français', 'code': 'fr-FR', 'fileName': 'fr-FR.png' },
      { 'name': 'Español', 'code': 'es-ES', 'fileName': 'es-ES.png' },
      { 'name': 'Polski', 'code': 'pl-PL', 'fileName': 'pl-PL.png' },
      /*{ 'name': 'Brabants', 'code': 'nl-NL-br', 'fileName': 'nl-NL-br.png' } */
    ],
  },

  /**
   * Getters kunnen gebruikt worden om gegevens te krijgen die gebaseerd zijn op de data in de store.
   * Bijvoorbeeld voor het filteren door een lijst van items uit de store en de resultaten daarvan tellen:
   *
   * doneTodosCount () {
   *  return store.state.todos.filter(todo => todo.done).length
   * }
   */
  getters: {
      isMobileCart: (state) => state.isMobileCart,
  },

  /** 
   * Mutaties worden gebruikt om de state aan te passen.
   * Mutaties zijn vergelijkbaar met events: elke mutatie heeft een string type en een handler.
   * In de handler functie wordt de state-mutatie uitgevoerd, de state wordt als eerste parameter meegegeven.
   * 
   * Voorbeeld: 
   * increment (state) {
   *   // mutate state
   *   state.shopID++
   * }
   * 
   * Deze kunnen ook een payload meenemen, een extra argument.
   * Voorbeeld:
   * increment (state, n) {
   *   state.shopID += n
   * }
   * 
   * -> payload mag ook een object zijn.
   * Voorbeeld:
   * increment (state, payload) {
   *   state.count += payload.amount
   * }
   */
  mutations: {
    SAVE_LOAD_CART_RESULT(state, payload) {
        state.loadCartResult.errorCode = payload.errorCode;
        state.loadCartResult.errorMessage = payload.errorMessage;
        state.loadCartResult.shopReservationKey = payload.shopReservationKey;
        state.loadCartResult.shopReservationUntil = payload.shopReservationUntil;
        state.loadCartResult.shopReservationOrderRef = payload.shopReservationOrderRef;
    },
    SAVE_SHOPNUMBERANDTRACKINGID(state, payload){
      state.shopNumber = payload.shopNumber;
      state.shopTrackerID = payload.shopTrackerID;
    },

    // seatingplan
    SAVE_RANKS(state, payload){
      //state.ranks = payload.items
      state.ranks.items = payload.items;
      state.ranks.count = payload.count;
    },
    EMPTY_RANKS(state){
      state.ranks.items = [];
      state.ranks.count = [];
    },
    SAVE_SEATINGSHOW(state, payload){
      state.seatingshow = payload;
    },
    SAVE_SEATPOPUPSHOW(state, payload){
      state.seatpopupshow = payload;
    },
    SAVE_DEFAULT_TICKETTYPE_SEATINGPLAN(state, payload){
      state.defaultseatingplantickettype = payload
    },
    SAVE_SEATINGPLAN(state, items) {
      items.forEach(item => {
        if (item.type === "stoel"){
          state.seatingplan.seats[state.seatingplan.seats.length] = item;
        }
        if (item.type === "blok"){
          state.seatingplan.bloks[state.seatingplan.bloks.length] = item;
        }
        if (item.type === "label"){
          state.seatingplan.labels[state.seatingplan.labels.length] = item;
        }
        if (item.type === "lijn"){
          state.seatingplan.lines[state.seatingplan.lines.length] = item;
        }
      });
      console.log("Seats:" + state.seatingplan.seats.length)
      console.log("Bloks" + state.seatingplan.bloks.length)
      console.log("Lables:" + state.seatingplan.labels.length)
      console.log("Lines:" + state.seatingplan.lines.length)
    },    
    EMPTY_SEATINGPLAN(state){
      state.seatingplan.seats = [];
      state.seatingplan.bloks = [];
      state.seatingplan.labels = [];
      state.seatingplan.lines = [];
    },
    // seatingplan

    RESET_HIDDEN_TICKETTYPES(state) {
      state.hiddenTicketTypes = 0;
    },
    UPDATE_CART(state, payload) {
      state.cart = payload;
    },
    UPDATE_ISADDED_ISUPDATED(state, payload) {
      state.isAdded = payload.isAdded;
      state.isUpdated = payload.isUpdated;
    },
    CHANGE_CURRENTSLOT(state, payload) {
      state.currentSlot = payload;
    },
    CHANGE_LOADED(state, payload){
      state.isLoaded = payload;
    },
    CHANGE_METHOD_AND_ISSUER_CART(state, payload) {
      state.cart.method = payload.method;
      state.cart.issuer = payload.issuer;
    },
    CHANGE_OPEN_CUSTOM_TERMS(state, payload) {
      state.openCustomTerms = payload
    },
    CHANGE_RESET_POPUP_TO_TOP(state, payload) {
      state.resetPopUpToTop = payload
    },
    CHANGE_SCROLLPOSITION(state, payload) {
      state.scrollPosition = payload;
    },
    CHANGE_STEP(state, payload) {
      state.step = payload;
    },
    CHANGE_TRANSACTIONCOST(state, payload) {
      state.transactionCost = payload;
    },
    IS_IN_IFRAME(state,payload) {
      state.isInIFrame = payload;
    },
    CLOSE_POPUP(state){
      state.isPopUpOpen = false;
      state.popUpOpenByShoppingCartInfo = false;
    },
    OPEN_POPUP(state, payload){
      state.isPopUpOpen = true;

      if (payload.selectedEvent == undefined) {
        // pak de eerste uit de lijst
        var evID = 0;
        if (state.cart.bestelItems.length > 0) {
            evID = state.cart.bestelItems[0].shopEvID;
        } else {
            evID = state.events.events.items[0].ID;
        }
        // Loop events adf en zoek het ID
        state.events.events.items.forEach(item => {
          if (item.ID == evID) {
            payload.selectedEvent = item;
          }
        });
      }
      // selectedEvent is object
      state.selectedEvent = payload.selectedEvent;

      state.popUpOpenByShoppingCartInfo = payload.popUpOpenByShoppingCartInfo;
      state.whichPopUpToOpen = payload.whichPopUpToOpen;
    },
    REMOVE_ERROR(state) {
      state.error.error1 = "";
      state.error.error2 = "";
      state.error.errorTimerMilliseconds = "";
    },
    REMOVE_WARNING(state) {
      state.warning.warning1 = "";
      state.warning.warning2 = "";
      state.warning.warningTimerSeconds = "";
    },
    SAVE_BROWSER_LANGUAGE(state, payload) {
      state.browserLanguage = payload;
    },
    SAVE_CUSTOMER_FIELDS(state, payload) {

      // Validate velden indien die ontbreken
      // Kijk of het veld visitorVatNumber in de jsona zit, anders toevoegen
      if(!payload.hasOwnProperty('visitorVatNumber')) {
        payload.visitorVatNumber = '';
      }

      state.customerFields = payload;
    },
    SAVE_DEFAULT_PAYMENT_TRANSACTION(state, payload) {
        state.paymentMethodDefault = payload.paymentMethodDefault
      state.paymentMethodDefaultCost = payload.paymentMethodDefaultCost
      state.hasPaymentTransactionCost = payload.hasPaymentTransactionCost
    },  
    SAVE_ERROR(state, { error1, error2, errorTimerMilliseconds }) {
      state.error.error1 = error1;
      state.error.error2 = error2;
      state.error.errorTimerMilliseconds = errorTimerMilliseconds;
    },
    SAVE_EVENTS(state, payload) {
      state.events = payload;
    },
    SAVE_SELECTED_EVENT(state,payload) {
       state.selectedEvent = payload;
       console.log(state.selectedEvent.title.items) ;
    },
    SAVE_FIELDS(state, payload) {
      state.fields = payload;
    },
    SAVE_CUSTOMER_FIELDS_LAYOUT(state,payload) {
      state.customerFieldsLayout = payload;
    },
    SAVE_CLIENTPAGE(state,payload) {
      if (payload == true) {
        state.isClientPage = payload;
      }
    },

    SAVE_DIRECTEVENT(state,payload) {
      state.isDirectEvent = payload;
    },
    SAVE_DIRECTMODE(state,payload) {
      state.isDirectOrder = payload;
    },
    SAVE_PAYMENTMETHODS(state, payload) {
      state.paymentMethods = payload;
    },
    SAVE_PAYMENT_STATUS(state, payload) {
      state.paymentStatus = payload;
    },
    SAVE_LOCALE_LANGUAGE(state, payload) {
      state.localeLanguage = payload;
    },
    SAVE_SELECTED_PAYMENT_METHOD(state, payload) {
      state.selectedPaymentMethod = payload;
      localStorage.setItem('selectedPaymentMethod', JSON.stringify(payload))
    },
    SAVE_SHOP_CUSTOM_TERMS(state, payload) {
      state.shopCustomTerms = payload.shopCustomTerms;
      state.shopCustomTermsTitle = payload.shopCustomTermsTitle;
    },
    SAVE_STATUS(state, payload) {
      state.status = payload;
    },
    SAVE_QUEUE_TOKEN(state, payload) {
      state.queueToken = payload;
    },
    SAVE_STRING_HALLO(state, payload) {
      state.stringHallo = payload;
    },
    SAVE_STRING_EVENEMENT(state, payload) {
      state.stringEvenement = payload;
    },
    SAVE_SHOP_ID(state, payload) {
      state.shopID = payload;
    },
    SAVE_TERMS(state, payload) {
      state.terms = payload;
    },
    SAVE_TERUG_BTN_TEXT(state, payload) {
      state.terugBtnText = payload;
    },
    SAVE_TIMESLOTS(state, payload) {
      state.timeslots = payload;
    },
    SAVE_WARNING(state, { warning1, warning2, warningTimerMilliseconds }) {
      state.warning.warning1 = warning1;
      state.warning.warning2 = warning2;
      state.warning.warningTimerSeconds = warningTimerMilliseconds;
    },
    SAVE_ISMOBILECART(state,payload) {
      state.isMobileCart = payload;
    }
  },

  /**
   * Acties zijn vergelijkbaar met mutaties, de verschillen zijn:
   * - in plaats van zelf de state aan te passen, roept een actie (met commit()) een mutatie aan.
   * - acties kunnen ook operaties uitvoeren.
   */
  actions: {

    // URL shopNum en shopTrackerID maken
    saveShopNumAndTrackerID({commit}, path) {


      // Haal het path uit elkaar.
      // Als er een string inzit die met /shop begint, dan is dat het shopNumber
      var shopNumber = "0";
      var shopTrackerID = "0";

      var parts = path.split("/");
      for (var i = 0; i < parts.length; i++) {
        // Als de string met /shop begint, dan is dat het shopNumber
        if (parts[i].includes("shop-")) {

          // Verwijder shop
          var temp = parts[i].replace("shop-", "");

          // Kijk of er e '-' teken inzit. Zoja dan is het eerste deel het shopNumber en het tweede deel het shopTrackerID
          if (temp.includes("-")) {
            var tempParts = temp.split("-");
            shopNumber = tempParts[0];
            shopTrackerID = tempParts[1];
          } else {
            shopNumber = temp;
          }
        }
      }

      commit('SAVE_SHOPNUMBERANDTRACKINGID', {shopNumber: shopNumber, shopTrackerID: shopTrackerID});
    },

    // seatingplan
    getSeats({commit}) {
      console.log("HTTP getseats fired")
      
      store.dispatch('removeError');
      store.dispatch('removeWarning');

      HTTP.get('/shop/'+this.state.shopID+'/event/'+this.state.selectedEvent.ID+'/seatingplan/'+this.state.selectedEvent.evSeatingPlanID).then(result => {
        commit('SAVE_RANKS', result.data.body.seatingplan.ranks);
        commit('SAVE_SEATINGPLAN', result.data.body.seatingplan.seatingplan.items);
        $("#preloader").fadeOut("slow");     
        return true
      }).catch(function (error) {
        // request is niet gelukt dus error
        var error1 = error;
        var error2 = 'informatie is niet op te halen. probeer het opnieuw';
        dispatch('setError',{ error1, error2})
        $("#preloader").fadeOut("slow");    
        return false 
      });
    },
    // seatingplan
    /*
    loadStatus({commit}) {
      console.log("HTTP loadStatus fired")
      HTTP.get('/shop/'+this.state.shopID+'/status').then(result => {

        commit('SAVE_STATUS', result.data);
        if (result.data.status=="OK") {
          commit('SAVE_STATUS', 'OK: '+Object.values(result.data.body));
          
          // shopToken moet ook naar de localStorage
          localStorage.setItem('shopToken', result.data.body.status.shopToken);

          if(router.currentRoute.path != '/closed' && this.state.status.shopStatus == "CLOSED") {
            router.push('/closed')
          }

          HTTP.get('/shop/'+this.state.shopID+'/events').then(result => {
            document.getElementById("preloader").style.display = 'none';
          })

        } else {
          commit('SAVE_STATUS', 'Error: '+error);  
          // shopToken moet ook naar de localStorage
          localStorage.setItem('shopToken', result.data.body.status.shopToken);

          if(router.currentRoute.path != '/closed' && this.state.status.shopStatus == "CLOSED") {
            router.push('/closed')
          }
        }
      }).catch(function (error) {
        commit('SAVE_STATUS', 'Er is een server exception: '+error);

        // shopToken moet ook naar de localStorage
        localStorage.setItem('shopToken', result.data.body.status.shopToken);

        if(router.currentRoute.path != '/closed' && this.state.status.shopStatus == "CLOSED") {
          router.push('/closed')
        }
      }).then(result => {

      });
    },
     */
    initShop({commit, dispatch}) {
      console.log("HTTP initShop fired");



      // bepalen of er via een iframe wordt geladen.
      if(window.self !== window.top) {
          commit('IS_IN_IFRAME', true);
          console.log('Via een iframe gestart');
      }else{
          commit('IS_IN_IFRAME', false);
      }

      var shopToken = '';
      if (localStorage.getItem("shopToken") !== null) {
        shopToken = localStorage.getItem("shopToken");
      }



      /* Check of de URL een usercode heeft. In dat geval is hij gestart vanuit het dashboard.
         De usercode wordt gecontroleerd in de API.
         De usercode wordt gereset, iedere keer als het dashboard een nieuwe sessie krijgt
         Om te zorgen dat alles nog werkt na een refresh (als bijvoorbeeld de shop gesloten is), het token opslaan in de localstorage.
       */
      let url = window.location.href;
      let params = (new URL(url)).searchParams;
      var usercode = '';
      if(params.get('u')) {
        usercode = params.get('u') //'?userToken='+
      } else if (localStorage.getItem("shopTempToken") !== null) { /* shopAdmin token, maar dna anders genoemd ... */
        usercode = localStorage.getItem("shopTempToken");
      }



      // als de data van de initCustomerFormPaymentForm al beschikbaar is in de localStorage
      // haal deze dan op en sla deze op in de store
      if(localStorage.getItem('initForm') !== null) {
        var initFormData = JSON.parse(localStorage.getItem('initForm'))
        commit('SAVE_FIELDS', initFormData.fields);
        commit('SAVE_PAYMENTMETHODS', initFormData.paymentMethods);                 
        commit('SAVE_DEFAULT_PAYMENT_TRANSACTION', {paymentMethodDefault: initFormData.paymentMethodDefault, paymentMethodDefaultCost: initFormData.paymentMethodDefaultCost, hasPaymentTransactionCost: initFormData.hasPaymentTransactionCost});
        commit('SAVE_SHOP_CUSTOM_TERMS', {shopCustomTerms: initFormData.shopCustomTerms, shopCustomTermsTitle: initFormData.shopCustomTermsTitle})
      }  

      // Ophalen / initialiseren customerfields
      if(localStorage.getItem("customerFields") === null) {
        localStorage.setItem("customerFields", JSON.stringify(this.state.customerFields))

      } else {
        commit('SAVE_CUSTOMER_FIELDS',JSON.parse(localStorage.getItem("customerFields")));
      }

      // Queue token ophalen als hij bestaat en controleren of hij nog geldig is
        if(localStorage.getItem('queueToken') !== null) {
            const tokenObject = JSON.parse(localStorage.getItem('queueToken'));
            const now = new Date();
            if (now.getTime() > tokenObject.expiry) { // Verlopen??
                localStorage.removeItem('queueToken');
                commit('SAVE_QUEUE_TOKEN', "");
            } else {
                commit('SAVE_QUEUE_TOKEN', tokenObject.value);
            }
        }

      HTTP.get('/shop/'+this.state.shopID+'/init?shopToken='+shopToken+'&queueToken='+this.state.queueToken+'&userToken='+usercode+'&shopNumber='+this.state.shopNumber+'&shopTrackerID='+this.state.shopTrackerID).then(result => {
        // haal het laadscherm weg (met fadeout)
        $("#preloader").fadeOut("slow");

        if(result.data.errorMsg == "This shop does not exist" && router.currentRoute.path != '/shopDoesNotExist') {
          router.push('/shopDoesNotExist');  
        }
        else {
          var isStatusOK = checkInitResultStatus(result)
          // checken of result body wel een status bevat
          if(isStatusOK) {
            commit('SAVE_STATUS', result.data.body.status);

            // shopToken moet ook naar de localStorage
            localStorage.setItem('shopToken', result.data.body.status.shopToken);
            localStorage.setItem('shopTempToken', result.data.body.status.shopAdminToken);

            localStorage.setItem('shopAnalytics',result.data.body.status.shopAnalytics);
            localStorage.setItem('shopFacebookPixel',result.data.body.status.shopFacebookPixel);

            if (result.data.body.status.shopStatus == "BUSY") {

              // queueToken opslaan voo 25 minuten
              const now = new Date();
              const expiryTime = now.getTime() + 25 * 60 * 1000; // 20 minuten in milliseconden
              const tokenObject = {
                value: result.data.body.status.queueToken,
                expiry: expiryTime
              };
              localStorage.setItem('queueToken', JSON.stringify(tokenObject));
               commit('SAVE_QUEUE_TOKEN', result.data.body.status.queueToken);
               router.push('/busy');
               return;
            }



            commit('SAVE_CUSTOMER_FIELDS_LAYOUT',result.data.body.customerFieldsLayout);
            commit('SAVE_PAYMENTMETHODS', result.data.body.paymentMethods);                 
            commit('SAVE_DEFAULT_PAYMENT_TRANSACTION', {paymentMethodDefault: result.data.body.paymentMethodDefault, paymentMethodDefaultCost: result.data.body.paymentMethodDefaultCost, hasPaymentTransactionCost: result.data.body.hasPaymentTransactionCost});
            commit('SAVE_SHOP_CUSTOM_TERMS', {shopCustomTerms: result.data.body.shopCustomTerms, shopCustomTermsTitle: result.data.body.shopCustomTermsTitle})

          } else {
            console.error("Init error");
            var errorCode = result.data.errorNo;
            var errorMessage = "Result has no status";
            dispatch('setError', {error1: errorCode, error2: errorMessage})
          }
        
          var areEventsOK = checkInitResultEvents(result)
          // checken of request goed is gegaan en benodigde info aanwezig is
          if(areEventsOK) {          
            commit('SAVE_EVENTS', result.data.body.events);
          } else {
            var errorCode = result.data.errorNo;
            var errorMessage = "Result has no event";
            dispatch('setError', {error1: errorCode, error2: errorMessage})
          }

          if(result.data.errorMsg != "") {
            var errorCode = result.data.errorNo;
            var errorMessage = result.data.errorMsg;
            dispatch('setError', {error1: errorCode, error2: errorMessage})
          }

          commit('UPDATE_CART', result.data.body.cart);
          commit('CHANGE_TRANSACTIONCOST', result.data.body.transactionCostList);

          // selectedEvent zetten, ivm donatie tesxten (derze zittne per event ivm de verzamel sites.
          if (result.data.body.events.events.items.length > 0) {
            commit('SAVE_SELECTED_EVENT', result.data.body.events.events.items[0]);
          }

          //console.log(result.data.body);
          commit('CHANGE_LOADED', true);



          // shopStatus is CLOSED, dan naar de closed pagina
          if (this.state.status.shopStatus == "CLOSED" && window.location.href.includes('/event/') && result.data.body.status.shopAdminToken!="") {
            // Miks doen
          } else if (Functions.isShopClosed()) {
             var redirToClosed = false;
             if (router.currentRoute.path==undefined) {
               var redirToClosed = true;
             } else if (!router.currentRoute.path.includes('/closed')) {
               var redirToClosed = true;
             }

             if (redirToClosed) {
               if (this.state.shopNumber != "0") {
                 router.push('/shop-'+this.state.shopNumber+'/closed');
               } else {
                 router.push('/closed')
               }
             }
          }

        }


        if(window.location.href.includes('/clientshop') || window.location.href.includes('/directcart')) {

          // achtergrondkleur uitzetten.
          document.body.style.backgroundColor = "transparent";

          // selected event zettem
          let url = window.location.href;
          let params = (new URL(url)).searchParams;
      
          if(params.get('ev')) {
            var curEvent = params.get('ev');

            result.data.body.events.events.items.forEach(item => {
                if (item.ID == curEvent) {
                  commit('OPEN_POPUP', {selectedEvent: item, whichPopUpToOpen: "bestellen", popUpOpenByShoppingCartInfo: false});
                  store.dispatch('openPopUp', {
                    selectedEvent: item,
                    popUpOpenByShoppingCartInfo: false,
                    whichPopUpToOpen: "bestellen"
                  });
                }
              });
          }
       }
        }).catch(function (error) {
          // request is niet gelukt dus error
          var error1 = error.message;
          var error2 = 'Er is een server exception';
          dispatch('setError',{ error1, error2})
          $("#preloader").fadeOut("slow");     
        }).then(
      );
    },
    validateInvitationCode({dispatch}, payload) {
      console.log("HTTP validateInvitationCode fired")

      store.dispatch('removeError');
      store.dispatch('removeWarning');

      let formData = new FormData();
      if(payload == '') {
        var errorMessage = "No invitationcode found in the inputfield";
        dispatch('setError', {error1: errorMessage, errorTimerMilliseconds: 5000})
      }
      else {
        formData.append('invitationCode', String(payload))
        HTTP.post('/shop/'+this.state.shopID+'/validateinvitation', formData).then(result => {
          if(result.data.errorMsg != "") {
            var errorCode = result.data.errorNo;
            var errorMessage = result.data.errorMsg;
            dispatch('setError', {error1: errorCode, error2: errorMessage, errorTimerMilliseconds: 5000})
          } else {
            if(router.currentRoute.path != '/') {
            router.push('/')
            }
          } 
        }).catch(function (error) {
          // request is niet gelukt dus error
          var error1 = error;
          var error2 = 'Er is een server exception';
          dispatch('setError',{ error1, error2})
          $("#preloader").fadeOut("slow");     
        });
      }  
    },
    checkQueueBusy({commit, dispatch},payload) {
      store.dispatch('removeError');
      store.dispatch('removeWarning');

          HTTP.get('/shop/'+this.state.shopID+'/queue/poll?queueToken='+store.state.queueToken+'&shopNumber='+store.state.shopNumber).then(result =>
          {
            if (result.data.status=="OK") {

               if (result.data.body.shopStatus=="BUSY") {
                  router.push('/busy');
                  return;
               } else if (result.data.body.shopStatus=="RELOAD") {

                 // Opslaan laatste queueToken (met nieuwe verloop tijd)
                 // queueToken opslaan voo 25 minuten
                 const now = new Date();
                 const expiryTime = now.getTime() + 25 * 60 * 1000; // 20 minuten in milliseconden
                 const tokenObject = {
                   value: result.data.body.status.queueToken,
                   expiry: expiryTime
                 };
                 localStorage.setItem('token', JSON.stringify(tokenObject));
                 commit('SAVE_QUEUE_TOKEN', result.data.body.status.queueToken);


                 // Pagina herladen met laatste url
                  var lastUrl = localStorage.getItem('entrancePath');
                  if (lastUrl === undefined) { lastUrl = '/'; } // Homepage als de entrancePath niet bestaat (zou alleen kunnen als men de localstorage leeggooit).

                  // Indien direct naar de busy gegaaan, dan terug naar de homepage (als je reschtstreeks naar deze pagina bent gegaan).
                  if (lastUrl === '/busy') { lastUrl = '/'; }

                  window.location.href = lastUrl;

               }

            } else {

              var errorCode = result.data.errorNo;
              var errorMessage = result.data.errorMsg;

              if(result.data.errorMsg == "This shop does not exist" && router.currentRoute.path != '/shopDoesNotExist') {
                router.push('/shopDoesNotExist');
              }

              dispatch('setError',{ error1:errorCode, error2:errorMessage})
              $("#preloader").fadeOut("slow");
            }
          }).catch(function (error) {
              // request is niet gelukt dus error
              var error1 = error;
              var error2 = 'Er is een server exception in slots';
              dispatch('setError',{ error1, error2})
              $("#preloader").fadeOut("slow");
          });
    },
    getTimeslots({commit, dispatch}, payload) {
      store.dispatch('removeError');
      store.dispatch('removeWarning');

      var eventID = payload.eventID;
      //var selectSlot = payload.selectSlot;
      var selectSlot = 0;
      HTTP.get('/shop/'+this.state.shopID+'/event/'+eventID+'/timeslots?selectSlot='+selectSlot).then(result => 
        {
          if (result.data.status=="OK") {

            slots.initSlots(result.data.body.calendar);

            commit('SAVE_TIMESLOTS', result.data.body);

          } else {          

            var errorCode = result.data.errorNo;
            var errorMessage = result.data.errorMsg;
            
            if(result.data.errorMsg == "This shop does not exist" && router.currentRoute.path != '/shopDoesNotExist') {
              router.push('/shopDoesNotExist');  
            }

            dispatch('setError',{ error1:errorCode, error2:errorMessage})
            $("#preloader").fadeOut("slow");     
          }
        }).catch(function (error) {
          
          // request is niet gelukt dus error
          var error1 = error;
          var error2 = 'Er is een server exception in slots';
          dispatch('setError',{ error1, error2})
          $("#preloader").fadeOut("slow");     

        }).then(

      );
    },
    addCart({commit, dispatch}, payload) {

      // Opruiimen eventuele foutmeldingen
      store.dispatch('removeError');
      store.dispatch('removeWarning');

      // Result
      var callStatus = {status:"OK",message:""};

      var paymentMethodParam;
      if(this.state.selectedPaymentMethod.Key == '') {
        paymentMethodParam = this.state.paymentMethodDefault;
      } else {
        paymentMethodParam = this.state.selectedPaymentMethod.Key;
      }

      var issuerParam;
      if(this.state.selectedPaymentMethod.selectedIssuer == '') {
        issuerParam = this.state.selectedPaymentMethod.selectedIssuer;
      } else {
        issuerParam = '';
      }

      let data = new FormData;
      data.append('shopToken',localStorage.getItem('shopToken'))
      data.append('shopNumber',this.state.shopNumber)
      data.append('shopTrackerID',this.state.shopTrackerID)
      data.append('accountID',payload.accountId)
      data.append('eventID', payload.eventID)
      data.append('ticketTypeID', payload.ticketTypeID)
      data.append('timeSlot', payload.timeSlot)
      data.append('price', payload.price)
      data.append('amount', payload.quantity)
      data.append('pakketID', 0)
      data.append('spaarID', 0)
      data.append('paymentMethod', paymentMethodParam)
      data.append('paymentIssuer', issuerParam)
      data.append('seat',JSON.stringify(payload.seat));

      var getAccountData = 0;
      if(this.state.cart.bestelItems.length == 0) {
        getAccountData = 1;
      }
      data.append('getAccountData', getAccountData)

      if(payload.donation) {
        data.append('donation', payload.donation)
      } else {
        data.append('donation', 0)
      }   

      // reset de popup van de checkout terug naar boven
      store.dispatch('resetPopUpToTop', true)

      //'/shop/'+this.state.shopID+'/cartadd?shopToken=CES61CB2A&accountID=3&totalTicketAmount=100&uniqueKey&amount&price&couponCode&invitationCode&timeSlot'

      return HTTP.post('/shop/'+this.state.shopID+'/cartadd', data).then(result => {
        //console.log(result.data)
        if (result.data === undefined) {

          callStatus.status = "ERROR";
          callStatus.message = error1;
          return callStatus;

        } else if (result.data.status=="OK") {
          
          commit('CHANGE_TRANSACTIONCOST', result.data.body.transactionCostList);
          commit('UPDATE_CART', result.data.body.cart);
          // commit('UPDATE_ISADDED_ISUPDATED', {isAdded: result.data.body.isAdded, isUpdated: result.data.body.isUpdated})

          if(getAccountData == 1){  
            commit('SAVE_CUSTOMER_FIELDS_LAYOUT',result.data.body.customerFieldsLayout);
            commit('SAVE_PAYMENTMETHODS', result.data.body.paymentMethods);                 
            commit('SAVE_DEFAULT_PAYMENT_TRANSACTION', {paymentMethodDefault: result.data.body.paymentMethodDefault, paymentMethodDefaultCost: result.data.body.paymentMethodDefaultCost, hasPaymentTransactionCost: result.data.body.hasPaymentTransactionCost});
            commit('SAVE_SHOP_CUSTOM_TERMS', {shopCustomTerms: result.data.body.shopCustomTerms, shopCustomTermsTitle: result.data.body.shopCustomTermsTitle})
          }

          var text;
          if(result.data.body.isAdded == 1){
              text = Selector.SelectLanguageStringFromLangJSON('Alert', 'TicketsZijnToegevoegdAanDeWinkelwagen');
          } else if(result.data.body.isUpdated == 1) {
              text = Selector.SelectLanguageStringFromLangJSON('Alert', 'AantalTicketsIsAangepast');
          }

          callStatus.status = "OK";
          callStatus.message = text;
          return callStatus;

        } else {        
          var text;  
          if(result.data.errorNo==1003){

            // Stoelenplan update werkt nog niet
            commit("EMPTY_SEATINGPLAN")
            commit("EMPTY_RANKS")
            commit('SAVE_RANKS', result.data.body.seatingplan.ranks);
            commit('SAVE_SEATINGPLAN', result.data.body.seatingplan.seatingplan.items);
            text = Selector.SelectLanguageStringFromLangJSON("Checkout", "addCartError1003");

            callStatus.status = "ERROR";
            callStatus.message = text;
            return callStatus;

            //error als een stoel al bezet is in de api
          } else if (result.data.errorNo==1001) { // uitverkocht
            text = Selector.SelectLanguageStringFromLangJSON("Checkout", "addCartError1001");

            var event = Functions.getEventFromID(payload.eventID);
            if (event!=null && event.evHasTimeSlots==0)  { // check uitverkocht, behalve bij een event.

              // Zet dit tickettype op uitverkocht! Indien alle tickettypes zijn uitverkocht, dan event op uitverkocht zetten.
              Functions.setTicketTypeSoldOut(payload.eventID,payload.ticketTypeID);
             
            }
            if (event!=null && event.evHasTimeSlots==1)  { 
                store.dispatch('saveTimeSlots',null);
            }

            callStatus.status = "ERROR";
            callStatus.message = text;
            return callStatus;

          } else if (result.data.errorNo==1002) { // niet meer beschikbaar
            if (result.data.body.amount==1) {            
              text = Selector.SelectLanguageStringFromLangJSONWithParams("Checkout", "addCartError1002Enkelvoud", {"amount":result.data.body.amount});
            } else {
              text = Selector.SelectLanguageStringFromLangJSONWithParams("Checkout", "addCartError1002Meervoud", {"amount":result.data.body.amount});
            }
            callStatus.status = "ERROR";
            callStatus.message = text;
            return callStatus;

          } else {

            callStatus.status = "ERROR";
            callStatus.message = result.data.errorMsg;
            return callStatus;

          }

          // onmdat er iets fout is gegaan de originele cart weer laden:
          commit('UPDATE_CART', store.state.cart);

        }

        }).catch(function (error) {
          
          $("#preloader").fadeOut("slow");
          callStatus.status = "ERROR";
          callStatus.message = error;

          return callStatus;

        }).then(

        );
    },
    delCart({commit, dispatch}, payload) {

      // Opruimen eventuele foutmeldingen
      store.dispatch('removeError');
      store.dispatch('removeWarning');

      // Result
      var callStatus = {status:"OK",message:""};

      var paymentMethodParam;
      if(this.state.selectedPaymentMethod.Key == '') {
        paymentMethodParam = this.state.paymentMethodDefault;
      } else {
        paymentMethodParam = this.state.selectedPaymentMethod.Key;
      }

      var issuerParam;
      if(this.state.selectedPaymentMethod.selectedIssuer == '') {
        issuerParam = this.state.selectedPaymentMethod.selectedIssuer;
      } else {
        issuerParam = '';
      }

      let data = new FormData;
      data.append('shopToken',localStorage.getItem('shopToken'))
      data.append('shopID', this.state.shopID)
      data.append('shopNumber', this.state.shopNumber)
      data.append('shopTrackerID', this.state.shopTrackerID)
      data.append('accountID', payload.accountId)
      data.append('uniqueKey', payload.key)
      data.append('paymentMethod', paymentMethodParam)
      data.append('paymentIssuer', issuerParam)

      if(payload.donation == 1) {
        data.append('donation', payload.donation)
      } else {
        data.append('donation', 0)
      }

      //'/shop/'+this.state.shopID+'/cartdel?shopToken=CES61CB2A&shopID=&accountID=3&totalTicketAmount=100&uniqueKey=123456789'

      return HTTP.post('/shop/'+this.state.shopID+'/cartdel', data).then(result => {
        if (result.data === undefined) {
          // Dan  is de REST server gecrasht.
          var error1 = result;
          var error2 = 'Er is een server exception';
          dispatch('setError',{ error1, error2})

          callStatus.status = "ERROR";
          callStatus.message = "Er is een server exception";
          return callStatus;

        } else  if (result.data.status=="OK") {
          commit('CHANGE_TRANSACTIONCOST', result.data.body.transactionCostList);
          commit('UPDATE_CART', result.data.body.cart);

          callStatus.status = "OK";
          callStatus.message = "Er is een server exception";
          return callStatus;

        } else {          

          if(result.data.errorMsg == "This shop does not exist" && router.currentRoute.path != '/shopDoesNotExist') {
            router.push('/shopDoesNotExist');  
          }

          callStatus.status = "ERROR";
          callStatus.message = result.data.errorMsg;
          return callStatus;

        }

        }).catch(function (error) {

          callStatus.status = "ERROR";
          callStatus.message = error;
          return callStatus;

        }).then(

        );
    },
    emptyCart({commit, dispatch}) {
      console.log("HTTP emptyCart fired")

      store.dispatch('removeError');
      store.dispatch('removeWarning');

      // verwijder de data van de init uit de localStorage
      localStorage.removeItem('initForm');
      localStorage.setItem("fromEmptyCart", "true");

      var paymentMethodParam;
      if(this.state.selectedPaymentMethod.Key == '') {
        paymentMethodParam = this.state.paymentMethodDefault;
      } else {
        paymentMethodParam = this.state.selectedPaymentMethod.Key;
      }

      var issuerParam;
      if(this.state.selectedPaymentMethod.selectedIssuer == '') {
        issuerParam = this.state.selectedPaymentMethod.selectedIssuer;
      } else {
        issuerParam = '';
      }

      var accountID = this.state.cart.bestelItems[0].accountId;
      
      let data = new FormData;
      data.append('shopToken',localStorage.getItem('shopToken'))
      data.append('shopID', this.state.shopID)
      data.append('accountID', accountID)
      data.append('paymentMethod', paymentMethodParam)
      data.append('paymentIssuer', issuerParam)

      //'/shop/'+this.state.shopID+'/cartempty?shopToken=CES61CB2A&shopID=&accountID=3&totalTicketAmount=100'

      return HTTP.post('/shop/'+this.state.shopID+'/cartempty', data).then(result => {
        if (result.data.status=="OK") {
          commit('CHANGE_TRANSACTIONCOST', result.data.body.transactionCostList);
          commit('UPDATE_CART', result.data.body.cart)
          return result.data.status
        } else {          

          var errorCode = result.data.errorNo;
          var errorMessage = result.data.errorMsg;
          
          if(result.data.errorMsg == "This shop does not exist" && router.currentRoute.path != '/shopDoesNotExist') {
            router.push('/shopDoesNotExist');  
          }

          dispatch('setError',{ error1:errorCode, error2:errorMessage})  

          return result.data.errorNo
        }

        }).catch(function (error) {
          
          // request is niet gelukt dus error
          var error1 = error;
          var error2 = 'Er is een server exception in slots';
          dispatch('setError',{ error1, error2})
          $("#preloader").fadeOut("slow");     
          
          return;

        }).then(

        );
    },
    loadCart({commit, dispatch}) {
      console.log("HTTP loadCart fired")

      store.dispatch('removeError');
      store.dispatch('removeWarning');

      // haal de order uit de url
      let url = window.location.href;
      let params = (new URL(url)).searchParams;
      var shopReservationKey = '';
      if(params.get('o')) {
        shopReservationKey = params.get('o') //'?shopReservationKey='+
      }

      // 9a64dbe7ea7f959003558c8989cc8fcb5217370655a374c53f36a839262aa37a

      let data = new FormData;
      data.append('shopReservationKey', shopReservationKey)
      data.append('getAccountData', 1)    

      return HTTP.post('/shop/'+this.state.shopID+'/cartload', data).then(result => {
        if (result.data.status=="OK") {
          $("#preloader").fadeOut("slow");

          dispatch('setSaveLoadCartResult', {errorCode: 0, errorMessage: "",shopReservationKey: shopReservationKey, shopReservationOrderRef: result.data.body.cart.orderRef, shopReservationUntil: result.data.body.cart.orderReservedUntil});

          // indien customerFields al gezet en generalTems aan staat, dan deze onthouden en datdelijk aan zetten
          var temp = JSON.parse(localStorage.getItem("customerFields"));
          if (temp != undefined && temp.generalTerms != undefined && temp.generalTerms == true) {
            result.data.body.cart.customerFields.generalTerms = true;
          }

          commit('CHANGE_LOADED', false);
          commit('SAVE_DIRECTMODE',true);
          commit('SAVE_EVENTS', result.data.body.events);
          commit('UPDATE_CART', result.data.body.cart)
          commit('SAVE_CUSTOMER_FIELDS_LAYOUT', result.data.body.customerFieldsLayout);
          commit('SAVE_CUSTOMER_FIELDS', result.data.body.cart.customerFields);
          commit('SAVE_PAYMENTMETHODS', result.data.body.paymentMethods);                 
          commit('SAVE_DEFAULT_PAYMENT_TRANSACTION', {paymentMethodDefault: result.data.body.paymentMethodDefault, paymentMethodDefaultCost: result.data.body.paymentMethodDefaultCost, hasPaymentTransactionCost: result.data.body.hasPaymentTransactionCost});
          commit('SAVE_SHOP_CUSTOM_TERMS', {shopCustomTerms: result.data.body.shopCustomTerms, shopCustomTermsTitle: result.data.body.shopCustomTermsTitle})
          commit('CHANGE_TRANSACTIONCOST', result.data.body.transactionCostList);   
          commit('SAVE_STATUS',  result.data.body.status);
          commit('CHANGE_LOADED', true);


          return result.data
        } else {          
          $("#preloader").fadeOut("slow");

          var errorCode = result.data.errorNo;
          var errorMessage = result.data.errorMsg;

          dispatch('setSaveLoadCartResult', {errorCode: errorCode, errorMessage: errorMessage, shopReservationKey: shopReservationKey});
          commit('CHANGE_LOADED', true);


          // dispatch('setError',{ error1:errorCode, error2:errorMessage})
          return;
  
        }

        }).catch(function (error) {
          
          // request is niet gelukt dus error
          var error1 = error;
          var error2 = 'Er is een server exception in loadCart';
          dispatch('setError',{ error1, error2})
          $("#preloader").fadeOut("slow");     

        }).then(

      )
    },
    startPayment({commit, dispatch}, payload) {

      // Eventuele meldingen uitzetten??
      store.dispatch('removeError');
      store.dispatch('removeWarning');

      //var amount;
      var method = this.state.selectedPaymentMethod.Key;

      var issuer = '';
      if(this.state.selectedPaymentMethod.selectedIssuer != undefined) {
        issuer = this.state.selectedPaymentMethod.selectedIssuer;
      }     

      var totaalBedrag = 0;      
      totaalBedrag = this.state.cart.totalPriceTickets + this.state.cart.donation + this.state.cart.transactionCost;

      // TotaalBedrag afronden naar 2 cijfers achter de komma.
      totaalBedrag = Math.round((totaalBedrag + Number.EPSILON) * 100) / 100;  // De Epsilon, zorgt voor een correctie op binair verschillen.

      let data = new FormData;
      data.append('shopToken',localStorage.getItem('shopToken'))
      data.append('shopNumber', this.state.shopNumber);
      data.append('shopReservationKey', this.state.loadCartResult.shopReservationKey);
      data.append('shopTrackerID', this.state.shopTrackerID);
      data.append('accountID', payload);
      data.append('paymentMethod', method);
      data.append('paymentIssuer', issuer);
      data.append('amount', totaalBedrag);


      data.append('visitorType', this.state.customerFields.visitorType);

      data.append('visitorCompany', this.state.customerFields.visitorCompany);
      data.append('visitorVatNumber', this.state.customerFields.visitorVatNumber);

      data.append('visitorFirstName', this.state.customerFields.visitorFirstName);
      data.append('visitorLastName', this.state.customerFields.visitorLastName);
      data.append('visitorName', this.state.customerFields.visitorName);
      data.append('visitorEmail', this.state.customerFields.visitorEmail);
      data.append('visitorPhone', this.state.customerFields.visitorPhone);
      data.append('visitorGender', this.state.customerFields.visitorGender);
      data.append('visitorAge', this.state.customerFields.visitorAge);
      data.append('visitorPhone', this.state.customerFields.visitorPhone);
      data.append('visitorStreet', this.state.customerFields.visitorStreet);
      data.append('visitorZipcode', this.state.customerFields.visitorZipcode);
      data.append('visitorCity', this.state.customerFields.visitorCity);
      data.append('visitorNewsLetter', this.state.customerFields.visitorNewsLetter);
      data.append('visitorLanguage', this.state.localeLanguage);
      data.append('visitorCountry', this.state.browserLanguage);


      var callResult = {
          status: 'OK',
          totaalBedrag: 0,
          paymentStatus: "",
          errorLevel: 0,
          errorCode: 0,
          errorMsg: ""
      }

      //'/shop/'+this.state.shopID+'/startpayment?shopToken=CES61CB2A&accountID=3&amount=100&method=ideal&issuer=ideal_BUNQNL2A'
      // Opvragne welke basis url in HTTP zit
      var hulpUrl = HTTP.defaults.baseURL;


      return HTTP.post('/shop/'+this.state.shopID+'/startpayment', data, {
        headers: {
          'Content-Type': 'multipart/form-data',  // Dit zorgt ervoor dat de data als FormData wordt verzonden
          'Accept': 'application/json'           // Dit geeft aan dat je een JSON-response verwacht
        }
      }).then(result => {
            if (result.data.status=="OK") {

              // Cart leegmaken, hij is verwerkt.
              commit('UPDATE_CART',{
                  bestelItems: [],
                  method: '',
                  totalPrice: 0,
                  totalTickets: 0,
                  totalPriceTickets:0,
                  transactionCost: 0,
                  validUntil: '',
                  donationPrice: 0,
                  issuer: ''
                }); // leeg maken cart

              if(totaalBedrag > 0) {  // indien 0 euro, dan geen redirect!!
                if ( window.location !== window.top.location ) {

                  const message = {
                    type: 'redir',
                    redir: result.data.body.redirect,
                  };
                  window.parent.postMessage(message, document.referrer);


                } else {
                  window.location.href = result.data.body.redirect;
                }
                callResult.totaalBedrag = totaalBedrag;

              } else if(totaalBedrag == 0) { // Er is direct afgerekend / alles is vastgelegd
                commit('SAVE_PAYMENT_STATUS', result.data.body.paymentStatus);
                callResult.totaalBedrag = totaalBedrag;
                //callResult.paymentStatus = result.data.body.paymentStatus.paymentStatus;

                Functions.redirPage('/orderresult/OK');

              }
            } else {

                if (result.data.errorMsg == "This shop does not exist" && router.currentRoute.path != '/shopDoesNotExist') {
                  router.push('/shopDoesNotExist');
                }

                callResult.status = "ERROR";
                callResult.errorCode = result.data.errorNo;
                callResult.errorMsg = result.data.errorMsg;
                callResult.errorLevel = "NORMAL";


              //dispatch('setError',{ error1:errorCode, error2:errorMessage}) ;
            }
            return callResult;

        }).catch(function (error) {

            $("#preloader").fadeOut("slow"); // App loader uitzetten

            callResult.status = "ERROR";
            callResult.errorCode = error.message;

            if (getActiveServer('developmentMode')) {
              if (error.response !== undefined && error.response.data !== undefined) {
                callResult.errorMsg = error.response.data;
              } else {
                callResult.errorMsg = error.message;
              }

            } else {
              callResult.errorMsg = error.message;
            }

            callResult.errorLevel = "SEVERE";
            return callResult;

        })
    },
    checkPayment({commit, dispatch}, payload) {

      console.log("HTTP checkPayment fired")
      store.dispatch('removeError');
      store.dispatch('removeWarning');

      let data = new FormData;
      data.append('orderRef', payload) //payload '003T01606'
      
      //'/shop/'+this.state.shopID+'/checkpayment?orderKey=003T01606'  ---> cascom
      return HTTP.post('/shop/'+this.state.shopID+'/checkpayment', data).then(result => {
            if (result.data.status=="OK") {

              commit('SAVE_STATUS', result.data.body.status);
              commit('SAVE_PAYMENT_STATUS', result.data.body.paymentStatus);
              return result.data.body.paymentStatus.paymentStatus;

            } else {          
              var errorCode = result.data.errorNo;
              var errorMessage = result.data.errorMsg;
              
              if(result.data.errorMsg == "This shop does not exist" && router.currentRoute.path != '/shopDoesNotExist') {
                router.push('/shopDoesNotExist');  
              }

              dispatch('setError',{ error1:errorCode, error2:errorMessage}) 
              return;
            }

            }).catch(function (error) {
              
              // request is niet gelukt dus error
              var error1 = error;
              var error2 = 'Er is een server exception in slots';
              dispatch('setError',{ error1, error2})
              $("#preloader").fadeOut("slow");     

            }).then(
          );    
    },
    getEventById({commit}, eventID) {
      console.log("HTTP getEventByID fired")

      store.dispatch('removeError');
      store.dispatch('removeWarning');

      return HTTP.get('/shop/'+this.state.shopID+'/event/'+eventID).then(result => {
        commit('SAVE_EVENTS', result.data.body);
        $("#preloader").fadeOut("slow");
        return true;
      }).catch(function (error) {
        // request is niet gelukt dus error
        var error1 = error;
        var error2 = 'Er is een server exception';
        dispatch('setError',{ error1, error2})
        $("#preloader").fadeOut("slow");     
        return false;
      });
    },

    getTerms({commit}) {
      console.log("HTTP getTerms fired")

      store.dispatch('removeError');
      store.dispatch('removeWarning');

      var accountID = this.state.cart.bestelItems[0].accountId;
      
      let data = new FormData;
      data.append('accountID', accountID)

      //'/shop/'+this.state.shopID+'/cartempty?shopToken=CES61CB2A&shopID=&accountID=3&totalTicketAmount=100'

      return HTTP.post('/shop/'+this.state.shopID+'/getterms', data).then(result => {
        if (result.data.status=="OK") {
          commit('SAVE_TERMS', result.data.body)
          return result.data.status
        } else {          

          var errorCode = result.data.errorNo;
          var errorMessage = result.data.errorMsg;
          
          if(result.data.errorMsg == "This shop does not exist" && router.currentRoute.path != '/shopDoesNotExist') {
            router.push('/shopDoesNotExist');  
          }

          dispatch('setError',{ error1:errorCode, error2:errorMessage})  

          return result.data.errorNo
        }

        }).catch(function (error) {
          
          // request is niet gelukt dus error
          var error1 = error;
          var error2 = 'Er is een server exception in getterms';
          dispatch('setError',{ error1, error2})
          $("#preloader").fadeOut("slow");     
          
          return;

        }).then(

        );
    },
    setIsMobileCart({commit}) {
      const mediaQuery = window.matchMedia('(max-width: 767px)');
      commit('SAVE_ISMOBILECART', mediaQuery.matches);
      //commit('SAVE_ISMOBILECART',payload);
    },
    changeCurrentSlot({commit}, payload) {
      commit('CHANGE_CURRENTSLOT', payload)
    },
    openPopUp({commit}, payload) {
      commit('OPEN_POPUP', payload);
    },
    resetPopUpToTop({commit}, payload) {
      commit('CHANGE_RESET_POPUP_TO_TOP', payload);
    },
    closePopUp({commit}) {
      commit('CLOSE_POPUP');
    },
    updateScrollPosition({commit}, payload) {
      commit('CHANGE_SCROLLPOSITION', payload);
    },
    changeStep({commit}, payload) {
      commit('CHANGE_STEP', payload)
    },
    changeOpenCustomTerms({commit}, payload) {
      commit('CHANGE_OPEN_CUSTOM_TERMS', payload)
    },
    setSelectedPaymentMethod({commit}, payload) {
      commit('SAVE_SELECTED_PAYMENT_METHOD', payload);
    },
    registerBrowserLanguage({commit}, payload) {
      commit('SAVE_BROWSER_LANGUAGE', payload); 
    },
    registerLocaleLanguage({commit}, payload) {
      commit('SAVE_LOCALE_LANGUAGE', payload); 
    },
    saveCustomerFields({commit}, payload) {
      localStorage.setItem("customerFields", JSON.stringify(payload))
      commit('SAVE_CUSTOMER_FIELDS', payload)
    },
    changeMethodAndIssuerCart({commit}, payload) {
      commit('CHANGE_METHOD_AND_ISSUER_CART', payload)
    }, 
    registerStringHallo({commit}, payload) {
      commit('SAVE_STRING_HALLO', payload);
    },
    registerStringEvenement({commit}, payload) {
      commit('SAVE_STRING_EVENEMENT', payload);
    },
    registerShopID({commit}, payload) {
      commit('SAVE_SHOP_ID', payload);
    },
    registerTerugBtnText({commit}, payload) {
      commit('SAVE_TERUG_BTN_TEXT', payload);
    },
    removeError({commit}) {
      commit('REMOVE_ERROR');
    },
    removeWarning({commit}) {
      commit('REMOVE_WARNING');
    },
    setSaveLoadCartResult({commit}, payload) {
      commit('SAVE_LOAD_CART_RESULT', payload);
    },
    saveDirectEvent({commit}, payload) {
      commit('SAVE_DIRECTEVENT', payload);
    },
    saveClientPage({commit}, payload) {
      commit('SAVE_CLIENTPAGE', payload);
    },
    setSelectedEvent({commit}, payload) {
      commit('SAVE_SELECTED_EVENT', payload);
    },
    setError({commit}, payload) {
      var error1 = payload.error1;
      var error2 = payload.error2;
      var errorTimerMilliseconds = payload.errorTimerMilliseconds
      commit('SAVE_ERROR', {error1, error2, errorTimerMilliseconds});
    },
    setWarning({commit}, payload) {
      var warning1 = payload.warning1;
      var warning2 = payload.warning2;
      var warningTimerMilliseconds = payload.warningTimerMilliseconds
      commit('SAVE_WARNING', {warning1, warning2, warningTimerMilliseconds});
    },
    getEvents({commit}) {
      console.log("HTTP getEvents fired")

      HTTP.get('/shop/'+this.state.shopID+'/events').then(result => {
      commit('SAVE_EVENTS', result.data);
      }).catch(function (error) {
        commit('SAVE_EVENTS', 'Er is een server exception: '+error);
      });
    },
    updateCart({commit}, payload) {
      commit('UPDATE_CART', payload);
    },
    getCart({}) {
      console.log("HTTP getCart fired")

      HTTP.get('/cart').then().catch(function (error) {
        console.error(error)
      });
    },
    getCartStatus({}) {
      console.log("HTTP getCart Status fired")

      HTTP.get('/cart/status').then().catch(function (error) {
        console.error(error)
      });
    },
    deleteCart({}, parameter) {
      console.log("HTTP deleteCart fired")

      HTTP.post('/cart/del/{id}/{amount}', {id: parameter.id, amount: parameter.amount}).then().catch(function (error) {
        console.error(error)
      });
    },
    validateCart({}) {
      console.log("HTTP validateCart fired")

      HTTP.get('/cart/validate').then().catch(function (error) {
        console.error(error)
      });
    },
    saveTimeSlots({commit},payload) {
      commit('SAVE_TIMESLOTS',payload)
    }
  },

  /**
   * Mocht de applicatie te groot worden, kunnen modules worden opgesteld.
   * Deze modules hebben zelf een state, mutaties, acties, getters en modules in zich.
   * Op deze manier kan er onderscheid gemaakt worden, zodat de store iets overzichtelijker wordt.
   */
  modules: {
  }
});

function checkInitResultEvents(result) {
  var resultsAreOK = false;
  // alles is goed gegaan en er is een body
  if(result.data.code == 200 && result.data.status == "OK" && typeof result.data.body != 'undefined') {      
    // kijk of de body events heeft
    if(result.data.body.events != []) {
      resultsAreOK = true;
    }
    else {
      resultsAreOK = false;
    }
  }
  return resultsAreOK;
}

function checkInitResultStatus(result) {
  var resultsAreOK = false;
  // alles is goed gegaan en er is een body
  if(result.data.code == 200 && result.data.status == "OK" && typeof result.data.body != 'undefined') {
    // kijk of de body status bevat
    if(result.data.body.status != []) {
      // als dit zo is, check dan of status niet undefined is
      if(typeof result.data.body.status.shopToken != '') {
        resultsAreOK = true;
      } else {
        resultsAreOK = false;
      }
    }
  }
  return resultsAreOK;
}

function getActiveServer(field) {

  /**
   * 
   * Let op!,
   * Als je hier een server gaat toevoegen moet dit IP adres ook in de Rest-api als "Allow-Origin" 
   * in de header worden toegevoegd (TVDBServers.php)
   */

  var ret = "";
  var servers = [

      // René Lokaal met lokale test database
      {
        "domein":"localhost:8080",
        "api": "http://localhost:8081",
        "development": true
      },

      // René Lokaal met online database
    { 
        "domein":"192.168.2.48:8081",
        "api": "https://api.ticketview.nl",
        "development": false
    },

  ]
  var domein = window.location.host;

  servers.forEach(server => {
      if(domein.includes(server.domein)) {
        if (field === "apiUrl") { ret = server.api; }
        if (field === "developmentMode") { ret = server.development; }
      }
  });

  if (ret === "") {
    if (field === "apiUrl") { ret = "https://api.ticketview.nl"; }
    if (field === "developmentMode") { ret =false; }
  }
  return ret;
}