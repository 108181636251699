// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.code-input-container[data-v-6325b146] {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.code-inputs[data-v-6325b146] {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 10px;
}
.code-input[data-v-6325b146] {
  width: 35px;
  height: 45px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}
.busy-indicator[data-v-6325b146] {
  margin-bottom: 10px;
  color: red;
  font-weight: bold
}
button[data-v-6325b146] {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
button[data-v-6325b146]:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.spinner[data-v-6325b146] {
  position: absolute;
  top: -10PX;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 10px 20px 10px 20px;
  border-radius: 12px;
  background-color: white;
  border: #aaa solid 1px;
}

/* Mediaquery voor mobiele telefoons en portatrait tablets */
@media only screen and (max-width: 768px) {
.code-input[data-v-6325b146] {
    width: 22px;
    height: 30px;
    font-size: 12px;
}
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
