// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,700;0,800;1,600&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Didact+Gothic&display=swap);"]);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --lightblue: #009fe3;
    --darkblue: #015b8c;
    --white: #ffffff;
}



.btn {
    font-weight: bold;
    width: 150px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
    color: white;
    outline: none;
    text-transform: uppercase;
    padding: 8px 4px 8px 4px;
}
.btnGreen {
    background-color: #95c11f;
    border: 1px solid #95c11f;
}
.btnGreen:disabled {
    background-color: #c2c2c2;
    border: 1px solid #696969;
}
.btnDisabled {
    background-color: #c2c2c2;
    border: 1px solid #696969;
    border: 1px solid #9c9c9c;
}

.btnBlue {
    background-color: #1aa8e2;
    border: 1px solid #1aa8e2;
}
.btnDarkBlue {
    background-color: #015b8c;
    border: 1px solid #015b8c;
}
.btnGrey {
    background-color: rgb(199, 199, 199);
    border: 1px solid rgb(199, 199, 199);
    pointer-events: none;
}
.btnWhite {
    background-color: #fff;
    color: #1aa8e2;
    border: 1px solid #1aa8e2;
    
}
.btnOrange {
    background-color: #f17916;
    border: 1px solid #f17916;
}
.btnRed {
    color: #c12a1f;
    background-color: white;
    border: 1px solid #c12a1f;
    pointer-events: none;
}


/* Reset default styles */


select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position-x: calc(100% - 10px);
    background-position-y: 17px;


    background-color: white;
    border: 1px solid #ccc;
    padding: 8px 12px;
    font-size: 16px;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
    position: relative;
    padding-top:20px;
    padding-left: 10px;
    font-size: 14px;
}

.select-top-arrow {
    background-position-y: -2px;
}


/* Style the dropdown arrow */
select::-ms-expand {
    display: none;
}

/* Custom arrow using pseudo-element */
select::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 12px;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid #333;
    pointer-events: none;
    transform: translateY(-50%);
}

/* Additional container to handle arrow positioning */
.select-container {
    position: relative;
    display: inline-block;
}

.select-container select {
    width: 100%;
}`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
