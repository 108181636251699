// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.directOrderCheckout[data-v-9cb7bbca] {
    margin-top: 80px;
}
.home[data-v-9cb7bbca] {
  width:100%;
  height:100%;

  display: flex;
  flex-direction: column;

  position: relative;
}
.header[data-v-9cb7bbca] {

  flex-shrink: 0;
  flex-grow: 0;

  background-color: #005b8c;
  width: 100%;
  min-height: 450px;
  min-width: 350px;
  position: relative;
  box-sizing: border-box;
  padding-top: 70px;


  padding-bottom:240px; /* hoogte van de list event + eventuele tussenafstand, die overlapt */
}
.shopTitleAuto[data-v-9cb7bbca] {
  color: white;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
  width:100%;
  max-width:1000px;
  text-align: center;
  margin:auto;
  margin-bottom:20px;
}
.wrapper[data-v-9cb7bbca] {
  flex-shrink: 0;
  flex-grow: 1;
  z-index: 1;
}
.inner[data-v-9cb7bbca] {
  max-width:1200px;
  margin:auto;
  justify-items: center;
  align-items: center;
  margin-top: -350px;
  position: relative;
}
Footer[data-v-9cb7bbca] {
  flex-shrink: 0;
}
.errorContainer[data-v-9cb7bbca] {
  position: relative;
  margin: auto;
  margin-top:-400px;
  max-width: 400px;
  min-height: 300px;
  background-color: white;
  border-radius: 12px;
}
.okIcon[data-v-9cb7bbca] {
  width: 100px;
  margin: 20px auto;
}




`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
