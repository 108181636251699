// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.TextReadMore[data-v-c9a5baf0] {
    height:80px;
    position: relative;
    overflow: hidden;
}
.TextReadMore .readMore[data-v-c9a5baf0] {
    height:80px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 30px;
    margin: 0;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1)60%);
}
.readMore .readMoreImg[data-v-c9a5baf0] {
    margin-top: 50px;
    width: 40px;
    height: 30px;
    cursor: pointer;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
