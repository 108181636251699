<template>
    <div>
        <a>
            <img :src="require(`@/assets/icons/pinterest-icon.png`)" alt="" class="sharer button pinterest" data-sharer="pinterest" :data-url="url" :data-image="image"/> 
        </a>
    </div>
</template>

<script>
export default {
    props: {
        url: {
            type: String
        },
        image: {}
    }
}
</script>

<style scoped>
img.sharer {
    position:relative;
    cursor: pointer;
    margin-right: 2px;
    width: 30px
}
img.sharer:hover {
    opacity:0.75;
}
@media(max-width: 450px) {
    img.sharer {
        width: 30px
    }
}
</style>