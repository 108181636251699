<template>
    <div class="processPayment">
        <div class="headerProcessPayment">
        </div> 
        <div class="contentWrapper">
            <div class="contentContainer"> 
                <div class="loadingWrapper">        
                    <img class="loading" :src="require(`../assets/Loader.gif`)" />
                    <!-- <img class="loading" :src="require(`../assets/gifs/Loading GIF.gif`)" /> -->
                </div>
                <div class="title">Nog eventjes geduld...</div> 
                <div class="text">We zijn je betaling aan het controleren.</div>  
            </div>  
        </div>
    </div>
</template>

<script>
import { store } from '../store/index';
import router from '../router/index'
import {Functions} from '@/util/util.js';

export default {
    mounted() {
        let url = window.location.href;
        let params = (new URL(url)).searchParams;
        var orderRef = '';
        if(params.get('orderRef')) {
            orderRef = params.get('orderRef')
        }

      store.dispatch('checkPayment', orderRef).then((response) => {
            if(response == "OPEN") {
                setTimeout(function(){ store.dispatch('checkPayment', orderRef).then((response) => {
                    Functions.redirPage('/orderresult/'+response);
                })}, 5000);
            }
            else {
                Functions.redirPage('/orderresult/'+response);
            }
        })
    }
}
</script>

<style scoped>
.processPayment {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255);
    overflow-y: scroll;
}

.headerProcessPayment {
    background-color: #005b8c;
    width: 100%;
    min-height: 350px; 
    position: relative;
    box-sizing: border-box;
    padding-top: 70px;
}

.contentWrapper {
    display: inline-block;
    vertical-align: middle;
    margin-top: -280px;
}

.title {
    color: rgb(70, 70, 70);
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    width:100%;
    max-width:1000px;
    text-align: center;    
    margin:auto;
    margin-top: 35px;
    margin-bottom:15px;
    font-size: 40px;
}

.contentContainer {
    margin-top: 60px;
    min-width: 1000px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    min-height: 400px;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
    padding: 45px 45px 45px 45px;
    overflow-x: hidden;
}

.text {
      color: rgb(80, 80, 80);
      font-weight: bold;
      font-family: 'Didact Gothic', sans-serif; 
      font-size: 25px;
      max-width:1000px;
      text-align: center;  
      white-space: nowrap;
      overflow: hidden;
      position: relative;
      margin-bottom: 35px;
}

.loading {
    display: inline;
    overflow: hidden;
    width: 120px;
    margin-top: 50px;
    margin-left: 20px;
}
.loadingWrapper {
    width: 225px;
    height: 225px;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
    border: 5px solid #1aa8e2;
    margin: 0 auto;
}

@media(max-width: 1120px) {
    .contentContainer {
        width: 90%;
    }
}
</style>