<template id="alert-template">
    <div class="alert" v-if="state.error.error1">
        <div style="text-align: center">
            <div style="margin: auto; display: flex; flex-direction: column">
                <div style="font-size: 24px; font-weight: bold; color: white">{{ state.error.error1 }}</div>
                <div v-if="state.error.error2" style="max-height: 250px; overflow-y: auto; font-size: 14px; font-weight: bold; color: white" v-html="state.error.error2" />
                <div v-if="state.error.errorTimerMilliseconds" style="font-size: 14px; font-weight: bold; color: white">??{{timer}}</div>
            </div>
        </div>
        <br />
        <button class="btn btnDarkBlue" @click="close">sluiten</button>
    </div>
</template>

<script>
import {Functions} from '../util/util.js';
import {store} from '../store/index';
export default {
    name: "ErrorMessage",
    props: [],
    setup() {
        return {
            state: store.state
        }
    },
    computed: {
        timer: function() {
            var time = Number(store.state.error.errorTimerMilliseconds);
            setTimeout(() => {
                this.close();
            }, time);
        },
    },
    methods: {
        /*open: function() {
            if(this.$router.currentRoute.name == "Home") {
                document.getElementById("header").style.top = "20px";
            }
        },*/
        close: function() {
            Functions.removeError();          

            /*if(this.$router.currentRoute.name == "Home") {
                document.getElementById("header").style.top = "0";            
            }*/            
        },
    }
}
</script>

<style scoped>
template {
    font-size: 24px;
    font-family: Arial;
}
.alert {
    background: red 10%;
    padding: 20px;
    width: 100%;
    font-weight: bold;
    color: white;
}

.alertTable {
    margin-left: auto;
    margin-right: auto;
    table-layout: auto;
    border-collapse:separate; 
    border-spacing: 0 5px;
}
.alertIcon {
    padding: 20px 30px 20px 20px;
    font-size: 40px;
}
.alert_body {
    text-align: center;
}
.alert_close {
    cursor: pointer;
    padding: 20px 20px 20px 30px;
    font-size: 20px;
}
</style>