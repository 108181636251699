// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.calendarLoading[data-v-cafdcaa0] {    
   margin-top:30px;
   width: 50px;   
   margin-left:200px;
}
.ticketOrderList[data-v-cafdcaa0] {
    position: relative;
    padding: 20px 20px 20px 20px;
}
.btnVerderWinkelen[data-v-cafdcaa0] {
    left: 0px;
}
.rightAlignBtnDirectCart[data-v-cafdcaa0] {
    margin-left: 450px;
}
.labelKiesTickets[data-v-cafdcaa0], .labelKiesDatumTijd[data-v-cafdcaa0] {
    font-size: 22px;
    font-weight: bold;
}
.ticketTypeContainer[data-v-cafdcaa0] {
    margin-top: 20px;
}
.lijn[data-v-cafdcaa0] {
  border-top: 1px solid lightgray;
  width: 100%;
  padding: 0;
  margin: 10px 0px 10px 0px;
}
.subtotaallijn[data-v-cafdcaa0] {
  border-top: 1px solid gray;
  width: 100%;
  padding: 0;
  margin: 30px 0px 10px 0px;
}
.totaallijn[data-v-cafdcaa0] {
  border-top: 1px solid black;
  width: 100%;
  padding: 0;
  margin: 30px 0px 10px 0px;
}
.totaalContainer[data-v-cafdcaa0], .subTotaalContainer[data-v-cafdcaa0] {
    display: flex;
    flex-direction: row;
    margin-bottom: 30px;
}
.totaalLabelWrapper[data-v-cafdcaa0], .subTotaalLabelWrapper[data-v-cafdcaa0] {
    display: flex;
    flex-direction: row;
    width: 88%;
}
.labelTotaal[data-v-cafdcaa0], .labelSubTotaal[data-v-cafdcaa0] {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    margin-right: 10px;
}
.labelKosten[data-v-cafdcaa0], .labelSubKosten[data-v-cafdcaa0] {
    color: grey;
    align-self: flex-end;
}
.totaalPriceWrapper[data-v-cafdcaa0], .subTotaalPriceWrapper[data-v-cafdcaa0] {
    width: 12%;
}
.totaalPrice[data-v-cafdcaa0], .subTotaalPrice[data-v-cafdcaa0] {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 18px;
    float: right;
    align-self: flex-end;
}

/* Calendar & timeslots */
.calendarWrapper[data-v-cafdcaa0]  {
    padding-top:30px;
    min-height:350px;
}
.buttonWrapper[data-v-cafdcaa0] {
    display: flex;
    justify-content: space-between;
}
@media(max-width: 1120px) {
.btnBestellingAfronden[data-v-cafdcaa0] {
        margin-left: auto;
}
}
@media(max-width: 550px) {
.btnVerderWinkelen[data-v-cafdcaa0] {
        margin-right: 10px;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
