<template>
    <div>
        <a>
            <img :src="require(`@/assets/icons/twitter-icon.png`)" alt="" class="sharer button twitter" data-sharer="twitter" :data-title="title" data-hashtags="ticketview" :data-url="url"/> 
        </a>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String
        },
        url: {
            type: String
        }
    }
}
</script>

<style scoped>
img.sharer {
    position:relative;
    cursor: pointer;
    margin-right: 2px;
    width: 30px
}
img.sharer:hover {
    opacity:0.75;
}
@media(max-width: 450px) {
    img.sharer {
        width: 30px
    }
}
</style>