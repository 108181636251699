// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.busyShop[data-v-88e55e40] {
    display: flex;
    flex-direction: column;
    height:100%;
}
.content[data-v-88e55e40] {
    flex-grow: 1;
}
.footer[data-v-88e55e40] {
    flex-shrink: 0;
}
.clientPage[data-v-88e55e40] {
    margin-top:20px!important;
}
.headerBusyShop[data-v-88e55e40] {
    background-color: #005b8c;
    width: 100%;
    min-height: 350px;
    position: relative;
    box-sizing: border-box;
    padding-top: 70px;
}
.shopTitleAuto[data-v-88e55e40] {
    color: white;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    width:100%;
    max-width:1000px;
    text-align: center;
    margin:auto;
    margin-bottom:20px;
}
.busyShopContainer[data-v-88e55e40] {
    max-width:1000px;
    margin-top: -180px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
    padding: 35px 1px 35px 35px;
}
@media(max-width: 1100px) {
.closedShopContainer[data-v-88e55e40]{
  max-width:80%;
  padding: 35px 20px 35px 20px;
}
}
@media(max-width: 767px) {
.closedShopContainer[data-v-88e55e40]{
  font-size: 12px;
}
.shopOpentOp[data-v-88e55e40] {
  font-size: 16px;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
