// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.eventCard[data-v-61f707ca] {
    width: 300px;
    height: 420px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px; 
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    margin: 0px 30px 60px 30px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
    color: rgb(46, 46, 46);
}
.eventInfoCntr[data-v-61f707ca] {
    padding:20px 10px 20px 20px;
}
.eventImage[data-v-61f707ca] {
    width: 300px;
    height: 200px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: 50% 50%;
       object-position: 50% 50%;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.eventTitle[data-v-61f707ca], .eventTitleUitverkocht[data-v-61f707ca] {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    
    overflow: hidden;
    max-height:55px; 


    position: relative;
    overflow: hidden;
    font-size: 21px;
    text-align: left;
    margin-top:8px;
    line-height: 24px;
    font-family: 'Nunito', sans-serif;
    color: rgb(65, 65, 65);
}
.eventTitleUitverkocht[data-v-61f707ca] {
    color: lightgray;
}
.eventDate[data-v-61f707ca] {
    font-weight: bold;
    color: #f09200;
    text-align: left;
    margin-top:0px;
    font-family: 'Didact Gothic', sans-serif;
    text-transform: uppercase;
    font-size: 15px;
}
.eventDate[data-v-61f707ca]:first-letter {
    text-transform: uppercase;
}
.eventPrice[data-v-61f707ca] {
    text-align: left;
    font-size: 14px;
    font-family: 'Didact Gothic', sans-serif;  
    margin-bottom:15px;
}
.eventPriceUitverkocht[data-v-61f707ca] {
    text-align: left;
    font-size: 14px;
    font-family: 'Didact Gothic', sans-serif;  
    margin-bottom:15px;  
    color: #d3d3d3;
}
.wachtlijstbutton[data-v-61f707ca] {  
    width: 100%;
    padding: 5px 2px 5px 2px;
    margin-bottom:5px;
}
.button-box[data-v-61f707ca] {
    position: absolute;
    width: 100%;
    bottom: 0px;
    left: 0px;
    padding: 0px 20px 20px 20px;
}
.infobutton[data-v-61f707ca], .bestelbutton[data-v-61f707ca], .uitverkochtbutton[data-v-61f707ca], .uitgeschakeldeBestelbutton[data-v-61f707ca] {
    width: 45%;
}
.infobutton[data-v-61f707ca] {
    float: left;
}
.bestelbutton[data-v-61f707ca], .uitgeschakeldeBestelbutton[data-v-61f707ca], .uitverkochtbutton[data-v-61f707ca] {
    float: right;
}
@media(max-width: 450px) {
.eventCard[data-v-61f707ca] {
    max-width:1100px;
    height: 400px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px; 
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    margin: 0px 30px 60px 30px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
