// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.termsPage[data-v-f53a9626] {
    padding: 20px 20px 20px 20px;
    text-align: left;
    position: relative;
}
.termsTitle[data-v-f53a9626] {
    color:#1aa8e2;
    font-size:24px;
}
.titleTermsOrg[data-v-f53a9626] {
    font-size: 20px!important;
    color: rgb(65, 65, 65);
    margin-top: 10px;
    font-weight: bold;
}
.titleTicketView[data-v-f53a9626], .titleOrg[data-v-f53a9626], .titleCustom[data-v-f53a9626] {
    font-weight: bold;
    font-size: 28px;   
    font-family: 'Nunito', sans-serif;
    color: rgb(65, 65, 65);
}
.termsWrapper[data-v-f53a9626] {
    position: relative;
    max-height:500px;
    overflow-y: scroll;
}
.termsWrapper[data-v-f53a9626]::after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 25px;
    background: linear-gradient(rgba(255, 255, 255, 0.001), white)
}
.twoTerms1[data-v-f53a9626] {
    max-height: 250px;
}
.twoTerms2[data-v-f53a9626] {
    max-height: 200px;
}
.lijn[data-v-f53a9626] {
  border-top: 1px solid #1aa8e2;
  margin-top: 10px;
  margin-bottom: 30px;
}
.agreedBtn[data-v-f53a9626], .notAgreedBtn[data-v-f53a9626] {
    text-align: center;
}
.buttonWrapper[data-v-f53a9626] {
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
}
@media(max-width: 550px) {
.notAgreedBtn[data-v-f53a9626] {
        margin-right: 10px;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
