// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.site-footer[data-v-40ab164b]
{
  background-color:#005b8c;
  width: 100%;
}
.container[data-v-40ab164b] {
  font-size:16px;
  line-height:20px;
  color:white;
  font-family: 'Didact Gothic', sans-serif; 
  padding: 20px 20px;
  max-width:1200px;
  margin:auto;
  font-size:12px;
}
.lijn[data-v-40ab164b]
{
  border-top: 1px solid white;
  margin-top: 10px;
  margin-bottom: 10px;
}
.site-footer a[data-v-40ab164b]
{
  color:white;
  text-decoration: none;
}
.algemene-voorwaarden-text[data-v-40ab164b], .disclaimer-text[data-v-40ab164b], .copyright-text[data-v-40ab164b], .linkDivider[data-v-40ab164b]
{
  padding-right:10px;
  float: left;
}
.ticketViewLogo[data-v-40ab164b] {
  height: 15px;
  float: right;
  margin-right:10px;
}
@media (max-width:767px)
{
.ticketViewLogo[data-v-40ab164b] {
    margin-top:10px;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
