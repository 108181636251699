<template>
    <div class="shoppingCartWrapper">
      <div class="shoppingCartInfo" v-if="totalTickets > 0" >
          <div class="shoppingCartInfoCounter" >{{totalTickets}}</div>

          <div class="shoppingCartInfoContainer" v-on:click="openPopUp">
              <div class="shoppingCartInfoIconContainer">
                  <img class="shoppingCartIcon" src="@/assets/icons/Shoppingcart-icon.svg" />
              </div>
              <div class="shoppingCartInfoTotalPriceWrapper">
                  {{getMyCurrencyFormatted(totalPrice)}}
              </div>
          </div>
      </div>
    </div>
</template>

<script>
import {store} from '@/store/index';
import {Functions} from '@/util/util';
export default {
    setup() {
        return {
            state: store.state
        }
    },
    computed: {
        totalTickets() {
            if (store.state.cart.totalTickets != null) {
                return store.state.cart.totalTickets;
            }
        },
        getTransactionCosts() {

            var transactionCostsToReturn = 0.00;
            if(this.state.selectedPaymentMethod.Key != ''){
                console.log(store.state.transactionCost);
                Object.keys(store.state.transactionCost).forEach(key => {
                        if (key==this.state.selectedPaymentMethod.Key) {
                            transactionCostsToReturn = this.state.transactionCost[this.state.selectedPaymentMethod.Key];
                            return;
                        }
                    }
                )
            }
            
            return transactionCostsToReturn;
        },
        totalPrice() {
            
            var prijs =  store.state.cart.totalPriceTickets + store.state.cart.donation + this.getTransactionCosts;
            return prijs;
        },
    },
    methods: {
        openPopUp() {
            if (store.state.isMobileCart) {
              store.dispatch('changeStep', 'changeoverzicht');
            } else {
              store.dispatch('changeStep', 'klantinformatie');
            }
            this.$emit('shoppingCartInfoClicked')
        }, 
        getMyCurrencyFormatted(price){
            return Functions.formatMyCurrency(price);
        },
    },
}
</script>

<style scoped>
.shoppingCartInfo {
    position: fixed;
    right: 10px;
    bottom: 10px;
    margin-top: 780px;
    height: 100px;
    width: 200px;
}

.shoppingCartInfoContainer {
    width: 200px;
    height: 80px;
    border-radius: 15px;
    background-color: lightgray;
    cursor: pointer;
}

.shoppingCartInfoIconContainer {
    border-radius: 15px;
    width: 80px;
    height: 80px;
    background-color: #009fe3;
    position: relative;
    margin-top: -15px;
    padding-top: 20px;
    padding-left: 4px;
}

.shoppingCartInfoCounter {
    position: relative;
    z-index: 1;
    margin-left: 50px;
    border-radius: 40%;
    border: 2px solid white;
    width: 40px;
    height: 35px; 
    background-color: darkorange;
    padding-top: 3px;
    color: white;
    font-weight: bold;
    text-align: center; 
}

.shoppingCartIcon {
    margin-top: -2px;
    width: 45px;
}

.shoppingCartInfoTotalPriceWrapper {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    width: 110px;
    text-align: left;
    margin-top: -55px;
    margin-left: 85px;
    text-align: center;
}
.shoppingCartWrapper {
  display: block;
  background-color: red;
}


/*
@media(max-width: 767px) { 
    .shoppingCartInfo {
        width: 80px;
        right: 34px;
    }
    .shoppingCartInfoContainer {
        background-color: transparent;
        width: 80px;
    }
    .shoppingCartInfoTotalPriceWrapper {
        display: none;
    }
}


.shoppingCartWrapper {
    display: none;
}

@media(min-width: 768px) {
    .shoppingCartWrapper {
        display: block;
    }
}
*/
</style>