// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.alert-mask[data-v-d0c93148] {
    position: fixed;
    top: -30px;
    left: 0;
    width: 100%;
    height: 110%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 2;
    overflow-y: scroll;
    text-align: center;
}
.clientPage[data-v-d0c93148] {
    background-color: transparent!important;
}
.alert-wrapper[data-v-d0c93148] {
    display: inline-block;
    vertical-align: middle;
    margin-top: 45px;
}
.alert-container[data-v-d0c93148] {
    width: 500px;
    left:0;
    right:0;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    min-height: 400px;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: absolute;
    font-family: 'Didact Gothic', sans-serif;
}
.loadingGif[data-v-d0c93148] {
    width: 100px;
    margin: 80px auto;
}
.alertTitle[data-v-d0c93148] {
    font-size: 22px;
    font-weight: bold;
    padding-left: 20px;
    padding-right: 20px;
    color: rgb(65, 65, 65);
}
.alertText[data-v-d0c93148] {
    margin-top: 20px;
}
.okIcon[data-v-d0c93148] {
    width: 100px;
    margin: 20px auto;
}
.btnCloseAlert[data-v-d0c93148], .btnConfirmYes[data-v-d0c93148] {
    margin-top: 50px;
}
.btnConfirmNo[data-v-d0c93148] {
    margin-top: 50px;
    margin-right: 20px;
}
@media(max-width: 550px) {
.alert-container[data-v-d0c93148] {
        margin: 0 auto;
        width: 90%;
}
} 
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
