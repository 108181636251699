// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.shoppingCart[data-v-5d3e6332] {
    position: relative;
    text-align: left;
}
.shoppingCart-header[data-v-5d3e6332] {
    width: 100%;
    padding: 20px 20px 20px 20px;
    position: relative;
    text-align: center;
}
.shoppingCartTitle[data-v-5d3e6332] {
    overflow: hidden;
    font-weight: bold;
    font-size: 28px;   
    font-family: 'Nunito', sans-serif;
    color: rgb(65, 65, 65);
}
.shoppingCart-body[data-v-5d3e6332] {
    min-height: 20px;
}
.mask[data-v-5d3e6332] {
    position: absolute;
    width: 100%;
    height: 100%;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 1;
} 
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
