<template>
    <div class="ticketsPersonaliseren" v-if="state.isLoaded">
        <Alert :title="alertTitle" :text="alertText" :alertType="alertType" :active="active" :time="alertTime" :okButtonActive="okButtonActive" @closeAlert="closeAlert"></Alert>
        <div class="ticketsPersonaliserenHeader">
            <Flag v-once></Flag>
            <div class="ticketsPersonaliserenTitle">{{translateString('Checkout', 'TicketsPersonaliseren')}}</div>
        </div>
        <div class="ticketsPersonaliserenBody">
            <div class="buttonWrapper">
                <button class="btn btnWhite stepBackBtn" v-on:click="goBackToStepKlantinformatie"><font-awesome-icon icon="caret-left" /> {{translateString('Checkout', 'Vorige')}}</button>
            
                <!-- <button v-if="!state.isDirectOrder && !state.isDirectCart" class="btn btnWhite btnVerderWinkelen" v-on:click="closePopUp()">{{translateString('Checkout', 'VerderWinkelen')}}</button>  -->

                <!--<button v-if="state.cart.totalPrice > 0" v-bind:class="{rightAlignBtnDirectOrder: state.isDirectOrder, rightAlignBtnDirectCart: state.isDirectCart}" class="btn btnGreen btnRight" v-on:click="goToNextStepBetalen">{{translateString('Checkout', 'Volgende')}} <font-awesome-icon icon="caret-right" /></button>    -->

                <button v-if="state.cart.totalPrice == 0 " v-bind:class="{rightAlignBtnDirectOrder: state.isDirectOrder, rightAlignBtnDirectCart: state.isDirectCart}" class="btn btnGreen btnRight" v-on:click="goToNextStep">{{translateString('Checkout', 'NuBestellen')}} <font-awesome-icon icon=" cart" /></button>      
                <button v-else-if="state.cart.totalPrice > 0 " v-bind:class="{rightAlignBtnDirectOrder: state.isDirectOrder, rightAlignBtnDirectCart: state.isDirectCart}" class="btn btnGreen btnRight" v-on:click="goToNextStep">{{translateString('Checkout', 'Volgende')}} <font-awesome-icon icon="caret-right" /></button>      

            </div>      

            <div class="lijn"></div>


            <div v-if="customerFieldsLayout != null">

                 <div class="ticketTypeBlock" v-for="(block, indexBlock) in inputFieldList" :key="indexBlock">
                     <div class="blockHeader">
                        <strong style="font-size:18px;">{{ block.eventName }}</strong><br />
                        <small><i>{{ block.ticketTypeName }} | {{ block.displayDateOrTimeslot }}</i></small><br />
                        <br />
                      </div>
                      <div class="blockBody">
                            <table style="width:100%">

                                <tr v-for="(ticket, indexTicket) in block.tickets" :key="indexTicket">
                                    <td style="width:80px; vertical-align: top; padding-top:30px;"><strong>Ticket #{{ ticket.nummer }}</strong></td>
                                    <td>
                                        <hr />
                                        <!-- per ticket herhaal velden -->
                                        <div v-for="(field, indexField) in ticket.fields" :key="indexField">
                                            <InputField 
                                                v-model="field.value"                             
                                                v-bind:layout="field.layout"
                                                @change="saveCustomerFields()"
                                                />
                                        </div>

                                    </td>
                                </tr>
                                
                            </table>
                      </div>
                      <br />  
                      <hr />
                      <br />
                 </div>
                 inputfieldList<br />
                 {{ inputFieldList }}

            </div>
        </div>        
    </div>
</template>

<script>
import Flag from '../../Flag.vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {store} from '@/store/index';
import {Selector} from '@/language/language';
import {Functions} from '@/util/util';
import Alert from '@/components/Alert';
import InputField from '../components/InputField.vue';


export default {
    name: 'CustomerForm',
    data() {
        return {
            state: store.state,
            customerFieldsLayout: store.state.customerFieldsLayout, 
            customerFields: store.state.customerFields,    
            alertTitle: '',
            alertText: '',
            alertType: '',
            alertTime: null,
            okButtonActive: 0,
            active: false,
            inputFieldList: Array()
        }
    },
    components: {
        Flag,
        Alert,
        InputField
    },
    methods: {
        saveCustomerFields() {
            alert('save!');
        },
        prepareInputFields() {

                var lastTicketTypeID = -1;
                var indexFields = -1;
                var indexBlock = -1;
                var ticketNo = -1;
                var dateTime = "";

                console.error("cart:");
                console.error(this.state.cart.bestelItems);


                  for (const [keyCartItem, cartItem] of Object.entries(this.state.cart.bestelItems)) {

                        if (!Functions.hasPersonalFieldsFromTicketType(this.customerFieldsLayout,cartItem.ticketTypeID)) {  continue; }
                        
                        dateTime = "";
                        if (cartItem.timeSlot!=undefined && cartItem.timeSlot!="") {
                            dateTime = Functions.capitalizeFirstLetter(Functions.formatDateTime(cartItem.timeSlot, "short", "short", true,true,"short"));
                        } else {
                            var tt = Functions.getTicketTypeFromID(cartItem.ticketTypeID); // opzoeken tickettype
                            dateTime = Functions.dateTimeFromTicketType(tt,"short","short",true,true,"short");
                        }


                        for(var tel=0; tel < cartItem.quantity; tel++) {

                            // Per tickettype block, 
                            if (lastTicketTypeID!=cartItem.ticketTypeID) {
                                indexBlock++;  
                                this.inputFieldList[indexBlock] = 
                                    {
                                        "ticketTypeID": cartItem.ticketTypeID,
                                        "ticketTypeKey": cartItem.ticketTypeKey,
                                        "eventName": cartItem.eventName,
                                        "ticketTypeName": cartItem.ticketTypeName,
                                        "displayDateOrTimeslot": dateTime,
                                        "tickets":Array()
                                    }
                                indexFields = -1;
                                ticketNo = -1;
                                lastTicketTypeID = cartItem.ticketTypeID;
                            }

                            // ticket num aanmaken
                            ticketNo++;
                            this.inputFieldList[indexBlock].tickets[ticketNo] =  {
                                "nummer": ticketNo+1,
                                "fields": Array()
                            } 
                            
                            
                            // layout velden opzoeken voor dit tickettype en toevoegen .....
                            indexFields = -1;
                            for (const [keyLayout, layout] of Object.entries(this.customerFieldsLayout)) {
                                
                                for (const [keyTT, valueTT] of Object.entries(layout.tickettypes)) {
                                    if (keyTT==cartItem.ticketTypeID && valueTT.personal==1) {

                                        // overrulen layout met details van het tickettype
                                        var hulp = layout;
                                        hulp.mode = valueTT.layout.mode;
                                        hulp.personal = valueTT.layout.personal;
                                        hulp.label = valueTT.layout.label;
                                        hulp.hint = valueTT.layout.hint;
                                        hulp.layout = valueTT.layout.layout;
                                        hulp.textlines = valueTT.layout.textlines;
                                        hulp.default = valueTT.layout.default;
                                        hulp.minvalue = valueTT.layout.minvalue;
                                        hulp.maxvalue = valueTT.layout.maxvalue;
                                        hulp.validate = valueTT.layout.validate;    
                                        hulp.ticketTypes = Array();

                                        // toevoegen
                                        var newObjInputField = {
                                            "layout":hulp,
                                            "value": ""
                                        };

                                        indexFields++;
                                        this.inputFieldList[indexBlock].tickets[ticketNo].fields[indexFields] = newObjInputField;
                                       

                                        break;
                                    }
                                }
                            }
                            
                        }


                  }
                  
        },
        goBackToStepKlantinformatie() {
            store.dispatch('changeStep', 'klantinformatie');
        },
        goToNextStep() {  
            // var resultCheck = Functions.checkFieldsAndShoppingCart(1);
            // TODO

            var error = "";
            
           // error = this.validateForm();

            if (error=="") {

                    if (this.state.cart.totalPrice==0) {

                        this.alertType = '';
                        this.alertText = Selector.SelectLanguageStringFromLangJSON('Alert', 'BestellingVerwerken')
                        this.active = true;

                    } else {
                        store.dispatch('changeStep', 'betalen');
                    }

            } else {
                this.showAlertError(error)
            }
        },
        showAlertError(alertMessage) {
            this.alertTitle = this.translateString('Alert', 'Oeps');
            this.alertText = alertMessage;
            this.alertType = 2;
            this.okButtonActive = 1;
            this.active = true;
        },
        closeAlert(close) {
            this.active = close;
            this.alertTitle = ''
            this.alertText = ''
            this.alertType = ''
            this.alertTime = null
            this.answer = null
            this.okButtonActive = 0;
        },
        closePopUp() {
            const $body = document.querySelector('body');
            let scrollPosition = store.state.scrollPosition;
            $body.style.removeProperty('overflow');
            $body.style.removeProperty('position');
            $body.style.removeProperty('top');
            $body.style.removeProperty('width');
            window.scrollTo(0, scrollPosition);

            store.dispatch('closePopUp');
        },
        translateString(componentOrPageName, stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated)
            return translatedString;
        },
    },
    mounted() {

        this.customerFieldsLayout = store.state.customerFieldsLayout;

        this.prepareInputFields();
/*
        this.customerFields = store.state.customerFields;
        this.customerFieldsLayout = store.state.customerFieldsLayout;

        this.customerFields = store.state.customerFields;        
        if (this.customerFields.visitorGender=="" && this.customerFieldsLayout!=null) {
            var ar = this.customerFieldsLayout.shopHasGender.default.split(';');
            if (ar[0]!=null) {
                this.customerFields.visitorGender = ar[0];
            }
        }
        */
    },
}
</script>

<style scoped>

.btnRight {
    margin-left:auto;
}


.ticketsPersonaliseren{
    position: relative;
    left: 0;
    text-align: left;
}

.ticketsPersonaliserenHeader {
    min-height: 100px;
    width: 100%;
    padding: 20px 20px 20px 20px;
    position: relative;
    background-color: whitesmoke;
    border-top-left-radius: 10px;
} 

.ticketsPersonaliserenTitle {
    font-weight: bold;
    font-size: 28px;  
    font-family: 'Nunito', sans-serif;
    max-width: 550px;
    color: rgb(65, 65, 65);
}

.ticketsPersonaliserenBody {
    padding: 20px 20px 20px 20px;
    font-family: 'Didact Gothic', sans-serif; 
}

.btnVerderWinkelen {
    margin-left: 10px;
}
.nextStepBtn {
    margin-left: 150px;
}
.rightAlignBtnDirectOrder {
    margin-left: 450px;
}
.rightAlignBtnDirectCart {
    margin-left: 300px;
}

.buttonWrapper {
    display: flex;
}

.lijn {
  border-top: 1px solid lightgray;
  width: 100%;
  padding: 0;
  margin: 30px 0px 30px 0px;
}

input:focus ~ .floating-label,
 input:not(:focus):valid ~ .floating-label {
  left: -240px;
  top: -16px;
  font-size: 10px;
  opacity: 1;
  color: gray;
}

.floating-label {
  position: relative;
  pointer-events: none;
  left: -240px;
  top: -4px;
  /*transition: 0.2s ease-out all; */
  transition: all .3s cubic-bezier(0.000, 0.205, 0.035, 1.570);
}


.inputText {
  font-size: 14px;
  width: 250px;
  height: 45px;
  outline: none;
  border:1px lightgray solid;
  padding-left:8px;
  padding-top:12px;
  border-radius:4px;
}
.inputWrapper  {
  margin-bottom: 10px;
}
.inputFirstLetterCap::first-letter {
    text-transform: uppercase;
}

.textAgeWarning {
    font-size: 14px;
}

.labelTerms {
    cursor: pointer;
    display: block;
    margin-top: -23px;
    margin-left: 20px;
}
.labelLeesMeer {
    color: #2d468a;
    text-decoration: underline #2d468a;
}

@media(max-width: 1120px) {
    .ticketsPersonaliserenHeader {
        border-top-right-radius: 10px;
    }
    .rightAlignBtnDirectCart {
        margin-left: auto;
    }
}
@media(max-width: 550px) {
    .btnVerderWinkelen, .stepBackBtn {
        margin-right: 10px;
        margin-left: 0px;
    }
}
@media(max-width: 450px) {
    .floating-label {
        position: relative;
        pointer-events: none;
        left: 10px;
        top: -32px;
        display: inline-block;
        /*transition: 0.2s ease-out all; */
        transition: all .3s cubic-bezier(0.000, 0.205, 0.035, 1.570);
    } 
    input:focus ~ .floating-label,
    input:not(:focus):valid ~ .floating-label{
        left: 10px;
        top: -46px;
        font-size: 10px;
        opacity: 1;
        color: gray;
        display: inline-block;
    }
    .nextStepBtn {
        margin-left: 10px; 
    }    
}
</style>