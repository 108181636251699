// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.legenda{
    color: white;
    font-size: 14px;
    background: transparent;
    margin: 20px 20px!important;
    line-height: 18px;
    display: block;
}
.legenda li {
    float:left;
    margin-left:15px;
    list-style-type: none;
}
.legenda_blok{
    margin: 4px 5px 0px 0px;
    width: 12px;
    height:12px;
    float: left;
}
.rangen {
    display:block;
    color: white;
    margin:5px 20px!important;
}
.rangen li {
    float:left;
    margin-left:15px;
}
.rangen_blok  {
    height:14px;
    width:14px;
    border-radius: 0em 0em 0.3em 0.3em;
    float:left;
    background-color: white;
    border-top-width: 5px;
    border-top-style: solid;
    margin-top:3px;
    margin-right:5px;
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
