<template>
    <div class="orderResult" v-if="isValid">
        <Confetti v-if="status == 'OK'" />
        <div class="headerOrderResult">
          <HeaderBackground />
          <TextFit v-once class="shopTitleAuto" style="position: relative; z-index:1; max-height:60px; padding-left:20px; padding-right:20px;" >{{ state.status.shopTitle }}</TextFit>
        </div> 
        <div class="contentWrapper">
            <div class="contentContainer" > 
                <img v-if="status == 'OK'" class="gif-overlay1" :src="require(`../assets/Vlaggen-01.svg`)" />
                <img v-if="status == 'OK'" class="gif-overlay2" :src="require(`../assets/Vlaggen-02.svg`)" />
                <img v-if="status == 'OK'" class="gif-overlay3" :src="require(`../assets/Vlaggen-03.svg`)" />

                <div v-if="status == 'OK'" class="gifWrapper borderSuccess">        
                    <img class="gif" :src="require(`../assets/gifs/Elmo GIF.gif`)" />     
                </div>
            
                <div v-if="status == 'ERROR'" class="gifWrapper borderError">        
                    <img class="gif" :src="require(`../assets/gifs/Homer Simpson GIF.gif`)" />
                </div>
                
                <div v-if="status == 'OPEN'" class="gifWrapper borderDoubt">        
                    <img class="gif" :src="require(`../assets/gifs/Koekiemonster GIF.gif`)" />
                </div>

                <div class="title" v-html="getTranslationFromArray(paymentStatus.title.items)"></div> 
 
                <div class="error" v-if="status == 'ERROR'" v-html="paymentStatus.paymentError"></div> 

                <div class="text" v-html="getTranslationFromArray(paymentStatus.text.items)"></div> 

                <div class="note" v-html="getTranslationFromArray(paymentStatus.note.items)"></div> 

                <div class="tip" v-html="getTranslationFromArray(paymentStatus.tip.items)"></div>

                <div class="btn btnWhite btnSluiten" v-on:click="redirect">Sluiten</div>
            </div>
        </div>
    </div>
</template>

<script>
import {Selector} from '@/language/language.js';
import {store} from '@/store/index';
import router from '../router'
import HeaderBackground from "@/components/HeaderBackground.vue";
import TextFit from "@/components/TextFit.vue";
import Confetti from "@/components/Confetti.vue";
import {Functions} from '@/util/util.js';


export default {
  components: {TextFit, HeaderBackground, Confetti},
    setup() {
        return {
            state: store.state
        }
    },
    data() {
        return {
            paymentStatus: null,
            isValid: true // Initialiseer als true, om de rest van de pagina niet te laden als het niet valide is
        }
    },
    props: {
        status: {
            type: String,
            required: true
        }
    },
    methods: {
        getTranslationFromArray(arrayWithTransations) {
            var stringTranslation = Selector.SelectLanguageStringFromArray(arrayWithTransations)
            return stringTranslation;
        },
        redirect() {

            if(this.state.paymentStatus.returnUrl != '') {
                window.location.href = this.state.paymentStatus.returnUrl;
            } else {

                let shopNumber = Functions.getShopNumberFromURL(window.location.href);
                if (shopNumber === "0" || shopNumber === "") {
                    shopNumber = "/";
                } else {
                    shopNumber = "/shop-" + shopNumber;
                }

                // indien huidige site op local host draait dan daar naar toe teurg gaan. Andrs de online verise
                if (window.location.href.includes('localhost')) {
                  window.location.href = shopNumber;

                } else {

                  // indien er een afzender is dan naar de afzender gaan
                  // Indien direct vanuit een event, dan eventueel daar naar terug.
                  if (localStorage.getItem('previousPath') != null && localStorage.getItem('previousPath') != '') {
                    window.location.href = localStorage.getItem('previousPath');
                    localStorage.setItem('previousPath', '');
                  } else {
                     window.location.href = 'https://' + this.state.shopID + '.ticketview.shop'+shopNumber;
                  }
                }
            }
        },
        validateData() {
          if (
              this.$store.state === undefined ||
              this.$store.state.status === undefined ||
              this.$store.state.status.shopTitle === undefined
          ) {
            // Redirect naar home met een refresh
            window.location.href = '/';
            this.isValid = false; // Stel isValid in op false zodat de rest van de pagina niet wordt geladen
          }
        }
    },
    created() {
        this.validateData();
        this.paymentStatus = this.state.paymentStatus;
    }
}
</script>

<style scoped>
.orderResult {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255);
    overflow-y: scroll;
}

.headerOrderResult {
    background-color: #005b8c;
    width: 100%;
    min-height: 350px; 
    position: relative;
    box-sizing: border-box;
    padding-top: 70px;
    padding-bottom:240px;
}

.contentWrapper {
    display: inline-block;
    vertical-align: middle;
    margin-top: -280px;
}

.contentContainer {
    margin-top: 60px;
    width:1000px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    min-height: 400px;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
    padding: 45px 45px 45px 45px;
    overflow-x: hidden;
}

.title {
    color: rgb(70, 70, 70);
    font-weight: bold;
    font-family: 'Nunito', sans-serif; 
    width:100%;
    max-width:1000px;
    text-align: center;    
    margin:auto;
    margin-top: 35px;
    margin-bottom:15px;
    font-size: 44px;
}

.error {
    color: rgb(70, 70, 70);
    font-weight: bold;
    font-family: 'Nunito', sans-serif; 
    width:100%;
    max-width:1000px;
    text-align: center;    
    margin:auto;
    margin-top: 18px;
    margin-bottom:15px;
    font-size: 18px;
}

.text {
    color: rgb(80, 80, 80);
    font-family: 'Didact Gothic', sans-serif; 
    font-size: 18px;
    max-width:1000px;
    text-align: center;  
    position: relative;
    margin-bottom: 35px;
}

.note {
    color: rgb(80, 80, 80);
    font-family: 'Didact Gothic', sans-serif; 
    font-size: 14px;
    max-width:1000px;
    text-align: center;  
    position: relative;
    margin-bottom: 35px;
}

.tip {
    color: rgb(80, 80, 80);
    font-family: 'Didact Gothic', sans-serif; 
    font-size: 14px;
    max-width:1000px;
    text-align: center;  
    position: relative;
}

.gif {
    display: inline;
    margin-left: -15%;
    height: 100%;
    width: auto;
    overflow: hidden;
}
.gifWrapper {
    width: 225px;
    height: 225px;
    position: relative;
    overflow: hidden;
    border-radius: 50%; 
    margin: 0px auto;
}

.gif-overlay1 {
    position: absolute;
    left: -370px;
    top: -4px;
    width: 100%;
    z-index: 1;
    height: 150px;
}
.gif-overlay2 {
    position: absolute;
    right: -370px;
    top: -4px;
    width: 100%;
    z-index: 1;
    height: 150px;
}
.gif-overlay3 {
    position: absolute;
    height: 120px;
    width: 100%;
    left: 0%;
    top: -10px;
    z-index: 1;
}

.borderSuccess {
    border: 10px solid lightgreen;
}
.borderError {
    border: 10px solid red;
}
.borderDoubt {
    border: 10px solid orange;
}

.btnSluiten {
    margin: 0 auto;
    margin-top: 35px;
}

.shopTitleAuto {
  color: white;
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
  width:100%;
  max-width:1000px;
  text-align: center;
  margin:auto;
  margin-bottom:20px;

}


@media(max-width: 1120px) {
    .contentContainer {
        width: 90%;
    }
}
</style>