// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.personalShop[data-v-031bab90] {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255);
    overflow-y: scroll;
}
.headerPersonalShop[data-v-031bab90] {
    background-color: #005b8c;
    width: 100%;
    min-height: 350px; 
    position: relative;
    box-sizing: border-box;
    padding-top: 70px;
}
.contentWrapper[data-v-031bab90] {
    display: inline-block;
    vertical-align: middle;
    margin-top: -280px;
}
.contentContainer[data-v-031bab90] {
    margin-top: 60px;
    width:1000px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    min-height: 400px;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
    padding: 45px 45px 45px 45px;
    overflow-x: hidden;
}
.title[data-v-031bab90] {
    --max-lines: 2;
    overflow: hidden;
    font-weight: bold;
    font-size: 30px;  
    font-family: 'Nunito', sans-serif;
    color: rgb(65, 65, 65);
}
.vulDezeHieronderIn[data-v-031bab90] {
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 5px;
    color: rgb(65, 65, 65);
    font-family: 'Didact Gothic', sans-serif;
}
.validationCodeWrapper[data-v-031bab90] {
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.inputValidationCode[data-v-031bab90] {
    width: 9.5rem;
    height: 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-transform: uppercase; 
    background-color: rgb(211, 210, 210);
    color: rgb(48, 48, 48);
    border: none;
    text-align: center;
    padding: 5px 5px 5px 10px;
    outline: none;
}
.btnValidateCode[data-v-031bab90] {
    padding: 2px 1px 2px 1px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 30px;
    min-width: 40px;
    text-align: center;
    background-color: #005b8c;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 14px;
}
.btnValidateCodeImage[data-v-031bab90] {
    height: 25px;
    min-width: 25px;
    vertical-align: middle;
}
@media(max-width: 1120px) {
.contentContainer[data-v-031bab90] {
        width: 90%;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
