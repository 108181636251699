<template>
  <div class="busyShop" v-if="state.isLoaded">
    <div class="content">
      <div>
        <div class="headerBusyShop" v-if="!isClientShop">
          <HeaderBackground />
          <Flag/>
          <TextFit v-once class="shopTitleAuto" style="position:relative; z-index:1; max-height:60px; padding-left:20px; padding-right:20px;" >{{ state.status.shopTitle }}</TextFit>
        </div>

        <div class="busyShopContainer" v-bind:class="getClientClass('clientPage')">
          <div>
            <img style="border-radius: 50%; max-width: 75%;" src="@/assets/gifs/wait.gif"/>
            <br />
            <br />
            <br />

            <div v-html="translateString('Wachtrij','uitleg')"></div>

          </div>
        </div>
      </div>
    </div>
    <Footer class="footer" v-if="!isClientShop" />
  </div>
</template>

<script>

import {Functions} from '@/util/util.js';
import {Selector} from '@/language/language.js';
import {store} from '@/store/index';

import { ref, onMounted, onUnmounted } from 'vue';
import HeaderBackground from "@/components/HeaderBackground.vue";
import Flag from "@/components/Flag.vue";
import TextFit from "@/components/TextFit.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {Footer, TextFit, Flag, HeaderBackground},
  setup() {

    let intervalId;

    onMounted(() => {

      intervalId = setInterval(() => {
        store.dispatch('checkQueueBusy', false);
      }, 30000);

    });

    onUnmounted(() => {
      // Wis het interval wanneer de component wordt vernietigd
      clearInterval(intervalId);
    });

    return {
      state: store.state


    }
  },
  computed: {
    isClientShop() {
      if(this.state.isClientPage) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    getClientClass(className) {
      if(this.state.isClientPage) {
        return className;
      } else {
        return '';
      }
    },
    translateString(componentOrPageName, stringNotTranslated) {
      var translatedString = Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated)
      return translatedString;
    },

  }


}
</script>

<style scoped>
  .busyShop {
    display: flex;
    flex-direction: column;
    height:100%;
  }

  .content {
    flex-grow: 1;
  }

  .footer {
    flex-shrink: 0;
  }

  .clientPage {
    margin-top:20px!important;
  }


  .headerBusyShop {
    background-color: #005b8c;
    width: 100%;
    min-height: 350px;
    position: relative;
    box-sizing: border-box;
    padding-top: 70px;
  }


  .shopTitleAuto {
    color: white;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    width:100%;
    max-width:1000px;
    text-align: center;
    margin:auto;
    margin-bottom:20px;
  }

  .busyShopContainer {
    max-width:1000px;
    margin-top: -180px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 60px;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
    padding: 35px 1px 35px 35px;
  }



  @media(max-width: 1100px) {
  .closedShopContainer{
  max-width:80%;
  padding: 35px 20px 35px 20px;

  }
  }

  @media(max-width: 767px) {
  .closedShopContainer{
  font-size: 12px;
  }
  .shopOpentOp {
  font-size: 16px;
  }
}
</style>