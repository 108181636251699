// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.isSelected[data-v-97efcf52] {
    border: 1px solid #1aa8e2;
    box-shadow: 2px 2px 2px 2px #1aa8e2;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
}
.paymentMethod[data-v-97efcf52] {
    width: 150px;
    height: 165px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px; 
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    margin: 0px 0px 40px 0px;
    /*box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;*/
    position: relative;
    color: rgb(46, 46, 46);
    text-align: center;
}
.paymentMethodImage[data-v-97efcf52] {
    margin-top: 10px;
    width: 100px;
    border-radius: 10px;
}
.paymentMethodInfoCntr[data-v-97efcf52] {
    padding: 0px 10px 10px 10px;
}
.paymentMethodName[data-v-97efcf52] {
    font-weight: bold;
    font-size: 14px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
