// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.home[data-v-fae5bece] {
   width:100%;   
   height:100%;

   
   display: flex;
   flex-direction: column;

   
   /*
   display: flex;
   flex-direction: column;
   flex-shrink: 0;
   flex-grow: 0;
   */
   position: relative;
}
.eventListWrapper[data-v-fae5bece] {
  flex-shrink: 0;
  flex-grow: 1;
  position: relative;
}
Footer[data-v-fae5bece] {
  flex-shrink: 0;
}
.eventList[data-v-fae5bece] {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, auto));
    max-width:1200px;
    margin:auto;
    margin-top:-200px;
    justify-items: center;
    align-items: center;
}
.giphyContainer[data-v-fae5bece] {
    width: 300px;
    height: 400px;
    padding-bottom: 0px;
    padding-left: 0px;
    padding-right: 0px; 
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
}
.giphy[data-v-fae5bece] {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    height: 50%;
    width: 100%;
}
.noEventsFoundText[data-v-fae5bece] {
      color: rgb(80, 80, 80);
      font-weight: bold;
      font-family: 'Didact Gothic', sans-serif; 
      font-size: 25px;
      max-width:1000px;
      text-align: center;  
      white-space: nowrap;
      overflow: hidden;
      position: relative;
      margin-top: 50px;
}
.header[data-v-fae5bece] {

    flex-shrink: 0;
    flex-grow: 0;

    background-color: #005b8c;
    width: 100%;
    min-height: 450px; 
    min-width: 350px; 
    position: relative;
    box-sizing: border-box;
    padding-top: 70px;


    padding-bottom:240px; /* hoogte van de list event + eventuele tussenafstand, die overlapt */
}
.tags[data-v-fae5bece] {
    margin-bottom:200px;

    position: relative /* voor de geblurrede header */
}
.accountName[data-v-fae5bece] {
    color: white;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;

    width:100%;
    max-width:1000px;
    text-align: center;
    
    margin:auto;

    white-space: nowrap;
    overflow: hidden;
}
.shopTitle[data-v-fae5bece] {
    color: orangered;
}
.shopTitleAuto[data-v-fae5bece] {
    color: white;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    width:100%;
    max-width:1000px;
    text-align: center;    
    margin:auto;
    margin-bottom:20px;
}

/********** Search **********/
/* component linksboven op het scherm zetten */
.searchbar-wrapper[data-v-fae5bece] {
    position: absolute;
    left: 25px;
    top: 25px;
}
  
/* zoekbalk en zoekbutton */
.searchbar-wrapper .input-holder[data-v-fae5bece] {
    height: 40px;
    width: 40px;
    overflow: hidden;
    background: rgba(255,255,255,0);
    border-radius: 6px;
    position: relative;
    transition: all 0.3s ease-in-out;
}
.searchbar-wrapper.active .input-holder[data-v-fae5bece] {
    width: 250px;
    border-radius: 50px;
    background: whitesmoke;
    transition: all .5s cubic-bezier(0.000, 0.105, 0.035, 1.570);
}
  
/* input text in zoekbalk */
.searchbar-wrapper .input-holder .searchbar-input[data-v-fae5bece] {
    width: 100%;
    height: 20px;
    padding: 0px 50px 0 20px;
    opacity: 0;
    position: absolute;
    top: 0px;
    left: 0px;
    background: transparent;
    box-sizing: border-box;
    border: none;
    outline: none;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 20px;
    color: grey;
    transform: translate(0, 60px);
    transition: all .3s cubic-bezier(0.000, 0.105, 0.035, 1.570);
    transition-delay: 0.3s;
}
.searchbar-wrapper.active .input-holder .searchbar-input[data-v-fae5bece] {
    opacity: 1;
    transform: translate(0, 10px);
}
  
/* button zoekicon (vergrootglas) */
.searchbar-wrapper .input-holder .searchbar-icon[data-v-fae5bece] {
    width: 40px;
    height: 40px;
    border: none;
    border-radius: 50px;
    background: #005b8c;
    padding: 0px;
    outline: none;
    position: relative;
    z-index: 2;
    float: right;
    border: 2px solid white;
    cursor: pointer;
    transition: all 0.3 ease-in-out;
}
.searchbar-wrapper.active .input-holder .searchbar-icon[data-v-fae5bece] {
    width: 34px;
    height: 34px;
    margin: 3px;
    border-radius: 30px;
}
  
/* Plaatsing vergrootglas op button */
.searchbar-wrapper .input-holder .searchbar-icon span[data-v-fae5bece] {
    width: 18px;
    height: 18px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    transform: rotate(45deg);
    transition: all .4s cubic-bezier(0.650, -0.600, 0.240, 1.650);
}
.searchbar-wrapper.active .input-holder .searchbar-icon span[data-v-fae5bece] {
    width: 17px;
    height: 19px;
    transform: rotate(-45deg);
}
.searchbar-wrapper .input-holder .searchbar-icon span[data-v-fae5bece]::before, .searchbar-wrapper .input-holder .searchbar-icon span[data-v-fae5bece]::after {
    position: absolute;
    content:'';
}
  
/* Streepje van het vergrootglas */
.searchbar-wrapper .input-holder .searchbar-icon span[data-v-fae5bece]::before {
    width: 3px;
    height: 8px;
    left: 7px;
    top: 14px;
    border-radius: 2px;
    background: white;
}
/* Rondje van het vergrootglas */
.searchbar-wrapper .input-holder .searchbar-icon span[data-v-fae5bece]::after {
    width: 11px;
    height: 11px;
    left: 0px;
    top: 0px;
    border-radius: 16px;
    border: 3px solid white;
}
  
/* button close */
.searchbar-wrapper .close[data-v-fae5bece] {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    cursor: pointer;
    transform: rotate(-180deg);
    transition: all .3s cubic-bezier(0.285, -0.450, 0.935, 0.110);
    transition-delay: 0.2s;
}
.searchbar-wrapper.active .close[data-v-fae5bece] {
    right: -30px;
    transform: rotate(45deg);
    transition: all .6s cubic-bezier(0.000, 0.105, 0.035, 1.570);
    transition-delay: 0.5s;
}
.searchbar-wrapper .close[data-v-fae5bece]::before, .searchbar-wrapper .close[data-v-fae5bece]::after {
    position: absolute;
    content: '';
    background: white;
    border-radius: 2px;
}
  
/* kruisje in de close button */
.searchbar-wrapper .close[data-v-fae5bece]::before {
    width: 3px;
    height: 20px;
    left: 9px;
    top: 0px;
}
.searchbar-wrapper .close[data-v-fae5bece]::after {
    width: 20px;
    height: 3px;
    left: 0px;
    top: 9px;
}

/*************** Tags ******************/
.tags[data-v-fae5bece]{
    display: table;
    margin: 0 auto;
}
.tagList[data-v-fae5bece] {
    display: inline-block;
}
.tag[data-v-fae5bece] {
    font-size: 16px;
    margin: 5px 5px;
    padding: 5px 10px 5px 10px;
    border-radius: 80px;    
    background-color: #005b8c;
    color: whitesmoke;
    border: 2px solid whitesmoke;
    cursor: pointer;
}
.tag[data-v-fae5bece]:hover {
    background-color: lightgray;
    color: black;
}
.tag.active[data-v-fae5bece] {
    color: #005b8c;
    background-color: whitesmoke;
}
.LabelCodeReceived[data-v-fae5bece] {
  color: white;
  font-size: 16px;
}
.LabelClickHere[data-v-fae5bece] {
  cursor: pointer;
}
.month-label[data-v-fae5bece] {
  color: #005b8c;
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
  position: absolute;
  left:0;
  top:-200px;

  font-size: 12px;
  background-color: red;
  padding: 15px;
  color: white;
  -moz-border-radius-topright: 5px;
  -moz-border-radius-bottomright: 5px;
  -webkit-border-top-right-radius: 5px;
  -webkit-border-bottom-right-radius: 5px;
  z-index: 2;
}
.sticky-label[data-v-fae5bece] {
  position: fixed;
  top:0px;
}
.inputCode[data-v-fae5bece] {
  position: relative
}
@media(max-width: 1120px) {
.header[data-v-fae5bece] {
        padding-top: 80px;
}
.accountName[data-v-fae5bece], .shopTitle[data-v-fae5bece], .shopTitleAuto[data-v-fae5bece] {
        width:90%;
}
}
/*
@media (max-width: 767px) {
  .ShoppingCartInfo {
    display:none;
  }  
}

 */







`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
