<template id="warn-template">
    <div class="warn" v-if="state.warning.warning1">
        <table class="warnTable">
            <tr>
                <td class="warnIcon">
                    <img class="imgWarningGear" :src="require(`@/assets/icons/gear-icon.png`)" alt="">
                </td>
                <td class="warn_body">        
                    <div>
                        {{ state.warning.warning1 }}
                    </div>
                    <div v-if="state.warning.warning2">
                        {{ state.warning.warning2 }}
                    </div>
                    <div v-if="state.warning.warningTimerSeconds">
                        {{timer}}
                    </div>                   
                </td>
                <td class="warn_close" @click="close">X</td>    
            </tr>
        </table>
    </div>
</template>

<script>
import {Functions} from '../util/util.js';
import {store} from '../store/index';
export default {
    name: "WarningMessage",
    props: [],
    setup() {
        return {
            state: store.state
        }
    },
    computed: {
        timer: function() {
            var time = Number(store.state.warning.warningTimerMilliseconds);
            setTimeout(() => {
                this.close();
            }, time);
        }
    },
    methods: {
        close: function() {
            Functions.removeWarning();
        },
    }
}
</script>

<style scoped>
template {
    font-size: 24px;
    font-family: Arial;
}
.warn {
    background: #fdbe25;
    padding: 20px;  
    width: 100%;
    font-weight: bold;
    color: rgb(54, 54, 54);
}

.warnTable {
    margin-left: auto;
    margin-right: auto;
    table-layout: auto;
    border-collapse:separate; 
    border-spacing: 0 5px;
}
.warnIcon {
    padding: 10px 30px 0px 0px;
}
.imgWarningGear {
    height: 40px;
    width: 40px;
}
.warn_body {
    text-align: center;
}
.warn_close {
    cursor: pointer;
    padding: 20px 20px 20px 30px;
    font-size: 20px;
}
</style>