// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` /* GEEN SCOPE !! */
.termsTicketView, .termsOrg, .termsCustom {
    margin-top: 10px;
    max-height: 500px;
    margin-bottom: 30px;
    background: white;
    padding: 15px 0;
}
.termsTicketView, .termsOrg, .termsCustom {
    font-size: 14px;
    color:rgb(65, 65, 65);
    padding-bottom: 40px;
}
.termsTicketView > h1,.termsOrg > h1,.termsCustom > h1 {
    font-size: 28px!important;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color:rgb(65, 65, 65);
    margin-top:0px;
}
.termsTicketView > h2,.termsOrg > h2,.termsCustom > h2 {
    font-size: 20px!important;
    color:rgb(65, 65, 65);
    margin-top:10px;
}
.termsTicketView > h3,.termsOrg > h3,.termsCustom > h3 {
    font-size: 14px!important;
    color:rgb(65, 65, 65);
    margin-top:10px;
}

`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
