<template>
    <div class="closedShop" v-if="state.isLoaded">
      <div class="content">
        <div v-if="state.status.shopStatus == 'CLOSED'">

          <div class="headerClosedShop" v-if="!isClientShop">
            <HeaderBackground />
            <Flag/>
            <TextFit v-once class="shopTitleAuto" style="position:relative; z-index:1; max-height:60px; padding-left:20px; padding-right:20px;" >{{ state.status.shopTitle }}</TextFit>
          </div>
          <div class="closedShopContainer" v-bind:class="getClientClass('clientPage')">
            <div v-if="state.status.shopStatusText">
              <img style="border-radius: 50%; max-width: 75%;" src="@/assets/gifs/wait.gif"/>
              <br />
              <div v-if="state.status.shopStatusReopen != '0000-00-00 00:00:00' && state.status.shopStatusReopenCount == 1">
                <CountdownRings :end="state.status.shopStatusReopen" @end="openTheShop"></CountdownRings>
              </div>
              <div class="closedShopTitle">
                <div v-if="state.status.shopStatusReopen != '0000-00-00 00:00:00' && state.status.shopStatusReopenCount == 1">
                  <div class="shopOpentOp">{{translateString('ClosedShopPage', "DezeShopOpentOp")}} {{getDateAndTimeFormatted(state.status.shopStatusReopen, true, false)}} {{translateString('General', "om")}} {{getDateAndTimeFormatted(state.status.shopStatusReopen, false, true)}}</div>
                </div>
              </div>
              <br />
              <br />
              <div class="shopStatusText" v-html="getTranslationFromArray(state.status.shopStatusText.items)"></div>
            </div>

            <div v-if="state.status.showPersonalShopInput == '1'">
              <br />
              <div v-show="!isCodeActive">

                <div class="LabelCodeReceived">{{translateString('General', 'PersoonlijkeOfActiecodeOntvangen')}}</div>

                <div v-show="!hasCodeOpen">
                  <div class="LabelCodeReceived">&raquo; <a @click="hasCodeOpen = true" style="text-decoration: underline; font-size: 14px; cursor: pointer">{{ translateString("General","KlikHierOmUwCodeInTeVullen")}}</a> &laquo;</div>
                </div>

                <div v-show="hasCodeOpen && !isCodeActive">
                  <!-- Toon input voor code -->
                  <div class="LabelCodeReceived" style="font-size: 14px;">{{translateString('General', 'VulHieronderDeCodeIN')}}:</div>
                  <CodeInput :isVisible="hasCodeOpen" style="margin-top:15px;" @verified="handleHasInvitationCode"  />
                </div>
              </div>
              <div v-show="isCodeActive">
                <div class="LabelCodeReceived">{{translateString('General','CodeIsActief')}}</div>
                <div class="LabelCodeReceived">{{translateString('General','CodeShopAangepast')}}</div>
                <div class="LabelCodeReceived"><a @click="undoInvitation()" style="text-decoration: underline; font-size: 14px; cursor: pointer">{{translateString('General','CodeCancel')}}</a></div>
              </div>

              <br />
              <br />
              <div v-show="shopAdminToken()" style="border-top: dashed 1px #ccc; padding-top:30px;">
                Je bent ingelogd in het Dashboard van TicketView.<br />
                Daarom kun je alvast in de shop om alles te controleren.<br />
                <br />
                <button @click="reopenShopForAdmin()" class="btn btnGreen">Ga naar de shop</button>
              </div>
            </div>

          </div>
        </div>
      </div>
      <Footer class="footer" v-if="!isClientShop" />
  </div>
</template>

<script>
import {Selector} from '../language/language';
import {Functions} from '../util/util';
import {store} from '../store/index';
import CountdownRings from '../components/CountdownRings';
import Flag from '../components/Flag';
import Footer from '../components/Footer';
import TextFit from '../components/TextFit';
import HeaderBackground from "@/components/HeaderBackground.vue";
import CodeInput from "@/components/CodeInput.vue";

export default {
    setup() {
      return {
        state: store.state
      }
    },
    data() {
      return {
        input: '',
        hasCodeOpen: false,
        isCodeActive: false,
        textColor: 'white',
        textShadow: ''
      }
    },
    components: {
      CodeInput,
      HeaderBackground,
        CountdownRings,
        Flag,
        Footer,
        TextFit
    },
    mounted() {
      this.textColor = store.state.status.layoutTextColor;
      this.backgroundColor = this.state.status.layoutBackgroundColor;
      this.textShadow = "";
    },
    methods: {
      getClientClass(className) {
        if(this.state.isClientPage) {
          return className;
        } else {
          return '';
        }
      },
      reopenShopForAdmin() {
            if (this.state.shopNumber!="0") {
              this.$router.push('/shop-'+this.state.shopNumber);
            } else {
              this.$router.push('/');
            }
        },
        goToPersonalShop() {
          if (this.state.shopNumber!="0") {
            this.$router.push('/shop-'+this.state.shopNumber+'/personalshop');
          } else {
            this.$router.push('/personalshop');
          }
        },
        openTheShop() {
            store.dispatch('initShop');

            //this.$router.push('/');
          if (!Functions.isShopClosed()) {
              this.$router.push(localStorage.getItem('entrancePath'));
          }


        },
        translateString(componentOrPageName, stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated)
            return translatedString;
        },
        getTranslationFromArray(arrayWithTransations) {
            var stringTranslation = Selector.SelectLanguageStringFromArray(arrayWithTransations)

            // Replace alle \n tekens met een BR
            stringTranslation = stringTranslation.replace(/(?:\r\n|\r|\n)/g, '<br />');
            return stringTranslation;
        },
        getDateAndTimeFormatted(date, returnDate, returnTime) {
            var lengthWeekday = "long";
            var lengthMonth = "long";
            var lengthYear = "long";
            return Functions.formatDateTime(date, lengthWeekday, lengthMonth, returnDate, returnTime, lengthYear);
        },
      handleHasInvitationCode(code) {
        this.isCodeActive = true;
        this.state.hasCodeOpen = true;
        this.state.inviteCode = code;
      },
      undoInvitation() {
        this.isCodeActive = false;
        this.state.hasCodeOpen = false;
        this.state.inviteCode = "";
        this.hasCodeOpen = false;
      },
      shopAdminToken() {
          if (this.state.status.shopAdminToken != '') {
              return true;
          } else {
              return false;
          }
      }

    },
    computed: {
        isClientShop() {
          if(this.state.isClientPage) {
            return true;
          } else {
            return false;
          }
        }
    },
    updated() {
      if(store.state.status.shopStatus == 'OPEN') {
        this.openTheShop();
      }
    }
}
</script>

<style scoped>
  .closedShop {
      display: flex;
      flex-direction: column;
      height:100%;
  }

  .content {
    flex-grow: 1;
  }

  .footer {
    flex-shrink: 0;
  }

  .clientPage {
    margin-top:20px!important;
  }


  .headerClosedShop {
    background-color: #005b8c;
    width: 100%;
    min-height: 350px; 
    position: relative;
    box-sizing: border-box;
    padding-top: 70px;
  }

  
  .shopTitleAuto {
    color: white;
    font-weight: bold;
    font-family: 'Nunito', sans-serif;
    width:100%;
    max-width:1000px;
    text-align: center;    
    margin:auto;
    margin-bottom:20px;

  }

  .closedShopContainer {
      max-width:1000px;
      margin-top: -180px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 60px;
      font-size: 15px;
      background-color:white;
      border-radius: 10px;
      box-shadow: 2px 2px 2px 2px grey;
      box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
      position: relative;
      padding: 35px 1px 35px 1px;
  }

  .accountName {
      color: white;
      font-weight: bold;
      font-family: 'Nunito', sans-serif;
      width:100%;
      max-width:1000px;
      text-align: center;    
      margin:auto;
      white-space: nowrap;
      overflow: hidden;
  }

  .closedShopTitle {
      display: flex;
      justify-content: center;
  }

  .lockWrapper {
      padding-top: 30px;
      margin-right: 10px;
  }

  .lock {
      background: gray;
      border-radius: 3px;
      width: 25px;
      height: 20px;
      position: relative;
  }
  .lock:before {
      content: "";
      position: absolute;
      border:3px solid gray;
      top: -14px;
      left: 2.3px;
      width: 15px;
      height: 27px;
      border-radius: 35px 35px 0 0;
  }

  .shopStatusText {
      font-size: 20px;
      color: rgb(80, 80, 80);
      font-family: 'Didact Gothic', sans-serif;
      padding-left: 30px;
      padding-right: 30px;
      max-width: 800px;
      margin: auto;
  }

  .shopOpentOp {
      color: rgb(80, 80, 80);
      font-weight: bold;
      font-family: 'Didact Gothic', sans-serif; 
      font-size: 20px;
      margin-top: 35px;
      max-width:1000px;
      text-align: center;  
      white-space: nowrap;
      overflow: hidden;
      position: relative;
  }

  .closedShopValidationCodeContainer {
      font-size: 14px;
      margin-top: 35px;
      color: rgb(82, 82, 82);
      font-family: 'Didact Gothic', sans-serif;  
  }

.LabelCodeReceived {
  color: rgb(82, 82, 82);
  margin-top: 10px;
  font-size: 16px;
}

.LabelClickHere {
  cursor: pointer;
  color: #005b8c;
  text-decoration: underline;
}

  .heeftMoeiteMetWachten {
      margin-top: 35px;
      font-size: 14px;
      color: grey; 
  }

  .wachtRegel1Wrapper {
      font-family: 'Didact Gothic', sans-serif; 
  }
  .jeKuntBijvoorbeeld {
      margin-right: 5px;
      color: grey;
  }

  .wachtRegel2Wrapper {
      display: flex;
      justify-content: center;
      font-family: 'Didact Gothic', sans-serif; 
  }
  .labelOf {
      margin-left: 5px;
      margin-right: 5px;
      color: grey;
  }


  @media(max-width: 1100px) {
    .closedShopContainer{
      max-width:80%;
      padding: 35px 20px 35px 20px;

    }
  }

  @media(max-width: 767px) {
    .closedShopContainer{
      font-size: 12px;
    }
    .shopOpentOp {
      font-size: 16px;
    }
  }
</style>