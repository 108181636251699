// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.shoppingCartInfo[data-v-79b1ef56] {
    position: fixed;
    right: 10px;
    bottom: 10px;
    margin-top: 780px;
    height: 100px;
    width: 200px;
}
.shoppingCartInfoContainer[data-v-79b1ef56] {
    width: 200px;
    height: 80px;
    border-radius: 15px;
    background-color: lightgray;
    cursor: pointer;
}
.shoppingCartInfoIconContainer[data-v-79b1ef56] {
    border-radius: 15px;
    width: 80px;
    height: 80px;
    background-color: #009fe3;
    position: relative;
    margin-top: -15px;
    padding-top: 20px;
    padding-left: 4px;
}
.shoppingCartInfoCounter[data-v-79b1ef56] {
    position: relative;
    z-index: 1;
    margin-left: 50px;
    border-radius: 40%;
    border: 2px solid white;
    width: 40px;
    height: 35px; 
    background-color: darkorange;
    padding-top: 3px;
    color: white;
    font-weight: bold;
    text-align: center;
}
.shoppingCartIcon[data-v-79b1ef56] {
    margin-top: -2px;
    width: 45px;
}
.shoppingCartInfoTotalPriceWrapper[data-v-79b1ef56] {
    position: relative;
    font-size: 20px;
    font-weight: bold;
    width: 110px;
    text-align: left;
    margin-top: -55px;
    margin-left: 85px;
    text-align: center;
}
.shoppingCartWrapper[data-v-79b1ef56] {
  display: block;
  background-color: red;
}


/*
@media(max-width: 767px) { 
    .shoppingCartInfo {
        width: 80px;
        right: 34px;
    }
    .shoppingCartInfoContainer {
        background-color: transparent;
        width: 80px;
    }
    .shoppingCartInfoTotalPriceWrapper {
        display: none;
    }
}


.shoppingCartWrapper {
    display: none;
}

@media(min-width: 768px) {
    .shoppingCartWrapper {
        display: block;
    }
}
*/
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
