<template>
    <div class="personalShop">
        <div class="headerPersonalShop">
            <GoBack></GoBack>
            <Flag></Flag>
        </div>
        <div class="contentWrapper"> 
            <div class="contentContainer">      
                <div class="title">{{translateString('General', 'PersoonlijkeOfActiecodeOntvangen')}}</div>
                <div class="vulDezeHieronderIn">{{translateString('PersonalShop', "VulDezeHierIn")}}</div>

                <div class="validationCodeWrapper">
                    <input class="inputValidationCode" maxLength="14" placeholder="" v-model="input"/>
                    <div class="btnValidateCode" v-on:click="validateCode()">
                        <img class="btnValidateCodeImage" :src="require(`@/assets/icons/Arrow closed shop.png`)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Selector} from '../language/language';
import {store} from '../store/index';
import GoBack from '@/components/GoBack.vue';
import Flag from '@/components/Flag.vue';

export default {
    data() {
      return {
        input: ''
      }
    },
    components: {
        GoBack,
        Flag
    }, 
    methods: {
        translateString(componentOrPageName, stringNotTranslated) {
            var translatedString = Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated)
            return translatedString;
        },
        validateCode() {
          store.dispatch('validateInvitationCode', this.input);
        }
    }
}
</script>

<style scoped>
.personalShop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255);
    overflow-y: scroll;
}

.headerPersonalShop {
    background-color: #005b8c;
    width: 100%;
    min-height: 350px; 
    position: relative;
    box-sizing: border-box;
    padding-top: 70px;
}

.contentWrapper {
    display: inline-block;
    vertical-align: middle;
    margin-top: -280px;
}

.contentContainer {
    margin-top: 60px;
    width:1000px;
    margin-bottom: 60px;
    margin-left: auto;
    margin-right: auto;
    min-height: 400px;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    box-shadow: 2px 2px 2px 2px grey;
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    position: relative;
    padding: 45px 45px 45px 45px;
    overflow-x: hidden;
}

.title {
    --max-lines: 2;
    overflow: hidden;
    font-weight: bold;
    font-size: 30px;  
    font-family: 'Nunito', sans-serif;
    color: rgb(65, 65, 65);
}

.vulDezeHieronderIn {
    font-size: 18px;
    margin-top: 25px;
    margin-bottom: 5px;
    color: rgb(65, 65, 65);
    font-family: 'Didact Gothic', sans-serif;  
}

.validationCodeWrapper {
    margin-top: 35px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.inputValidationCode {
    width: 9.5rem;
    height: 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    text-transform: uppercase; 
    background-color: rgb(211, 210, 210);
    color: rgb(48, 48, 48);
    border: none;
    text-align: center;
    padding: 5px 5px 5px 10px;
    outline: none;
}

.btnValidateCode {
    padding: 2px 1px 2px 1px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    height: 30px;
    min-width: 40px;
    text-align: center;
    background-color: #005b8c;
    border: 1px solid transparent;
    cursor: pointer;
    font-size: 14px;
}

.btnValidateCodeImage {
    height: 25px;
    min-width: 25px;
    vertical-align: middle;
}

@media(max-width: 1120px) {
    .contentContainer {
        width: 90%;
    }
}
</style>