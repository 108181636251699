// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.directOrderCheckout[data-v-27d723e3] {
  margin-top: 80px;
}
.home[data-v-27d723e3] {
  width:100%;
  height:100%;

  display: flex;
  flex-direction: column;

  position: relative;
  background-color: transparent;
}
.wrapper[data-v-27d723e3] {
  flex-shrink: 0;
  flex-grow: 1;
  z-index: 1;
}
.inner[data-v-27d723e3] {
  max-width:1200px;
  margin:auto;
  justify-items: center;
  align-items: center;
  position: relative;
}
Footer[data-v-27d723e3] {
  flex-shrink: 0;
}



`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
