<template>
    <footer class="site-footer" :style="{backgroundColor: backgroundColor}" v-if="state.isLoaded">
      <div class="container" :style="{color: textColor}">
        <div class="teksten">
          {{translateString('Footer', 'DeETicketsWordenVerwerktDoorTicketView', {accountName: state.status.accountName})}}.
           {{translateString('Footer', 'TicketViewIsNietAansprakelijk')}}.
           {{translateString('Footer', 'VerdereContactenLopenVia', {accountName: state.status.accountName})}}.
        </div>
        <div class="lijn" :style="{borderTopColor: textColor}"></div>
        <div class="bottom-links">
            
            <div class="algemene-voorwaarden-text">
                <a :style="{color:textColor}" href="https://www.ticketview.nl/mvc/assets/pdf/algemene_voorwaarden.pdf">{{translateString('Footer', 'AlgemeneVoorwaarden')}}</a>
            </div>
            <div class="linkDivider">|</div>
            <div class="disclaimer-text">
                <a :style="{color:textColor}" href="#">{{translateString('Footer', 'Disclaimer')}}</a>
            </div>
            <div class="linkDivider">|</div>
            <div class="copyright-text">&copy;
                <a :style="{color:textColor}" href="https://www.ticketview.nl/">TicketView</a> {{  year() }}
            </div>
            <img class="ticketViewLogo" src="../assets/TicketViewLogo.png" />               
        </div>
         <div style="clear:both;"></div>
    </div>
</footer>
</template>

<script>
import {Selector} from '../language/language';
import {store} from '../store/index';
export default {
  setup() {
    return {
      state: store.state
    }
  },
  data() {
    return {
      textColor: "white",
      textShadow: "",
      backgroundColor: "#005b8c"
    }
  },
  mounted() {
    this.backgroundColor = store.state.status.layoutBackgroundColor;
    this.textColor = store.state.status.layoutTextColor;
   // this.textShadow = store.state.status.layoutTextShadow;

  },
  methods: {
    // return translation of a string
    translateString(componentOrPageName, stringNotTranslated, ObjectParam) {
      var translatedString;
      if(ObjectParam != undefined) {
        translatedString = Selector.SelectLanguageStringFromLangJSONWithParams(componentOrPageName, stringNotTranslated, ObjectParam)
      } else {
        translatedString = Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated)
      }    
      return translatedString;
    },
    year() {
      return new Date().getFullYear();
    }
  }
}
</script>

<style scoped>

.site-footer
{
  background-color:#005b8c;
  width: 100%;
}

.container {
  font-size:16px;
  line-height:20px;
  color:white;
  font-family: 'Didact Gothic', sans-serif; 
  padding: 20px 20px;
  max-width:1200px;
  margin:auto;
  font-size:12px;
}

.lijn
{
  border-top: 1px solid white;
  margin-top: 10px;
  margin-bottom: 10px;
}


.site-footer a
{
  color:white;
  text-decoration: none;
}

 
.algemene-voorwaarden-text, .disclaimer-text, .copyright-text, .linkDivider
{
  padding-right:10px;
  float: left; 
}
.ticketViewLogo {
  height: 15px;
  float: right;
  margin-right:10px;
}

@media (max-width:767px)
{
  .ticketViewLogo {
    margin-top:10px;
  }

}
</style>