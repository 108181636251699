<template>
    <div class="TicketRemover">
        <div class="ticketTypeWrapper">
            <div class="ticketTypeNameAndEventNameWrapper">
                <div class="ticketTypeCalculatedQuantityAndEventName">{{ticketType.quantity}}x <a v-html="ticketType.eventName"></a></div>
                <div class="ticketTypeName" v-html="ticketType.ticketTypeName"></div>  
                <div class="ticketTypeTimeSlot" v-if="!isDateTimeEmpty(ticketType.timeSlot)">{{getDateAndTimeFormatted(ticketType.timeSlot, 1, true, true)}}</div>
                <div class="ticketTypeSeat" v-if="ticketType.seat != ''">{{getSeatFormatted(ticketType.seat)}}</div>          
            </div>  

            <div v-if="allowEditing" class="ticketPriceWrapper">
                <div class="ticketTypeCalculatedPrice">{{getMyCurrencyFormatted(ticketType.price * ticketType.quantity)}}</div>                  
            </div>   
            <div v-else class="ticketPriceWrapperWithoutRemover">
                <div class="ticketTypeCalculatedPrice">{{getMyCurrencyFormatted(ticketType.price * ticketType.quantity)}}</div>                  
            </div>   

            <div v-if="allowEditing" class="ticketRemoverContainer">
                <span class="remover" v-on:click="removeItem"></span>
            </div>                   
        </div>
    </div>
</template>

<script>
import {Functions} from '@/util/util.js';
import {store} from '@/store/index';
import { createNamespacedHelpers } from 'vuex';
export default {
    data() {
        return {
            optionsSelector: [],
            calculatedPrice: 0,
        }   
    },
    setup() {
        return {
            state: store.state
        }
    },
    props: {
        ticketType: {

        }
    },
    methods: {
        isDateTimeEmpty(dateTime) {
            return Functions.isDateTimeEmpty(dateTime);
        },
        getDateAndTimeFormatted(date, dateOption, returnDate, returnTime){
            if(dateOption == 0) {
                var lengthWeekday = "short";
                var lengthMonth = "long";
            }
            if(dateOption == 1 || dateOption == 2) {
                var lengthWeekday = "short";
                var lengthMonth = "short";
            }
            return Functions.formatDateTime(date, lengthWeekday, lengthMonth, returnDate, returnTime);
        },
        getSeatFormatted(seat) {
            let ret = "";
            let ar = seat.split("|");
            if (ar.length>=3) {
                ret = "(" + ar[0] + ', rij ' + ar[1] + ', stoel ' + ar[2] + ")";
            }
            return ret;
        },
        calculatePrice(price, quantity) {
            var calculatedPrice = price * quantity;
            return this.getMyCurrencyFormatted(calculatedPrice)
        },
        getMyCurrencyFormatted(price){
            return Functions.formatMyCurrency(price);
        },
        removeItem () {
            var price = this.ticketType.price
            // tickettype id
            var id = this.ticketType.ticketTypeID;
            
            // event id
            var eventID = this.ticketType.eventID

            // naam tickettype
            var ticketName = this.ticketType.ticketTypeName;

            // hoeveelheid tickets
            var quantity = this.ticketType.quantity;

            // prijs van een ticket
            var ticketPrice = this.ticketType.price / quantity;    
            
            // timeslot
            var timeSlot = this.ticketType.timeSlot

            var key = this.ticketType.key;

            // geef de nodige gevens terug aan de orderlist
            this.$emit('removeThisItem', {id, eventID, price, ticketName, ticketPrice, quantity, timeSlot, key});
        },
    },
    computed: {
        allowEditing() {
            var isEditingAllowed = true;
            if(store.state.step != 'bestellen' && store.state.step != "klantinformatie" && store.state.step != "changeoverzicht") {
                isEditingAllowed = false;
            }
            if (store.state.isDirectOrder) { // Afronden bestelling uit de boxoffice
                isEditingAllowed = false;
            }
            return isEditingAllowed;
        }
    }
}
</script>

<style scoped>
.TicketRemover {
    width: 100%;
}
.ticketTypeWrapper {
    display: flex;
}
.ticketTypeNameAndEventNameWrapper {
    width: 90%;
}
.ticketTypeCalculatedQuantityAndEventName {
    font-size: 16px;
    font-weight: bold;
    margin-right: 2px;
}

.ticketTypeName{
    font-size: 16px;
    margin-right: 2px;
}
.ticketTypeTimeSlot {
    font-size: 16px;
    margin-right: 2px;
    text-transform: capitalize;
}
.ticketTypeSeat {
    font-size: 12px;
    font-style: italic;
    margin-right: 2px;
    text-transform: capitalize;
}
.ticketTypeCalculatedPrice {
    font-size: 16px;  
}
/*.ticketTypeCalculatedPrice + span {
  padding-right: 0px;
}*/
span:after {
  position: relative;
  content: '✖';
  color: red;
  cursor: pointer;
}

.ticketRemoverContainer {  
    text-align: right;
    padding-top: 1px;
}
.ticketPriceWrapper {
    text-align: right;
    width: 100px;
    margin-right: 10px;
}

.ticketPriceWrapperWithoutRemover {
    text-align: right;
    width: 120px;
    margin-right: 0px;
}
</style>