<script setup>

  import {ref, onMounted, reactive, computed, nextTick, watch} from 'vue';
  import {Selector} from "@/language/language.js";
  import {store} from "@/store/index.js";

  const backgroundColor = ref('#005b8c');
  const backgroundImage = ref('https://storage.googleapis.com/ticketview/upload/3/account-header/14490.jpeg');
  const backgroundImageDarken = ref(0.5);
  const backgroundImageBlur = ref(5);
  //const backgroundImage = ref('');

  // When the component is mounted, set the visibility to true
  onMounted(() => {
    backgroundColor.value = store.state.status.layoutBackgroundColor;
    backgroundImage.value = store.state.status.layoutBackgroundImage;
    if (backgroundImage.value === "") {
      backgroundImageBlur.value = 0;
      backgroundImageDarken.value = 0;
    } else {
      backgroundImageBlur.value = store.state.status.layoutBackgroundImageBlur;
      backgroundImageDarken.value = store.state.status.layoutBackgroundImageDarken;
    }
  });

  const divStyle = computed(() => ({
    '--background-image': `url(${backgroundImage.value})`,
    '--background-darken': backgroundImageDarken.value,
    '--background-blur': `${backgroundImageBlur.value}px`,
    backgroundColor: backgroundColor.value,
    backgroundImage: `url(${backgroundImage.value})`
  }));

</script>

<template>
  <div class="header-background" :style="divStyle" :class="pseudoClass" />
</template>

<style scoped>

.header-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 0;

  background-image: var(--background-image);
}


.header-background::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Donkere overlay */
  backdrop-filter: blur(10px); /* Blur effect */

  background: rgba(0, 0, 0, var(--background-darken));
  backdrop-filter: blur(var(--background-blur));
  -webkit-backdrop-filter: blur(var(--background-blur));
}



</style>