<template>
  <div v-if="showConsent" id="cookieConsentContainer">
    <strong>Cookies "{{ accountName }}"</strong><br />
    <small v-html="translateString('Cookie','cookieMessage')" /><br />
    <br />
    <button class="btn btnGreen" @click="acceptCookies" style="margin-left: 10px; color:black;" v-html="translateString('Cookie','cookieButtonOK')"></button>
    <button class="btn btnOrange" @click="declineCookies" style="margin-left: 10px; color:black;" v-html="translateString('Cookie','cookieButtonNOK')"></button>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import {Selector} from '@/language/language.js';

export default {
  name: 'CookieMessage',
  props: {
    accountName: {
      type: String,
      default: 'JouwAccountNaam'
    },
    id: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const showConsent = ref(false);
    const cookieKey = `TicketViewCookie`;

    const checkCookieConsent = () => {
      const consent = localStorage.getItem(cookieKey);
      if (!consent) {
        showConsent.value = true;
      }
    };

    const translateString = (componentOrPageName, stringNotTranslated) => {
      return Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated);
    };


    const acceptCookies = () => {
      localStorage.setItem(cookieKey, 'accepted');
      showConsent.value = false;
      // refresh page
      location.reload();
    };

    const declineCookies = () => {
      localStorage.setItem(cookieKey, 'declined');
      showConsent.value = false;
    };

    onMounted(() => {
      checkCookieConsent();
    });

    return {
      showConsent,
      acceptCookies,
      declineCookies,
      translateString,
      accountName: props.accountName
    };
  }
};
</script>

<style scoped>
/* Voeg hier eventueel extra CSS toe */
  #cookieConsentContainer {
    position: relative;
    z-index:9999;
    top: 0;
    width: 100%;
    background-color: #2c2c2c;
    color:white;
    padding: 30px;
    text-align: center;
  }
</style>