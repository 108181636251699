<template>
  <div class="code-input-container">
    <div class="code-inputs">
      <input
          v-for="(char, index) in codeArray"
          :key="index"
          v-model="codeArray[index]"
          @input="onInput(index)"
          @keydown.backspace="onBackspace(index)"
          @paste="onPaste"
          :maxlength="1"
          class="code-input"
          :disabled="isBusy"
          :ref="el => inputRefs[index] = el"
          type="text"
          pattern="[A-Z0-9]"
          style="text-transform: uppercase;"
      />
    </div>
    <div v-if="isBusy" class="busy-indicator">{{ translateString("General","Controleren code ...") }}</div>
    <div v-if="isBusy" class="spinner"><img src="@/assets/gifs/spinner.gif"></div>
    <button v-show="!isBusy" @click="verifyCode" :disabled="!isComplete"> {{ translateString("General","Verfieer code") }}</button>
  </div>
</template>

<script>
import { ref, reactive, computed, nextTick, onMounted, watch } from 'vue';
import {Selector} from '@/language/language';
import {store} from "@/store/index.js";




export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true
    }
  },
  emits: ['verified'],
  setup(props,{ emit }) {
    const codeArray = reactive(Array(10).fill(''));
    const isBusy = ref(false);
    const inputRefs = ref([]);

    const translateString = (componentOrPageName, stringNotTranslated) => {
      return Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated);
    };

    const isComplete = computed(() => {
      return codeArray.every(char => char !== '');
    });

    const onInput = async (index) => {
      codeArray[index] = codeArray[index].toUpperCase().replace(/[^A-Z0-9]/g, '');
      if (index < 9 && codeArray[index] !== '') {
        await nextTick();
        if (inputRefs.value[index + 1]) {
          inputRefs.value[index + 1].focus();
        }
      } else if (index === 9 && isComplete.value) {
        isBusy.value = true;
        verifyCode();
      }
    };

    const onBackspace = async (index) => {
      if (index > 0 && codeArray[index] === '') {
        await nextTick();
        if (inputRefs.value[index - 1]) {
          inputRefs.value[index - 1].focus();
        }
      }
    };

    const onPaste = async (event) => {
      event.preventDefault();
      const pasteData = event.clipboardData.getData('Text').toUpperCase().replace(/[^A-Z0-9]/g, '');
      for (let i = 0; i < 10 && i < pasteData.length; i++) {
        codeArray[i] = pasteData[i];
      }
      if (pasteData.length >= 10) {
        await nextTick();
        isBusy.value = true;
        verifyCode();
      }
    };

    const verifyCode = () => {
      isBusy.value = true;


      store.dispatch('initShop').then(response => {

        if (response != "OK") {

        }
      });



      // Simuleer server call
      setTimeout(() => {
        isBusy.value = false;
        alert('Code verified: ' + codeArray.join(''));
        emit('verified', codeArray.join(''));
      }, 2000);
    };

    onMounted(() => {
      inputRefs.value = new Array(10).fill(null);
    });

    const setFocusOnFirstInput = async () => {
      await nextTick();
      if (inputRefs.value[0]) {
        inputRefs.value[0].focus();
      }
    };

    watch(() => props.isVisible, (newVal) => {
      if (newVal) {
        setFocusOnFirstInput();
      }
    });

    return {
      codeArray,
      isBusy,
      isComplete,
      onInput,
      onBackspace,
      verifyCode,
      inputRefs,
      onPaste,
      translateString
    };
  },
};
</script>

<style scoped>
.code-input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.code-inputs {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 10px;
}

.code-input {
  width: 35px;
  height: 45px;
  text-align: center;
  font-size: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.busy-indicator {
  margin-bottom: 10px;
  color: red;
  font-weight: bold
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.spinner {
  position: absolute;
  top: -10PX;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 10px 20px 10px 20px;
  border-radius: 12px;
  background-color: white;
  border: #aaa solid 1px;
}

/* Mediaquery voor mobiele telefoons en portatrait tablets */
@media only screen and (max-width: 768px) {
  .code-input {
    width: 22px;
    height: 30px;
    font-size: 12px;
  }
}

</style>