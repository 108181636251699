// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.popUpContainer-mask[data-v-93942450] {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    z-index: 2;
    overflow-y: scroll;
}
.popUpContainer-wrapper[data-v-93942450] {
    display: flex;
    align-items: center; 
    justify-content: center;
    margin-top: 45px;        
    padding:0px 20px 0px 20px;
}
.checkoutWrap[data-v-93942450] {
    width:90%;
    max-width:1100px;
}
@media (max-width: 767px) {
.checkoutWrap[data-v-93942450] {
        width:100%;
}
.popUpContainer-wrapper[data-v-93942450] {
        padding:0px 5px 0px 5px;
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
