// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;base64,PHN2ZyBmaWxsPSdibGFjaycgaGVpZ2h0PScyNCcgdmlld0JveD0nMCAwIDI0IDI0JyB3aWR0aD0nMjQnIHhtbG5zPSdodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2Zyc+PHBhdGggZD0nTTcgMTBsNSA1IDUtNXonLz48cGF0aCBkPSdNMCAwaDI0djI0SDB6JyBmaWxsPSdub25lJy8+PC9zdmc+", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.ticketTypeWrapper[data-v-1b2c572a] {
    display: flex;
    flex-direction: row;
}
.ticketTypeNameAndPriceWrapper[data-v-1b2c572a] {
    flex: auto;
}
.ticketTypeName[data-v-1b2c572a] {
    font-size: 16px;
    --max-lines: 2;
    font-weight: bold;
    word-wrap: break-word;
}
.ticketUitverkocht[data-v-1b2c572a] {
    text-decoration: line-through;
}
.labelUitverkocht[data-v-1b2c572a] {
    text-transform: uppercase;
    color: red;
}
.ticketTypePrice[data-v-1b2c572a] {
    font-size: 16px;
    white-space: nowrap;
}
.ticketSelectorContainer[data-v-1b2c572a] {  
    width: 150px;
    min-width: 150px;
}
.ticketSelectorWrapper[data-v-1b2c572a] {
    height: 100%;
    padding: 10px 0 10px 20px;
}

/*.selector {
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  padding: 2px;
}*/
.selector[data-v-1b2c572a] {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.25);
    position: relative;
    border-radius: 4px;

    background: transparent;
    outline: none;
    color: rgb(46, 46, 46);
    height: 1.5rem;

    font-size: inherit;
   
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    background-repeat: no-repeat;
    background-position-x: 21px;
    background-position-y: 0;
    padding: 0 0 0 8px;
    width: 44px;
}
.selector[data-v-1b2c572a]:disabled {
    background: rgb(220,220,220)
}

/*hide the items when the select box is closed:*/
.selector.select-hide[data-v-1b2c572a] {
  display: none;
}
.selector.select-items div[data-v-1b2c572a]:hover, .same-as-selected[data-v-1b2c572a] {
  background-color: rgba(0, 0, 0, 0.1);
}
.ticketTypeCalculatedPrice[data-v-1b2c572a] {
    font-size: 16px;
    float: right;
}
.ticketTypeCalculatedPriceSoldOut[data-v-1b2c572a] {
    font-size: 16px;
    float: right;
    color: grey;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
