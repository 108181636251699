// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.checkout[data-v-4a10eca6] {
    width: 100%;
    overflow-x:hidden;
    margin-bottom:50px;
    padding-bottom: 20px;
    min-height: 400px;
    font-size: 15px;
    background-color:white;
    border-radius: 10px;
    /* box-shadow: 2px 2px 2px 2px grey; */
    box-shadow: rgba(0, 0, 0, 0.4) 0px 4px 12px;
    font-family: 'Didact Gothic', sans-serif;
}
.clientPage[data-v-4a10eca6] {
  box-shadow: none!important;
  border:1px solid darkgray;
}
.checkOutColumns[data-v-4a10eca6] {
    width:100%;
    display:flex;
    flex-direction: row;
}
.stappenPaginas[data-v-4a10eca6] {
    flex-grow: 1;
}
.shoppingCart[data-v-4a10eca6] {
    flex-basis: 300px;
}
.bestelContainer[data-v-4a10eca6], .customerFormWrap[data-v-4a10eca6], .paymentForm[data-v-4a10eca6], .terms[data-v-4a10eca6], .personaliseren[data-v-4a10eca6] {
    width:100%;
    border-top-left-radius: 10px;
}
.customerFormDirectOrderMessage[data-v-4a10eca6] {
  background-color: whitesmoke;
  padding: 20px 10px 10px 10px;
  border-bottom: dashed 1px lightgray;
}
.customerFormDirectOrderMessage span[data-v-4a10eca6]:nth-child(1) {
  font-size: 16px;
  font-weight: bold;
  display: block;
}
.customerFormDirectOrderMessage span[data-v-4a10eca6]:nth-child(2) {
  font-size: 13px;
  color: red;
  font-weight: bold;
  text-transform: uppercase;
}
@media (max-width: 767px) {
#shoppingCart[data-v-4a10eca6] {
        display: none;
}
}


`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
