<template>
    <div class="Donation">
        <div class="donationWrapper">
            <Alert :title="alertTitle" :text="alertText" :alertType="alertType" :active="active" :time="alertTime" :okButtonActive="okButtonActive" @closeAlert="closeAlert"></Alert>
            <div class="donationNameAndTextWrapper">
                <div class="donationName">{{translateString('Checkout', 'Donation')}} <a class="fromDonateMinLabel" v-if="state.selectedEvent.accPaymentDonateMin > 0"> ({{translateString('Checkout','DonationMinimum',{"amount":getMyCurrencyFormatted(state.selectedEvent.accPaymentDonateMin)})}})</a></div>
                <div class="donationText" >{{SelectLanguageStringFromArray(state.selectedEvent.accPaymentDonateText.items)}}</div>
            </div>
            <div>€</div>
            <div v-if="allowEditing">
                <input v-model="donationPrice" type="text" @change="checkDonation" name="donationInput" step="any" :placeholder="0" required>
            </div>
            <div v-else>
                <input class="disabledInput" v-model="donationPrice" name="donationInput" disabled>
            </div>

            <div v-if="allowEditing" class="removerContainer">
                <span v-on:click="resetDonationInput" class="remover"></span>
            </div>                            
        </div>
    </div>
</template>

<script>
import {Functions} from '@/util/util.js';
import {Selector} from '@/language/language.js';
import {store} from '@/store/index';
import Alert from '@/components/Alert';
export default {
    data() {
        return {
            donationPrice: 0,
            alertTitle: '',
            alertText: '',
            alertType: '',
            active: false,
            alertTime: null,
            okButtonActive: 0, 
        }   
    },
    mounted() {
      Functions.validateSelectedEvent();
      this.setDonationIfPresentInCart();
    },
  components: {
        Alert
    },
    methods: {
        SelectLanguageStringFromArray(text) {
            return Selector.SelectLanguageStringFromArray(text);
        },
        setDonationIfPresentInCart() {
            if(store.state.cart.donation != '0') {
                var donation = this.getMyCurrencyFormatted(store.state.cart.donation)
                this.donationPrice = donation.replace('€', '').trim();
            }     
        },
        getTranslationFromArray(arrayWithTransations) {
            var stringTranslation = Selector.SelectLanguageStringFromLangJSON(arrayWithTransations)
            return stringTranslation;
        },
        calculatePrice(price, quantity) {
            var calculatedPrice = price * quantity;
            return this.getMyCurrencyFormatted(calculatedPrice)
        },
        getMyCurrencyFormatted(price){
            return Functions.formatMyCurrency(price);
        },
        translateString(componentOrPageName, stringNotTranslated, ObjectParam) {
            var translatedString;
            if(ObjectParam != undefined) {
                translatedString = Selector.SelectLanguageStringFromLangJSONWithParams(componentOrPageName, stringNotTranslated, ObjectParam)
            } else {
                translatedString = Selector.SelectLanguageStringFromLangJSON(componentOrPageName, stringNotTranslated)
            }    
            return translatedString;
        },
        resetDonationInput() {
            this.donationPrice = "0";

            // set alert        
            this.alertType = '';
            this.active = true;
            this.alertText = this.translateString('Alert', 'DonatieVerwijderen')

            store.dispatch('delCart', {accountId: 0, key: 0, donation: 1}).then(response => {
                  if (response.status === 'OK') {
                    this.alertTitle = this.translateString('Alert', 'Succesvol');
                    this.alertText = this.translateString('Alert', 'DonatieVerwijderd')
                    this.alertType = 1;
                    this.alertTime = 1000;
                  } else {
                    this.alertTitle = this.translateString('Alert', 'Oeps');
                    this.alertText = response.message;
                    this.alertType = 2; // Error type
                    this.alertTime = null;
                    this.okButtonActive = 1;
                  }
            })
        },
        addDonationToCart() {
            var donation = parseFloat(this.donationPrice.replace(',', '.'));

            // Opzoeken in cart of welke events er al in zitten om te bepalen welk account actief is (ivm verzamelsites)
            var accountID = 0;
            if(store.state.cart.bestelItems.length > 0) {
                accountID = store.state.cart.bestelItems[0].accountId;
            }


            // set alert
            this.alertType = '';
            this.active = true;
            this.alertText = this.translateString('Alert', 'DonatieToevoegen')

            store.dispatch('addCart', {accountId: accountID, eventID: 0, ticketTypeID: 0, timeSlot: 0, price: 0, quantity: 0, donation: donation, seat:{} }).then(response => {
                if (response.status === 'OK') {
                  this.alertTitle = this.translateString('Alert', 'Succesvol');
                  this.alertText = this.translateString('Alert', 'DonatieToegevoegd')
                  this.alertType = 1;
                  this.alertTime = 1000;

                  this.donationPrice = this.getMyCurrencyFormatted(donation).replace('€', '').trim();


                } else {
                  this.alertTitle = this.translateString('Alert', 'Oeps');
                  this.alertText = response.message;
                  this.alertType = 2; // Error type
                  this.alertTime = null;
                  this.okButtonActive = 1;
                }
            })
        },
        checkDonation() {

            // Valideren of er wle een selected event is (in geval van een passepartout)
          if (this.state.selectedEvent == null && this.state.cart.bestelItems.length > 0) {
            this.state.selectedEvent = this.state.cart.bestelItems[0];
          }

            if(this.donationPrice == '') {
                this.donationPrice = "0";
                this.$emit('donation', this.donationPrice)
            } else if(this.state.selectedEvent.accPaymentDonateMin != 0) {
                var donation = this.donationPrice.replace(',', '.')
                if(0 < parseFloat(donation)) {
                    if( parseFloat(donation) < parseFloat(this.state.selectedEvent.accPaymentDonateMin)) {
                        // zet alert op het scherm
                        this.alertTitle = this.translateString('Alert', 'Oeps');
                        this.alertText = this.translateString('Alert', 'DeOrganisatieHeeftEenMinimumIngesteld', {accPaymentDonateMin: this.getMyCurrencyFormatted(this.state.selectedEvent.accPaymentDonateMin)});
                        this.alertType = 2;
                        this.okButtonActive = 1;
                        this.active = true;

                        // zet de minimale donatieprijs
                        // this.donationPrice = this.getMyCurrencyFormatted(this.state.selectedEvent.accPaymentDonateMin);
                        //this.$emit('donation', "1,00");
                        var orgDonation = this.state.cart.donation;
                        if (orgDonation==0) {
                          this.donationPrice = 0;
                        } else {
                          this.donationPrice = this.getMyCurrencyFormatted(orgDonation);
                        }
                    }
                    else if (parseFloat(donation) > 99000000000000) {
                        this.donationPrice = '99000000000000';
                        //this.$emit('donation', this.donationPrice)
                        this.addDonationToCart();
                    }
                    else {
                        //this.$emit('donation', this.donationPrice)
                        this.addDonationToCart();
                    }         
                }
                else if(donation == 0) {
                    //this.$emit('donation', this.donationPrice)
                    this.resetDonationInput();
                } 
                else {
                    this.donationPrice = "0";
                    //this.$emit('donation', this.donationPrice)
                    this.resetDonationInput();
                }    
            }
            else {
                //this.$emit('donation', this.donationPrice)
                this.addDonationToCart();
            }
        },
        closeAlert(close) {
            this.active = close;
            this.alertTitle = ''
            this.alertText = ''
            this.alertType = ''
            this.alertTime = null
            this.answer = null
            this.okButtonActive = 0;
        },
    },
    setup() {
        return {
            state: store.state
        }
    },
    computed: {
        allowEditing() {
            var isEditingAllowed = true;
            if(store.state.step != 'bestellen' && store.state.step != "klantinformatie" && store.state.step != "changeoverzicht") {
                isEditingAllowed = false;
            }

            //document.getElementById('donationInput').disabled=!isEditingAllowed
 
            return isEditingAllowed;
        }
    }
}
</script>

<style scoped>
.Donation {
    position: relative;
    color: grey;
    font-size: 12px;
}
.donationWrapper {
    display: flex;
}
.donationName {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}
.fromDonateMinLabel {
    font-size: 12px;
    text-transform: lowercase;
    font-weight: normal;
}
.donationNameAndTextWrapper {
    width: 90%;
}
input {
    width: 90px;
  height: 20px;
  font-size: 14px;
  transition: 0.6s;
  border: none;
  border-bottom: 1px solid #CCC;
  background-color: transparent;
  text-align: right;
  margin-right: 10px;
}
input:focus {
    outline: none;
    border-bottom: 1px solid #28a2a2;
}

.disabledInput {
    margin-right: 0px;
}

.remover:after {
  position: relative;
  content: '✖';
  color: red;
  cursor: pointer;
}
.removerContainer {  
    text-align: right;
    padding-top: 2px;
}
.donationText {
  font-style: italic;
  font-weight: bold;
}
</style>