<template>
    <div @click.stop id="flags">
        <img id="default-flag" class="default-flag" :src="require(`../assets/flags/${currentlySetLanguage}.png`)" v-on:click="openClose"/>
        <div class="other-flags" :class="{'other-flags-active': areOtherFlagsActive}">
            <div v-for="(lang, index) in languages" :key="index" ref="otherflags">
                <div class="other-flag-wrapper">
                    <div class="other-flag"  v-on:click="changeLanguage($event, index)">
                        <span>{{ lang.name }}</span>
                        <img :dataid="lang.code" :ref="setFlagRef" :src="require(`@/assets/flags/${lang.fileName}`)" alt="">                    
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import {store} from '../store/index';
export default {
    data() {
        return {
            currentlySetLanguage: localStorage.getItem("localeLanguage"),
            languages: store.state.languages,
            areOtherFlagsActive: false,
            flagRefs: []
        }
    },
    setup() {
        return {
            state: store.state
        }
    },
    methods: {
        openClose: function() {
            if(this.areOtherFlagsActive == false) {
                this.areOtherFlagsActive = true;
            } else {
                this.areOtherFlagsActive = false;
            }
        },
        setFlagRef(el) {
            if(el) {
                this.flagRefs.push(el)
            }
        },
        beforeUpdate() {
            this.flagRefs = [];
        },
        updated() {
        },
        changeLanguage: function(event, index) {
            // taal + afbeelding ophalen
            /*var imgObject = $(event.currentTarget).find('img');            
            var newLanguageImage = $(imgObject).attr('src');
            var newLanguageSet = $(imgObject).attr('dataid');*/

            // ref probeersel
            var imgObject = this.flagRefs[index];
            var newLanguageImage = imgObject.attributes.src.value;
            var newLanguageSet = imgObject.attributes.dataid.value;

            // als het ooit niet lukt om de taal op te halen, dan engels
            if(newLanguageSet == null || typeof newLanguageSet == 'undefined') {
                newLanguageSet = "en-EN";
            }

            // nieuwe taal opslaan in store      
            store.dispatch('registerLocaleLanguage', newLanguageSet);

            // nieuwe taal opslaan in localstorage
            localStorage.setItem("localeLanguage", newLanguageSet);

            // Nieuwe afbeelding zetten
            $('.default-flag').attr('src', newLanguageImage);

            // open / close div with other flags
            this.openClose();
        }
    }
}
</script>

<style scoped>
#flags {
    position: absolute;
    right: 25px;
    top: 25px;
}

#default-flag {
    position: relative;
    float: right;
    width: 40px;
    cursor: pointer;
    border-radius: 50px;
    background-color: white;
    border: 2px solid white;  
}

.other-flags {
    margin-right:-48px;
    margin-top:50px;
    position:relative;
    display: none;
    cursor: pointer;
    font-size: 12px; 
    text-align: center;
    font-weight: bold;
    background-color: rgb(255, 255, 255);
    width: 140px;
    border: solid 1px rgb(221, 221, 221);
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    overflow: hidden;
    z-index:1;
}
.other-flags-active {
    display: block;
}

.other-flag {
    padding: 8px;
    box-sizing: border-box;
    vertical-align: middle;
    height:57px; 
    /* background-color: red; */
    clear: both;
}
.other-flag:hover {
    background-color: rgb(174, 213, 252);
}
.other-flag span {
    float:left;
    padding-top:14px;
}

.other-flag img {
    width: 40px;
    float:right;
}
</style>