// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
template[data-v-14000ce2] {
    font-size: 24px;
    font-family: Arial;
}
.alert[data-v-14000ce2] {
    background: red 10%;
    padding: 20px;
    width: 100%;
    font-weight: bold;
    color: white;
}
.alertTable[data-v-14000ce2] {
    margin-left: auto;
    margin-right: auto;
    table-layout: auto;
    border-collapse:separate; 
    border-spacing: 0 5px;
}
.alertIcon[data-v-14000ce2] {
    padding: 20px 30px 20px 20px;
    font-size: 40px;
}
.alert_body[data-v-14000ce2] {
    text-align: center;
}
.alert_close[data-v-14000ce2] {
    cursor: pointer;
    padding: 20px 20px 20px 30px;
    font-size: 20px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
