// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
template[data-v-5af6dd0a] {
    font-size: 24px;
    font-family: Arial;
}
.warn[data-v-5af6dd0a] {
    background: #fdbe25;
    padding: 20px;  
    width: 100%;
    font-weight: bold;
    color: rgb(54, 54, 54);
}
.warnTable[data-v-5af6dd0a] {
    margin-left: auto;
    margin-right: auto;
    table-layout: auto;
    border-collapse:separate; 
    border-spacing: 0 5px;
}
.warnIcon[data-v-5af6dd0a] {
    padding: 10px 30px 0px 0px;
}
.imgWarningGear[data-v-5af6dd0a] {
    height: 40px;
    width: 40px;
}
.warn_body[data-v-5af6dd0a] {
    text-align: center;
}
.warn_close[data-v-5af6dd0a] {
    cursor: pointer;
    padding: 20px 20px 20px 30px;
    font-size: 20px;
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
