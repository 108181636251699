// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header-background[data-v-39ecca4b] {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: 0;

  background-image: var(--background-image);
}
.header-background[data-v-39ecca4b]::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Donkere overlay */
  -webkit-backdrop-filter: blur(10px);
          backdrop-filter: blur(10px); /* Blur effect */

  background: rgba(0, 0, 0, var(--background-darken));
  backdrop-filter: blur(var(--background-blur));
  -webkit-backdrop-filter: blur(var(--background-blur));
}



`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
