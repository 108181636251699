// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
img.sharer[data-v-5f36d6d7] {
    position:relative;
    cursor: pointer;
    margin-right: 2px;
    width: 30px
}
img.sharer[data-v-5f36d6d7]:hover {
    opacity:0.75;
}
@media(max-width: 450px) {
img.sharer[data-v-5f36d6d7] {
        width: 30px
}
}
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
